import React, { useEffect, useState, useMemo, useRef } from "react";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import BizClass from "../Menu_Management/MenuManagement.module.scss";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import { addMenuOrSubMenu, getMenuList, getMenuType } from "../Menu_Management/Resources/MenuMangementService";
import { getModuleMaster } from "../Module/Resources/moduleService";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { SearchTextLength } from "../../../Configration/Utilities/Constants";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import { ImBin, ImEye } from "react-icons/im";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import { HiArrowCircleRight, HiPlusCircle } from "react-icons/hi";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import classNames from "classnames";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { getRightsList } from "./Resources/AccessRightsService";
import AddRights from "./Views/AddRights/AddRights";

function AccessRights() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });
  const [moduleMasterData, setModuleMasterData] = useState([]);
  const [filterModuleMasterData, setFilterModuleMasterData] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingModuleMasterData] = useState(false);

  const [selectedModule, setSelectedModule] = useState({});
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const getModuleMasterData = async () => {
    try {
      setIsLoadingModuleMasterData(true);
      let result = await getModuleMaster();
      setIsLoadingModuleMasterData(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setModuleMasterData(result.responseData);
          setFilterModuleMasterData(result.responseData);
        } else {
          setModuleMasterData([]);
          setFilterModuleMasterData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const [isLoadingRightsList, setIsLoadingRightsList] = useState(false);
  const [rightsListData, setRightsListData] = useState([]);
  const getRightsListDataList = async (moduleCode) => {
    debugger;
    try {
      setIsLoadingRightsList(true);
      let result = await getRightsList("MODULE", 0, moduleCode, 0);
      console.log(result, "result");
      setIsLoadingRightsList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setRightsListData(result.responseData);
        } else {
          setRightsListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      console.log(error, "error");
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const updateRightList = (addedData) => {
    rightsListData.unshift(addedData);
    setRightsListData(rightsListData);
    if (gridApi) {
      gridApi.setRowData(rightsListData);
    }
  };

  const onDisplayClick = (moduleData) => {
    debugger;
    getRightsListDataList(moduleData.ModuleCode);
    setSelectedModule(moduleData);
  };

  const onRefreshClick = () => {
    setRightsListData([]);
    getModuleMasterData();
  };

  const [AddRightsMasterformPopupActive, setRightsMasterAddformPopupActive] = useState(false);
  const toggleAddRightsMasterformPopup = () => {
    if (selectedModule && selectedModule.ModuleCode) {
      setRightsMasterAddformPopupActive(!AddRightsMasterformPopupActive);
    } else {
      setMsgAlert({
        open: true,
        type: "warning",
        msg: "please select Module",
      });
    }
  };

  const [moduleMasterGridApi, setModuleMasterGridApi] = useState();
  const onModuleMasterGridReady = (params) => {
    setModuleMasterGridApi(params.api);
  };

  const [searchTextModuleMaster, setSearchTextModuleMaster] = useState("");
  const onSearchMasterCommonCode = (val) => {
    setSearchTextModuleMaster(val);
    moduleMasterGridApi.setQuickFilter(val);
    moduleMasterGridApi.refreshCells();
    setModuleMasterData([]);
  };

  const onClickSearchModuleMaster = () => {
    getModuleMasterData();
  };

  useEffect(() => {
    getModuleMasterData();
  }, []);

  const [searchTextMenuList, setSearchTextMenuList] = useState("");
  const onSearchMasterDataFile = (val) => {
    setSearchTextMenuList(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    }
    return { background: "" };
  };

  const moduleGridColumnDefs = [
    { field: "#", headerName: "Action", width: 80, pinned: "left", lockPosition: "1", cellRenderer: "actionTemplate", cellRendererParams: { onDisplayClick } },
    {
      field: "ModuleCode",
      headerName: "Module Code",
      width: 180,
      pinned: "left",
      lockPosition: "1",
    },
    {
      field: "ModuleName",
      headerName: "Module Name",
      width: 180,
      pinned: "left",
    },
  ];

  const setupRightListColumnDefs = [
    { field: "#", headerName: "Sr No.", width: 70, valueGetter: "node.rowIndex + 1" },
    {
      field: "MenuName",
      headerName: "Menu Name",
      width: 180,
    },
    {
      field: "RightName",

      headerName: "Right Name",
      width: 180,
    },
    {
      field: "RightCode",
      headerName: "Right Code",
      width: 100,
    },
    {
      field: "RightAPIURL",
      headerName: "API URL",
      width: 240,
    },

    {
      field: "ApplyToAdmin",
      headerName: "Admin Status",
      width: 115,
    },
    {
      field: "SPName",
      headerName: "SP Name",
      width: 200,
    },
  ];

  const onClickSearchBar = (data) => {
    if (data) {
      onDisplayClick(data);
    } else {
      setMsgAlert({ open: true, type: "warning", msg: "Select Module" });
    }
  };

  return (
    <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {AddRightsMasterformPopupActive ? (
        <AddRights
          toggleAddRightsMasterformPopup={toggleAddRightsMasterformPopup}
          moduleData={selectedModule}
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
          updateRightList={updateRightList}
        />
      ) : null}
      <div className={BizClass.Biz_Bm_Manage_Menu_ContentBox}>
        <Splitter varient="column" template="1fr 9px 3fr">
          <div className={BizClass.MainSection}>
            <PageBar title="">
              <PageBar.Search focus={true} onClick={() => onRefreshClick()} value={searchTextModuleMaster} onChange={(e) => onSearchMasterCommonCode(e.target.value)} />
              {/* <PageBar.Button onClick={() => onRefreshClick()}>Reload</PageBar.Button> */}
            </PageBar>

            <DataGrid
              rowData={filterModuleMasterData}
              columnDefs={moduleGridColumnDefs}
              onGridReady={onModuleMasterGridReady}
              animateRows={true}
              loader={isLoadingModuleMasterData ? <Loader /> : null}
              getRowStyle={getRowStyle}
              frameworkComponents={{
                actionTemplate: cellTemplate,
              }}
            />
          </div>
          <div className={BizClass.MainSection}>
            <PageBar title={` ${selectedModule && selectedModule.ModuleName ? `(${selectedModule.ModuleName}) Rights List` : "Menu List"}`}>
              <PageBar.Search value={searchTextMenuList} onClick={() => onClickSearchBar(selectedModule)} onChange={(e) => onSearchMasterDataFile(e.target.value)} />
              <PageBar.Button onClick={() => toggleAddRightsMasterformPopup()}>Add</PageBar.Button>
            </PageBar>
            <DataGrid
              rowData={rightsListData}
              loader={isLoadingRightsList ? <Loader /> : null}
              onGridReady={onGridReady}
              getRowStyle={getRowStyle}
              columnDefs={setupRightListColumnDefs}
            />
          </div>
        </Splitter>
        <Footer />
      </div>
    </div>
  );
}

export default AccessRights;

const cellTemplate = (props) => {
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View List"
      >
        <ImEye style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => props.onDisplayClick(props.data)} />
      </span>
    </div>
  );
};
