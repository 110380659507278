/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from "react";
import { createCustomer } from "../../../Resources/customerRegistrationService";
import { getCommonValue, getCommonMasterData } from "../../../Resources/commonService";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import moment from "moment";

const AddCustomerPopup = (props) => {
  let toggleCustomerAddPopup = props.toggleCustomerAddPopup;
  let setMsgAlert = props.setMsgAlert;
  let updateCustomerList = props.updateCustomerList;
  let updateSelectedCustomerList = props.updateSelectedCustomerList;
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [industryTypeList, setIndustryTypeList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtCustomerName: "",
    txtCountry: null,
    txtIndustryType: null,
    txtCurrency: null,
    txtLanguage: null,
    txtRegistrationDate: "",
  });

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
    console.log(btnloaderActive, "btnloaderActive");
  }, []);

  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const getCountryList = async () => {
    try {
      setIsLoadingCountry(true);
      let result = await getCommonMasterData("GETCOUNTRYLIST");
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCountryList(result.responseData);
          setIsLoadingCountry(false);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(isLoadingCurrency, isLoadingIndustryType, isLoadingLanguage);
    }
  };
  const [isLoadingCurrency, setIsLoadingCurrency] = useState(false);
  const getCurrencyList = async () => {
    try {
      setIsLoadingCurrency(true);
      let result = await getCommonValue(123);
      console.log("CurrencyList", result);
      setIsLoadingCurrency(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCurrencyList(result.responseData);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  const [isLoadingIndustryType, setIsLoadingIndustryType] = useState(false);
  const getIndustryTypeList = async () => {
    try {
      setIsLoadingIndustryType(true);
      let result = await getCommonValue(103);
      setIsLoadingIndustryType(false);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          setIndustryTypeList(result.responseData);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  const [isLoadingLanguage, setIsLoadingLanguage] = useState(false);
  const getLanguageList = async () => {
    try {
      setIsLoadingLanguage(true);
      let result = await getCommonValue(104);
      setIsLoadingLanguage(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setLanguageList(result.responseData);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getCountryList();
    getCurrencyList();
    getIndustryTypeList();
    getLanguageList();
  }, []);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtCustomerName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Select Customer Name";
      }
    }
    if (name === "txtCountry") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Select Country";
      }
    }
    if (name === "txtIndustryType") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Select  Industry Type";
      }
    }
    if (name === "txtCurrency") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Select Currency!";
      }
    }
    if (name === "txtLanguage") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Select Langauge";
      }
    }
    if (name === "txtRegistrationDate") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Cannot be empty";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtCustomerName"] = validateField("txtCustomerName", formValues.txtCustomerName);
      errors["txtCountry"] = validateField("txtCountry", formValues.txtCountry);
      errors["txtIndustryType"] = validateField("txtIndustryType", formValues.txtIndustryType);
      errors["txtCurrency"] = validateField("txtCurrency", formValues.txtCurrency);
      errors["txtLanguage"] = validateField("txtLanguage", formValues.txtLanguage);
      errors["txtRegistrationDate"] = validateField("txtRegistrationDate", formValues.txtRegistrationDate);

      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      setBtnloaderActive(true);
      const ip = await publicIpv4();
      let user = getSessionStorage("user");
      console.log(user, "user");
      let formData = {
        customerName: formValues.txtCustomerName,
        countryCode: formValues.txtCountry && formValues.txtCountry.CountryMasterID ? formValues.txtCountry.CountryMasterID : "",
        currencyCode: formValues.txtCurrency && formValues.txtCurrency.CommonMasterValueID ? formValues.txtCurrency.CommonMasterValueID : "",
        industryCode: formValues.txtIndustryType && formValues.txtIndustryType.CommonMasterValueID ? formValues.txtIndustryType.CommonMasterValueID : "",
        languageCode: formValues.txtLanguage && formValues.txtLanguage.CommonMasterValueID ? formValues.txtLanguage.CommonMasterValueID : "",
        dor: formValues.txtRegistrationDate,
        activeStatus: 0,
        objCommon: {
          insertUserId: user && user.User && user.User.user && user.User.user.AppAccessID ? user.User.user.AppAccessID : 0,
          insertIpAddress: ip,
        },
      };
      let result = await createCustomer(formData);
      console.log(result, "result&&&&&");
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          if (result && result.responseData.length > 0) {
            let customer = {
              CustomerMasterID: result.responseData[0].customerMasterID,
              CustomerName: formValues.txtCustomerName,
              DOR: formValues.txtRegistrationDate,
              Industry: formValues.txtIndustryType && formValues.txtIndustryType.CommonMasterValue ? formValues.txtIndustryType.CommonMasterValue : "",
              LanguageName: formValues.txtLanguage && formValues.txtLanguage.CommonMasterValue ? formValues.txtLanguage.CommonMasterValue : "",
              CountryName: formValues.txtCountry && formValues.txtCountry.CountryName ? formValues.txtCountry.CountryName : "",
              CurrencyName: formValues.txtCurrency && formValues.txtCurrency.CommonMasterValue ? formValues.txtCurrency.CommonMasterValue : "",
              CurrencyCode: formValues.txtCurrency && formValues.txtCurrency.CommonMasterValueID ? formValues.txtCurrency.CommonMasterValueID : "",
            };
            updateCustomerList(customer);
            debugger;
            updateSelectedCustomerList(customer);
          }
        }
        clearForm();
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtCustomerName: "",
      txtCountry: null,
      txtIndustryType: null,
      txtCurrency: null,
      txtLanguage: null,
      txtRegistrationDate: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title="Add New Customer" column={2} onSubmit={handleSave} show={() => toggleCustomerAddPopup()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"550px"}>
            <Form.InputGroup req={true} errorMsg={formValidationError["txtCustomerName"]} label="Customer Name">
              <Form.InputControl
                ref={firstTextInput}
                control="input"
                name="txtCustomerName"
                type="text"
                value={formValues.txtCustomerName}
                onChange={(e) => {
                  formValidationError["txtCustomerName"] = validateField("txtCustomerName", e);
                  updateState(e.target.name, e.target.value);
                }}
              />
            </Form.InputGroup>
            <Form.InputGroup req={true} errorMsg={formValidationError["txtCountry"]} label="Country">
              <Form.InputControl
                control="select"
                name="txtCountry"
                menuPlacement="auto"
                isClearable={true}
                getOptionLabel={(option) => `${option.CountryName}`}
                value={formValues.txtCountry}
                getOptionValue={(option) => `${option}`}
                options={countryList}
                isLoading={isLoadingCountry}
                onChange={(e) => {
                  formValidationError["txtCountry"] = validateField("txtCountry", e);
                  updateState("txtCountry", e);
                }}
                isSearchable={true}
                label="Select Country"
                noOptionsMessage={() => "No Result Found"}
              />
            </Form.InputGroup>
            <Form.InputGroup req={true} errorMsg={formValidationError["txtIndustryType"]} label="IndustryType">
              <Form.InputControl
                control="select"
                name="txtIndustryType"
                menuPlacement="auto"
                isClearable={true}
                getOptionLabel={(option) => `${option.CommonMasterValue}`}
                value={formValues.txtIndustryType}
                getOptionValue={(option) => `${option}`}
                options={industryTypeList}
                onChange={(e) => {
                  formValidationError["txtIndustryType"] = validateField("txtIndustryType", e);
                  updateState("txtIndustryType", e);
                }}
                isSearchable={true}
                label="Select Industry Type"
                noOptionsMessage={() => "No Result Found"}
              />
            </Form.InputGroup>
            <Form.InputGroup req={true} errorMsg={formValidationError["txtCurrency"]} label="Currency">
              <Form.InputControl
                control="select"
                name="txtCurrency"
                menuPlacement="auto"
                isClearable={true}
                getOptionLabel={(option) => `${option.CommonMasterValue}`}
                value={formValues.txtCurrency}
                getOptionValue={(option) => `${option}`}
                options={currencyList}
                onChange={(e) => {
                  formValidationError["txtCurrency"] = validateField("txtCurrency", e);
                  updateState("txtCurrency", e);
                }}
                isSearchable={true}
                placeholder="Select Currency"
                noOptionsMessage={() => "No Result Found"}
              />
            </Form.InputGroup>
            <Form.InputGroup req={true} errorMsg={formValidationError["txtLanguage"]} label="Langauge">
              <Form.InputControl
                control="select"
                name="txtLanguage"
                menuPlacement="auto"
                isClearable={true}
                getOptionLabel={(option) => `${option.CommonMasterValue}`}
                value={formValues.txtLanguage}
                getOptionValue={(option) => `${option}`}
                options={languageList}
                onChange={(e) => {
                  formValidationError["txtLanguage"] = validateField("txtLanguage", e);
                  updateState("txtLanguage", e);
                }}
                isSearchable={true}
                placeholder="Select Language"
                noOptionsMessage={() => "No Result Found"}
              />
            </Form.InputGroup>
            <Form.InputGroup req={true} errorMsg={formValidationError["txtRegistrationDate"]} label="Registration Date">
              <Form.InputControl
                control="input"
                type="date"
                min={moment().format("YYYY-MM-DD")}
                name="txtRegistrationDate"
                value={formValues.txtRegistrationDate}
                onChange={(e) => {
                  formValidationError["txtRegistrationDate"] = validateField("txtRegistrationDate", e);
                  updateState(e.target.name, e.target.value);
                }}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddCustomerPopup;
