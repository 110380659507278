import React, { Component, createRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import classNames from "classnames";

function DataGrid(props) {
  const { className, children, loader, theme, enableRangeSelection, rowSelection, rowData, ref, onGridReady, rowActions, ...restProps } = props;
  // const [dragResized, setDragResized] = useState(false);

  // var timer;
  // const autoResize = (val) => {
  //   if (timer) clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     const gridApi = val.api;
  //     const columnApi = val.columnApi;
  //     let colDefs = [...gridApi.getColumnDefs()];

  //     //Auto Resizable Columns
  //     let autoResizableColumns = [];
  //     colDefs.forEach((val, index) => {
  //       if (val.autoResizeValues) {
  //         autoResizableColumns.push({
  //           index: index,
  //           id: val.field,
  //           autoResizeValues: val.autoResizeValues,
  //         });
  //       }
  //     });
  //     if (autoResizableColumns.length > 0) {
  //       const rowRendered = gridApi.rowRenderer;
  //       let firstRenderedRow = rowRendered.firstRenderedRow;
  //       let lastRenderedRow = rowRendered.lastRenderedRow + 1;

  //       let columnData = rowData.slice(firstRenderedRow, lastRenderedRow);

  //       let width = [];
  //       columnData.forEach((row) => {
  //         autoResizableColumns.forEach(({ id, autoResizeValues }) => {
  //           if (row[id]) {
  //             if (row[id].length > autoResizeValues.maxCharacters) {
  //               width[id] = autoResizeValues.maxLength;
  //             } else {
  //               if (!width[id]) width[id] = 0;
  //             }
  //           }
  //         });
  //       });

  //       autoResizableColumns.forEach((val) => {
  //         if (width[val.id] === 0) {
  //           delete colDefs[val.index]["width"];
  //           columnApi.autoSizeColumn(val.id, true);
  //         } else {
  //           colDefs[val.index]["width"] = width[val.id];
  //         }
  //       });
  //       gridApi.setColumnDefs(colDefs);
  //     }
  //   }, 400);
  // };

  // const onColumnResized = (val) => {
  //   if (val.finished && dragResized) {
  //     let colDef = val.column.getColDef();
  //     console.log("colDef", colDef);
  //     delete colDef.autoResizeValues;
  //     val.column.setColDef(colDef);
  //     setDragResized(false);
  //   }
  //   if (!val.finished) setDragResized(true);
  // };
  const onReady = (p) => {
    const gridApi = p.api;
    gridApi.actions = rowActions || [];
    let actionsArray = gridApi.actions;
    actionsArray = actionsArray.map((val) => {
      return { currentNode: null, ...val };
    });
    gridApi.addAction = (node, name) => {
      try {
        if (node === undefined) {
          throw new Error("Cannot set highlight to node undefined.");
        }
        if (!actionsArray) {
          throw new Error("Action property is not defined.");
        }
        if (actionsArray.length < 1) {
          throw new Error("Provided highlight is empty.");
        }
        const actionData = actionsArray.find((val) => name === val.name);

        if (!actionData) {
          throw new Error("Wrong action name.");
        }
        const preViousNode = actionData.currentNode;
        if (actionData.currentNode === node) {
          actionData.onActionChange && actionData.onActionChange(node, false);
          actionData.currentNode = null;
        } else {
          actionData.onActionChange && actionData.onActionChange(node, true);
          actionData.currentNode = node;
        }

        //refresh
        if (actionData.refresh !== "none") {
          if (actionData.refresh === "full") {
            gridApi.redrawRows();
          } else {
            const rowNodes = [node];
            if (preViousNode) rowNodes.push(preViousNode);
            gridApi.redrawRows({ rowNodes });
          }
        }
      } catch (e) {
        throw e;
      }
    };
    gridApi.getActions = (node) => {
      const actionNames = [];
      actionsArray.forEach((val) => {
        if (node === val.currentNode) {
          actionNames.push(val.name);
        }
      });
      return actionNames;
    };
    gridApi.getActionRowNode = (actionName) => {
      const action = actionsArray.find((val) => val.name === actionName);
      return action && action.currentNode ? action.currentNode : null;
    };
  };
  return (
    <div className={classNames(theme ? null : "Biz_CustomizedAgGrid", theme ? theme : "ag-theme-balham", className)}>
      {loader}
      <AgGridReact
        ref={ref}
        // onBodyScroll={autoResize}
        // onColumnResized={onColumnResized}
        enableRangeSelection={enableRangeSelection ? enableRangeSelection : true}
        rowSelection={rowSelection ? rowSelection : "multiple"}
        rowData={rowData}
        defaultColDef={{
          sortable: true,
          resizable: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        }}
        onGridReady={(p) => {
          onReady(p);
          onGridReady && onGridReady(p);
        }}
        {...restProps}
      >
        {children}
      </AgGridReact>
    </div>
  );
}

export default DataGrid;

function EditableDataGrid(props) {
  const { className, children, loader, theme, rowData, ...restProps } = props;
  return (
    <div className={classNames(theme ? null : "Biz_CustomizedAgGrid", theme ? theme : "ag-theme-blue", className)}>
      {loader}
      <AgGridReact rowData={rowData} {...restProps}>
        {children}
      </AgGridReact>
    </div>
  );
}

DataGrid.Editable = EditableDataGrid;

/* NUMERIC EDITOR */

const KEY_BACKSPACE = "Backspace";
const KEY_DELETE = "Delete";
const KEY_F2 = "F2";
const KEY_ENTER = "Enter";
const KEY_TAB = "Tab";

class NumericEditor extends Component {
  constructor(props) {
    super(props);

    this.inputRef = createRef(null);

    this.cancelBeforeStart = this.props.charPress && "1234567890".indexOf(this.props.charPress) < 0;

    this.state = this.createInitialState(props);

    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setCaret();
  }

  render() {
    return <input ref={this.inputRef} value={this.state.value} onKeyDown={this.onKeyDown} onChange={this.handleChange} className="numeric-input" />;
  }

  /* Component Editor Lifecycle methods */

  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.state.value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return this.cancelBeforeStart;
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    return this.state.value > 1000000;
  }

  /* Utility methods */
  createInitialState(props) {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.eventKey === KEY_BACKSPACE || props.eventKey === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      highlightAllOnFocus,
    };
  }

  onKeyDown(event) {
    if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (!this.finishedEditingPressed(event) && !this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) event.preventDefault();
    }
  }

  isLeftOrRight(event) {
    return ["ArrowLeft", "ArrowRight"].indexOf(event.key) > -1;
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
  }

  isKeyPressedNumeric(event) {
    const charStr = event.key;
    return this.isCharNumeric(charStr);
  }

  deleteOrBackspace(event) {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
  }

  finishedEditingPressed(event) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }

  setCaret() {
    // https://github.com/facebook/react/issues/7835#issuecomment-395504863
    setTimeout(() => {
      const currentInput = this.inputRef.current;
      currentInput.focus();
      if (this.state.highlightAllOnFocus) {
        currentInput.select();

        this.setState({
          highlightAllOnFocus: false,
        });
      } else {
        // when we started editing, we want the caret at the end, not the start.
        // this comes into play in two scenarios:
        //   a) when user hits F2
        //   b) when user hits a printable character
        const length = currentInput.value ? currentInput.value.length : 0;
        if (length > 0) {
          currentInput.setSelectionRange(length, length);
        }
      }
    });
  }
}
