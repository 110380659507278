import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import Button from "../../../Framework/OldFramework/Button/Button";
import { getEncryptdata } from "../Resource/SupportService";
import { useNavigate } from "react-router-dom";

const Encryption = () => {
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtEncryptData: "",
  });
  const navigate = useNavigate();
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });
  const [clicked, setClicked] = useState(false);
  const [encryptedData, setEncryptedData] = useState([]);

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!formValues.txtEncryptData || typeof formValues.txtEncryptData === "undefined") {
      formIsValid = false;
      errors["txtEncryptData"] = "Cannot be empty";
    }

    setFormValidationError(errors);
    return formIsValid;
  };

  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      let formData = {
        password: formValues.txtEncryptData,
      };
      setBtnloaderActive(true);
      let result = await getEncryptdata(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        setClicked(true);
        if (result.responseData) {
          setEncryptedData(result.responseData);
          clearForm();
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          setEncryptedData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  const CloseButton = () => {
    navigate("/home");
  };

  const clearForm = () => {
    setFormValues({
      txtEncryptData: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };
  return (
    <Modal varient={"center"} title="Encrypt Data" onSubmit={onSubmit} show={() => CloseButton()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"480px"}>
            <Form.InputGroup errorMsg={formValidationError["txtEncryptData"]} label="Data" req={true}>
              <Form.InputControl
                control="input"
                label="Enter Data to be Encrypted"
                name="txtEncryptData"
                type="text"
                value={formValues.txtEncryptData}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
            {clicked ? (
              <Form.InputGroup row={3} label="Encrypted Data">
                <Form.InputControl control="textarea" rows={"3"} value={encryptedData} />
              </Form.InputGroup>
            ) : null}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Encrypyt
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Encryption;
