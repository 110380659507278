import axios from "axios";
import APIEndpoints from "../../../APIMethods/APIEndpoints/APIEndpoints";
import ApiCalling from "../../../APIMethods/Utilities/ApiCalling/ApiCalling";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";

export const getCompanyMasterSelect = async (action, searchText = "", customerMasterId = 0, companyMasterId = 0) => {
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const ip = await publicIpv4();
  const requestData = {
    action: action,
    searchText: searchText,
    customerMasterId: customerMasterId,
    companyMasterId: companyMasterId,
    filterId1: 0,
    filterId2: "",
    objCommon: {
      insertUserId: insertUserID,
      insertIpAddress: ip,
    },
  };

  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyMasterList);
  return response;
};

export const getCompanyUserSelect = async (appAccessCode, companyUserId, companyMasterId, viewMode, userRelationType) => {
  const requestData = {
    appAccessCode: appAccessCode,
    companyUserId: companyUserId,
    companyMasterId: companyMasterId,
    insertUserId: 0,
    viewMode: viewMode,
    userRelationType: userRelationType,
    userType: 0,
    searchText: "#ALL",
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyUser);
  return response;
};

export const getCompanyAddressSelect = async (companyAddressId, companyMasterId, locationTypeId, viewMode) => {
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const requestData = {
    viewMode: viewMode,
    addressCode: companyAddressId,
    companyMasterId: companyMasterId,
    locationTypeId: locationTypeId,
    addressTypeId: 0,
    insertUserId: insertUserID,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyAddress);
  return response;
};

export const companyAddressLocationAssignment = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyMasterAddressLocation);
  return response;
};

export const biznextSupportUserCreate = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.BiznextSupportUserCreate);
  return response;
};

export const UserProfileAssign = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.User.UserProfileAssign);
  return response;
};

export const getCompanyParameterSelect = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.Company.BiznextParameterList);
  return response;
};

export const getCompanyParameterList = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.Company.CompanyParameterList);
  return response;
};

export const CreateCompanyParameter = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.Company.CreateCompanyParameter);
  return response;
};

export const getCompanyParameterValueSelect = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.Company.BiznextParameterValue);
  return response;
};

export const createCompany = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CreateCompany);
  return response;
};

export const updateCompany = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyMasterUpdate);
  return response;
};

export const getCompanyMasterConfigSelect = async (companyMasterId, companyConfigID, viewMode) => {
  const requestData = {
    action: viewMode,
    searchText: "",
    companyMasterId: companyMasterId,
    companyConfigId: companyConfigID,
    filterId1: 0,
    filterId2: "",
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyConfigList);
  return response;
  //   try {
  //     let userData = getSessionStorage("session");
  //     if (!checkAuthExist() || userData === null || !userData.Token) {
  //       return {
  //         responseCode: 401,
  //         responseData: null,
  //         responseMessage: CustomMsg.UnAuthorized,
  //       };
  //     }
  //     debugger;
  //     let requestData = {
  //       CompanyMasterID: companyMasterId.toString(),
  //       CompanyConfigID: companyConfigID.toString(),
  //       ViewMode: viewMode,
  //       commonSelectDto: {
  //         SelectUserID: userData.User.AppAccessID.toString(),
  //       },
  //     };
  //     let response = await axios.post(
  //       MainUrl.Base_Url +
  //         Endpoints.CRMSetup.CompanyRegistration.CompanyMasterConfigSelect,
  //       requestData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: userData.Token,
  //         },
  //       }
  //     );
  //     if (response.status === 200 || response.status === 201) {
  //       const result = await response.data;
  //       if (result.ResponseCode === 1) {
  //         let buff = new Buffer(result.ResponseDynamic, "base64");
  //         const decompressedData = await buff;
  //         var data = JSON.parse(decompressedData.toString());
  //         return {
  //           responseCode: result.ResponseCode,
  //           responseData: data,
  //           responseMessage: result.ResponseMessage,
  //         };
  //       }
  //       return {
  //         responseCode: result.ResponseCode,
  //         responseData: null,
  //         responseMessage: result.ResponseMessage,
  //       };
  //     }
  //     return {
  //       responseCode: 0,
  //       responseData: null,
  //       responseMessage: CustomMsg.Error,
  //     };
  //   } catch (error) {
  //     if (error && error.response && error.response.status === 401) {
  //       return {
  //         responseCode: 401,
  //         responseData: null,
  //         responseMessage: CustomMsg.UnAuthorized,
  //       };
  //     }
  //     if (error && error.response && error.response.status === 400) {
  //       return {
  //         responseCode: 0,
  //         responseData: null,
  //         responseMessage: error.response.data.ResponseMessage,
  //       };
  //     }
  //     return {
  //       responseCode: 0,
  //       responseData: null,
  //       responseMessage: CustomMsg.Error,
  //     };
  //   }
};

export const createCompanyConfig = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CreateCompanyConfig);
  return response;
};

export const companyConfigUpdateIsActive = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.UpdateConfigStatus);
  return response;
};

export const companyConfigUpdate = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.UpdateCompanyConfig);
  return response;
};

export const createCompanyAddress = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyMasterAddressCreate);
  return response;
};

export const updateCompanyAddress = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyMasterAddressUpdate);
  return response;
};

export const CompanySetDefaultAddress = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.Company.CompanyMasterAddressDefault);
  return response;
};

export const getLoginHistory = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.User.UserLoginHistory);
  return response;
};

export const createCompanyUser = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.User.CreateUser);
  return response;
};

export const enableDisableCompanyUser = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.User.enableDisableUser);
  return response;
};

export const getUserReferanceSelect = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.User.GetUserReference);
  return response;
};

export const resetPassword = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.User.resetPassword);
  return response;
};

export const getCompanyClientReferenceSelect = async (clientDBConnection, companyMasterId, searchBy) => {
  const requestData = {
    main: {
      CompanyMasterID: companyMasterId ? companyMasterId.toString() : "0",
      ClientDBConnection: clientDBConnection,
      SearchBy: searchBy,
    },
  };
  const response = await ApiCalling(requestData, APIEndpoints.Department.AddDepartment);
  return response;
  //   try {
  //     let userData = getSessionStorage("session");
  //     if (!checkAuthExist() || userData === null || !userData.Token) {
  //       return {
  //         responseCode: 401,
  //         responseData: null,
  //         responseMessage: CustomMsg.UnAuthorized,
  //       };
  //     }

  //     let requestData = {
  //       CompanyMasterID: companyMasterId ? companyMasterId.toString() : "0",
  //       //  "ClientDBConnection": clientDBConnection,
  //       SearchBy: searchBy,
  //     };
  //     console.log(
  //       MainUrl.Base_Url +
  //         Endpoints.CRMSetup.CompanyRegistration.CompanyClientReferenceSelect
  //     );
  //     console.log(JSON.stringify(requestData));
  //     console.log(userData.Token);
  //     let response = await axios.post(
  //       MainUrl.Base_Url +
  //         Endpoints.CRMSetup.CompanyRegistration.CompanyClientReferenceSelect,
  //       requestData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: userData.Token,
  //         },
  //       }
  //     );
  //     if (response.status === 200 || response.status === 201) {
  //       const result = await response.data;
  //       if (result.ResponseCode === 1) {
  //         let buff = new Buffer(result.ResponseDynamic, "base64");
  //         const decompressedData = await buff;
  //         var data = JSON.parse(decompressedData.toString());
  //         return {
  //           responseCode: result.ResponseCode,
  //           responseData: data,
  //           responseMessage: result.ResponseMessage,
  //         };
  //       }
  //       return {
  //         responseCode: result.ResponseCode,
  //         responseData: null,
  //         responseMessage: result.ResponseMessage,
  //       };
  //     }
  //     return {
  //       responseCode: 0,
  //       responseData: null,
  //       responseMessage: CustomMsg.Error,
  //     };
  //   } catch (error) {
  //     if (error && error.response && error.response.status === 401) {
  //       return {
  //         responseCode: 401,
  //         responseData: null,
  //         responseMessage: CustomMsg.UnAuthorized,
  //       };
  //     }
  //     if (error && error.response && error.response.status === 400) {
  //       return {
  //         responseCode: 0,
  //         responseData: null,
  //         responseMessage: error.response.data.ResponseMessage,
  //       };
  //     }
  //     return {
  //       responseCode: 0,
  //       responseData: null,
  //       responseMessage: CustomMsg.Error,
  //     };
  //   }
};

export const checkConnectionStringValid = async (clientDBConnectionString) => {
  const requestData = {
    main: {
      ClientDBConnection: clientDBConnectionString,
    },
  };
  const response = await ApiCalling(requestData, APIEndpoints.Department.AddDepartment);
  return response;
  //   try {

  //     if (!checkAuthExist() || userData === null || !userData.Token) {
  //       return {
  //         responseCode: 401,
  //         responseData: null,
  //         responseMessage: CustomMsg.UnAuthorized,
  //       };
  //     }

  //     let requestData = {
  //       ClientDBConnection: clientDBConnectionString,
  //     };
  //     let response = await axios.post(
  //       MainUrl.Base_Url +
  //         Endpoints.CRMSetup.CompanyRegistration.CheckConnectionStringValid,
  //       requestData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: userData.Token,
  //         },
  //       }
  //     );
  //     if (response.status === 200 || response.status === 201) {
  //       const result = await response.data;
  //       return {
  //         responseCode: result.ResponseCode,
  //         responseData: null,
  //         responseMessage: result.ResponseMessage,
  //       };
  //     }
  //     return {
  //       responseCode: 0,
  //       responseData: null,
  //       responseMessage: CustomMsg.Error,
  //     };
  //   } catch (error) {
  //     if (error && error.response && error.response.status === 401) {
  //       return {
  //         responseCode: 401,
  //         responseData: null,
  //         responseMessage: CustomMsg.UnAuthorized,
  //       };
  //     }
  //     if (error && error.response && error.response.status === 400) {
  //       return {
  //         responseCode: 0,
  //         responseData: null,
  //         responseMessage: error.response.data.ResponseMessage,
  //       };
  //     }
  //     return {
  //       responseCode: 0,
  //       responseData: null,
  //       responseMessage: CustomMsg.Error,
  //     };
  //   }
};

export const UserResetPassword = async (formData) => {
  const requestData = {
    main: {
      ...formData,
    },
  };
  const response = await ApiCalling(requestData, APIEndpoints.Department.AddDepartment);
  return response;
};

export const companyUserUpdateStatus = async (formData) => {
  const requestData = {
    main: {
      ...formData,
    },
  };
  const response = await ApiCalling(requestData, APIEndpoints.Department.AddDepartment);
  return response;
  //   try {
  //     var ip = await publicIp.v4();
  //     let userData = getSessionStorage("session");
  //     if (!checkAuthExist() || userData === null || !userData.Token) {
  //       return {
  //         responseCode: 401,
  //         responseData: null,
  //         responseMessage: CustomMsg.UnAuthorized,
  //       };
  //     }

  //     let requestData = {
  //       ...formData,
  //       commonTrackingDto: {
  //         InsertUserID: userData.User.AppAccessID.toString(),
  //         InsertIPAddress: ip,
  //       },
  //     };
  //     let response = await axios.post(
  //       MainUrl.Base_Url +
  //         Endpoints.CRMSetup.CompanyRegistration.CompanyUserUpdateStatus,
  //       requestData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: userData.Token,
  //         },
  //       }
  //     );

  //     if (response.status === 200 || response.status === 201) {
  //       const result = await response.data;
  //       return {
  //         responseCode: result.ResponseCode,
  //         responseData: result,
  //         responseMessage: result.ResponseMessage,
  //       };
  //     }
  //     return {
  //       responseCode: 0,
  //       responseData: null,
  //       responseMessage: CustomMsg.Error,
  //     };
  //   } catch (error) {
  //     if (error && error.response && error.response.status === 401) {
  //       return {
  //         responseCode: 401,
  //         responseData: null,
  //         responseMessage: CustomMsg.UnAuthorized,
  //       };
  //     }
  //     if (error && error.response && error.response.status === 400) {
  //       return {
  //         responseCode: 0,
  //         responseData: null,
  //         responseMessage: error.response.data.ResponseMessage,
  //       };
  //     }
  //     return {
  //       responseCode: 0,
  //       responseData: null,
  //       responseMessage: CustomMsg.Error,
  //     };
  //   }
};

export const getCompanyAddressRelationSelect = async (accountingBranchId, companyMasterId, viewMode, productionPlantID) => {
  let userData = getSessionStorage("session");
  const requestData = {
    viewMode: viewMode,
    companyMasterId: companyMasterId,
    accountingBranchId: accountingBranchId,
    productionPlantID: productionPlantID,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyMasterAddressRelationList);
  return response;
};

export const companyAddressRelationInsert = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyMasterAddressRelation);
  return response;
};
export const getCompanyMasterConfig = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.Department.AddDepartment);
  return response;
};

export const GetHistoryData = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.GetHistoryData);
  return response;
};
