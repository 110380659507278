/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BizClass from "../../Tax.module.scss";
import { getCountry } from "../../../GeographicData/Resources/GeographicDataService";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import Button from "../../../../../Framework/OldFramework/Button/Button";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";
import { publicIpv4 } from "public-ip";
import { deleteTaxLocalization, getTaxLocalization } from "../../Resources/TaxService";
import AddTaxLocalizationUnassigned from "./AddTaxLocalizationUnassigned";
import { HiArrowCircleRight, HiTrash } from "react-icons/hi";

function AddTaxLocalizationAssigned(props) {
  let toggleAddTaxLocalizationPopup = props.toggleAddTaxLocalizationPopup;
  let setMsgAlert = props.setMsgAlert;
  let popupData = props.popupData;
  let setConfirmAlert = props.setConfirmAlert;

  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [filter] = useState({
    fromData: "",
    toDate: "",
  });

  const [gridApi, setGridApi] = useState(null);
  const [search, setSearch] = useState("");

  const _onGridReady = (params) => {
    setGridApi(params.api);
  };

  const _onSearch = (val) => {
    setSearch(val);
    if (gridApi) {
      gridApi.setQuickFilter(val);
    }
  };

  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const getCountryList = async () => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingCountry(true);
      const formData = {
        Action: "GETASSIGNCOUNTRY",
        TaxCategoryID: popupData.TaxCategoryID,
        CountryMasterID: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getTaxLocalization(formData);
      console.log(result, "result");
      setIsLoadingCountry(false);
      if (result.responseCode === 1) {
        console.log(result);
        if (result && result.responseData && result.responseData.length > 0) {
          if (search && search.toLowerCase().includes("#")) {
            _onSearch("");
          }
          setCountryList(result.responseData);
        } else {
          setCountryList([]);
        }
      } else {
        setCountryList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onclickGetcountryList = () => {
    getCountryList();
  };

  const [btnLoaderActive, setBtnLoaderActive] = useState(false);

  const onClickDelete = async (data) => {
    console.log(data, "deletedata");
    try {
      if (gridApi) {
        gridApi.applyTransaction({ remove: [data] });

        const filterdItem = countryList.filter((x) => String(x.CountryMasterID) !== String(data.CountryMasterID));
        setCountryList(filterdItem);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onClickDeleteCountry = async (data) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      let formData = {
        TaxDefinitionLocalIDs: data && data.TaxDefinitionLocalID ? String(data.TaxDefinitionLocalID) : "",

        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await deleteTaxLocalization(formData);

      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        // const results = userList.filter((el) => {
        //   return el.CompanyUserID !== data.CompanyUserID;
        // });
        // setUserList(results);
        onClickDelete(data);
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const columnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellRendererParams: { onClickDeleteCountry, setConfirmAlert },
    },
    {
      field: "CountryName",
      headerName: "Country Name",
      width: 400,
    },
    {
      field: "AllowState",
      headerName: "Allow State",
      width: 160,
      valueFormatter: (param) => (param.value && param.value === 1 ? "YES" : "NO"),
    },
  ];

  const [importTaxLocalizatioPopup, setImportTaxLocalizatioPopup] = useState(false);
  const toggleImportTaxLocalizationPopup = () => {
    setImportTaxLocalizatioPopup(!importTaxLocalizatioPopup);
  };

  //   const updateTaxList = (data) => {
  //     debugger;
  //     data.forEach((item) => countryList.unshift(item));
  //     if (gridApi) {
  //       gridApi.setRowData(countryList);
  //     }
  //   };

  const updateTaxList = (data) => {
    setCountryList(data);
    if (gridApi) {
      gridApi.setRowData(countryList);
    }
  };

  return (
    <>
      {importTaxLocalizatioPopup && (
        <AddTaxLocalizationUnassigned
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
          toggleImportTaxLocalizationPopup={toggleImportTaxLocalizationPopup}
          popupData={popupData}
          updateTaxList={updateTaxList}
        />
      )}
      <Modal varient="half" left={0} index={1} title="Tax Localization" show={() => toggleAddTaxLocalizationPopup()}>
        <Modal.Body>
          <div className={BizClass.modal_main_section}>
            <PageBar>
              <PageBar.Search focus={true} onChange={(e) => _onSearch(e.target.value)} value={search} onClick={() => onclickGetcountryList()} />
              <PageBar.Button focus={true} onClick={() => toggleImportTaxLocalizationPopup()}>
                Import
              </PageBar.Button>
            </PageBar>
            <DataGrid
              rowData={countryList}
              onGridReady={_onGridReady}
              loader={isLoadingCountry ? <Loader /> : null}
              animateRows={true}
              columnDefs={columnDefs}
              rowSelection="multiple"
              suppressRowClickSelection={false}
              frameworkComponents={{
                actionTemplate: TaxActionTemplate,
              }}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" varient="secondary" trigger={btnLoaderActive}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddTaxLocalizationAssigned;

const TaxActionTemplate = (props) => {
  const onClickDeleteTax = () => {
    props.onClickDeleteCountry(props.data);
    console.log("props.data", props.data);
  };

  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "5px" }}>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiTrash
          title="Get Definition"
          style={{ fontSize: "16px", color: "#34495E" }}
          onClick={() =>
            props.setConfirmAlert({
              open: true,
              title: "Delete",
              msg: "Are you sure to delete ?",
              button: {
                confirmText: "Delete",
                abortText: "Cancel",
                Color: "Danger",
              },
              onConfirm: () => onClickDeleteTax(),
            })
          }
        />
      </span>
    </div>
  );
};
