import React, { useEffect, useState } from "react";
import "./AddressAssignment.scss";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import classNames from "classnames";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import UnAssignedAddressPopup from "../UnAssignedAddressPopup/UnAssignedAddressPopup";
import { companyAddressRelationInsert, getCompanyAddressRelationSelect } from "../../../Resources/companyRegistrationService";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { BiRefresh } from "react-icons/bi";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import { ImEye } from "react-icons/im";
import ViewAddressRelationHistory from "../ViewAddressRelationHistory/ViewAddressRelationHistory";

const AddressAssignment = (props) => {
  const toggleAddressAssignmentPopup = props.toggleAddressAssignmentPopup;
  let selectedCompanyMaster = props.selectedCompanyMaster;
  let address = props.address;
  let setMsgAlert = props.setMsgAlert;
  const setConfirmAlert = props.setConfirmAlert;
  debugger;
  const tabList = [
    { value: "ACCWAR", lable: "Account to Warehouse", code: "120002" },
    { value: "PRDWAR", lable: "Production Plant to Warehouse", code: "120001" },
  ];
  const [locationTypeList, setLocationTypeList] = useState({
    tabs: tabList,
    currentTab: tabList[0],
  });
  const [selectedLocationType, setSelectedLocationType] = useState({});
  const [selectedTab, setSelectedTab] = useState({
    value: "ACCWAR",
    lable: " Warehouse to Accounting",
    code: "120002",
  });

  const handleSelectTab = (tab) => {
    debugger;
    console.log("tab", tab);
    setSelectedTab(tab);
    setLocationTypeList({
      ...locationTypeList,
      currentTab: tab,
    });
    getCompanyAddressAssignedToCompanyAddress(address.CompanyAddressID, tab && tab.value ? tab.value : "ACCWAR", selectedCompanyMaster.CompanyMasterID);
    setSelectedLocationType(tab);
    setUnAssignedAddressPopup(false);
    console.log("handleSelectTab", locationTypeList);
  };

  useEffect(() => {
    setSelectedLocationType(locationTypeList.currentTab);
    getCompanyAddressAssignedToCompanyAddress(address.CompanyAddressID, locationTypeList.currentTab.value, selectedCompanyMaster.CompanyMasterID);
  }, []);

  const [assignedAddressList, setAssignedAddressList] = useState([]);
  const [isLoadingAssignedAddress, setIsLoadingAssignedAddress] = useState(false);
  const [filteredAssignedAddressList, setFilteredAssignedAddressList] = useState([]);

  const getCompanyAddressAssignedToCompanyAddress = async (accountingBranchId, pType, companyMasterId) => {
    debugger;
    try {
      setIsLoadingAssignedAddress(true);
      let productionPlantID = address.CompanyAddressID;
      console.log(address.CompanyAddressID, "warehouseMasterId");
      let viewMode = "";
      if (pType === "ACCWAR") {
        viewMode = "WASSIGN";
      } else if (pType === "PRDWAR") {
        viewMode = "PPASSIGN";
      }
      debugger;
      const result = await getCompanyAddressRelationSelect(
        pType === "ACCWAR" ? accountingBranchId : 0,
        companyMasterId ? companyMasterId : 0,
        viewMode,
        pType === "ACCWAR" ? 0 : productionPlantID,
      );
      setIsLoadingAssignedAddress(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setAssignedAddressList(result.responseData);
          setFilteredAssignedAddressList(result.responseData);
          if (searchTextAssigendAddress && searchTextAssigendAddress.toLowerCase().includes("#")) {
            onSearchAssignedAddress("");
          }
          console.log("result.responseData.Value1", result.responseData);
        } else {
          setAssignedAddressList([]);
          setFilteredAssignedAddressList([]);
        }
      } else {
        setAssignedAddressList([]);
        setFilteredAssignedAddressList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [assignedAddressGridApi, setAssignedAddressGridApi] = useState();
  const onAssignedAddressGridReady = (params) => {
    setAssignedAddressGridApi(params.api);
  };

  const [searchTextAssigendAddress, setSearchTextAssigendAddress] = useState("");
  const onSearchAssignedAddress = (val) => {
    setSearchTextAssigendAddress(val);
    assignedAddressGridApi.setQuickFilter(val);
    assignedAddressGridApi.refreshCells();
  };

  const [unAssignedAddressPopup, setUnAssignedAddressPopup] = useState({
    isOpen: "",
    popupData: null,
  });
  const toggleUnAssignedAddressPopup = (data) => {
    setUnAssignedAddressPopup({ isOpen: !unAssignedAddressPopup.isOpen, popupData: data });
  };

  const [deletedAssignedAddress, setDeletedAssignedAddress] = useState();
  const onClickDeleteAssignedAddress = async (data) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    setUnAssignedAddressPopup(false);
    console.log("data", data);
    try {
      const ip = await publicIpv4();
      setIsLoadingAssignedAddress(true);
      const requestjson = {
        action: "UNASSIGN",
        addressRelationId: data.AddressRelationID.toString(),
        addressRelationType: data.AddressRelationType.toString(),
        companyMasterId: selectedCompanyMaster.CompanyMasterID.toString(),
        accountingBranchId: "0",
        warehouseMasterId: props.address.CompanyAddressID.toString(),
        productionPlantId: "0",
        storeId: "0",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };

      console.log("requestjson", requestjson);
      const result = await companyAddressRelationInsert(requestjson);
      console.log("result", result);

      setIsLoadingAssignedAddress(false);
      if (result.responseCode === 1) {
        console.log(result.responseData);
        const newObj = {
          AddressDisplayName: data.AddressDisplayName,
          AddressRelationID: data.AddressRelationID,
          CompanyMasterID: data.CompanyMasterID,
          CompanyAddressID: data.CompanyAddressID,
          AddressRelationTyp: data.AddressRelationTyp,
          IsNewlyAdded: true,
        };

        updateAssignedAddressList(newObj);
        let oldData = assignedAddressList;
        let FilterData = oldData.filter((x) => x.AddressRelationID !== data.AddressRelationID);
        setAssignedAddressList([]);
        setAssignedAddressList(FilterData);
        setFilteredAssignedAddressList([]);
        setFilteredAssignedAddressList(FilterData);
        if (assignedAddressGridApi) {
          assignedAddressGridApi.setRowData(FilterData);
        }
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
      } else {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        return;
      }
    } catch (error) {
      //  setMsgAlert({ open: true, type: 'success', msg: result.responseMessage });
      console.log(error);
    }
  };

  const updateAssignedAddressList = (data) => {
    debugger;
    console.log("updateUnAssignedAddressList", data);
    debugger;
    assignedAddressList.unshift({ ...data, IsNewlyAdded: true });
    setAssignedAddressList(assignedAddressList);
    console.log(assignedAddressGridApi, "assignedAddressGridApi");
    if (assignedAddressGridApi) {
      assignedAddressGridApi.setRowData(assignedAddressList);
    }

    // console.log(assignedList);
    // if (assignedList && assignedList.length > 0) {
    //   assignedList.forEach((data) => {
    //     if (!assignedAddressList.some((x) => x.CompanyAddressID.toString() === data.CompanyAddressID.toString())) {
    //       assignedAddressList.unshift(data);
    //     }
    //   });
    // }
    // setAssignedAddressList([]);
    // setAssignedAddressList(assignedAddressList);
    // setFilteredAssignedAddressList([]);
    // setFilteredAssignedAddressList(assignedAddressList);
  };

  const [historyPopupActive, sethistoryPopupActive] = useState({
    IsOpen: false,
    popupData: null,
    viewMode: "",
  });

  const toggleHistory = (data, viewMode) => {
    sethistoryPopupActive({
      IsOpen: !historyPopupActive.IsOpen,
      popupData: data,
      viewMode: viewMode,
    });
  };

  const companyAddressColnDef = [
    {
      field: "#",
      headerName: "Action",
      width: 98,
      pinned: "left",
      cellRenderer: "actionTemplate",
      cellRendererParams: { onClickDeleteAssignedAddress, setConfirmAlert, toggleHistory },
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: "1" },
    { field: "AddressDisplayName", headerName: "Display Name", width: 150 },
  ];

  return (
    <React.Fragment>
      {unAssignedAddressPopup.isOpen ? (
        <UnAssignedAddressPopup
          //   deletedAssignedAddress={deletedAssignedAddress}
          getCompanyAddressAssignedToCompanyAddress={getCompanyAddressAssignedToCompanyAddress}
          updateAssignedAddressList={updateAssignedAddressList}
          toggleUnAssignedAddressPopup={toggleUnAssignedAddressPopup}
          setMsgAlert={props.setMsgAlert}
          selectedCompanyMaster={selectedCompanyMaster}
          address={address}
          selectedTab={selectedTab}
          selectedLocationType={selectedLocationType}
        />
      ) : null}
      {historyPopupActive.IsOpen ? (
        <ViewAddressRelationHistory
          show={toggleHistory}
          viewMode={historyPopupActive.viewMode}
          setMsgAlert={setMsgAlert}
          popupData={historyPopupActive.popupData}
          setConfirmAlert={setConfirmAlert}
          address={address}
          selectedCompanyMaster={selectedCompanyMaster}
          selectedLocationType={selectedLocationType}
        />
      ) : null}

      <Modal
        varient={"bottom"}
        title={"Address Assigned List  (" + (address && address.AddressDisplayName ? `${address.AddressDisplayName})` : "")}
        show={toggleAddressAssignmentPopup}
        right={0}
        width={"49.5vw"}
        top={0}
      >
        <Modal.Header>
          <div className={"ModalHeadSearchBox"}>
            <PageBar.Search
              onClick={() => getCompanyAddressAssignedToCompanyAddress(address.CompanyAddressID, locationTypeList.currentTab.value, selectedCompanyMaster.CompanyMasterID)}
              value={searchTextAssigendAddress}
              onChange={(e) => onSearchAssignedAddress(e.target.value)}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="Card">
            <div className={classNames("CardBack", "DynBiz__Customer_AddPopup_FormContentSectionHead")}>
              <ul className="DynBiz_Customer_AddPopup__TabPanel">
                {(locationTypeList && locationTypeList.tabs && locationTypeList.tabs.length) > 0
                  ? locationTypeList.tabs.map((data, i) => {
                      return (
                        <li
                          key={i}
                          className={
                            locationTypeList && locationTypeList.currentTab.value.toString() === data.value.toString()
                              ? "DynBiz_Customer_AddPopup__TabList DynBiz_ActiveCustomer_AddPopup__TabList"
                              : "DynBiz_Customer_AddPopup__TabList"
                          }
                          onClick={() => {
                            handleSelectTab(data);
                          }}
                        >
                          {data.lable}
                        </li>
                      );
                    })
                  : null}
              </ul>
              <PageBar.Button
                onClick={() => getCompanyAddressAssignedToCompanyAddress(address.CompanyAddressID, locationTypeList?.currentTab?.value, selectedCompanyMaster.CompanyMasterID)}
              >
                <BiRefresh title="Refresh" style={{ fontSize: "26px" }} />
              </PageBar.Button>
              <PageBar.Button
                onClick={() =>
                  //  handleSelectTab(selectedTab)
                  toggleUnAssignedAddressPopup()
                }
              >
                Import
              </PageBar.Button>
            </div>
            <DataGrid
              rowData={assignedAddressList}
              loader={isLoadingAssignedAddress ? <Loader /> : null}
              columnDefs={companyAddressColnDef}
              onGridReady={onAssignedAddressGridReady}
              frameworkComponents={{
                actionTemplate: GroupItemDimensionActionTemplate,
              }}
              getRowStyle={function (data) {
                if (data.data.IsNewlyAdded) {
                  return { background: "#d5a10e" };
                }
                return { background: "" };
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AddressAssignment;
const GroupItemDimensionActionTemplate = (props) => {
  const setConfirmAlert = props.setConfirmAlert;
  const onClickDeleteAssignedAddress = () => {
    props.onClickDeleteAssignedAddress(props.data);
  };

  const toggleHistoryData = () => {
    debugger;
    props.toggleHistory(props.data, "Assigned");
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
        <span
          style={{
            cursor: "pointer",
            display: "grid",
            marginRight: "7px",
            marginTop: "1px",
          }}
          title="History"
        >
          <ImEye title="View History" style={{ fontSize: "18px", color: "#34495E" }} onClick={() => toggleHistoryData()} />
        </span>
        <span
          style={{
            cursor: "pointer",
            display: "grid",
            marginRight: "7px",
            marginTop: "1px",
          }}
          title="Delete"
        >
          <RiDeleteBin6Fill
            className="Biz__Icon"
            style={{ fontSize: "15px", color: "#f54e60" }}
            onClick={() =>
              setConfirmAlert({
                open: true,
                title: "Delete",
                msg: "Are you sure to delete ?",
                button: {
                  confirmText: "Delete",
                  abortText: "Cancel",
                  Color: "Danger",
                },
                onConfirm: () => onClickDeleteAssignedAddress(),
              })
            }
          />
        </span>
      </div>
    </React.Fragment>
  );
};
