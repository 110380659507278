const APIEndpoints = {
  CheckClientUserCredentails: "https://identity.biznextcontroller.com/api/auth/login",
  CommonMaster: {
    CommonMaster: "master-data",
    master: "master-data/value",
  },
  Company: {
    CompanyMasterList: "company-master/list",
    CompanyUser: "company-master/company-user",
    CompanyAddress: "company-master/address/list",
    CreateCompany: "company-master/create",
    CompanyMasterUpdate: "company-master/update",
    CompanyMasterUpdateStatus: "company-master/updateStatus",
    CompanyMasterAddressLocation: "company-master/address/location",
    CompanyMasterAddressRelationList: "company-master/address/relation-list",
    CompanyMasterAddressRelation: "company-master/address/relation",
    CompanyMasterAddressCreate: "company-master/address/create",
    CompanyMasterAddressUpdate: "company-master/address/update",
    CompanyMasterAddressDefault: "company-master/address/default",
    CompanyParameterList: "company-master/parameter",
    GetCompanyUserMenu: "company-master/menu",
    CreateCompanyUserMenu: "company-master/menu/create",
    GetCompanyUserMenuAssign: "company-master/menu-assign",
    ManageCompanyUserMenuAssign: "company-master/menu-assign/manage",
    CreateCompanyParameter: "company-master/parameter/create",
    BiznextParameterList: "biznext/parameter",
    BiznextParameterValue: "biznext/parameter/values",
    MenuListType: "menu-master/types",
    GetCompanyMenuAssign: "company-master/company-menu",
    ManageCompanyMenuAssign: "company-master/company-menu/manage",
    GetCityList: "master-data/city",
    CompanyMasterUserMenu: "company-master/user-menu",
    CreateUserMenu: "company-master/user-menu/create",
    CompanyConfigList: "company-master/config",
    BiznextSupportUserCreate: "user/bs/user-create",
    CreateCompanyConfig: "company-master/config/create",
    UpdateCompanyConfig: "company-master/config/update",
    UpdateConfigStatus: "company-master/config/update-status",
    GetHistoryData: "company-master/address/relation-history",
  },
  Customer: {
    CustomerMasterList: "customer-master/list",
    CustomerStatus: "customer-master/status",
    CreateCustomer: "customer-master/create",
  },
  User: {
    CreateUser: "user/create",
    CreateUserProfile: "user/profile/create",
    enableDisableUser: "/user/update-status",
    RightsAssignOrUnAssigned: "user/rights/manage",
    RightsProfileAssignOrUnAssigned: "user/profile/rights/manage",
    GetRightsList: "user/rights/select",
    ManageUserProfileAssign: "user/profile/manage",
    UserProfileAssign: "",
    UserLoginHistory: "user/login-history",
    GetUserReference: "BMCIG/USER/GetUserReference",
    resetPassword: "user/update-password",
    profileHistory: "user/profile/rights/select",
    UserHistory: "user/rights/select",
  },
  Module: {
    GetModuleMaster: "module-master",
  },
  MenuManagement: {
    MenuMasterList: "menu-master",
    MenuMasterCreate: "menu-master/create",
    MenuMasterUpdate: "menu-master/update",
  },
  Master: {
    PageMaster: {
      GetPageMasterData: "Page-Master/PageMaster/Get",
      CreatePageMasterData: "Page-Master/PageMaster/Create",
      UpdatePageMasterData: "Page-Master/PageMaster/Update",
      GetPageControlType: "Setup/Common/GetCommonValue",
    },
    PageConfig: {
      GetPageConfigData: "Page-Master/PageConfig/Get",
      CreatePageConfigData: "Page-Master/PageConfig/Create",
      UpdatePageConfigData: "Page-Master/PageConfig/Update",
    },
    PageVariable: {
      GetPageVariableData: "Page-Master/PageVariable/Get",
      CreatePageVariableData: "Page-Master/PageVariable/Create",
      UpdatePageVariableData: "Page-Master/PageVariable/Update",
    },
    pageParamter: {
      GetpageParamterData: "Page-Master/PageParameter/Get",
      CreatepageParamterData: "Page-Master/PageParameter/Create",
      UpdatepageParamterData: "Page-Master/PageParameter/Update",
      GetpageParamterValueData: "Page-Master/PageParameterValues/Get",
      CreatepageParamterValuerData: "Page-Master/PageParameterValues/Create",
      UpdatepageParamterValueData: "Page-Master/PageParameterValues/Update",
    },
    AssignedParameter: {
      GetAssignedParameterData: "Page-Master/ParameterAssign/Get",
      AssignParameterData: "Page-Master/ParameterAssign/Assign",
      DeleteParameterData: "Page-Master/ParameterAssign/Delete",
    },
    Tax: {
      TaxList: "Tax-Category/TaxCategory/Get",
      TaxDefinitionValue: "Tax-Category/TaxDefinition/Get",
      createTaxData: "Tax-Category/TaxCategory/Create",
      createTaxDefinitionData: "Tax-Category/TaxDefinition/Create",
      GetTaxLocalization: "Tax-Category/Localisation/Get",
      CreateTaxLocalization: "Tax-Category/Localisation/Create",
      DeleteTaxLocalization: "Tax-Category/Localisation/Delete",
      TaxCategoryStatusUpdate: "Tax-Category/TaxCategory/Update/Status",
      TaxDefinitionStatusUpdate: "Tax-Category/TaxDefinition/Update/Status",
      UpdateTaxData: "Tax-Category/TaxCategory/Update",
      UpdateTaxDefData: "Tax-Category/TaxDefinition/Update",
    },
    CommonMaster: {
      CommonMasterCode: "master-data/code",
      CreateMasterData: "master-data/code/create",
      CreateDataValue: "master-data/value/create",
      CommonMasterDataValue: "master-data/value",
    },
    CommonRelation: {
      CommonRelationList: "common-relation",
      CommonRelationValue: "common-relation/first-value",
      createCommonRelationData: "common-relation/create",
      CreateCommonRelationValue: "common-relation/value/create",
    },
    GetDepartmentList: "hrms/department",
    CreateDepartment: "hrms/department/create",
    GetDesignation: "hrms/designation",
    createDesignation: "hrms/designation/create",
    GetDivision: "hrms/division",
    createDivision: "hrms/division/create",
    GetBankMasterData: "Bank-Master/Get",
    CreateBankMaster: "Bank-Master/Create",
    UpdateBankMaster: "Bank-Master/Update",
    BankMasterStatusUpdate: "Bank-Master/Active",
  },
  AccessControl: {
    createUserProfile: "user/profile/create",
    GetCompanyUserProfile: "user/profile",
    userProfileMenuAssignManage: "user/profile/menu-assign-manage",
    UpdateCompanyUserProfile: "user/profile/update",
    UpdateUserProfileStatus: "user/profile/update-status",
    GetBiznextRights: "biznext/rights",
    CreateBiznextRights: "biznext/rights/create",
  },
  Support: {
    SupportUsersData: "user/bs/users",
    CreateSupportUser: "user/bs/user-create",
    getEncryptdata: "auth/password-encryption",
    deleteSupportUser: "user/bs/delete",
    resetPassword: "user/bs/update-password",
    AssignCompany: "user/bs/company-assign-manage",
  },
  GeographicalData: {
    GetCountry: "geographic-data/country/get",
    GetStates: "geographic-data/state/get",
    GetCities: "geographic-data/city/get",
    CreateCity: "geographic-data/city/create",
    CreateState: "geographic-data/state/create",
    CreateCountry: "geographic-data/country/create",
    UpdateCountry: "geographic-data/country/update",
    UpdateState: "geographic-data/state/update",
    UpdateCountryStatus: "geographic-data/country/update-status",
    UpdateStateStatusData: "geographic-data/state/update-status",
    UpdateCityStatusData: "geographic-data/city/update-status",
    UpdateCity: "geographic-data/city/update",
  },
  Report: {
    APIHistory: "report/api-calling",
  },
};

export default APIEndpoints;
