import axios from "axios";
import APIEndpoints from "../../../APIMethods/APIEndpoints/APIEndpoints";
import { getSessionStorage, checkAuthExist } from "../../../APIMethods/Auth/auth";
import ApiCalling from "../../../APIMethods/Utilities/ApiCalling/ApiCalling";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";

const publicIp = require("public-ip");

export const getCustomerMasterSelect = async () => {
  const requestData = {
    action: "CUSTOMERLIST",
    searchText: "",
    customerMasterId: 0,
    filterId: 0,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling(requestData, APIEndpoints.Customer.CustomerMasterList);
  return response;
};

export const createCustomer = async (request) => {
  const requestData = {
    ...request,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Customer.CreateCustomer);
  return response;
};
