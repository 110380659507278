import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { CreatepageParamterValuerData, GetPageParamterValueData, UpdatepageParamterValueData } from "../../Resources/PageParameterService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddPageParameterValue = (props) => {
  const firstTextInput = useRef();
  let selectedpageParameter = props.selectedpageParameter;
  let toggleAddpageParameterValuePopup = props.toggleAddpageParameterValuePopup;
  let setMsgAlert = props.setMsgAlert;
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  let updatepageParameterValueList = props.updatepageParameterValueList;
  const _updateListingpageParamterValue = props._updateListingpageParamterValue;
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtDefValue: "",
    txtTaxType: null,
    txtParameterValueName: "",
    txtPageParameter: "",
  });
  const [oldFormValues, setOldFormValues] = useState({
    txtDefValue: "",
    txtTaxType: null,
    txtParameterValueName: "",
    txtPageParameter: "",
  });

  const TaxTypeOptions = [
    { Name: "Percentage", Value: 1 },
    { Name: "Fixed", Value: 2 },
  ];

  useEffect(() => {
    if (isEditMode) {
      if (popupData) {
        setEditBankMaster(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);
  const [editBankMaster, setEditBankMaster] = useState();
  useEffect(() => {
    if (editBankMaster) {
      formValues.txtParameterValueName = editBankMaster.ParameterValue;
    }
    setFormValues({ ...formValues });
    setOldFormValues({ ...formValues });
  }, [editBankMaster]);

  const validateField = (name, value) => {
    let errorMsg = "";
    if (name === "txtParameterValueName") {
      if (!value || typeof value === "undefined") {
        errorMsg = "Cannot be empty";
      }
    }
    return errorMsg;
  };

  const [formValidationError, setFormValidationError] = useState({});
  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;

      errors["txtParameterValueName"] = validateField("txtParameterValueName", formValues.txtParameterValueName);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);
      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "error", message: "Something went wrong!" });
      return false;
    }
  };

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };
  const [saveMore, setSaveMore] = useState(false);

  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }

      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;

      let formData = {};
      if (isEditMode) {
        formData = {
          PageParameterValueID: popupData?.PageParameterValueID || 0,
          PageParameterID: selectedpageParameter?.PageParameterID || 0,
          ParameterValue: formValues?.txtParameterValueName || "",

          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          PageParameterID: selectedpageParameter?.PageParameterID || 0,
          ParameterValue: formValues?.txtParameterValueName || "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }

      setBtnloaderActive(true);
      let fun = isEditMode ? UpdatepageParamterValueData : CreatepageParamterValuerData;
      let result = await fun(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          let addedCode = {
            ParameterValue: formValues?.txtParameterValueName || "",
            PageParameterValueID: isEditMode
              ? popupData.PageParameterValueID
              : result.responseData && result.responseData.data && result.responseData.data.PageParameterValueID
              ? result.responseData.data.PageParameterValueID
              : 0,
            IsNewlyAdded: true,
          };
          if (isEditMode) {
            _updateListingpageParamterValue({ ...addedCode, IsNewlyAdded: false, IsUpdated: true });
            toggleAddpageParameterValuePopup(false, null);
          } else {
            setSaveMore(true);
            updatepageParameterValueList(addedCode);
          }

          clearForm();
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to Update" : "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && popupData) {
      return true;
    }

    return false;
  };

  const [definitionValueList, setDefinitionValueList] = useState([]);
  const [isLoadingDefinitionValue, setIsLoadingDefinitionValue] = useState(false);
  const getDefinitionValue = async (data) => {
    console.log("getDefinitionValue", data);
    const ip = await publicIpv4();
    try {
      const formData = {
        SearchText: "#ALL",
        TaxDefinitionID: 0,
        TaxCategoryID: data?.TaxCategoryID || 0,
        TaxDefinitionType: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setIsLoadingDefinitionValue(true);
      const result = await GetPageParamterValueData(formData);
      console.log("getDefinitionValue", result);
      setIsLoadingDefinitionValue(false);
      debugger;
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setDefinitionValueList(result.responseData);
          console.log("onClickGetTax", result.responseData);
        } else {
          setDefinitionValueList([]);
        }
      } else {
        setDefinitionValueList([]);
        setMsgAlert({
          open: true,
          type: "error",
          msg: result.responseMessage,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtDefValue: "",
      txtTaxType: null,
      txtParameterValueName: "",
      txtPageParameter: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  // useEffect(() => {
  //   getDefinitionValue(selectedpageParameter);
  // }, []);

  return (
    <Modal
      varient={"center"}
      title={isEditMode ? "Update  Page Parameter Value" : "Add Page Parameter Value"}
      onSubmit={handleSave}
      show={() => toggleAddpageParameterValuePopup(false, null)}
    >
      <Modal.Body>
        <Form>
          <Form.Group style={{ rowGap: "0px" }} controlwidth={"320px"}>
            {/* <Form.InputGroup errorMsg={formValidationError["txtPageParameter"]} label="Page Parameter" req={true}>
              <Form.InputControl
                control="select"
                name="txtPageParameter"
                getOptionLabel={(option) => `${option.TaxDefinitionName}`}
                getOptionValue={(option) => `${option}`}
                isLoading={isLoadingDefinitionValue}
                options={definitionValueList}
                value={formValues.txtPageParameter}
                onChange={(e) => updateState("txtPageParameter", e)}
              />
            </Form.InputGroup> */}

            <Form.InputGroup errorMsg={formValidationError["txtParameterValueName"]} label="Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtParameterValueName"
                type="text"
                value={formValues.txtParameterValueName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                maxLength={100}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" disabled={checkEditFormControlValues() || btnloaderActive} type="submit" trigger={false}>
          {isEditMode ? "Update" : saveMore ? "Save More" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddPageParameterValue;
