import axios from "axios";
import { checkAuthExist, getSessionStorage } from "../../Auth/auth";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import { Buffer } from "buffer";
import APIEndpoints from "../../APIEndpoints/APIEndpoints";
import { Success } from "../../../Configration/Utilities/Constants";
import * as Sentry from "@sentry/react";
import ClientConfig from "../../../Configration/ClientConfig/ClientConfig.json";
const pako = require("pako");
const publicIp = require("public-ip");

export function getBaseURL() {
  let baseURL = {
    URL: "",
  };
  if (window.location.hostname === "localhost") {
    baseURL.URL = ClientConfig.devBaseUrl;
  } else if (window.location.hostname === ClientConfig.devHostName) {
    baseURL.URL = ClientConfig.devBaseUrl;
  } else if (window.location.hostname === ClientConfig.productionHostName) {
    baseURL.URL = ClientConfig.productionBaseUrl;
  }
  return baseURL;
}

export const ApiCalling = async (requestApiData, apiPath, commonName, header) => {
  // const ip = await publicIp.v4();
  const ip = "0.00.00.00";
  const user = getSessionStorage("user");
  const requestData = {
    ...requestApiData,
  };

  try {
    // console.log(JSON.stringify(requestData), "RequestData");
    // console.log(APIEndpoints.baseUrl + apiPath, "Api Url");
    // console.log(user, "user");
    let urlValue = getBaseURL();

    const response = await axios.post(urlValue.URL + apiPath, requestData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: user.Token,
        // apiaccesscode: apiPath.accessCode,
        ...header,
      },
    });

    if ((response && response.status === 200) || response.status === 201) {
      console.log(response, "response");
      const result = await response.data;
      if (result.code === 200) {
        if (result.data) {
          return {
            responseCode: 1,
            responseData: result.data,
            responseMessage: result.message,
          };
        }
        return {
          responseCode: 1,
          responseData: null,
          responseMessage: result.message,
        };
      }
      return {
        responseCode: result.ResponseCode,
        responseData: null,
        responseMessage: result.message,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    if (!checkAuthExist() && error && error.response && error.response.status === 401) {
      return {
        responseCode: 401,
        responseData: null,
        responseMessage: CustomMsg.UnAuthorized,
      };
    } else if (error && error.response && error.response.status === 400) {
      return {
        ResponseCode: 0,
        responseData: null,
        responseMessage: error && error.response && error.response.data && error.response.data.message ? error.response.data.message : CustomMsg.Error,
      };
    } else if (error && error.response && error.response.status === 412) {
      return {
        ResponseCode: 0,
        responseData: null,
        responseMessage: error && error.response && error.response.data && error.response.data.message ? error.response.data.message : CustomMsg.Error,
      };
    }

    return {
      ResponseCode: 0,
      responseData: null,
      responseMessage: error && error.response && error.response.data && error.response.data.message ? error.response.data.message : CustomMsg.Error,
    };
  }
};

export default ApiCalling;

// ************************************** Code For Approval  ************************************//
export const getMasterDataFileList = async (request) => {
  const requestData = {
    main: {
      masterDataCode: request ? request : 0,
    },
    objCommon: {},
  };

  const response = await ApiCalling(requestData, APIEndpoints.ItemMaster.GetMasterData);
  return response;
};

//********************************* BMC Api Calling Start ***********************************//

export const apiCallingWithFormData = async (requestApiData, apiPath) => {
  try {
    if (!checkAuthExist()) {
      return {
        ResponseCode: 401,
        responseData: null,
        responseMessage: "Session Expired",
      };
    }

    const user = getSessionStorage("user");

    const response = await axios({
      method: "post",
      url: user.BaseURL + apiPath,
      data: requestApiData,
      headers: {
        "Content-Type": "multipart/form-data",
        apiToken: user && user.ApiToken && user.ApiToken.ApiToken ? user.ApiToken.ApiToken : "",
        apiClientName: user && user.ClientName ? user.ClientName : "",
        apiUserName: user && user.AppAccessUID ? user.AppAccessUID : "",
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (response && response.status === 200) {
      const result = await response.data;
      if (result.ResponseCode.toString() === "1") {
        const buff = Buffer.from(result.responseDynamic ? result.responseDynamic : "", "base64");
        if (buff.length !== 0) {
          const data = JSON.parse(pako.inflate(buff, { to: "string" }));
          return {
            ResponseCode: 1,
            responseData: data,
            responseMessage: result.responseMessage,
          };
        }
        return {
          ResponseCode: 1,
          responseData: [],
          responseMessage: result.responseMessage,
        };
      }
      return {
        ResponseCode: Number(result.ResponseCode),
        responseData: null,
        responseMessage: result.responseMessage,
      };
    }
    return { ResponseCode: 0, responseData: null, responseMessage: "" };
  } catch (error) {
    Sentry.captureException(error);
    if (!checkAuthExist() && error && error.response && error.response.status === 401) {
      return {
        ResponseCode: 401,
        responseData: null,
        responseMessage: "Something went wrong",
      };
    }
    console.log(error);
    return {
      ResponseCode: 0,
      responseData: null,
      responseMessage: error && error.response && error.response.data && error.response.statusText ? error.response.statusText : "Something went wrong",
    };
  }
};
