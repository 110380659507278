import React, { useState, useEffect, useMemo } from "react";
import "./Login.css";
import { useForm } from "react-hook-form";
import BizNext_Logo from "../../../../Framework/Assets/Images_Components/biznext-logo.svg";
import { RiErrorWarningLine, RiCloseLine } from "react-icons/ri";
import LoginBanner from "../../../../Framework/Assets/Images_Components/LoginBanner.svg";

import ErrorMessage from "../../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { checkAuthExist, setSessionStorage } from "../../../../APIMethods/Auth/auth";
import { CustomMsg } from "../../../../Configration/Utilities/customMessage";
import { authenticate } from "./Services/authenticateService";

function Login(props) {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const navigate = useNavigate();
  const togglePassword = () => {
    setRevealPassword(!revealPassword);
  };

  useMemo(() => {
    if (checkAuthExist()) {
      navigate("/home");
    } else {
      navigate("/");
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      debugger;
      console.log(data, "data");
      setBtnloaderActive(true);
      let result = await authenticate(data.txtUserName, data.txtPassword);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        if (result.responseData) {
          if (!result.responseData.token) {
            setMsgAlert({
              open: true,
              type: "error",
              msg: "Token is missing in the response",
            });
            console.log(result.responseData.token);
          } else {
            let currentDate = new Date();
            let minutes = 28;
            if (result.responseData.expirationTime) {
              minutes = Number(result.responseData.expirationTime) - 2;
            }
            let user = {
              User: result.responseData,
              UserName: data.txtUserName,
              Token: result.responseData.token,
              ...result.responseData,
              Expiry: new Date(currentDate.getTime() + minutes * 60000),
            };
            sessionStorage.clear();
            setSessionStorage("user", user);
            navigate("/home");
            props.loginHandler(true);
          }
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    if (msgAlert.open) {
      const timer = setTimeout(() => {
        setMsgAlert({ open: false, type: "", msg: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [msgAlert]);

  return (
    <div className="Main_LoginDiv">
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      <div className="login__ContentDiv">
        <form className="login__ContentBoxDiv" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="login__ContentBoxDivTxtV1">
            <span>BizNext</span> Controller
          </h2>
          <label className="login__ContentBoxDivIntxtLabl">Username</label>
          <input type="text" className="login__ContentBoxDivInBox" {...register("txtUserName", { required: true })} placeholder="" />
          {errors.txtUserName && <div className="login___ErrorTxt">Username is required</div>}
          <label className="login__ContentBoxDivIntxtLabl">Password</label>
          <input
            type={revealPassword ? "text" : "password"}
            className="login__ContentBoxDivInBox Biz_Bm__lg_InputPassbox"
            placeholder=""
            {...register("txtPassword", { required: true })}
          />
          <span onClick={togglePassword}>{revealPassword ? <AiFillEyeInvisible className="Biz_Bm__lgpass-icon" /> : <AiFillEye className="Biz_Bm__lgpass-icon" />}</span>
          {errors.txtPassword && <div className="login___ErrorTxt">Password is required</div>}
          <button type="submit" className={btnloaderActive ? "login__ContentBoxDivBtn FormBtnActive" : "login__ContentBoxDivBtn"}>
            <span className="login__BtnTxt">Login</span>
            <div className="login__btn__spinner">
              <div className="login__btn__bounce1"></div>
              <div className="login__btn__bounce2"></div>
              <div className="login__btn__bounce3"></div>
            </div>
          </button>
        </form>
        <div className="login__ContentBoxDivider">
          <h2 className="login__ContentBoxDividerTxt">© Product of 3S Logics Pvt. Ltd</h2>
        </div>
      </div>
      <div className="login__BizLogoDiv">
        <div className="login__BizLogoBox">
          <div className="login__BizLogoContentBox">
            <img src={BizNext_Logo} />
          </div>
        </div>
      </div>
      <div className="login__ImgDiv">
        <img src={LoginBanner} className="login__ImgBanner" />
      </div>
    </div>
  );
}
export default Login;
