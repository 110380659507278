import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { addCommonMasterCode } from "../../Resources/CommonMasterService";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddNewMaster = (props) => {
  let toggleAddNewMasterPopupActive = props.toggleAddNewMasterPopupActive;
  let setMsgAlert = props.setMsgAlert;
  let updateCommonCodeList = props.updateCommonCodeList;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtMasterName: "",
    txtDataType: null,
    txtAllowedCharacher: "",
    hasFixedValue: false,
  });

  const [formValidationError, setFormValidationError] = useState({});

  const options = [
    { value: "105001", label: "Int" },
    { value: "105001", label: "Date" },
    { value: "105001", label: "DateTime" },
    { value: "105001", label: "Text" },
    { value: "105001", label: "Double" },
  ];

  const firstTextInput = useRef();
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtMasterName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Master Name";
      }
    }
    if (name === "txtDataType") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Data Type";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtMasterName"] = validateField("txtMasterName", formValues.txtMasterName);
      errors["txtDataType"] = validateField("txtDataType", formValues.txtDataType);

      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      let formData = {
        commonMasterName: formValues.txtMasterName,
        hasFixValue: formValues.hasFixedValue ? 1 : 0,
        valueDataType: formValues.txtDataType && formValues.txtDataType.value ? formValues.txtDataType.value : 0,
        controlType: 0,
        allowedCharacter: formValues.txtAllowedCharacher,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await addCommonMasterCode(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          if (result.responseData && result.responseData.length > 0) {
            let addedCode = {
              CommonMasterCode: result.responseData.CommonMasterCode ? result.responseData.CommonMasterCode : 0,
              AllowedCharacter: formValues.txtAllowedCharacher,
              HasFixValue: formValues.hasFixedValue,
              ValueDataType: formValues.txtDataType && formValues.txtDataType.value ? formValues.txtDataType.value : 0,
              ActiveStatus: "",
              AllowedCharacter: formValues.txtAllowedCharacher,
              CommonMasterName: formValues.txtMasterName,
              ControlType: 0,
              InsertDateTime: "",
              InsertIPAddress: null,
              InsertUserID: null,
              IsDeleteFlag: 0,
              IsNewlyAdded: true,
            };
            updateCommonCodeList(addedCode);
            clearForm();
            toggleAddNewMasterPopupActive();
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtMasterName: "",
      txtDataType: null,
      txtAllowedCharacher: "",
      hasFixedValue: false,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title="Add New Master" onSubmit={onSubmit} show={() => toggleAddNewMasterPopupActive()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtMasterName"]} label="Master Name" req={true}>
              <Form.InputControl control="input" name="txtMasterName" type="text" value={formValues.txtMasterName} onChange={(e) => updateState(e.target.name, e.target.value)} />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtDataType"]} label="Data Type" req={true}>
              <Form.InputControl
                control="select"
                name="txtDataType"
                label="Select DataType"
                options={options}
                value={formValues.txtDataType}
                getOptionValue={(option) => `${option}`}
                onChange={(e) => updateState("txtDataType", e)}
                getOptionLabel={(option) => `${option.label}`}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtAllowedCharacher"]} req={false} label="Allowed character">
              <Form.InputControl
                control="input"
                name="txtAllowedCharacher"
                type="text"
                value={formValues.txtAllowedCharacher}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["hasFixedValue"]} label="Has FixValue">
              <Form.InputControl control="switch" name="hasFixedValue" checked={formValues.hasFixedValue} onChange={(e) => updateState(e.target.name, e.target.checked)} />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewMaster;
