import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BiRefresh } from "react-icons/bi";
import { FaEdit, FaTimes } from "react-icons/fa";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import PageTitle, { PageButton, PageSearch } from "../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, { FooterBox } from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { designationData } from "../Department/Resources/DeptService";
import AddDesignation from "./Views/AddDesignation";
// import AddDesignation from "./Views/AddDesignation";

const Designation = () => {
  let navigate = useNavigate();
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const [AddformPopupActive, setAddformPopupActive] = useState(false);

  const [DesignationList, setDesignationList] = useState([]);
  const [isLoadingDesgnList, setIsLoadingDesgnList] = useState(false);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getDesignationList = async () => {
    try {
      setIsLoadingDesgnList(true);
      const formData = {
        viewMode: "LIST",
        designationId: 0,
      };
      let result = await designationData(formData);
      console.log(result, "result");
      setIsLoadingDesgnList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setDesignationList(result.responseData);
        } else {
          setDesignationList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getDesignationList();
  }, []);

  const [AddDesignationPopup, setAddDesignationPopup] = useState(false);
  const toggleAddDesignation = () => {
    setAddDesignationPopup(!AddDesignationPopup);
  };

  const gridColumnDefs = [
    { field: "#", headerName: "Action", width: 80, pinned: "left", lockPosition: "1", cellRenderer: "actionTemplate", cellRendererParams: { toggleAddDesignation } },
    {
      field: "DesignationID",
      headerName: "Designation Code",
      width: 150,
      pinned: "left",
      lockPosition: "1",
    },
    {
      field: "DesignationName",
      headerName: "Designation Name",
      width: 280,
      pinned: "left",
    },
    { field: "ShortCode", headerName: "Short Code", width: 150 },
  ];

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const updateDesignationList = (department) => {
    DesignationList.unshift(department);
    setDesignationList(DesignationList);
    if (gridApi) {
      gridApi.setRowData(DesignationList);
    }
  };

  return (
    <React.Fragment>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {AddDesignationPopup ? <AddDesignation setMsgAlert={setMsgAlert} updateDesignationList={updateDesignationList} toggleAddDesignation={toggleAddDesignation} /> : null}
      <div className={`Biz_Listing_PageStart`}>
        <PageTitle Title="Designation">
          <PageSearch focus={true} onClick={() => getDesignationList()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
          <PageButton onClick={() => toggleAddDesignation()}>Add</PageButton>
        </PageTitle>
        <DataGrid
          rowData={DesignationList}
          columnDefs={gridColumnDefs}
          onGridReady={onGridReady}
          loader={isLoadingDesgnList ? <Loader /> : null}
          getRowStyle={function (data) {
            if (data.data.IsSelected) {
              return { background: "#ffc176" };
            } else {
              if (data.data.IsNewlyAdded) {
                return { background: "#d5a10e" };
              }
              return { background: "" };
            }
          }}
          frameworkComponents={{
            actionTemplate: cellTemplate,
          }}
        />

        <Footer>
          <FooterBox Aligned={"right"}></FooterBox>
        </Footer>
      </div>
    </React.Fragment>
  );
};

export default Designation;
const cellTemplate = (props) => {
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View List"
      >
        <FaEdit style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => props.toggleAddDesignation(props.data)} />
      </span>
    </div>
  );
};
