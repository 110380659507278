import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const getCommonRelation = async () => {
  const requestData = {
    searchText: "#ALL",
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.CommonRelation.CommonRelationList);
  return response;
};

export const getCommonRelationFirstValue = async (relationId) => {
  const requestData = {
    relationId: relationId,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.CommonRelation.CommonRelationValue);
  return response;
};

export const addCommonRelationData = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.CommonRelation.createCommonRelationData);
  return response;
};

export const addCommonRelationValueData = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.CommonRelation.CreateCommonRelationValue);
  return response;
};
