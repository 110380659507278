import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

const userData = getSessionStorage("user");

export const getRightsList = async (ViewMode, RightMasterID, ModuleID, MenuMasterID) => {
  const requestData = {
    rightMasterId: RightMasterID,
    viewMode: ViewMode,
    moduleId: ModuleID,
    menuMasterId: MenuMasterID,
  };

  const response = await ApiCalling(requestData, APIEndpoints.AccessControl.GetBiznextRights);
  return response;
};

export const getRightMasterInsert = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.AccessControl.CreateBiznextRights);
  return response;
};
