import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Company_Registration.css";
import Select from "react-select";
import { PageTitleSelectStyle } from "../../../Framework/Components/Widgets/SelectStyle/SelectStyle";
import Empty_data from "../../../Framework/Assets/images/empty_data.svg";
import { BsThreeDots, BsToggleOn } from "react-icons/bs";
import { FaSearch, FaEdit, FaPowerOff, FaCcMastercard, FaTimes, FaFileAlt } from "react-icons/fa";
import { MdLockReset } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { SkeletonCustomerDataV1, SkeletonCustomerDataV2, SkeletonDtboxData } from "../../../Framework/Components/Widgets/Skeleton/skeleton";
import {
  CompanySetDefaultAddress,
  getCompanyMasterSelect,
  companyConfigUpdateIsActive,
  getCompanyAddressSelect,
  getCompanyUserSelect,
  companyAddressLocationAssignment,
  UserResetPassword,
  getCompanyMasterConfig,
  getCompanyParameterSelect,
  getCompanyParameterValueSelect,
  getCompanyParameterList,
  getCompanyMasterConfigSelect,
  enableDisableCompanyUser,
} from "../Resources/companyRegistrationService";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import { formatDate } from "../../../Configration/Utilities/dateFormat";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import AddCompanyPopup from "./Views/AddCompanyPopUp/AddCompanyPopup";
import AddCompanyAddressPopup from "./Views/AddCompanyAddressPopup/AddCompanyAddressPopup";
import AddCompanyUserPopup from "./Views/AddCompanyUserPopup/AddCompanyUserPopup";
import AddCompanyConfigurationPopup from "./Views/AddCompanyConfigurationPopup/AddCompanyConfigurationPopup";
import ManageLocationTypePopup from "./Views/ManageLocationTypePopup/ManageLocationTypePopup";
import AddressAssignment from "./Views/AddressAssignment/AddressAssignment";
import AddParameter from "./Views/AddParameter/AddParameter";
import { HiArrowCircleRight } from "react-icons/hi";
import { getCommonMasterData } from "../Resources/commonService";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import AccessToSupport from "./Views/AccessToSupportPopup/AccessToSupport";
import UserProfileAssignPopup from "./Views/UserProfileAssignPopup/UserProfileAssignPopup";
import LoginHistoryPopup from "./Views/LoginHistoryPopup/LoginHistoryPopup";
import BMLoginSession from "../../Layout/BMLoginPopup/BM_Login";
import ResetPasswordPopup from "./Views/ResetPasswordPopup/ResetPasswordPopup";
import Form from "../../../Framework/Components/Layout/FormGroup/FormGroup";

function Company_Registration() {
  let navigate = useNavigate();
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });
  const [isLoadingCompanyList, setIsLoadingCompanyList] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [filteredCompanyList, setFilteredCompanyList] = useState([]);
  const [filteredCompanyConfigList, setFilteredCompanyConfigList] = useState([]);
  const [activeTabId, setActiveTabId] = useState("AD");
  const [isLoadingCompanyConfigList, setIsLoadingCompanyConfigList] = useState(false);

  const [selectedCompanyMaster, setSelectedCompanyMaster] = useState();

  const [companyAddress, setCompanyAddress] = useState([]);
  const [companyConfigList, setCompanyConfigList] = useState([]);
  const [companyUser, setCompanyUser] = useState([]);
  const [filteredCompanyUser, setFilteredCompanyUser] = useState([]);
  const [parameterList, setParameterList] = useState([{}, {}, {}]);
  const [addedParameterList, setAddedParameterLis] = useState([]);
  const [isLoadingParameterList, setIsLoadingParameterList] = useState(false);
  const [isLoadingCompanyParameterList, setIsLoadingCopmpanyParameterList] = useState(false);

  const [addCompanyPopupActive, setAddCompanyPopupActive] = useState({
    IsOpen: false,
    IsEditMode: false,
    popupData: null,
  });
  const [addCompanyAddressPopupActive, setAddCompanyAddressPopupActive] = useState({
    isOpen: false,
    popupData: null,
    IsEditMode: "",
  });
  const [addCompanyUserPopupActive, setAddCompanyUserPopupActive] = useState({
    IsOpen: false,
    IsEditMode: "",
    popupData: null,
  });
  const [addCompanyConfigurationPopupActive, setAddCompanyConfigurationPopupActive] = useState({
    IsOpen: false,
    IsEditMode: "",
    EditConfig: null,
    IsViewMode: "",
  });

  const [locationTypeList, setLocationTypeList] = useState([]);
  const [isLoadingLocationType, setIsLoadingLocationType] = useState(false);
  const [selectedLocationType, setSelectedLocationType] = useState();
  const [selectedCompanyConfig, setSelectedCompanyConfig] = useState();

  useEffect(() => {
    document.title = "Company" + " | " + "BizNext - An Integrated Business Management Platform";
  }, []);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const [formValues, setFormValues] = useState({
    txtReferenceType: null,
  });

  const referenceTypeOptions = [
    { Name: "EMP", Value: "EMP" },
    { Name: "BR", Value: "BR" },
    { Name: "ALL", Value: "#All" },
  ];

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
  };
  const [ParameterGridApi, setParameterGridApi] = useState();
  const onGridParameterReady = (params) => {
    setParameterGridApi(params.api);
  };

  const getCompanyList = async () => {
    debugger;
    try {
      setIsLoadingCompanyList(true);
      let result = await getCompanyMasterSelect("COMPANYLIST");
      setIsLoadingCompanyList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setCompanyList(result.responseData);
          setFilteredCompanyList(result.responseData);
        } else {
          setCompanyList([]);
          setFilteredCompanyList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyList();
    getParameterMasterList();
  }, []);

  const onClickCompanyMaster = async (data) => {
    if (selectedCompanyMaster && selectedCompanyMaster.CompanyMasterID === data.CompanyMasterID) {
      return;
    }
    setSearchTextUser("");
    setSearchTextAddress("");
    setSearchTextParameter("");
    setCompanyUser([]);
    setFilteredCompanyUser([]);
    setCompanyAddress([]);
    setCompanyConfigList([]);
    if (activeTabId === "US") {
      getCompanyUser("0", "0", data.CompanyMasterID, "SELECT", "#All");
      getCompanyMasterParameterList(data.CompanyMasterID);
    } else if (activeTabId === "AD") {
      if (selectedLocationType) {
        setSelectedLocationType(null);
      } else getLocationTypeList();
      getCompanyAddress(1, data.CompanyMasterID, 0, "GETADDRESS");
    } else if (activeTabId === "CF") {
      if (selectedCompanyConfig) {
        setSelectedCompanyConfig(null);
      } else getCompanyMasterConfig(data.CompanyMasterID);
    } else if (activeTabId === "PS") {
      getCompanyMasterParameterList(data.CompanyMasterID);
    }
    console.log("selected_Data", data);
    setSelectedCompanyMaster(data);
  };

  const onClickSearch = () => {
    getCompanyList();
  };

  const onSearchCompany = (e) => {
    let searchValue = e.target.value;
    if (searchValue !== "") {
      const filteredData = companyList.filter((data) => {
        return Object.values(data).join(" ").toLowerCase().includes(searchValue.toLowerCase());
      });
      setFilteredCompanyList(filteredData);
    } else {
      setFilteredCompanyList(companyList);
    }
  };
  const [searchTextUser, setSearchTextUser] = useState("");
  const onSearchUser = (val) => {
    setSearchTextUser(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };
  const [searchTextParameter, setSearchTextParameter] = useState("");
  const onSearchParameter = (val) => {
    setSearchTextParameter(val);
    ParameterGridApi.setQuickFilter(val);
    ParameterGridApi.refreshCells();
  };

  const [isLoadingCompanyUser, setIsloadingCompanyUser] = useState(false);
  const [userData, setUserData] = useState(false);
  const getCompanyUser = async (appAccessCode, companyUserId, companyMasterId, viewMode, userRelationType) => {
    try {
      setIsloadingCompanyUser(true);
      let result = await getCompanyUserSelect(1, 0, companyMasterId, "SELECT", userRelationType);
      setIsloadingCompanyUser(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setUserData(false);
          setCompanyUser(result.responseData);
          setFilteredCompanyUser(result.responseData);
        } else {
          setCompanyUser([]);
          setUserData(true);
          setFilteredCompanyUser([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const updateCompanyUserList = (data) => {
    companyUser.unshift(data);
    setCompanyUser(companyUser);
    if (gridApi) {
      gridApi.setRowData(companyUser);
    }
  };

  const getParameterMasterList = async () => {
    debugger;
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingParameterList(true);
      const formData = {
        action: "GETPARAMETER",
        biznextParameterId: 0,
        filterId: selectedCompanyMaster && selectedCompanyMaster.CompanyMasterID ? selectedCompanyMaster.CompanyMasterID : 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getCompanyParameterSelect(formData);
      setIsLoadingParameterList(false);
      console.log(result, "parameter results%%%%%%%");
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setParameterList(result.responseData);
        } else {
          setParameterList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const getCompanyMasterParameterList = async (companyMasterId) => {
    debugger;
    try {
      setIsLoadingCopmpanyParameterList(true);
      // const ip = await publicIpv4();
      const formData = {
        action: "LIST",
        companyParameterId: 0,
        companyMasterId: companyMasterId,
        objCommon: {
          insertUserId: 1,
          insertIpAddress: "0.00.00.00",
        },
      };
      let result = await getCompanyParameterList(formData);
      setIsLoadingCopmpanyParameterList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setAddedParameterLis(result.responseData);
        } else {
          setAddedParameterLis([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingParameterValue, setIsLoadingParameterValue] = useState(false);
  const [parameterValueList, setParameterValueList] = useState([]);
  const getCompanyParameterValue = async (biznextParameterId) => {
    try {
      const ip = await publicIpv4();
      setIsLoadingParameterValue(true);
      const formData = {
        searchText: "#ALL",
        biznextParameterId: biznextParameterId,
        filterId: 0,
        objCommon: {
          insertUserId: 1,
          insertIpAddress: ip,
        },
      };
      let result = await getCompanyParameterValueSelect(formData);
      setIsLoadingParameterValue(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setParameterValueList(result.responseData);
        } else {
          setParameterValueList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const getLocationTypeList = async () => {
    try {
      let result = await getCommonMasterData("LOCATIONTYPE");
      console.log(result, "locationType");
      console.log(result, "getLocationTypeList");
      if (result && result.responseCode === 1) {
        if (result.responseData) {
          setLocationTypeList(result.responseData);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [filteredAddress, setFilteredAddress] = useState([]);
  const [searchTextAddress, setSearchTextAddress] = useState("");
  const onSearchAddress = (e) => {
    setSearchTextAddress(e.target.value);
    if (searchTextAddress !== "") {
      const filteredData = companyAddress.filter((data) => {
        return Object.values(data).join(" ").toLowerCase().includes(searchTextAddress.toLowerCase());
      });
      setFilteredAddress(filteredData);
    } else {
      setFilteredAddress(companyAddress);
    }
  };
  const getCompanyAddress = async (companyAddressId, companyMasterId, locationTypeId, viewMode) => {
    try {
      let result = await getCompanyAddressSelect(companyAddressId, companyMasterId, locationTypeId, viewMode);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          setCompanyAddress(result.responseData);
          setFilteredAddress(result.responseData);
          //   console.log("setCompanyAddress", result.responseData);
        } else {
          setCompanyAddress([]);
          setFilteredAddress([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const updateAddressList = (data) => {
    companyAddress.unshift(data);
    setCompanyAddress(companyAddress);
  };

  const getCompanyMasterConfig = async (companyMasterId) => {
    try {
      setIsLoadingCompanyConfigList(true);
      let result = await getCompanyMasterConfigSelect(companyMasterId, "0", "CONFIGLIST");

      setIsLoadingCompanyConfigList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCompanyConfigList(result.responseData);
        } else {
          setCompanyConfigList(null);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedCompanyMaster) {
      if (activeTabId == "AD") {
        getCompanyAddress(1, selectedCompanyMaster.CompanyMasterID, 0, "GETADDRESS");
        getLocationTypeList();
      }
      if (activeTabId == "US") {
        getCompanyUser("0", "0", selectedCompanyMaster.CompanyMasterID, "SELECT", "#All");
      }
      if (activeTabId == "CF") {
        getCompanyMasterConfig(selectedCompanyMaster.CompanyMasterID);
      }
      if (activeTabId == "PS") {
        getCompanyMasterParameterList(selectedCompanyMaster.CompanyMasterID);
      }
    }
  }, [activeTabId]);
  let locationTypeId = selectedLocationType ? selectedLocationType.LocationTypeID : 0;
  useEffect(() => {
    if (selectedCompanyMaster) {
      if (selectedLocationType) {
        console.log(selectedLocationType, "selectedLocation");
        console.log(locationTypeId, "locationTypeId");
        getCompanyAddress(1, selectedCompanyMaster.CompanyMasterID, locationTypeId, "GETADDRESS");
      }
    }
  }, [selectedLocationType]);

  const updateSelectedCompanyList = (company) => {
    setSelectedCompanyMaster(company);
  };

  const updateCompanyList = (company, isEditData) => {
    if (!isEditData) {
      companyList.unshift(company);
      setCompanyList([]);
      setCompanyList(companyList);
    } else {
      const mappedData = companyList.map((data) => {
        if (data.CompanyMasterID === company.CompanyMasterID) {
          data.CountryName = company.CountryName;
          data.CountryMasterID = company.CountryMasterID;
        }
        return data;
      });
      setCompanyList([]);
      setCompanyList(mappedData);
    }

    setFilteredCompanyList([]);
    setFilteredCompanyList(companyList);
  };

  const updateCompanyAddress = (address) => {
    const mappedData = companyAddress.map((data) => {
      if (data.BMCAddressCode === address.BMCAddressCode) {
        data.IsDefaultAddress = 1;
      } else {
        data.IsDefaultAddress = 0;
      }
      return data;
    });
    setCompanyAddress([]);
    setCompanyAddress(mappedData);
  };
  const updateCompanyConfig = (config) => {
    setFilteredCompanyConfigList([]);
    setFilteredCompanyConfigList(config);
    setCompanyConfigList([]);
    setCompanyConfigList(config);
  };

  const onSetLocation = async (address, action, addressCode, locationTypeCode) => {
    try {
      let formData = {
        BMCAddressCode: address.BMCAddressCode.toString(),
        LocationTypeID: selectedLocationType.CommonMasterValueID.toString(),
        Action: "D",
      };
      let result = await companyAddressLocationAssignment(action, addressCode, locationTypeCode);
      if (result.responseCode === 1) {
        const locationTypeId = selectedLocationType ? selectedLocationType.CommonMasterValueID : 0;
        getCompanyAddress(1, selectedCompanyMaster.CompanyMasterID, locationTypeId, "GETADDRESS");
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const enableOrDisableUser = async (selectedRowData, type) => {
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      const ip = await publicIpv4();
      let action = selectedRowData.ActiveStatus === "Y" ? "N" : "Y";

      let formData = {
        appAccessCode: selectedRowData.BMCAppAccessCode,
        activeStatus: action,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await enableDisableCompanyUser(formData);
      if (result.responseCode === 1) {
        getCompanyUser("0", "0", selectedCompanyMaster.CompanyMasterID, "SELECT", "#All");
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });

        if (gridApi) {
          console.log(gridApi, "gridAPI");
          const itemsToUpdate = [];
          gridApi.forEachNode(function (rowNode) {
            if (selectedRowData.AppAccessID === rowNode.data.AppAccessID) {
              itemsToUpdate.push({ ...rowNode.data, ActiveStatus: type === "Y" ? "Y" : "N", IsUpdated: true });
              rowNode.setData({ ...rowNode.data, ActiveStatus: type === "Y" ? "Y" : "N", IsUpdated: true });
            } else {
              gridApi.setData(rowNode.data);
            }
          });
          // gridApi({
          //   update: itemsToUpdate,
          // });
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [userProfileAssignPopup, setUserAssignProfilePopup] = useState({
    IsOpen: false,
    popupData: null,
  });
  const toggleAssignProfile = (data) => {
    setUserAssignProfilePopup({
      IsOpen: !userProfileAssignPopup.IsOpen,
      popupData: data,
    });
  };

  const toggleAddCompanyPopup = (isEdit, data) => {
    setAddCompanyPopupActive({
      IsOpen: !addCompanyPopupActive.IsOpen,
      IsEditMode: isEdit,
      popupData: data,
    });
  };

  const toggleAddCompanyAddressPopup = (isEdit, editData) => {
    setAddCompanyAddressPopupActive({
      isOpen: !addCompanyAddressPopupActive.isOpen,
      IsEditMode: isEdit,
      popupData: editData,
    });
  };

  const toggleAddCompanyUserPopup = (isEdit, editData) => {
    setAddCompanyUserPopupActive({
      IsOpen: !addCompanyUserPopupActive.IsOpen,
      IsEditMode: isEdit,
      popupData: editData,
    });
  };

  const toggleAddCompanyConfigurationPopup = (isEdit, editData, isView) => {
    setAddCompanyConfigurationPopupActive({
      IsOpen: !addCompanyConfigurationPopupActive.IsOpen,
      IsEditMode: isEdit,
      EditConfig: editData,
      IsViewMode: isView,
    });
  };

  const [loginHistoryPopup, setLoginHistoryPopup] = useState({
    IsOpen: false,
    popupData: null,
  });
  const toggleLoginHistoryPopup = (data) => {
    setLoginHistoryPopup({
      IsOpen: !loginHistoryPopup.IsOpen,
      popupData: data,
    });
  };

  const [changePasswodPopupActive, setChangePasswodPopupActive] = useState({
    IsOpen: false,
    UserData: null,
  });

  const toggleResetPasswordPopup = (userData) => {
    console.log("toggleResetPasswordPopup", userData);
    setChangePasswodPopupActive({
      IsOpen: !changePasswodPopupActive.IsOpen,
      UserData: userData,
    });
  };

  const [manageLocationTypePopupActive, setManageLocationTypePopupActive] = useState({ IsOpen: false, Address: null });
  const toggleManageLocationTypePopup = (address) => {
    setManageLocationTypePopupActive({
      IsOpen: !manageLocationTypePopupActive.IsOpen,
      Address: address,
    });
  };

  const [addressAssignmentPopupActive, setAddressAssignmentPopupActive] = useState({ IsOpen: false, Address: null });
  const toggleAddressAssignmentPopup = (isopen, address) => {
    setAddressAssignmentPopupActive({ IsOpen: isopen, Address: address });
  };

  const [selectedParameter, setSelectedParameter] = useState([]);
  const onClickGetParameterValue = (data) => {
    console.log("onClickGetParameterValue", data);
    setSelectedParameter(data);
    getCompanyParameterValue(data.BiznextParameterID);
    setParameterValueList([]);
  };

  useEffect(() => {
    if (msgAlert.open) {
      const timer = setTimeout(() => {
        setMsgAlert({ open: false, type: "", msg: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [msgAlert]);

  const [bmSessionPopupActive, setBMSessionPopupActive] = useState(false);

  const ToggleLoginPopup = () => {
    const selectedCompanyProfileToken = getSessionStorage(selectedCompanyMaster.CompanyMasterID);
    console.log(selectedCompanyProfileToken, "selectedCompanyProfileToken");
    if (!userData) {
      if (!selectedCompanyProfileToken) {
        setBMSessionPopupActive(!bmSessionPopupActive);
      } else {
        toggleAddCompanyUserPopup(false, null);
      }
    }
    toggleAddCompanyUserPopup(false, null);
  };

  const CompanyUserColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 150,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellRendererParams: { toggleAddCompanyUserPopup, toggleResetPasswordPopup, enableOrDisableUser, setConfirmAlert, toggleAssignProfile, toggleLoginHistoryPopup },
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: "1" },
    { field: "UserDisplayName", headerName: "Name", width: 150 },
    { field: "UserLoginID", headerName: "Login ID", width: 150 },
    { field: "UserType", headerName: "User Type", width: 110 },
    { field: "UserRelationName", headerName: "Relation Name", width: 200 },
    { field: "ProfileName", headerName: "Profile Name", width: 150 },
    { field: "UserRelationType", headerName: "User Relation Type", width: 150 },
    { field: "LastLoginTime", headerName: "Last Login Time", width: 180 },
    { field: "AccessToken", headerName: "User Access Token", width: 380 },
  ];

  const CompanyParameterColumnDef = [
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: "1" },
    { field: "BiznextParameterName", headerName: "Parameter Name", width: 180 },
    { field: "ParameterValue", headerName: "Parameter Value", width: 240 },
  ];

  const [addNewParameterPopupActive, setAddNewParameterPopupActive] = useState(false);

  const toggleAddNewParameterPopupActive = () => {
    setAddNewParameterPopupActive(!addNewParameterPopupActive);
  };

  const updateParameterList = (data) => {
    addedParameterList.unshift(data);
    setAddedParameterLis(addedParameterList);
    if (ParameterGridApi) {
      ParameterGridApi.setRowData(addedParameterList);
    }
    console.log(ParameterGridApi, "ParameterGridApi");

    // ParameterGridApi.applyTransaction({
    //   add: [data],
    //   addIndex: 0,
    // });
  };
  // useEffect(()=>{
  // if(updateData && updateData.BiznextParameterID){
  // parameterList.unshift(updateData);
  // if(gridApi){

  // }
  // }
  // },[updateData]);

  return (
    <>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {addCompanyPopupActive.IsOpen ? (
        <AddCompanyPopup
          isEditMode={addCompanyPopupActive.IsEditMode}
          updateSelectedCompanyList={updateSelectedCompanyList}
          updateCompanyList={updateCompanyList}
          setMsgAlert={setMsgAlert}
          toggleAddCompanyPopup={toggleAddCompanyPopup}
          selectedCompanyMaster={selectedCompanyMaster}
          getCompanyList={getCompanyList}
          setConfirmAlert={setConfirmAlert}
          popupData={addCompanyPopupActive.popupData}
        />
      ) : null}
      {/* {bmSessionPopupActive && (
        <BMLoginSession
          selectedCompanyMaster={selectedCompanyMaster}
          setMsgAlert={setMsgAlert}
          toggleAddCompanyUserPopup={toggleAddCompanyUserPopup}
          setBMSessionPopupActive={setBMSessionPopupActive}
          ToggleLoginPopup={ToggleLoginPopup}
        />
      )} */}
      {addCompanyUserPopupActive.IsOpen ? (
        <AddCompanyUserPopup
          userData={userData}
          selectedAddress={addCompanyUserPopupActive.EditAddress}
          isEditMode={addCompanyUserPopupActive.IsEditMode}
          setMsgAlert={setMsgAlert}
          selectedCompanyMaster={selectedCompanyMaster}
          getCompanyUser={getCompanyUser}
          popupData={addCompanyUserPopupActive.popupData}
          toggleAddCompanyUserPopup={toggleAddCompanyUserPopup}
          updateCompanyUserList={updateCompanyUserList}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      {addCompanyAddressPopupActive.isOpen ? (
        <AddCompanyAddressPopup
          popupData={addCompanyAddressPopupActive.popupData}
          isEditMode={addCompanyAddressPopupActive.IsEditMode}
          getCompanyAddress={getCompanyAddress}
          selectedLocationType={selectedLocationType}
          selectedCompanyMaster={selectedCompanyMaster}
          setMsgAlert={setMsgAlert}
          toggleAddCompanyAddressPopup={toggleAddCompanyAddressPopup}
          updateAddressList={updateAddressList}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      {manageLocationTypePopupActive.IsOpen ? (
        <ManageLocationTypePopup
          selectedLocationType={selectedLocationType}
          getCompanyAddress={getCompanyAddress}
          locationTypeList={locationTypeList}
          address={manageLocationTypePopupActive.Address}
          toggleManageLocationTypePopup={toggleManageLocationTypePopup}
          setMsgAlert={setMsgAlert}
          selectedCompanyMaster={selectedCompanyMaster}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      {addCompanyConfigurationPopupActive.IsOpen ? (
        <AddCompanyConfigurationPopup
          selectedCompanyMaster={selectedCompanyMaster}
          selectedConfig={addCompanyConfigurationPopupActive.EditConfig}
          getCompanyMasterConfig={getCompanyMasterConfig}
          isEditMode={addCompanyConfigurationPopupActive.IsEditMode}
          IsViewMode={addCompanyConfigurationPopupActive.IsViewMode}
          setMsgAlert={setMsgAlert}
          toggleAddCompanyConfigurationPopup={toggleAddCompanyConfigurationPopup}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}

      {changePasswodPopupActive.IsOpen ? (
        <ResetPasswordPopup
          popupData={changePasswodPopupActive.UserData}
          toggleResetPasswordPopup={toggleResetPasswordPopup}
          setMsgAlert={setMsgAlert}
          selectedCompanyMaster={selectedCompanyMaster}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      {userProfileAssignPopup.IsOpen ? (
        <UserProfileAssignPopup
          setMsgAlert={setMsgAlert}
          selectedCompanyMaster={selectedCompanyMaster}
          popupData={userProfileAssignPopup.popupData}
          toggleAssignProfile={toggleAssignProfile}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      {loginHistoryPopup.IsOpen ? (
        <LoginHistoryPopup
          setMsgAlert={setMsgAlert}
          selectedCompanyMaster={selectedCompanyMaster}
          popupData={loginHistoryPopup.popupData}
          toggleLoginHistoryPopup={toggleLoginHistoryPopup}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      {addNewParameterPopupActive ? (
        <AddParameter
          data={selectedCompanyMaster}
          setMsgAlert={setMsgAlert}
          toggleAddNewParameterPopupActive={toggleAddNewParameterPopupActive}
          parameterValueList={parameterValueList}
          parameterList={parameterList}
          updateParameterList={updateParameterList}
          isLoadingParameterList={isLoadingParameterList}
          isLoadingParameterValue={isLoadingParameterValue}
          getParameterMasterList={getParameterMasterList}
          getCompanyParameterValue={getCompanyParameterValue}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      {addressAssignmentPopupActive.IsOpen ? (
        <AddressAssignment
          selectedCompanyMaster={selectedCompanyMaster}
          address={addressAssignmentPopupActive.Address}
          toggleAddressAssignmentPopup={toggleAddressAssignmentPopup}
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      <div className="Biz_Bm__Company Biz_Bm__PageStart">
        <div className="Biz_Bm__CompanyList_Div">
          <div className="Biz_Bm__CompanyList_SearchBox">
            <div className="Company__FormSearchDiv">
              <input type="text" onChange={(e) => onSearchCompany(e)} placeholder="Search..." className="Company__FormSearchBox" autoComplete="new-password" />
              <a className="Company__FormSearchAlIcon" onClick={() => onClickSearch()}>
                <FaSearch className="Biz_Bm__Icon" />
              </a>
            </div>
          </div>
          <div className="Biz_Bm__CompanyList">
            {!isLoadingCompanyList ? (
              filteredCompanyList.length > 0 ? (
                filteredCompanyList.map((data, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        selectedCompanyMaster && selectedCompanyMaster.CompanyMasterID === data.CompanyMasterID
                          ? "Biz_Bm__CompanyListBox Active__CompanyListBox"
                          : data.IsNewlyAdded
                          ? "Biz_Bm__CompanyListBox Newly_Added__CompanyList"
                          : "Biz_Bm__CompanyListBox"
                      }
                      onClick={() => onClickCompanyMaster(data)}
                    >
                      <h2>
                        {data.CompanyName ? `${data.CompanyName}` : ""} {data.CompanyCode ? `(${data.CompanyCode})` : ""}
                      </h2>
                      <h2>{data.Industry ? `${data.Industry}` : ""}</h2>
                      <p>{`${data.CountryName ? data.CountryName : "N/A"} || ${data.ImplementationDate ? formatDate(data.ImplementationDate) : "N/A"}`}</p>
                    </div>
                  );
                })
              ) : (
                <div className="Biz_Company_Empty_dataBox">
                  <img src={Empty_data} />
                  <p>No Results Found</p>
                </div>
              )
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <div className="Biz_Bm__CompanyDiv">
          <div className="Company__Pagetitle">
            <span>Company Details</span>
            <div className="Company__Pagetitle__box">
              <button onClick={() => toggleAddCompanyPopup(false, null)} className="Company__Pagetitle_btn Company__Pagetitle_btnSecd" biz-toltp="Add" biz-toltp-modifier="bottom">
                <GoPlus />
                Add
              </button>
              {selectedCompanyMaster && selectedCompanyMaster.CompanyMasterID ? (
                <button
                  onClick={() => toggleAddCompanyPopup(true, selectedCompanyMaster)}
                  className="Company__Pagetitle_btn Company__Pagetitle_btnSecd"
                  biz-toltp="Add"
                  biz-toltp-modifier="bottom"
                >
                  <GoPlus />
                  Edit
                </button>
              ) : null}
            </div>
          </div>
          <div className="Company__DetailBox">
            <div className="Company__DetailTxt">
              <div className="Company__DContentBox">
                <label>Company Name</label>
                <p>
                  {selectedCompanyMaster && selectedCompanyMaster.CompanyName ? (
                    selectedCompanyMaster.CompanyName + (selectedCompanyMaster.CompanyCode ? `(${selectedCompanyMaster.CompanyCode})` : "")
                  ) : (
                    <SkeletonCustomerDataV1 />
                  )}
                </p>
              </div>
              <div className="Company__DContentBox">
                <label>Country</label>
                <p>{selectedCompanyMaster && selectedCompanyMaster.CountryName ? selectedCompanyMaster.CountryName : <SkeletonCustomerDataV1 />}</p>
              </div>
              <div className="Company__DContentBox">
                <label>Industry Type</label>
                <p>{selectedCompanyMaster && selectedCompanyMaster.Industry ? selectedCompanyMaster.Industry : <SkeletonCustomerDataV2 />}</p>
              </div>
              <div className="Company__DContentBox">
                <label>Currency</label>
                <p>{selectedCompanyMaster && selectedCompanyMaster.Currency ? selectedCompanyMaster.Currency : <SkeletonCustomerDataV2 />}</p>
              </div>
              <div className="Company__DContentBox">
                <label>Language</label>
                <p>{selectedCompanyMaster && selectedCompanyMaster.Languages ? selectedCompanyMaster.Languages : <SkeletonCustomerDataV1 />}</p>
              </div>
              <div className="Company__DContentBox">
                <label>App Version</label>

                <p>{selectedCompanyMaster && selectedCompanyMaster.AppVersion ? selectedCompanyMaster.AppVersion : <SkeletonCustomerDataV1 />}</p>
              </div>
              <div className="Company__DContentBox">
                <label>Registration Date</label>
                <p>{selectedCompanyMaster && selectedCompanyMaster.ImplementationDate ? formatDate(selectedCompanyMaster.ImplementationDate) : <SkeletonCustomerDataV1 />}</p>
              </div>
            </div>
          </div>
          <div className="Company__MultiDetailBox">
            <div className="Company__MDB_BtnPanel">
              <ul className="Company__MDb_TabPanel">
                <li key="AD" onClick={() => setActiveTabId("AD")} className={activeTabId === "AD" ? "Company__MDb_TabList Active__MDb_TabList" : "Company__MDb_TabList "}>
                  Address
                </li>
                <li
                  key="CP"
                  onClick={() => {
                    setSearchTextAddress("");
                    setActiveTabId("CP");
                  }}
                  className={activeTabId === "CP" ? "Company__MDb_TabList Active__MDb_TabList" : "Company__MDb_TabList "}
                >
                  Contacts
                </li>
                <li
                  key="US"
                  onClick={() => {
                    setSearchTextAddress("");
                    setActiveTabId("US");
                  }}
                  className={activeTabId === "US" ? "Company__MDb_TabList Active__MDb_TabList" : "Company__MDb_TabList "}
                >
                  Users
                </li>
                <li
                  key="CF"
                  onClick={() => {
                    setSearchTextAddress("");
                    setActiveTabId("CF");
                  }}
                  className={activeTabId === "CF" ? "Company__MDb_TabList Active__MDb_TabList" : "Company__MDb_TabList "}
                >
                  Configuration
                </li>
                <li
                  key="PS"
                  onClick={() => {
                    setSearchTextAddress("");
                    setActiveTabId("PS");
                  }}
                  className={activeTabId === "PS" ? "Company__MDb_TabList Active__MDb_TabList" : "Company__MDb_TabList "}
                >
                  Parameter
                </li>
              </ul>
              <React.Fragment>
                {selectedCompanyMaster && selectedCompanyMaster.CompanyMasterID ? (
                  <React.Fragment>
                    {activeTabId === "AD" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "8px",
                        }}
                      >
                        <div style={{ minWidth: "220px", marginTop: "0px", height: "30px" }}>
                          <Select
                            name="LocationType"
                            menuPlacement="auto"
                            isClearable={true}
                            getOptionLabel={(option) => `${option.LocationType}`}
                            value={selectedLocationType}
                            getOptionValue={(option) => `${option}`}
                            options={locationTypeList}
                            onChange={(e) => setSelectedLocationType(e)}
                            isSearchable={true}
                            isLoading={isLoadingLocationType}
                            styles={PageTitleSelectStyle}
                            placeholder="Location Type"
                            noOptionsMessage={() => "No Result Found"}
                          ></Select>
                        </div>
                        <input
                          class="Menu_User_search"
                          placeholder="Search"
                          value={searchTextAddress}
                          style={{ marginTop: "0px", height: "26px" }}
                          onChange={(e) => onSearchAddress(e)}
                        />
                        <button onClick={() => toggleAddCompanyAddressPopup(false, null)} className="Company__Pagetitle_btn Company__Pagetitle_btnSecd">
                          <GoPlus />
                          Add Address
                        </button>
                      </div>
                    ) : null}
                    {activeTabId === "CP" ? (
                      <button className="Company__Pagetitle_btn Company__Pagetitle_btnSecd">
                        <GoPlus />
                        Add Contacts
                      </button>
                    ) : null}
                    {activeTabId === "US" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Form.InputControl
                          control="select"
                          label="Reference Type"
                          name="txtReferenceType"
                          menuPlacement="auto"
                          isClearable={true}
                          getOptionLabel={(option) => `${option.Name}`}
                          value={formValues.txtReferenceType}
                          getOptionValue={(option) => `${option}`}
                          options={referenceTypeOptions}
                          onChange={(e) => updateState("txtReferenceType", e)}
                        />
                        <PageBar.Search
                          onClick={() =>
                            getCompanyUser(
                              "0",
                              "0",
                              selectedCompanyMaster.CompanyMasterID,
                              "SELECT",
                              formValues.txtReferenceType && formValues.txtReferenceType.Value ? formValues.txtReferenceType.Value : "#All",
                            )
                          }
                          value={searchTextUser}
                          onChange={(e) => onSearchUser(e.target.value)}
                        />
                        <button
                          // onClick={() => toggleAddCompanyUserPopup(false, null)}
                          onClick={() => ToggleLoginPopup()}
                          className="Company__Pagetitle_btn Company__Pagetitle_btnSecd"
                        >
                          <GoPlus />
                          Add User
                        </button>
                      </div>
                    ) : null}
                    {activeTabId === "CF" ? (
                      <button onClick={() => toggleAddCompanyConfigurationPopup(false, null, false)} className="Company__Pagetitle_btn Company__Pagetitle_btnSecd">
                        <GoPlus />
                        Add Configration
                      </button>
                    ) : null}

                    {activeTabId === "PS" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <PageBar.Search value={searchTextParameter} onClick={() => getParameterMasterList()} onChange={(e) => onSearchParameter(e.target.value)} />
                        <button onClick={() => toggleAddNewParameterPopupActive()} className="Company__Pagetitle_btn Company__Pagetitle_btnSecd">
                          <GoPlus />
                          Add Parameter
                        </button>
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            </div>
            <div className="Company__MDB_ContentBox">
              <div className="Company__MDB_ContentMDiv">
                <div className={activeTabId === "AD" ? "Company__MDB_ContentDiv ActiveCompany__MDB_ContentDiv" : "Company__MDB_ContentDiv"}>
                  {filteredAddress && filteredAddress.length > 0 ? (
                    filteredAddress.map((data, i) => {
                      return (
                        <AddressCard
                          key={i}
                          updateCompanyAddress={updateCompanyAddress}
                          setMsgAlert={setMsgAlert}
                          onSetLocation={onSetLocation}
                          address={data}
                          selectedLocationType={selectedLocationType}
                          toggleManageLocationTypePopup={toggleManageLocationTypePopup}
                          toggleAddCompanyAddressPopup={toggleAddCompanyAddressPopup}
                          toggleAddressAssignmentPopup={toggleAddressAssignmentPopup}
                        />
                      );
                    })
                  ) : (
                    <SkeletonDtboxData />
                  )}
                </div>
                <div className={activeTabId === "CF" ? "Company__MDB_ContentDiv ActiveCompany__MDB_ContentDiv" : "Company__MDB_ContentDiv "}>
                  {companyConfigList && companyConfigList.length > 0 ? (
                    companyConfigList.map((data, i) => {
                      return (
                        <CompanyConfigCard
                          key={i}
                          getCompanyMasterConfig={getCompanyMasterConfig}
                          setMsgAlert={setMsgAlert}
                          config={data}
                          toggleAddCompanyConfigurationPopup={toggleAddCompanyConfigurationPopup}
                        />
                      );
                    })
                  ) : (
                    <SkeletonDtboxData />
                  )}
                </div>
                <div className={activeTabId === "US" ? "Company__MDB_ContentDiv ActiveCompany__MDB_ContentDiv_User" : "Company__MDB_ContentDiv "}>
                  <DataGrid
                    rowData={companyUser}
                    // rowData={[{}]}
                    columnDefs={CompanyUserColumnDefs}
                    onGridReady={onGridReady}
                    loader={isLoadingCompanyUser ? <Loader /> : null}
                    getRowStyle={function (data) {
                      if (data.data.IsSelected) {
                        return { background: "#ffc176" };
                      } else {
                        if (data.data.IsNewlyAdded) {
                          return { background: "#d5a10e" };
                        }
                        return { background: "" };
                      }
                    }}
                    frameworkComponents={{
                      actionTemplate: actionTemplate,
                    }}
                  />
                </div>
                <div className={activeTabId === "PS" ? "Company__MDB_ContentDiv ActiveCompany__MDB_ContentDiv_User" : "Company__MDB_ContentDiv "}>
                  <DataGrid
                    rowData={addedParameterList}
                    columnDefs={CompanyParameterColumnDef}
                    onGridReady={onGridParameterReady}
                    loader={isLoadingCompanyParameterList ? <Loader /> : null}
                    onClickGetParameterValue={onClickGetParameterValue}
                    getRowStyle={function (data) {
                      if (data.data.IsSelected) {
                        return { background: "#ffc176" };
                      } else {
                        if (data.data.IsNewlyAdded) {
                          return { background: "#d5a10e" };
                        }
                        return { background: "" };
                      }
                    }}
                    frameworkComponents={{
                      actionTemplate: parameterActionTemplate,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Company_Registration;

const actionTemplate = (props) => {
  let menuData = props.data;
  const setConfirmAlert = props.setConfirmAlert;
  const toggleAssignProfile = () => {
    props.toggleAssignProfile(menuData);
  };

  const handleToActiveInactive = (type) => {
    setConfirmAlert({
      open: true,
      title: "Confirmation",
      msg: `Are you sure to ${type === "Y" ? "De-activate" : "activate"} User?`,
      button: {
        confirmText: type === "Y" ? "De-activate" : "activate",
        abortText: "Cancel",
        Color: type === "Y" ? "Danger" : "",
      },
      onConfirm: () => props.enableOrDisableUser(props.data, type),
    });
  };

  const toggleLoginHistoryPopup = () => {
    props.toggleLoginHistoryPopup(menuData);
    console.log(menuData, "menuData");
  };

  const onEditUser = () => {
    props.toggleAddCompanyUserPopup(true, menuData);
  };

  const toggleResetPasswordPopup = () => {
    props.toggleResetPasswordPopup(menuData);
  };

  return (
    <React.Fragment>
      <div>
        <span title="Edit User" style={{ cursor: "pointer", paddingRight: "8px" }}>
          <FaEdit className="Biz_Bm__Icon" onClick={() => onEditUser()} />{" "}
        </span>

        <span title="Reset Password" style={{ cursor: "pointer", paddingRight: "8px" }}>
          <MdLockReset className="Biz_Bm__Icon" onClick={() => toggleResetPasswordPopup()} />{" "}
        </span>

        {props.data && props.data.ActiveStatus === "Y" ? (
          <a onClick={() => handleToActiveInactive("Y")} style={{ marginTop: "2px" }} title="De-Active">
            <BsToggleOn style={{ fontSize: "17px", color: "#4caf50" }} />
          </a>
        ) : (
          <a onClick={() => handleToActiveInactive("N")} style={{ marginTop: "2px" }} title={"Active"}>
            <BsToggleOn style={{ fontSize: "17px", color: "#ff0000", transform: "rotate(180deg)" }} />
          </a>
        )}
        <span title="Login History" style={{ cursor: "pointer", paddingRight: "8px", margin: "5px" }}>
          {" "}
          <FaCcMastercard className="Biz_Bm__Icon" onClick={() => toggleLoginHistoryPopup()} />
        </span>
      </div>
    </React.Fragment>
  );
};

function CompanyConfigCard(props) {
  let toggleAddCompanyConfigurationPopup = props.toggleAddCompanyConfigurationPopup;
  let getCompanyMasterConfig = props.getCompanyMasterConfig;
  let config = props.config;
  const ref = useRef();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  useOnClickOutside(ref, () => setAddModalOpen(false));
  let setMsgAlert = props.setMsgAlert;
  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }

  const onEditConfig = () => {
    console.log(config);
    setAddModalOpen(false);
    toggleAddCompanyConfigurationPopup(true, config, false);
  };
  const [viewMOde, setViewMode] = useState(false);
  const onViewConfig = () => {
    setAddModalOpen(false);
    setViewMode(true);
    toggleAddCompanyConfigurationPopup(false, config, true);
  };

  const onDeleteConfig = async () => {
    setAddModalOpen(false);

    try {
      let formData = {
        companyConfigId: config.CompanyConfigID ? config.CompanyConfigID : "",
        activeStatus: config.ActiveStatus === 1 ? 0 : 1,
        objCommon: {
          insertUserId: 1,
          insertIpAddress: "0.00.00.00",
        },
      };

      let result = await companyConfigUpdateIsActive(formData);

      console.log("result", result);
      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        getCompanyMasterConfig(config.CompanyMasterID);
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  return (
    <div className="Company__MDB_AddContBox">
      <div className={config.ActiveStatus === 1 ? "Company__MDB_AddContBox_Header Default_Header" : "Company__MDB_AddContBox_Header"}>
        <h4>{config.CompanyConfigCode ? config.CompanyConfigCode : ""}</h4>
        {/*           <div ref={ref}>
                    <BsThreeDots className="Biz_Icon" onClick={() => setAddModalOpen(!isAddModalOpen)} />
                    {
                        isAddModalOpen ? (selectedLocationType ?
                            <div>
                                {address.IsDefaultAddress === 0 ? <div className="Biz_Bm__Company_Address_setbar">
                                    <p> <a onClick={() => onSetDefault()}>Set Default {`(${selectedLocationType.CommonMasterValue})`}</a> </p>
                                </div> : null}
                            </div> :
                            <div className="Biz_Bm__Company_Address_setbar">
                                <p><a onClick={() => onEditAddress()}>Edit</a></p>
                                <p><a >Delete Address</a></p>
                                {address.IsDefaultAddress === 0 ?
                                <p><a onClick={() => onSetDefaultAddress(address)}>Set as Default Address</a></p>: null}
                                <p><a onClick={() => onManageLocationType()}>Location Type</a></p>
                                <p><a onClick={() => onAddressAssignment()}>Address Assignment</a></p>
                            </div>) : null
                    }
                </div> */}

        <div ref={ref}>
          <BsThreeDots className="Biz_Icon" onClick={() => setAddModalOpen(!isAddModalOpen)} />
          {isAddModalOpen ? (
            <div>
              <div className="Biz_Bm__Company_Address_setbar">
                <p>
                  <a onClick={() => onViewConfig()}>View</a>
                </p>
                <p>
                  <a onClick={() => onEditConfig()}>Edit</a>
                </p>
                <p>
                  <a onClick={() => onDeleteConfig()}>{config.ActiveStatus === 1 ? "Disable Configuration" : "Enable Configuration"}</a>
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="Company__MDB_AddContBox_Body">
        <p>{config.ConfigAPIURL ? config.ConfigAPIURL : ""}</p>
        <p>{config.ConfigApplicationURL ? config.ConfigApplicationURL : ""}</p>
        <p>{config.ApplicationFTP ? config.ApplicationFTP : ""}</p>
        <p>{config.ApplicationFTPHost ? config.ApplicationFTPHost : ""}</p>
        <p>{config.WinAppFTPID ? config.WinAppFTPID : ""}</p>
      </div>
    </div>
  );
}

function AddressCard(props) {
  let toggleAddCompanyAddressPopup = props.toggleAddCompanyAddressPopup;
  let filteredAddress = props.filteredAddress;
  let selectedLocationType = props.selectedLocationType;
  let onSetLocation = props.onSetLocation;
  let toggleManageLocationTypePopup = props.toggleManageLocationTypePopup;
  let toggleAddressAssignmentPopup = props.toggleAddressAssignmentPopup;
  let address = props.address;
  const ref = useRef();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  useOnClickOutside(ref, () => setAddModalOpen(false));
  let setMsgAlert = props.setMsgAlert;
  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }

  const onManageLocationType = () => {
    setAddModalOpen(true);
    toggleManageLocationTypePopup(address);
  };

  const onSetDefaultAddress = async (address) => {
    try {
      console.log("onSetDefaultAddress", address);
      debugger;
      let formData = {
        companyMasterId: address.CompanyMasterID,
        addressCode: address.BMCAddressCode,
        isDefaultAddress: 1,
      };
      let result = await CompanySetDefaultAddress(formData);
      if (result.responseCode === 1) {
        props.updateCompanyAddress(address);
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  const onSetDefault = () => {
    setAddModalOpen(false);
    onSetLocation(address);
  };

  const onEditAddress = () => {
    console.log(address);
    setAddModalOpen(false);
    toggleAddCompanyAddressPopup(true, address);
  };

  const onAddressAssignment = () => {
    setAddModalOpen(false);
    toggleAddressAssignmentPopup(true, address);
  };

  return (
    <div className="Company__MDB_AddContBox">
      <div className={address.IsDefaultAddress !== 0 ? "Company__MDB_AddContBox_Header Default_Header" : "Company__MDB_AddContBox_Header"}>
        <h4>{address.AddressDisplayName ? address.AddressDisplayName : ""}</h4>
        <div ref={ref}>
          <BsThreeDots style={{ cursor: "pointer" }} className="Biz_Icon" onClick={() => setAddModalOpen(!isAddModalOpen)} />
          {isAddModalOpen ? (
            selectedLocationType ? (
              <div>
                {address.IsDefaultAddress === 0 ? (
                  <div className="Biz_Bm__Company_Address_setbar">
                    <p>
                      {" "}
                      <a style={{ cursor: "pointer" }} onClick={() => onSetDefault()}>
                        Set Default {`(${selectedLocationType.CommonMasterValue})`}
                      </a>{" "}
                    </p>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="Biz_Bm__Company_Address_setbar">
                <p>
                  <a style={{ cursor: "pointer" }} onClick={() => onEditAddress()}>
                    Edit
                  </a>
                </p>
                {address.IsDefaultAddress === 0 ? (
                  <p>
                    <a style={{ cursor: "pointer" }} onClick={() => onSetDefaultAddress(address)}>
                      Set as Default Address
                    </a>
                  </p>
                ) : null}
                <p>
                  <a style={{ cursor: "pointer" }} onClick={() => onManageLocationType()}>
                    Location Type
                  </a>
                </p>
                <p>
                  <a style={{ cursor: "pointer" }} onClick={() => onAddressAssignment()}>
                    Address Relation
                  </a>
                </p>
              </div>
            )
          ) : null}
        </div>
      </div>
      <div className="Company__MDB_AddContBox_Body">
        <p>{address.AddressTitle ? address.AddressTitle : ""}</p>
        <p>{address.AddressLine1 ? address.AddressLine1 : ""}</p>
        <p>{address.AddressLine2 ? address.AddressLine2 : ""}</p>
        <p>{`${address.CityName ? address.CityName : ""}${address.StateName ? "," + address.StateName : ""}`}</p>
        <p>{address.PinCode ? address.PinCode : ""}</p>
        <p>{address.LocationType ? address.LocationType : ""}</p>
      </div>
    </div>
  );
}

const parameterActionTemplate = (props) => {
  const onClickGetParameterValue = () => {
    props.onClickGetParameterValue(props.data);
    console.log("props.data", props.data);
  };
  const buttonStyles = {
    display: "flex",
    fontSize: "16px",
    background: "transparent",
    border: "0px solid transparent",
    padding: "3px 0px 5px 0px",
  };

  const iconStyles = {
    fontSize: "16px",
    color: "rgb(52, 73, 94)",
  };

  const dFlexStyles = {
    display: "flex",
    gap: "8px",
  };
  return (
    <div style={dFlexStyles}>
      <button disabled={!props.data.BiznextParameterID} onClick={() => onClickGetParameterValue()} style={buttonStyles}>
        <HiArrowCircleRight style={iconStyles} />
      </button>
    </div>
  );
};
