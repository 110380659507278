import React, { useState, useEffect, useRef } from "react";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { CreateSupportUser } from "../../../Resource/SupportService";
import { getCompanyMasterSelect, getCompanyUserSelect } from "../../../../CRM/Resources/companyRegistrationService";
import { getCommonMasterData } from "../../../../CRM/Resources/commonService";
import { ImEye, ImEyeBlocked } from "react-icons/im";

const AddSupportUser = (props) => {
  let toggleAddSupportUsers = props.toggleAddSupportUsers;
  let setMsgAlert = props.setMsgAlert;
  let updateSupportUsersList = props.updateSupportUsersList;
  const [isModalAnimOpen, setModalAnimOpen] = useState(false);
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtUserName: "",
    txtPassword: "",
    txtCompany: null,
    txtCompanyUser: null,
  });

  const [formValidationError, setFormValidationError] = useState({});
  const [hidePassword, setHidePassword] = useState(true);

  const togglePassword = () => {
    setHidePassword(!hidePassword);
  };

  const [isLoadingCompanyList, setIsLoadingCompanyList] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const getCompanyList = async () => {
    debugger;
    try {
      setIsLoadingCompanyList(true);
      let result = await getCompanyMasterSelect("COMPANYLIST");
      setIsLoadingCompanyList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setCompanyList(result.responseData);
        } else {
          setCompanyList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingCompanyUserList, setIsLoadingCompanyUserList] = useState(false);
  const [companyUserList, setCompanyUserList] = useState([]);
  const getCompanyUserList = async (companyMasterId) => {
    try {
      setIsLoadingCompanyUserList(true);
      let result = await getCompanyUserSelect(1, 0, companyMasterId, "SELECT", "EMP");
      setIsLoadingCompanyUserList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData) {
          setCompanyUserList(result.responseData);
        } else {
          setCompanyUserList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const firstTextInput = useRef();
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
    getCompanyList();
  }, []);

  const [userNameDisabled, setUserNameDisabled] = useState(true);

  const updateState = (name, value) => {
    setFormValues((exist) => ({ ...exist, [name]: value }));
    if (name === "txtCompany") {
      if (value && value.CompanyMasterID) {
        formValues.txtCompanyUser = null;
        formValues.txtUserName = "";
        formValues.txtPassword = "";
        getCompanyUserList(value.CompanyMasterID);
      } else {
        formValues.txtCompanyUser = null;
        formValues.txtUserName = "";
        formValues.txtPassword = "";
        setCompanyUserList([]);
      }
    } else if (name === "txtCompanyUser") {
      debugger;
      setFormValues((exist) => ({
        ...exist,
        txtUserName: "",
        txtPassword: "",
      }));
      if (value && value.UserLoginID) {
        setUserNameDisabled(false);

        console.log(value.UserLoginID, "value.UserLoginID");
        formValidationError["txtUserName"] = validateField("txtUserName", value);
        setFormValues((exist) => ({
          ...exist,
          txtUserName: String(value.UserLoginID),
        }));
      }
    }
    formValidationError[name] = validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtUserName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter User Name";
      }
    }
    if (name === "txtPassword") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Password";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtUserName"] = validateField("txtUserName", formValues.txtUserName);
      errors["txtPassword"] = validateField("txtPassword", formValues.txtPassword);

      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      let formData = {
        companyMasterId: 1,
        bmcAppAccessCode: formValues.txtCompanyUser.BMCAppAccessCode,
        username: formValues.txtUserName,
        password: formValues.txtPassword,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await CreateSupportUser(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result && result.responseData) {
          let addData = {
            BSAppAccessID: result && result.responseData && result.responseData.BSAppAccessID ? result.responseData.BSAppAccessID : 0,
            SupportUserName: formValues.txtUserName,
            CompanyName: formValues.txtCompany.CompanyName,
            CompanyMasterID: formValues.txtCompany.CompanyMasterID,
            UserDisplayName: formValues.txtCompanyUser.UserDisplayName,
            UserLoginID: formValues.txtCompanyUser.UserLoginID,
            UserName: formValues.txtCompanyUser.UserDisplayName,
            BMCAppAccessCode: formValues.txtCompanyUser.BMCAppAccessCode,
            IsNewlyAdded: true,

            // "BMCAppAccessCode": 1,
            // "AppAccessUID": 11,
          };
          console.log(addData, "addData");
          updateSupportUsersList(addData);
          clearForm();
          toggleAddSupportUsers();
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtUserName: "",
      txtPassword: "",
      txtCompany: 0,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title="Add Support User" onSubmit={handleSave} show={() => toggleAddSupportUsers()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtCompany"]} label="Company" req={true}>
              <Form.InputControl
                getOptionLabel={(option) => `${option.CompanyName}`}
                value={formValues.txtCompany}
                getOptionValue={(option) => `${option}`}
                options={companyList}
                onChange={(e) => updateState("txtCompany", e)}
                isSearchable={true}
                isLoading={isLoadingCompanyList}
                label="Select Company"
                control="select"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtCompanyUser"]} label="Company Username" req={true}>
              <Form.InputControl
                name="txtCompanyUser"
                getOptionLabel={(option) => `${option.UserDisplayName}(${option.UserLoginID})`}
                value={formValues.txtCompanyUser}
                getOptionValue={(option) => `${option}`}
                options={companyUserList}
                onChange={(e) => {
                  updateState("txtCompanyUser", e);
                }}
                isLoading={isLoadingCompanyUserList}
                label="Select User"
                control="select"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtUserName"]} label="UserName" req={true}>
              <Form.InputControl
                control="input"
                label="UserName"
                name="txtUserName"
                type="text"
                value={formValues.txtUserName}
                onChange={(e) => {
                  updateState(e.target.name, e.target.value);
                  formValidationError["txtUserName"] = validateField("txtUserName", e);
                }}
                disabled={userNameDisabled}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtPassword"]} label="Password" req={true}>
              <Form.InputControl
                control="input"
                label="Password"
                name="txtPassword"
                type={hidePassword ? "password" : "text"}
                value={formValues.txtPassword}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                autoComplete="new-password"
              />
              {hidePassword ? (
                <span
                  style={{
                    cursor: "pointer",
                    display: "grid",
                    position: "absolute",
                    right: "5px",
                    top: "6px",
                  }}
                >
                  <ImEye style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => togglePassword()} />
                </span>
              ) : (
                <span
                  style={{
                    cursor: "pointer",
                    display: "grid",
                    position: "absolute",
                    right: "5px",
                    top: "6px",
                  }}
                >
                  <ImEyeBlocked style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => togglePassword()} />
                </span>
              )}
            </Form.InputGroup>{" "}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSupportUser;
