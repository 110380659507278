import React, { useState, useEffect } from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import BizClass from "../PageMaster/PageMaster.module.scss";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import PageTitle, { PageButton, PageSearch } from "../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, { FooterBox } from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { GetPageVariableData } from "./Resources/PageVariableService";
import AddPageVariable from "./Views/AddPageVariable";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";

const PageVariable = (props) => {
  const { selectedPage, formValuesPage } = props;
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const [PageVariableList, setPageVariableList] = useState([]);
  const [isLoadingPageVariableList, setIsLoadingPageVariableList] = useState(false);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getPageVariableList = async (selected) => {
    try {
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      setIsLoadingPageVariableList(true);
      const formData = {
        BizNextPageID: selected?.BizNextPageID || 0,
        PageVariableID: 0,
        SearchBy: searchMappedData ? searchMappedData : "#All",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await GetPageVariableData(formData);
      console.log(result, "result");
      setIsLoadingPageVariableList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          if (searchMappedData && searchMappedData.toLowerCase().includes("#")) {
            onSearchMappingData("");
          }
          setPageVariableList(result.responseData);
        } else {
          setPageVariableList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [AddPageVariablePopup, setAddPageVariablePopup] = useState({
    isOpen: false,
    popupData: null,
    isEditMode: "",
  });
  const toggleAddPageVariable = (isEditMode, data) => {
    setAddPageVariablePopup({ isOpen: !AddPageVariablePopup.isOpen, popupData: data, isEditMode: isEditMode });
  };

  const gridColumnDefs = [
    { field: "#", headerName: "Action", width: 80, pinned: "left", lockPosition: "1", cellRenderer: "actionTemplate", cellRendererParams: { toggleAddPageVariable } },
    {
      field: "PageVariableName",
      headerName: "Variable Name",
      width: 480,
      pinned: "left",
    },
  ];

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const updatePageVariableList = (department) => {
    PageVariableList.unshift(department);
    setPageVariableList(PageVariableList);
    if (gridApi) {
      gridApi.setRowData(PageVariableList);
    }
  };

  const _updateListingPageVariable = (record) => {
    if (gridApi) {
      const itemsToUpdate = [];
      gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.PageVariableID === record.PageVariableID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      gridApi.applyTransaction({
        update: itemsToUpdate,
      });
      gridApi.refreshCells();
    }
    let newData = PageVariableList.map((x) => {
      if (x.PageVariableID === record.PageVariableID) return record;
      return x;
    });
    setPageVariableList([...newData]);
  };

  const onClickSearchPageMaster = () => {
    getPageVariableList(selectedPage);
  };

  useEffect(() => {
    setPageVariableList([]);
  }, [formValuesPage.txtModule]);

  useEffect(() => {
    if (selectedPage) {
      getPageVariableList(selectedPage);
    }
  }, [selectedPage]);

  return (
    <React.Fragment>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {AddPageVariablePopup.isOpen ? (
        <AddPageVariable
          setMsgAlert={setMsgAlert}
          _updateListingPageVariable={_updateListingPageVariable}
          updatePageVariableList={updatePageVariableList}
          toggleAddPageVariable={toggleAddPageVariable}
          setConfirmAlert={setConfirmAlert}
          popupData={AddPageVariablePopup.popupData}
          isEditMode={AddPageVariablePopup.isEditMode}
          selectedPage={selectedPage}
        />
      ) : null}
      <>
        <div className={BizClass.MainSectionSecond}>
          <PageTitle Title="Page Variable">
            <PageSearch focus={true} onClick={() => onClickSearchPageMaster()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
            <PageButton onClick={() => toggleAddPageVariable(false, null)}>Add</PageButton>
          </PageTitle>
          <DataGrid
            rowData={PageVariableList}
            columnDefs={gridColumnDefs}
            onGridReady={onGridReady}
            loader={isLoadingPageVariableList ? <Loader /> : null}
            getRowStyle={function (data) {
              if (data.data.IsSelected) {
                return { background: "#ffc176" };
              } else {
                if (data.data.IsNewlyAdded) {
                  return { background: "#d5a10e" };
                }
                return { background: "" };
              }
            }}
            frameworkComponents={{
              actionTemplate: cellTemplate,
            }}
          />
        </div>
        <Footer>
          <FooterBox Aligned={"right"}></FooterBox>
        </Footer>
      </>
    </React.Fragment>
  );
};

export default PageVariable;
const cellTemplate = (props) => {
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View List"
      >
        <FaEdit style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => props.toggleAddPageVariable(true, props.data)} />
      </span>
    </div>
  );
};
