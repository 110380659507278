/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import BizClass from "../../PageParameter.module.scss";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import Button from "../../../../../Framework/OldFramework/Button/Button";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";
import { publicIpv4 } from "public-ip";
import DataGrid from "../../../../Layout/Common/DataGrid/DataGrid";
import { AssignParameterData, GetAssignedParameterData } from "../../../PageMaster/Resources/PageMasterService";
import { PageSearch } from "../../../../../Framework/OldFramework/PageTitle/PageTitle";

function PageUnassigned(props) {
  let toggleImportParameterUnAssignedPopup = props.toggleImportParameterUnAssignedPopup;
  let setMsgAlert = props.setMsgAlert;
  let updateParameterList = props.updateParameterList;
  let popupData = props.popupData;
  let setConfirmAlert = props.setConfirmAlert;
  let formValuesPage = props.formValuesPage;
  let selectedpageParameter = props.selectedpageParameter;

  const [UnAssignedParameterList, setUnAssignedParameterList] = useState([]);
  const [isLoadingUnAssignedParameterList, setIsLoadingUnAssignedParameterList] = useState(false);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getUnAssignedParameterList = async () => {
    try {
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      setIsLoadingUnAssignedParameterList(true);
      const formData = {
        Action: "GETUNASSIGNPAGE",
        PageParameterID: selectedpageParameter?.PageParameterID || 0,
        BizNextPageID: 0,
        ModuleMasterID: 0,
        MenuMasterID: 0,
        SearchText: searchMappedData,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await GetAssignedParameterData(formData);
      console.log(result, "result");
      setIsLoadingUnAssignedParameterList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          if (searchMappedData && searchMappedData.toLowerCase().includes("#")) {
            onSearchMappingData("");
          }
          setUnAssignedParameterList(result.responseData);
        } else {
          setUnAssignedParameterList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [btnLoaderActive, setBtnLoaderActive] = useState(false);
  const getSelectedRowData = () => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    console.log(selectedData, "data");
    return selectedData;
  };

  const onClickDelete = async (data) => {
    console.log(data, "deletedata");
    try {
      if (gridApi) {
        gridApi.applyTransaction({ remove: data }); // data should be an array

        const filteredItems = UnAssignedParameterList.filter((x) => !data.some((d) => String(x.BizNextPageID) === String(d.BizNextPageID)));
        setUnAssignedParameterList(filteredItems);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      setBtnLoaderActive(true);
      let checkedItem = getSelectedRowData();
      if (checkedItem.length === 0) {
        setMsgAlert({ open: true, type: "warning", msg: "Please select at least one country." });
        setBtnLoaderActive(false);
        return;
      }
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      const ip = await publicIpv4();
      let MasterIDList = checkedItem.map((e) => e.BizNextPageID).join(",");
      const formData = {
        Action: "PARA",
        BizNextPageID: 0,
        PageParameterID: selectedpageParameter?.PageParameterID || 0,
        BizNextPageIDs: MasterIDList,
        PageParameterIDs: "",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await AssignParameterData(formData);
      setBtnLoaderActive(false);
      console.log(result);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        console.log(result.responseData, "result.responseData");
        updateParameterList(result.responseData);
        onClickDelete(checkedItem);
      } else if (result.responseCode === "0") {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseData, "result.responseData");
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      setBtnLoaderActive(false);
      console.log(error);
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      let checkedItem = getSelectedRowData();
      if (checkedItem.length === 0) {
        setMsgAlert({ open: true, type: "warning", msg: "Please select atleast one bank." });
        setBtnLoaderActive(false);
        return;
      }
      setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Assign ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const columnDefs = [
    {
      headerName: "Select",
      field: "#",
      width: 80,
      pinned: "left",
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: false,
      headerCheckboxSelection: false,
    },
    {
      field: "BizNextPageName",
      headerName: "Page Name",
      width: 400,
    },
  ];

  const onClickSearchPageMaster = () => {
    getUnAssignedParameterList();
  };

  return (
    <>
      <Modal varient="half" width={"600px"} right={0} title="Unassigned Page List" onSubmit={handleSave} show={() => toggleImportParameterUnAssignedPopup()}>
        <Modal.Body>
          <div className={BizClass.modal_main_section}>
            <PageBar>
              <PageSearch focus={true} onClick={() => onClickSearchPageMaster()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
            </PageBar>
            <DataGrid
              rowData={UnAssignedParameterList}
              onGridReady={onGridReady}
              loader={isLoadingUnAssignedParameterList ? <Loader /> : null}
              animateRows={true}
              columnDefs={columnDefs}
              rowSelection="multiple"
              suppressRowClickSelection={false}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" varient="secondary" trigger={btnLoaderActive}>
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PageUnassigned;
