import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { UpdatepageParamterData, CreatePageParamterData } from "../../Resources/PageParameterService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddPageParameter = (props) => {
  const firstTextInput = useRef();
  let toggleaddpageParameterPopup = props.toggleaddpageParameterPopup;
  let setMsgAlert = props.setMsgAlert;
  let updatepageParamterList = props.updatepageParamterList;
  let _updateListingpageParamter = props._updateListingpageParamter;
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtParameterName: "",
  });
  const [oldFormValues, setOldFormValues] = useState({
    txtParameterName: "",
  });

  const validateField = (name, value) => {
    let errorMsg = "";
    if (name === "txtParameterName") {
      if (!value || typeof value === "undefined") {
        errorMsg = "Cannot be empty";
      }
    }
    return errorMsg;
  };

  const [formValidationError, setFormValidationError] = useState({});
  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtParameterName"] = validateField("txtParameterName", formValues.txtParameterName);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);
      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "error", message: "Something went wrong!" });
      return false;
    }
  };

  useEffect(() => {
    if (isEditMode) {
      if (popupData) {
        setEditBankMaster(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);
  const [editBankMaster, setEditBankMaster] = useState();
  useEffect(() => {
    if (editBankMaster) {
      formValues.txtParameterName = editBankMaster.ParameterName;
    }
    setFormValues({ ...formValues });
    setOldFormValues({ ...formValues });
  }, [editBankMaster]);

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const handleSave = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to Update" : "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };
  const [saveMore, setSaveMore] = useState(false);
  const onSubmit = async () => {
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;

      const ip = await publicIpv4();
      let formData = {};
      if (isEditMode) {
        formData = {
          PageParameterID: popupData && popupData.PageParameterID ? popupData.PageParameterID : 0,
          ParameterName: formValues.txtParameterName.toString(),

          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          ParameterName: formValues.txtParameterName.toString(),

          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }
      setBtnloaderActive(true);
      let fun = isEditMode ? UpdatepageParamterData : CreatePageParamterData;
      let result = await fun(formData);
      setBtnloaderActive(false);
      debugger;
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        console.log(result.responseData, "result.responseData");
        console.log(result.responseData.data, "result.responseData.data");
        if (result.responseData) {
          let addedCode = {
            ParameterName: formValues.txtParameterName,
            PageParameterID: isEditMode
              ? popupData.PageParameterID
              : result.responseData && result.responseData.data && result.responseData.data.PageParameterID
              ? result.responseData.data.PageParameterID
              : 0,
            IsNewlyAdded: true,
          };

          if (isEditMode) {
            _updateListingpageParamter({ ...addedCode, IsNewlyAdded: false, IsUpdated: true });
            toggleaddpageParameterPopup(false, null);
          } else {
            setSaveMore(true);
            updatepageParamterList(addedCode);
          }

          console.log(addedCode, "addedCode");
          clearForm();
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtParameterName: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && popupData) {
      return true;
    }

    return false;
  };

  return (
    <Modal varient={"center"} title={isEditMode ? "Update Page Paramter" : "Add Page Paramter"} onSubmit={handleSave} show={() => toggleaddpageParameterPopup(false, null)}>
      <Modal.Body>
        <Form>
          <Form.Group column={2} controlwidth={"180px"}>
            <Form.InputGroup column={3} errorMsg={formValidationError["txtParameterName"]} label="Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtParameterName"
                type="text"
                value={formValues.txtParameterName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                maxLength={100}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" disabled={checkEditFormControlValues() || btnloaderActive} type="submit" trigger={false}>
          {isEditMode ? "Update" : saveMore ? "Save More" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddPageParameter;
