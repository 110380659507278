import React, { useState, useEffect } from "react";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { AssignCompanyToUserUser, CreateSupportUser } from "../../../Resource/SupportService";
import { getCompanyMasterSelect, getCompanyUserSelect } from "../../../../CRM/Resources/companyRegistrationService";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import PageTitle, { PageButton } from "../../../../../Framework/OldFramework/PageTitle/PageTitle";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import { Success } from "../../../../../Configration/Utilities/Constants";
import UnassignedCompany from "../UnassignedCompany/UnassignedCompany";
import ConfirmDialog from "../../../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { BsFillTrash2Fill, BsFillTrashFill } from "react-icons/bs";

const AssignCompany = (props) => {
  let toggleCompanyAssignmentPopup = props.toggleCompanyAssignmentPopup;
  let setMsgAlert = props.setMsgAlert;
  let popupData = props.popupData;
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtCompany: null,
  });
  const [assignedCompanyGridApi, setAssignedCompanyGridApi] = useState();
  const onAssignedCompanyGridReady = (params) => {
    setAssignedCompanyGridApi(params.api);
  };
  const [formValidationError, setFormValidationError] = useState({});

  const [assignedCompanyList, setAssignedCompanyList] = useState([]);
  const [isLoadingAssignedCompany, setIsLoadingAssignedCompany] = useState(false);
  const getAssignedCompanyData = async (action) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      setIsLoadingAssignedCompany(true);
      let formData = {
        action: action,
        companyMasterId: "",
        userCompanyAssignmentId: popupData && popupData.BSAppAccessID ? popupData.BSAppAccessID.toString() : "",
        appAccessId: popupData && popupData.BMCAppAccessCode ? popupData.BMCAppAccessCode : 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      const result = await AssignCompanyToUserUser(formData);
      setIsLoadingAssignedCompany(false);
      console.log(result, "Assigned LIst");
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setAssignedCompanyList(result.responseData);
          console.log(result.responseData);
        } else {
          setAssignedCompanyList([]);
          console.log(result.responseMessage);
        }
      }
    } catch (error) {
      setMsgAlert({
        open: true,
        type: "error",
        msg: error.responseData && error.responseData.responseMessage ? error.responseData.responseMessage : CustomMsg.Error,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    getAssignedCompanyData("GETASSIGN");
  }, []);

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    // if (!formValues.txtCompany || typeof formValues.txtCompany === "undefined") {
    //   formIsValid = false;
    //   errors["txtCompany"] = "Select A Company";
    // }
    setFormValidationError(errors);
    return formIsValid;
  };

  const [companyUnAssignmentPopupActive, setCompanyUnAssignmentPopupActive] = useState({
    IsOpen: false,
    popupData: null,
  });

  const toggleCompanyUnAssignmentPopup = (userData) => {
    console.log("toggleResetPasswordPopup", userData);
    setCompanyUnAssignmentPopupActive({
      IsOpen: !companyUnAssignmentPopupActive.IsOpen,
      popupData: userData,
    });
  };
  const unAssignCompany = async (data, UserCompanyAssignmentID) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      let formData = {
        action: "UNASSIGN",
        companyMasterId: data,
        userCompanyAssignmentId: UserCompanyAssignmentID ? UserCompanyAssignmentID : "",
        appAccessId: popupData && popupData.AppAccessUID ? popupData.AppAccessUID : "",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setIsLoadingAssignedCompany(true);
      const result = await AssignCompanyToUserUser(formData);
      setIsLoadingAssignedCompany(false);
      if (result.responseCode === 1) {
        getAssignedCompanyData("GETASSIGN");
        if (assignedCompanyGridApi) assignedCompanyGridApi.applyTransaction({ remove: [data] });

        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const gridCompanyColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: "1",
      cellRenderer: "actionTemplate",
      cellRendererParams: { setConfirmAlert, unAssignCompany },
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1" },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 180,
    },
  ];

  const [searchTextState, setSearchTextState] = useState("");
  const onSearchChange = (val) => {
    setSearchTextState(val);
    assignedCompanyGridApi.setQuickFilter(val);
    assignedCompanyGridApi.refreshCells();
  };

  return (
    <>
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {companyUnAssignmentPopupActive.IsOpen ? (
        <UnassignedCompany
          toggleCompanyUnAssignmentPopup={toggleCompanyUnAssignmentPopup}
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
          popupData={companyUnAssignmentPopupActive.popupData}
          getAssignedCompanyData={getAssignedCompanyData}
          listData={props.popupData}
        />
      ) : null}

      <Modal varient={"half"} right={0} title="Assigned Companies" show={() => toggleCompanyAssignmentPopup(null)}>
        <Modal.Body>
          <div style={{ display: "grid", gridAutoRows: "max-content auto" }}>
            <PageTitle>
              {/* <PageBar.Search onClick={() => onClickSearch()} value={searchTextState} onChange={(e) => onSearchChange(e.target.value)} /> */}
              <PageButton onClick={() => toggleCompanyUnAssignmentPopup(null)}>Assign</PageButton>
            </PageTitle>
            <DataGrid
              rowData={assignedCompanyList}
              columnDefs={gridCompanyColumnDefs}
              suppressRowClickSelection={true}
              rowSelection={"multiple"}
              loader={isLoadingAssignedCompany ? <Loader /> : null}
              getRowStyle={function (data) {
                if (data.data.IsSelected) {
                  return { background: "#ffc176" };
                } else {
                  if (data.data.FailedAssigned) {
                    return { background: "#d5a10e" };
                  }
                  return { background: "" };
                }
              }}
              frameworkComponents={{
                actionTemplate: companyUnassignActionTemplate,
              }}
              onGridReady={onAssignedCompanyGridReady}
            ></DataGrid>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button varient="secondary" type="submit" trigger={false}>
              Save
            </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignCompany;
const companyUnassignActionTemplate = (props) => {
  const onClickDelete = () => {
    console.log(props.data.CompanyMasterID, "props.data.CompanyMasterID)");
    props.unAssignCompany(props.data.CompanyMasterID.toString(), props.data.UserCompanyAssignmentID.toString());
  };
  return (
    <div style={{ display: "flex" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          paddingTop: "3px",
          paddingRight: "10px",
        }}
      >
        <BsFillTrashFill
          style={{ fontSize: "16px", color: "#34495E" }}
          onClick={() =>
            props.setConfirmAlert({
              open: true,
              title: "UnAssign Company",
              msg: "Are you sure to delete ?",
              button: {
                confirmText: "Delete",
                abortText: "Cancel",
                Color: "Danger",
              },
              onConfirm: () => onClickDelete(),
            })
          }
        />
      </span>
    </div>
  );
};
