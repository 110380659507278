import React, { useState, useEffect, useRef } from "react";
import { CustomMsg } from "../../../../Configration/Utilities/customMessage";
import { CreateDepartment, divisionData } from "../Resources/DeptService";
import Button from "../../../../Framework/Components/Widgets/Button/Button";
import Modal from "../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";

const AddDepartment = (props) => {
  let toggleAddDepartMent = props.toggleAddDepartMent;
  let setMsgAlert = props.setMsgAlert;
  let updateDepartmentList = props.updateDepartmentList;

  const [btnloaderActive, setBtnloaderActive] = useState(false);

  const [formValues, setFormValues] = useState({
    txtDepartmentName: "",
    txtDivision: null,
    txtShortDeptCode: "",
  });

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();
  const [DivisionList, setDivisionList] = useState([]);
  const [isLoadingDivisionList, setIsLoadingDivisionList] = useState(false);
  const getDivisionList = async () => {
    try {
      setIsLoadingDivisionList(true);
      const formData = {
        viewMode: "LIST",
        divisionId: 0,
      };
      let result = await divisionData(formData);
      console.log(result, "result");
      setIsLoadingDivisionList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setDivisionList(result.responseData);
        } else {
          setDivisionList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
    getDivisionList();
  }, []);

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtDepartmentName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter DivisionName";
      }
    }
    if (name === "txtDivision") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Division Code";
      }
    }
    if (name === "txtShortDeptCode") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Division Code";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtDepartmentName"] = validateField("txtDepartmentName", formValues.txtDepartmentName);
      errors["txtDivision"] = validateField("txtDivision", formValues.txtDivision);
      errors["txtShortDeptCode"] = validateField("txtShortDeptCode", formValues.txtShortDeptCode);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      let formData = {
        departmentName: formValues.txtDepartmentName,
        divisionCode: formValues.txtDivision && formValues.txtDivision.DivisionID ? formValues.txtDivision.DivisionID : 0,
        shortDeptCode: formValues.txtShortDeptCode,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await CreateDepartment(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          if (result && result.responseData) {
            let addedDepartment = {
              DepartmentCode: result.responseData.DepartmentID,
              DepartmentName: formValues.txtDepartmentName,
              DivisionCode: formValues.txtDivision && formValues.txtDivision.DivisionID ? formValues.txtDivision.DivisionID : null,
              DivisionName: formValues.txtDivision && formValues.txtDivision.DivisionName ? formValues.txtDivision.DivisionName : null,
              IsNewlyAdded: true,
            };
            updateDepartmentList(addedDepartment);
            clearForm();
            toggleAddDepartMent();
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtDepartmentName: "",
      txtDivision: null,
      txtShortDeptCode: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title="Add New Master" onSubmit={onSubmit} show={() => toggleAddDepartMent()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtDepartmentName"]} label="Master Value Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtDepartmentName"
                type="text"
                value={formValues.txtDepartmentName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtDivision"]} label="Division Name" req={true}>
              <Form.InputControl
                control="select"
                name="txtDivision"
                getOptionLabel={(option) => `${option.DivisionName}`}
                value={formValues.txtDivision}
                getOptionValue={(option) => `${option}`}
                options={DivisionList}
                onChange={(e) => updateState("txtDivision", e)}
                label="Select Division"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtShortDeptCode"]} label="Short Dept Code" req={true}>
              <Form.InputControl
                control="input"
                name="txtShortDeptCode"
                type="text"
                value={formValues.txtShortDeptCode}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDepartment;
