import React, { useEffect, useState } from "react";
import { MemoryRouter as Router, Routes, Route } from "react-router-dom";
import { checkAuthExist, setSessionStorage } from "../../APIMethods/Auth/auth";
import Page from "./Page";
import PageAuthenticator from "../../Configration/PageRouter/PageAuthenticator/PageAuthenticator";
import Login from "../../Components/Layout/Common/Login/Login";
import Notfound from "../../Components/Layout/ExceptionHandler/Notfound/notfound";
import Home from "../../Components/Layout/Common/Home/Home";
import Header from "../../Components/Layout/Common/Header/Header";
import Company_Registration from "../../Components/CRM/Company_Registration/Company_Registration";
import Customer_Registration from "../../Components/CRM/Customer_Registration/Customer_Registration";
import Module_Entity from "../../Components/Access_Control/Module/Module";
import MenuManagement from "../../Components/Access_Control/Menu_Management/MenuManagement";
import Common_Master from "../../Components/Master/Common_Master/Common_Master";
import CommonRelation from "../../Components/Master/Common_Relation/CommonRelation";
import MenuToCompany from "../../Components/Customer_Access/MenuToCompany/MenuToCompany";
import UserLoginSummary from "../../Components/Reports/UserLoginSummary/UserLoginSummary";
import MenuToUser from "../../Components/Customer_Access/MenuToUser/MenuToUser";
import ProfileManagement from "../../Components/Access_Control/ProfileManagement/ProfileManagement";
import AccessRights from "../../Components/Access_Control/AccessRights/AccessRights";
import Department from "../../Components/Master/Department/Department";
import GeographicData from "../../Components/Master/GeographicData/GeographicData";
import Designation from "../../Components/Master/Designation/Designation";
import Division from "../../Components/Master/Division/Division";
import SupportUsers from "../../Components/Support/SupportUsers/SupportUsers";
import Encryption from "../../Components/Support/Encryption/Encryption";
import LoginSession from "../../Components/Layout/Common/LoginPopup/LoginSession";
import BankMaster from "../../Components/Master/Bank/BankMaster";
import Tax from "../../Components/Master/Tax/Tax";
import PageMaster from "../../Components/Master/PageMaster/PageMaster";
import PageConfig from "../../Components/Master/PageConfig/PageConfig";
import PageVariable from "../../Components/Master/PageVariable/PageVariable";
import PageParameter from "../../Components/Master/PageParameter/PageParameter";
import ApiCallingSummary from "../../Components/Reports/ApiCallingSummary/APICallingSummary";

function PageRouter() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const loginHandler = (data) => {
    setIsLoggedIn(data);
    setSessionStorage("IsLoggedIn", data);
  };

  useEffect(() => {
    checkAuthExist();
    if (checkAuthExist()) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  return (
    <Router>
      {isLoggedIn ? (
        <>
          <Header loginHandler={loginHandler} />
          <LoginSession loginHandler={loginHandler} />
        </>
      ) : null}

      <Routes>
        <Route exact path="/" element={<PageAuthenticator />} />
        <Route exact path="/login" element={<Login loginHandler={loginHandler} />} />
        <Route exact path="/home" element={<Page component={<Home />} title="Home" />} />
        <Route path="/company_registration" element={<Page component={<Company_Registration />} title="Company_Registration" />} />
        <Route path="/customer_registration" element={<Page component={<Customer_Registration />} title="customer_registration" />} />
        <Route path="/module" element={<Page component={<Module_Entity />} title="module" />} />
        <Route path="/MenuManagement" element={<Page component={<MenuManagement />} title="MenuManagement" />} />
        <Route path="/Common_Master" element={<Page component={<Common_Master />} title="Common_Master" />} />
        <Route path="/common_relation" element={<Page component={<CommonRelation />} title="common_relation" />} />
        <Route path="/geographic_data" element={<Page component={<GeographicData />} title="Geographic_Data" />} />
        <Route path="/menu_company" element={<Page component={<MenuToCompany />} title="menu_company" />} />
        <Route path="/menu_user" element={<Page component={<MenuToUser />} title="menu_user" />} />
        <Route path="/ProfileManagement" element={<Page component={<ProfileManagement />} title="ProfileManagement" />} />
        <Route path="/RightMaster" element={<Page component={<AccessRights />} title="RightMaster" />} />
        <Route path="/department" element={<Page component={<Department />} title="Department" />} />
        <Route path="/bankMaster" element={<Page component={<BankMaster />} title="BankMaster" />} />
        <Route path="/division" element={<Page component={<Division />} title="Division" />} />
        <Route path="/designation" element={<Page component={<Designation />} title="Designation" />} />
        <Route path="/support_users" element={<Page component={<SupportUsers />} title="support Users" />} />
        <Route path="/encryption" element={<Page component={<Encryption />} title="Encryption" />} />
        <Route path="/user_login_summary" element={<Page component={<UserLoginSummary />} title="user_login_summary" />} />
        <Route path="/api-calling-summary" element={<Page component={<ApiCallingSummary />} title="Api Calling Summary" />} />
        <Route path="/tax" element={<Page component={<Tax />} title="Tax" />} />
        <Route path="/pageMaster" element={<Page component={<PageMaster />} title="Page Master" />} />
        <Route path="/pageConfig" element={<Page component={<PageConfig />} title="Page Config" />} />
        <Route path="/pageVariable" element={<Page component={<PageVariable />} title="Page Variable" />} />
        <Route path="/pageParameter" element={<Page component={<PageParameter />} title="Page Paramter" />} />
        <Route path="*" element={<Page component={<Notfound />} title="Not found" />} />
      </Routes>
    </Router>
  );
}

export default PageRouter;
