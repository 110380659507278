import React, { useState, useEffect, useRef } from "react";
import { publicIpv4 } from "public-ip";
import { CreateCity, UpdateCity } from "../../Resources/GeographicDataService";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../Framework/OldFramework/Button/Button";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";

const AddCity = (props) => {
  let toggleAddCity = props.toggleAddCity;
  let setMsgAlert = props.setMsgAlert;
  let updateCityList = props.updateCityList;
  let selectedCountryCode = props.selectedCountryCode;
  let selectedState = props.selectedState;
  let setConfirmAlert = props.setConfirmAlert;
  let _updateListing = props._updateListing;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;

  const [editCity, setEditCity] = useState();
  const [formValues, setFormValues] = useState({
    txtCityName: "",
    txtPincode: null,
  });

  const [formValidationError, setFormValidationError] = useState({});
  const firstTextInput = useRef();

  useEffect(() => {
    if (isEditMode) {
      if (popupData) {
        setEditCity(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (editCity) {
      formValues.txtCityName = editCity.CityName;
      formValues.txtPincode = editCity.PinCode;
    }
    setFormValues({ ...formValues });
  }, [editCity]);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtCityName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter City Name";
      }
    }
    if (name === "txtPincode") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Pincode";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtCityName"] = validateField("txtCityName", formValues.txtCityName);
      errors["txtPincode"] = validateField("txtPincode", formValues.txtPincode);

      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const onSubmit = async (e) => {
    try {
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      let formData = {};
      if (isEditMode) {
        formData = {
          cityMasterID: popupData && popupData.CityMasterID ? popupData.CityMasterID : 0,
          cityName: formValues.txtCityName ? formValues.txtCityName : "",
          pinCode: formValues.txtPincode ? formValues.txtPincode : 0,
          stateMasterID: selectedState && selectedState.StateMasterID ? selectedState.StateMasterID : 0,
          countryMasterID: selectedCountryCode && selectedCountryCode.CountryMasterID ? selectedCountryCode.CountryMasterID : 0,
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          cityName: formValues.txtCityName ? formValues.txtCityName : "",
          pinCode: formValues.txtPincode ? formValues.txtPincode : 0,
          stateMasterID: selectedState && selectedState.StateMasterID ? selectedState.StateMasterID : 0,
          countryMasterID: selectedCountryCode && selectedCountryCode.CountryMasterID ? selectedCountryCode.CountryMasterID : 0,
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }

      let fun = isEditMode ? UpdateCity : CreateCity;
      let result = await fun(formData);

      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          if (result && result.responseData) {
            let addedCity = {
              CityMasterID: result.responseData.data.CityMasterID ? result.responseData.data.CityMasterID : popupData && popupData.CityMasterID ? popupData.CityMasterID : 0,
              CityName: formValues.txtCityName ? formValues.txtCityName : "",
              PinCode: formValues.txtPincode && formValues.txtPincode ? formValues.txtPincode : 0,
              ActiveStatus: "YES",
              IsNewlyAdded: true,
            };

            if (isEditMode) {
              _updateListing({ ...addedCity, IsNewlyAdded: false, IsUpdated: true });
            } else {
              updateCityList(addedCity);
            }

            clearForm();
            toggleAddCity(false, null);
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const clearForm = () => {
    setFormValues({
      txtCityName: "",
      txtPincode: null,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal
      varient={"center"}
      title={isEditMode ? `Update ${popupData && popupData.CityName ? popupData.CityName : ""}` : "Add New City"}
      onSubmit={handleSave}
      show={() => toggleAddCity(false, null)}
    >
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtCityName"]} label="City Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtCityName"
                type="text"
                value={formValues.txtCityName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="City Name"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtPincode"]} label="Pincode" req={true}>
              <Form.InputControl
                control="input"
                maxLength="6"
                name="txtPincode"
                value={formValues.txtPincode}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
                label="Pincode"
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          {isEditMode ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCity;
