import React, { useState, useEffect, useRef } from "react";
import { CustomMsg } from "../../../../Configration/Utilities/customMessage";
import Button from "../../../../Framework/Components/Widgets/Button/Button";
import Modal from "../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CreatePageMasterData, UpdatePageMasterData } from "../Resources/PageMasterService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import { getModuleMaster } from "../../../Access_Control/Module/Resources/moduleService";
import { getMenuList } from "../../../Access_Control/Menu_Management/Resources/MenuMangementService";

const AddPageMaster = (props) => {
  let toggleAddPageMaster = props.toggleAddPageMaster;
  let setMsgAlert = props.setMsgAlert;
  let updatePageMasterList = props.updatePageMasterList;
  let _updateListingPageMaster = props._updateListingPageMaster;
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  let formValuesPage = props.formValuesPage;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtPageName: "",
    txtJSONPageName: "",
    txtJSONPath: "",
    // txtModule: null,
    txtMenu: null,
  });
  const [oldFormValues, setOldFormValues] = useState({
    txtPageName: "",
    txtJSONPageName: "",
    txtJSONPath: "",
    // txtModule: null,
    txtMenu: null,
  });

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();
  useEffect(() => {
    getModuleMasterData();
  }, []);

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtPageName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Page Name";
      }
    }
    if (name === "txtJSONPageName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter JSON Page Name";
      }
    }

    // if (name === "txtModule") {
    //   if (!value || typeof value === "undefined" || value === null) {
    //     errorsMsg = "Select Module";
    //   }
    // }
    if (name === "txtMenu") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Select Menu";
      }
    }

    if (name === "txtJSONPath") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter JSON Path";
      }
    }

    return errorsMsg;
  };

  const updateState = (name, value) => {
    // if (name === "txtModule") {
    //   console.log(value, "value");
    //   if (value && value.ModuleCode) {
    //     formValues.txtMenu = null;
    //     getMenuListData(value.ModuleCode);
    //   } else {
    //     setMenuListData([]);
    //     formValues.txtMenu = null;
    //   }
    // }
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    if (formValuesPage.txtModule) {
      getMenuListData(formValuesPage.txtModule.ModuleCode);
    }
  }, []);

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtPageName"] = validateField("txtPageName", formValues.txtPageName);
      // errors["txtJSONPageName"] = validateField("txtJSONPageName", formValues.txtJSONPageName);
      // errors["txtModule"] = validateField("txtModule", formValues.txtModule);
      errors["txtMenu"] = validateField("txtMenu", formValues.txtMenu);
      // errors["txtJSONPath"] = validateField("txtJSONPath", formValues.txtJSONPath);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };
  const [saveMore, setSaveMore] = useState(false);
  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;

      let formData = {};
      if (isEditMode) {
        formData = {
          BizNextPageID: popupData?.BizNextPageID || 0,
          BizNextPageName: formValues?.txtPageName || "",
          MenuMasterID: formValues?.txtMenu?.MenuMasterID || 0,
          PageJSONName: formValues?.txtJSONPageName || "",
          PageJSONPath: formValues?.txtJSONPath || "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          BizNextPageName: formValues?.txtPageName || "",
          MenuMasterID: formValues?.txtMenu?.MenuMasterID || 0,
          PageJSONName: formValues?.txtJSONPageName || "",
          PageJSONPath: formValues?.txtJSONPath || "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }
      setBtnloaderActive(true);
      let fun = isEditMode ? UpdatePageMasterData : CreatePageMasterData;
      let result = await fun(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          if (result && result.responseData) {
            let addedDivision = {
              BizNextPageName: formValues?.txtPageName || "",
              MenuName: formValues?.txtMenu?.MenuName || 0,
              MenuMasterID: formValues?.txtMenu?.MenuMasterID || 0,
              MenuMasterName: formValues?.txtMenu?.MenuName || "",
              PageJSONName: formValues?.txtJSONPageName || "",
              PageJSONPath: formValues?.txtJSONPath || "",
              ModuleName: formValuesPage?.txtModule?.ModuleName || "",
              ModuleMasterID: formValues?.txtModule?.ModuleCode || "",
              BizNextPageID: isEditMode
                ? popupData.BizNextPageID
                : result.responseData && result.responseData.data && result.responseData.data.BizNextPageID
                ? result.responseData.data.BizNextPageID
                : 0,
              IsNewlyAdded: true,
            };

            if (isEditMode) {
              _updateListingPageMaster({ ...addedDivision, IsNewlyAdded: false, IsUpdated: true });
              toggleAddPageMaster(false, null);
            } else {
              setSaveMore(true);
              updatePageMasterList(addedDivision);
              clearForm();
            }
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to Update" : "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && popupData) {
      return true;
    }

    return false;
  };
  const [editBankMaster, setEditBankMaster] = useState();
  useEffect(() => {
    debugger;
    if (isEditMode) {
      if (popupData) {
        setEditBankMaster(popupData);
        console.log(popupData, "popupData");
      }
    }
  }, []);

  useEffect(() => {
    if (editBankMaster) {
      formValues.txtPageName = editBankMaster?.BizNextPageName || "";
      // formValues.txtModule = { ModuleName: editBankMaster?.ModuleName || "", ModuleCode: editBankMaster?.ModuleMasterID || 0 };
      formValues.txtMenu = { MenuName: editBankMaster.MenuName || "", MenuMasterID: editBankMaster.MenuMasterID || 0 };
      formValues.txtJSONPageName = editBankMaster?.PageJSONName || "";
      formValues.txtJSONPath = editBankMaster?.PageJSONPath || "";
    }
    setFormValues({ ...formValues });
    setOldFormValues({ ...formValues });
  }, [editBankMaster]);

  const clearForm = () => {
    setFormValues({
      txtPageName: "",
      txtJSONPageName: "",
      txtJSONPath: "",
      // txtModule: null,
      txtMenu: null,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };
  const [moduleMasterData, setModuleMasterData] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingModuleMasterData] = useState(false);
  const getModuleMasterData = async () => {
    try {
      setIsLoadingModuleMasterData(true);
      let result = await getModuleMaster();
      setIsLoadingModuleMasterData(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setModuleMasterData(result.responseData);
        } else {
          setModuleMasterData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  const [menuListData, setMenuListData] = useState([]);
  const [isLoadingMenuList, setIsLoadingMenuList] = useState(false);
  const getMenuListData = async (moduleMasterId) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      setIsLoadingMenuList(true);
      const ip = await publicIpv4();
      console.log(ip, "IP address");
      const formData = {
        action: "MENULIST",
        searchText: "#ALL",
        moduleMasterId: moduleMasterId,
        menuMasterId: 0,
        filterId1: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getMenuList(formData);
      setIsLoadingMenuList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setMenuListData(result.responseData);
          console.log(result.responseData);
        } else {
          setMenuListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  return (
    <Modal varient={"center"} title={isEditMode ? "Update Page" : "Add New Page"} onSubmit={handleSave} show={() => toggleAddPageMaster()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtPageName"]} label="Biznext Page Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtPageName"
                type="text"
                value={formValues.txtPageName}
                onChange={(e) => {
                  formValidationError["txtPageName"] = validateField("txtPageName", e.target.value);
                  updateState(e.target.name, e.target.value);
                }}
              />
            </Form.InputGroup>

            {/* <Form.InputGroup errorMsg={formValidationError["txtModule"]} label="Module" req={true}>
              <Form.InputControl
                control="select"
                name="txtModule"
                type="text"
                value={formValues.txtModule}
                onChange={(e) => {
                  formValidationError["txtModule"] = validateField("txtModule", e);
                  updateState("txtModule", e);
                }}
                getOptionLabel={(option) => `${option.ModuleName}`}
                getOptionValue={(option) => `${option}`}
                options={moduleMasterData}
              />
            </Form.InputGroup> */}

            <Form.InputGroup errorMsg={formValidationError["txtMenu"]} label="Menu" req={true}>
              <Form.InputControl
                control="select"
                name="txtMenu"
                type="text"
                value={formValues.txtMenu}
                onChange={(e) => {
                  formValidationError["txtMenu"] = validateField("txtMenu", e);
                  updateState("txtMenu", e);
                }}
                getOptionLabel={(option) => `${option.MenuName}`}
                //MenuMasterID
                getOptionValue={(option) => `${option}`}
                options={menuListData}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtJSONPageName"]} label="JSON Page Name" req={false}>
              <Form.InputControl
                control="input"
                name="txtJSONPageName"
                type="text"
                value={formValues.txtJSONPageName}
                onChange={(e) => {
                  formValidationError["txtJSONPageName"] = validateField("txtJSONPageName", e.target.value);
                  updateState(e.target.name, e.target.value);
                }}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtJSONPath"]} label="JSON Page Path" req={false}>
              <Form.InputControl
                control="input"
                name="txtJSONPath"
                type="text"
                value={formValues.txtJSONPath}
                onChange={(e) => {
                  formValidationError["txtJSONPath"] = validateField("txtJSONPath", e.target.value);
                  updateState(e.target.name, e.target.value);
                }}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" disabled={checkEditFormControlValues() || btnloaderActive} type="submit" trigger={false}>
          {isEditMode ? "Update" : saveMore ? "Save More" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPageMaster;
