import { publicIpv4 } from "public-ip";
import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const addMenuOrSubMenu = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.MenuManagement.MenuMasterCreate);
  return response;
};

export const upadateMenuOrSubMenu = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.MenuManagement.MenuMasterUpdate);
  return response;
};

export const getMenuList = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.GetCompanyMenuAssign);
  return response;
};

export const getUserMenuList = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyMasterUserMenu);
  return response;
};

export const companyMenuAssignOrUnAssigned = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.ManageCompanyMenuAssign);
  return response;
};

export const getMenuType = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.MenuListType);
  return response;
};

export const getCompanyMasterSelect = async (companyMasterId, customerMasterID, viewMode) => {
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const ip = await publicIpv4();
  const requestData = {
    companyMasterID: companyMasterId,
    customerMasterID: customerMasterID,
    viewMode: viewMode,
    objCommon: {
      insertUserId: insertUserID,
      insertIpAddress: ip,
    },
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.MenuListType);
  return response;
};
