import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const addMenuOrSubMenu = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.MenuManagement.MenuMasterCreate);
  return response;
};

export const upadateMenuOrSubMenu = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.MenuManagement.MenuMasterUpdate);
  return response;
};

export const getMenuList = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.MenuManagement.MenuMasterList);
  return response;
};

export const getMenuType = async () => {
  const requestData = "";
  const response = await ApiCalling(requestData, APIEndpoints.Company.MenuListType);
  return response;
};
