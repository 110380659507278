import React, { useState, useEffect } from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import BizClass from "../PageMaster/PageMaster.module.scss";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import PageTitle, { PageButton, PageSearch } from "../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, { FooterBox } from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { GetPageConfigData } from "./Resources/PageConfigService";
import AddPageConfig from "./Views/AddPageConfig";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";

const PageConfig = (props) => {
  const { selectedPage, formValuesPage } = props;
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const [PageConfigList, setPageConfigList] = useState([]);
  const [isLoadingPageConfigList, setIsLoadingPageConfigList] = useState(false);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getPageConfigList = async (selected) => {
    try {
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      setIsLoadingPageConfigList(true);
      const formData = {
        BizNextPageID: selected?.BizNextPageID || 0,
        BizNextPageConfigID: 0,
        SearchBy: searchMappedData ? searchMappedData : "#All",

        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await GetPageConfigData(formData);
      console.log(result, "result");
      setIsLoadingPageConfigList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          if (searchMappedData && searchMappedData.toLowerCase().includes("#")) {
            onSearchMappingData("");
          }
          setPageConfigList(result.responseData);
        } else {
          setPageConfigList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onClickSearchPageMaster = () => {
    getPageConfigList(selectedPage);
  };

  useEffect(() => {
    if (selectedPage) {
      getPageConfigList(selectedPage);
    }
  }, [selectedPage]);

  useEffect(() => {
    setPageConfigList([]);
  }, [formValuesPage.txtModule]);

  const [AddPageConfigPopup, setAddPageConfigPopup] = useState({
    isOpen: false,
    popupData: null,
    isEditMode: "",
  });
  const toggleAddPageConfig = (isEditMode, data) => {
    if (selectedPage) {
      setAddPageConfigPopup({ isOpen: !AddPageConfigPopup.isOpen, popupData: data, isEditMode: isEditMode });
    } else {
      setMsgAlert({ open: true, type: "warning", msg: "Select Page First!" });
    }
  };

  const gridColumnDefs = [
    { field: "#", headerName: "Action", width: 80, pinned: "left", lockPosition: "1", cellRenderer: "actionTemplate", cellRendererParams: { toggleAddPageConfig } },

    {
      field: "PageControlName",
      headerName: "Control Name",
      width: 120,
    },
    {
      field: "ControlType",
      headerName: "Control Type",
      width: 150,
    },
    {
      field: "ControlPlaceholder",
      headerName: "Placeholder",
      width: 120,
    },
    {
      field: "isRequired",
      headerName: "isRequired",
      width: 120,
    },
    {
      field: "AllowOptional",
      headerName: "Allow Optional",
      width: 150,
    },
    {
      field: "TextExpression",
      headerName: "Text Expression",
      width: 150,
    },
    {
      field: "AllowedCharacter",
      headerName: "Allowed Character",
      width: 180,
    },
    {
      field: "ControlMinValue",
      headerName: "Min Value",
      width: 120,
    },
    {
      field: "ControlMaxValue",
      headerName: "Max Value",
      width: 120,
    },
    {
      field: "ControlMinLength",
      headerName: "Min Length",
      width: 120,
    },
    {
      field: "ControlMaxLength",
      headerName: "Max Length",
      width: 120,
    },
  ];

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const updatePageConfigList = (department) => {
    PageConfigList.unshift(department);
    setPageConfigList(PageConfigList);
    if (gridApi) {
      gridApi.setRowData(PageConfigList);
    }
  };

  const _updateListingPageConfig = (record) => {
    if (gridApi) {
      const itemsToUpdate = [];
      gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.BizNextPageConfigID === record.BizNextPageConfigID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      gridApi.applyTransaction({
        update: itemsToUpdate,
      });
      gridApi.refreshCells();
    }
    let newData = PageConfigList.map((x) => {
      if (x.BizNextPageConfigID === record.BizNextPageConfigID) return record;
      return x;
    });
    setPageConfigList([...newData]);
  };

  return (
    <React.Fragment>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {AddPageConfigPopup.isOpen ? (
        <AddPageConfig
          setMsgAlert={setMsgAlert}
          _updateListingPageConfig={_updateListingPageConfig}
          updatePageConfigList={updatePageConfigList}
          toggleAddPageConfig={toggleAddPageConfig}
          setConfirmAlert={setConfirmAlert}
          popupData={AddPageConfigPopup.popupData}
          isEditMode={AddPageConfigPopup.isEditMode}
          selectedPage={selectedPage}
        />
      ) : null}
      <div className={BizClass.MainSectionSecond}>
        <PageTitle Title="Page Config">
          <PageSearch focus={true} onClick={() => onClickSearchPageMaster()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
          <PageButton onClick={() => toggleAddPageConfig(false, null)}>Add</PageButton>
        </PageTitle>
        <DataGrid
          rowData={PageConfigList}
          columnDefs={gridColumnDefs}
          onGridReady={onGridReady}
          loader={isLoadingPageConfigList ? <Loader /> : null}
          getRowStyle={function (data) {
            if (data.data.IsSelected) {
              return { background: "#ffc176" };
            } else {
              if (data.data.IsNewlyAdded) {
                return { background: "#d5a10e" };
              }
              return { background: "" };
            }
          }}
          frameworkComponents={{
            actionTemplate: cellTemplate,
          }}
        />
      </div>
      <Footer>
        <FooterBox Aligned={"right"}></FooterBox>
      </Footer>
    </React.Fragment>
  );
};

export default PageConfig;
const cellTemplate = (props) => {
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View List"
      >
        <FaEdit style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => props.toggleAddPageConfig(true, props.data)} />
      </span>
    </div>
  );
};
