import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const getTax = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.TaxList);
  return response;
};

export const getTaxDefinitionValue = async (relationId) => {
  const requestData = {
    ...relationId,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.TaxDefinitionValue);
  return response;
};

export const addTaxData = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.createTaxData);
  return response;
};

export const UpdateTaxData = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.UpdateTaxData);
  return response;
};

export const AddTaxDefinitionData = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.createTaxDefinitionData);
  return response;
};

export const UpdateTaxDefData = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.UpdateTaxDefData);
  return response;
};

export const getTaxType = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.TaxType);
  return response;
};

export const taxCategoryStatusUpdate = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.TaxCategoryStatusUpdate);
  return response;
};

export const taxDefinitionStatusUpdate = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.TaxDefinitionStatusUpdate);
  return response;
};

export const getTaxLocalization = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.GetTaxLocalization);
  return response;
};

export const createTaxLocalization = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.CreateTaxLocalization);
  return response;
};

export const deleteTaxLocalization = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.Tax.DeleteTaxLocalization);
  return response;
};
