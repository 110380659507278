import React, { useMemo } from "react";
import "./Home.css";
import Welcome_img from "../../../../Framework/Assets/Images_Components/welcome.jpeg";
import { useNavigate } from "react-router-dom";
import { checkAuthExist } from "../../../../APIMethods/Auth/auth";

function Home(props) {
  let naviagte = useNavigate();
  useMemo(async () => {
    if (!checkAuthExist()) {
      naviagte("/");
    }
  }, []);

  return (
    <div className="Biz_Bm_Home_ContentBox Biz_Bm__PageStart">
      <img src={Welcome_img} className="Biz_Bm_Home_welcomescreen" />
    </div>
  );
}
export default Home;
