/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */

import React, { useEffect, useRef, useState } from "react";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import classNames from "classnames";
import BizClass from "./GeographicData.module.scss";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import { HiArrowCircleRight, HiDocumentText } from "react-icons/hi";
import { UpdateCityStatusData, UpdateCountryStatusData, UpdateStateStatusData, getCities, getCountry, getStates } from "./Resources/GeographicDataService";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";
import AddCity from "./Views/AddCity/AddCity";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import AddCountry from "./Views/AddCountry/AddCountry";
import { FaEdit } from "react-icons/fa";
import UpdateCountryStatus from "./Views/UpdateCountryStatus/UpdateCountryStatus";
import AddState from "./Views/AddState/AddState";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

const GeographicData = () => {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  //-----------------------------------------------------------------------------------------------------

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    // const gridElement = gridRef.current;

    // if (gridElement) {
    //   gridElement.addEventListener("blur", handleFocusOut, true);

    //   return () => {
    //     gridElement.removeEventListener("blur", handleFocusOut, true);
    //   };
    // }
    setGridApi(params.api);
  };

  const gridRef = useRef(null);

  const handleFocusOut = () => {
    console.log("Grid lost focus");
  };

  // useEffect(() => {
  //   const gridElement = gridRef.current;

  //   if (gridElement) {
  //     gridElement.addEventListener("blur", handleFocusOut, true);

  //     return () => {
  //       gridElement.removeEventListener("blur", handleFocusOut, true);
  //     };
  //   }
  // }, []);

  const [searchTextCountry, setSearchTextCountry] = useState("");
  const onSearchCountry = (val) => {
    setSearchTextCountry(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const [stateGridApi, setStateGridApi] = useState();
  const onStateGridReady = (params) => {
    setStateGridApi(params.api);
  };

  const [searchTextState, setSearchTextState] = useState("");
  const onSearchState = (val) => {
    setSearchTextState(val);
    stateGridApi.setQuickFilter(val);
    stateGridApi.refreshCells();
  };

  const [cityGridApi, setCityGridApi] = useState();
  const onCityGridReady = (params) => {
    setCityGridApi(params.api);
  };

  const [searchTextCity, setSearchTextCity] = useState("");
  const onSearchCity = (val) => {
    setSearchTextCity(val);
    cityGridApi.setQuickFilter(val);
    cityGridApi.refreshCells();
  };

  //-------------------------------------------------API CALLING----------------------------------------------------

  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const getCountryList = async () => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingCountry(true);
      const formData = {
        searchText: searchTextCountry,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getCountry(formData);
      console.log(result, "result");
      setIsLoadingCountry(false);
      if (result.responseCode === 1) {
        console.log(result);
        if (result && result.responseData && result.responseData.length > 0) {
          if (searchTextCountry && searchTextCountry.toLowerCase().includes("#")) {
            onSearchCountry("");
          }
          if (gridApi) {
            gridApi.refreshCells();
          }
          // setSelectedRowColor("");
          setStateList([]);
          setCityList([]);
          setSelectedCountryCode();
          setSelectedState();
          setCountryList(result.responseData);
        } else {
          setCountryList([]);
        }
      } else {
        setCountryList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [selectedCountryCode, setSelectedCountryCode] = useState();
  const onClickGetStates = (data) => {
    debugger;
    console.log(data, "selectedCountryCode");
    setStateList([]);
    console.log(data);
    setSelectedCountryCode(data);
    setSelectedState();
    // setSelectedRowColor(data.CountryMasterID);
    getStateList(data);
  };

  // const setSelectedRowColor = (CountryMasterID) => {
  //   if (gridApi) {
  //     gridApi.forEachNode(function (rowNode) {
  //       if (rowNode.data.CountryMasterID === CountryMasterID) {
  //         const newData = {
  //           ...rowNode.data,
  //           IsSelected: true,
  //         };
  //         rowNode.setData(newData);
  //       } else {
  //         rowNode.data.IsSelected = false;
  //         rowNode.setData(rowNode.data);
  //       }
  //     });
  //   }
  // };

  const [isLoadingState, setIsLoadingState] = useState(false);
  const [stateList, setStateList] = useState([]);
  const getStateList = async (data) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingState(true);
      const formData = {
        countryMasterID: data && data.CountryMasterID ? data.CountryMasterID : 0,
        searchText: searchTextState,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getStates(formData);
      console.log(result);
      setIsLoadingState(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          if (searchTextState && searchTextState.toLowerCase().includes("#")) {
            onSearchState("");
          }
          setCityList([]);
          setSelectedState();
          setStateList(result.responseData);
        }
      } else {
        setStateList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const setSelectedStateRowColor = (StateMasterID) => {
    if (stateGridApi) {
      stateGridApi.forEachNode(function (rowNode) {
        if (rowNode.data.StateMasterID === StateMasterID) {
          const newData = {
            ...rowNode.data,
            IsSelected: true,
          };
          rowNode.setData(newData);
        } else {
          rowNode.data.IsSelected = false;
          rowNode.setData(rowNode.data);
        }
      });
    }
  };

  const [selectedState, setSelectedState] = useState();
  const onClickGetCity = (data) => {
    setCityList([]);
    console.log(data);
    setSelectedState();
    setSelectedState(data);
    setSelectedStateRowColor(data.StateMasterID);
    getCityList(data);
  };

  const [isLoadingCity, setIsLoadingCity] = useState(false);
  const [cityList, setCityList] = useState([]);
  const getCityList = async (data) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingCity(true);
      const formData = {
        countryMasterID: data && data.CountryMasterID ? data.CountryMasterID : selectedCountryCode && selectedCountryCode.CountryMasterID ? selectedCountryCode.CountryMasterID : 0,
        stateMasterID: data && data.StateMasterID ? data.StateMasterID : 0,
        searchText: searchTextCity,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getCities(formData);
      console.log(result, "result");
      setIsLoadingCity(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          if (searchTextCity && searchTextCity.toLowerCase().includes("#")) {
            onSearchCity("");
          }
          setCityList(result.responseData);
        } else {
          setCityList([]);
        }
      } else {
        setCityList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  //-------------------------------------------------API CALLING----------------------------------------

  useEffect(() => {
    getCountryList();
  }, []);

  const [addCountryPopupActive, setAddCountryPopupActive] = useState({
    IsOpen: false,
    IsEditMode: "",
    popupData: null,
  });

  const toggleAddCountryPopup = (isEdit, editData) => {
    setAddCountryPopupActive({
      IsOpen: !addCountryPopupActive.IsOpen,
      IsEditMode: isEdit,
      popupData: editData,
    });
  };
  const [AddCityPopup, setAddCityPopup] = useState({
    IsOpen: false,
    IsEditMode: "",
    popupData: null,
  });

  const toggleAddCity = (isEdit, editData) => {
    debugger;
    console.log(selectedCountryCode, "selectedCountryCode");
    if (selectedCountryCode) {
      console.log(selectedCountryCode, "selectedCountryCode");
      console.log(selectedCountryCode.AllowState, "selectedCountryCode.AllowState");

      if (selectedCountryCode && selectedCountryCode.AllowState && selectedCountryCode.AllowState === 1) {
        if (selectedState) {
          setAddCityPopup({
            IsOpen: !AddCityPopup.IsOpen,
            IsEditMode: isEdit,
            popupData: editData,
          });
        } else {
          setMsgAlert({ open: true, type: "warning", msg: "Please Select State First" });
        }
      } else {
        if (selectedCountryCode && String(selectedCountryCode.AllowState) && selectedCountryCode.AllowState === 0) {
          setAddCityPopup({
            IsOpen: !AddCityPopup.IsOpen,
            IsEditMode: isEdit,
            popupData: editData,
          });
        } else {
          setMsgAlert({ open: true, type: "warning", msg: "Can not add city to the selected country!" });
        }
      }
    } else {
      setMsgAlert({ open: true, type: "warning", msg: "Please Select Country First" });
    }
  };

  const [addStatePopup, setAddStatePopup] = useState({ IsOpen: false, IsEditMode: "", popupData: null });
  const toggleAddStatePopup = (isEdit, data) => {
    if (selectedCountryCode) {
      if (selectedCountryCode.AllowState && selectedCountryCode.AllowState !== 0) {
        setAddStatePopup({
          IsOpen: !addStatePopup.IsOpen,
          popupData: data,
          IsEditMode: isEdit,
        });
      } else {
        setMsgAlert({ open: true, type: "warning", msg: "Can not add state to the selected country!" });
      }
    } else {
      setMsgAlert({ open: true, type: "warning", msg: "Please Select Country First" });
    }
  };
  //--------------------------------------------------------------------------------------------------------

  const updateCountryList = (data) => {
    setCountryList([]);
    countryList.unshift(data);
    setCountryList(countryList);
    if (gridApi) {
      gridApi.setRowData(countryList);
    }
  };

  const updateCityList = (date) => {
    cityList.unshift(date);
    setCityList(cityList);
    if (cityGridApi) {
      cityGridApi.setRowData(cityList);
    }
  };

  const updateStateList = (date) => {
    stateList.unshift(date);
    setStateList(stateList);
    if (stateGridApi) {
      stateGridApi.setRowData(stateList);
    }
  };

  const _updateCountryListing = (record) => {
    console.log(record, "record");
    if (gridApi) {
      console.log(gridApi);
      const itemsToUpdate = [];
      gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.CountryMasterID === record.CountryMasterID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      gridApi.applyTransaction({
        update: itemsToUpdate,
      });
      gridApi.refreshCells();
    }
    let newData = countryList.map((x) => {
      if (x.CountryMasterID === record.CountryMasterID) return record;
      return x;
    });
    setCountryList([...newData]);
  };

  const _updateStateListing = (record) => {
    console.log(record, "record");
    if (gridApi) {
      console.log(gridApi);
      const itemsToUpdate = [];
      gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.StateMasterID === record.StateMasterID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      gridApi.applyTransaction({
        update: itemsToUpdate,
      });
      gridApi.refreshCells();
    }
    let newData = stateList.map((x) => {
      if (x.StateMasterID === record.StateMasterID) return record;
      return x;
    });
    setStateList([...newData]);
  };

  const _updateCityListing = (record) => {
    console.log(record, "record");
    if (gridApi) {
      console.log(gridApi);
      const itemsToUpdate = [];
      gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.CityMasterID === record.CityMasterID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      gridApi.applyTransaction({
        update: itemsToUpdate,
      });
      gridApi.refreshCells();
    }
    let newData = cityList.map((x) => {
      if (x.CityMasterID === record.CityMasterID) return record;
      return x;
    });
    setCityList([...newData]);
  };

  //---------------------------------------------------------------------------------------------

  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const onSubmitCountryStatus = async (data, status) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      let formData = {
        activeStatus: status,
        countryMasterID: data && data.CountryMasterID ? data.CountryMasterID : 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await UpdateCountryStatusData(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        _updateCountryListing({ ...data, ActiveStatus: status, IsNewlyAdded: false, IsUpdated: true });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onSubmitStateStatus = async (data, status) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      let formData = {
        ActiveStatus: status,
        StateMasterID: data && data.StateMasterID ? data.StateMasterID : 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await UpdateStateStatusData(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        _updateStateListing({ ...data, ActiveStatus: status, IsNewlyAdded: false, IsUpdated: true });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onSubmitCityStatus = async (data, status) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      let formData = {
        activeStatus: status,
        cityMasterID: data && data.CityMasterID ? data.CityMasterID : 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await UpdateCityStatusData(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        _updateCityListing({ ...data, ActiveStatus: status, IsNewlyAdded: false, IsUpdated: true });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  //----------------------------------------------------------------------------------------------

  const CountryGridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      pinned: "left",
      cellRenderer: "actionTemplate",
      cellRendererParams: {
        onClickGetStates: onClickGetStates,
        toggleAddCountryPopup: toggleAddCountryPopup,
        onSubmitCountryStatus: onSubmitCountryStatus,
        setConfirmAlert,
        onClickGetCity: onClickGetCity,
        setStateList,
        setCityList,
        setSelectedCountryCode,
      },
      width: 100,
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1" },
    {
      field: "CountryName",
      headerName: "Country Name",
      width: 130,
    },
    {
      field: "Alpha3Code",
      headerName: "Alpha3Code",
      width: 110,
    },
    {
      field: "NumericCode",
      headerName: "Numeric Code",
      width: 120,
    },
    {
      field: "AllowState",
      headerName: "Allow State",
      width: 105,
      valueFormatter: (param) => (param.value && param.value === 1 ? "YES" : "NO"),
    },
    {
      field: "StateLabel",
      headerName: "State Lable",
      width: 110,
    },
  ];
  const StateGridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      pinned: "left",
      cellRenderer: "actionTemplate",
      cellRendererParams: {
        onClickGetCity: onClickGetCity,
        toggleAddStatePopup: toggleAddStatePopup,
        onSubmitStateStatus: onSubmitStateStatus,
        setConfirmAlert,
      },
      width: 100,
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1" },
    {
      field: "StateName",
      headerName: "State Name",
      width: 180,
    },
    {
      field: "StateTaxCode",
      headerName: "State TaxCode",
      width: 130,
    },
    {
      field: "ISOCode",
      headerName: "ISO Code",
      width: 120,
    },
    {
      field: "ActiveStatus",
      headerName: "Active Status",
      width: 130,
    },
  ];
  const cityGridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      pinned: "left",
      cellRenderer: "actionTemplate",
      cellRendererParams: {
        toggleAddCity: toggleAddCity,
        onSubmitCityStatus: onSubmitCityStatus,
        setConfirmAlert,
      },
      width: 100,
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: 2 },
    {
      field: "CityName",
      headerName: "Name",
      width: 180,
    },
    {
      field: "PinCode",
      headerName: "PinCode",
      width: 150,
    },
    {
      field: "ActiveStatus",
      headerName: "Active Status",
      width: 180,
    },
  ];

  //----------------------------------------------------------------------------------------------
  return (
    <>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {addCountryPopupActive.IsOpen ? (
        <AddCountry
          isEditMode={addCountryPopupActive.IsEditMode}
          setMsgAlert={setMsgAlert}
          getCountry={getCountry}
          popupData={addCountryPopupActive.popupData}
          toggleAddCountryPopup={toggleAddCountryPopup}
          updateCountryList={updateCountryList}
          setConfirmAlert={setConfirmAlert}
          selectedCountryCode={selectedCountryCode}
          _updateListing={_updateCountryListing}
        />
      ) : null}

      {AddCityPopup.IsOpen ? (
        <AddCity
          selectedState={selectedState}
          isEditMode={AddCityPopup.IsEditMode}
          popupData={AddCityPopup.popupData}
          setConfirmAlert={setConfirmAlert}
          selectedCountryCode={selectedCountryCode}
          setMsgAlert={setMsgAlert}
          updateCityList={updateCityList}
          toggleAddCity={toggleAddCity}
          _updateListing={_updateCityListing}
        />
      ) : null}

      {addStatePopup.IsOpen ? (
        <AddState
          isEditMode={addStatePopup.IsEditMode}
          setConfirmAlert={setConfirmAlert}
          selectedCountryCode={selectedCountryCode}
          setMsgAlert={setMsgAlert}
          updateStateList={updateStateList}
          popupData={addStatePopup.popupData}
          toggleAddStatePopup={toggleAddStatePopup}
          _updateListing={_updateStateListing}
        />
      ) : null}
      <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
        <Splitter varient="column" template="1fr 9px 1fr">
          <Splitter varient="row" template="1fr 9px 1fr">
            <div className={BizClass.Data}>
              <PageBar title="Country">
                <PageBar.Search
                  onClick={() => {
                    getCountryList();
                  }}
                  value={searchTextCountry}
                  onChange={(e) => onSearchCountry(e.target.value)}
                />
                <PageBar.Button onClick={() => toggleAddCountryPopup(false, null)}>Add Country</PageBar.Button>
              </PageBar>
              <DataGrid
                columnDefs={CountryGridColumnDefs}
                rowData={countryList}
                loader={isLoadingCountry ? <Loader /> : null}
                onGridReady={onGridReady}
                frameworkComponents={{
                  actionTemplate: CountryActionTemplate,
                }}
                getRowStyle={function (data) {
                  // if (data.data.IsSelected) {
                  //   return { background: "#ffc176" };
                  // } else {
                  //   if (data.data.IsNewlyAdded) {
                  //     return { background: "#d5a10e" };
                  //   }
                  //   return { background: "" };
                  // }
                  if (data?.data?.IsSelected) {
                    return { background: "#ffc176" };
                  } else {
                    if (data?.data?.IsNewlyAdded) {
                      return { background: "#d5a10e" };
                    } else if (data?.data?.IsUpdated) {
                      return { background: "#ffd40085" };
                    }
                    return { background: "" };
                  }
                }}
              />
            </div>
            <div className={BizClass.Data}>
              <PageBar title="State ">
                <PageBar.Search
                  onClick={() => {
                    getStateList(selectedCountryCode);
                  }}
                  value={searchTextState}
                  onChange={(e) => onSearchState(e.target.value)}
                />
                <PageBar.Button onClick={() => toggleAddStatePopup(false, null)}>Add State</PageBar.Button>
              </PageBar>
              <DataGrid
                columnDefs={StateGridColumnDefs}
                rowData={stateList}
                loader={isLoadingState ? <Loader /> : null}
                onGridReady={onStateGridReady}
                frameworkComponents={{
                  actionTemplate: StateActionTemplate,
                }}
                getRowStyle={function (data) {
                  if (data.data.IsSelected) {
                    return { background: "#ffc176" };
                  } else {
                    if (data.data.IsNewlyAdded) {
                      return { background: "#d5a10e" };
                    }
                    return { background: "" };
                  }
                }}
              />
            </div>
          </Splitter>

          <div className={BizClass.Data}>
            <PageBar title="Cities ">
              <PageBar.Search
                onClick={() => {
                  getCityList(selectedState);
                }}
                value={searchTextCity}
                onChange={(e) => onSearchCity(e.target.value)}
              />
              <PageBar.Button onClick={() => toggleAddCity(false, null)}>Add City</PageBar.Button>
            </PageBar>
            <DataGrid
              columnDefs={cityGridColumnDefs}
              rowData={cityList}
              loader={isLoadingCity ? <Loader /> : null}
              onGridReady={onCityGridReady}
              ref={gridRef}
              getRowStyle={function (data) {
                if (data.data.IsSelected) {
                  return { background: "#ffc176" };
                } else {
                  if (data.data.IsNewlyAdded) {
                    return { background: "#d5a10e" };
                  }
                  return { background: "" };
                }
              }}
              frameworkComponents={{
                actionTemplate: CityActionTemplate,
              }}
            />
          </div>
        </Splitter>
      </div>
      <Footer />
    </>
  );
};

export default GeographicData;

const CountryActionTemplate = (props) => {
  const onClickGetStates = () => {
    if (props.data && String(props.data.AllowState) && props.data.AllowState === 0) {
      props.setSelectedCountryCode(props.data);
      props.onClickGetCity(props.data);

      props.setStateList([]);
    } else {
      props.setSelectedCountryCode(props.data);
      props.onClickGetStates(props.data);
      props.setCityList([]);
    }
  };

  const onEditCountry = () => {
    props.toggleAddCountryPopup(true, props.data);
  };

  return (
    <React.Fragment>
      <div>
        <span title="View States" style={{ cursor: "pointer", paddingRight: "8px" }}>
          <HiArrowCircleRight style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onClickGetStates()} />
        </span>
        <span title="Update Country" style={{ cursor: "pointer", paddingRight: "8px" }}>
          <FaEdit style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onEditCountry()} />{" "}
        </span>

        <span style={{ cursor: "pointer", paddingRight: "8px" }}>
          {props.data && props.data.ActiveStatus === "YES" ? (
            <BsToggleOn
              title="Active"
              onClick={() =>
                props.setConfirmAlert({
                  open: true,
                  title: "InActive",
                  msg: "Are you sure to InActive ?",
                  button: { confirmText: "InActive", abortText: "Cancel", Color: "Danger" },
                  onConfirm: () => props.onSubmitCountryStatus(props.data, "NO"),
                })
              }
              style={{ fontSize: "17px", color: "#4caf50" }}
            />
          ) : props.data && props.data.ActiveStatus === "NO" ? (
            <BsToggleOff
              title="InActive"
              onClick={() =>
                props.setConfirmAlert({
                  open: true,
                  title: "Active",
                  msg: "Are you sure to Active ?",
                  button: { confirmText: "Active", abortText: "Cancel" },
                  onConfirm: () => props.onSubmitCountryStatus(props.data, "YES"),
                })
              }
              style={{ fontSize: "17px", color: "#ff0000" }}
            />
          ) : (
            <></>
          )}
        </span>
      </div>
    </React.Fragment>
  );
};

const StateActionTemplate = (props) => {
  const onClickGetCity = () => {
    props.onClickGetCity(props.data);
  };
  const onEditState = () => {
    debugger;
    props.toggleAddStatePopup(true, props.data);
  };

  return (
    <React.Fragment>
      <div>
        <span style={{ cursor: "pointer", paddingRight: "8px" }}>
          <HiArrowCircleRight style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onClickGetCity()} />
        </span>
        <span title="Update State" style={{ cursor: "pointer", paddingRight: "8px" }}>
          <FaEdit style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onEditState()} />{" "}
        </span>
        <span style={{ cursor: "pointer", paddingRight: "8px" }}>
          {props.data && props.data.ActiveStatus === "YES" ? (
            <BsToggleOn
              title="Active"
              onClick={() =>
                props.setConfirmAlert({
                  open: true,
                  title: "InActive",
                  msg: "Are you sure to InActive ?",
                  button: { confirmText: "InActive", abortText: "Cancel", Color: "Danger" },
                  onConfirm: () => props.onSubmitStateStatus(props.data, "NO"),
                })
              }
              style={{ fontSize: "17px", color: "#4caf50" }}
            />
          ) : props.data && props.data.ActiveStatus === "NO" ? (
            <BsToggleOff
              title="InActive"
              onClick={() =>
                props.setConfirmAlert({
                  open: true,
                  title: "Active",
                  msg: "Are you sure to Active ?",
                  button: { confirmText: "Active", abortText: "Cancel" },
                  onConfirm: () => props.onSubmitStateStatus(props.data, "YES"),
                })
              }
              style={{ fontSize: "17px", color: "#ff0000" }}
            />
          ) : (
            <></>
          )}
        </span>
      </div>
    </React.Fragment>
  );
};

const CityActionTemplate = (props) => {
  const onEditCity = () => {
    debugger;
    props.toggleAddCity(true, props.data);
  };

  return (
    <React.Fragment>
      <div>
        <span title="Update State" style={{ cursor: "pointer", paddingRight: "8px" }}>
          <FaEdit style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onEditCity()} />{" "}
        </span>
        <span style={{ cursor: "pointer", paddingRight: "8px" }}>
          {props.data && props.data.ActiveStatus === "YES" ? (
            <BsToggleOn
              title="Active"
              onClick={() =>
                props.setConfirmAlert({
                  open: true,
                  title: "InActive",
                  msg: "Are you sure to InActive ?",
                  button: { confirmText: "InActive", abortText: "Cancel", Color: "Danger" },
                  onConfirm: () => props.onSubmitCityStatus(props.data, "NO"),
                })
              }
              style={{ fontSize: "17px", color: "#4caf50" }}
            />
          ) : props.data && props.data.ActiveStatus === "NO" ? (
            <BsToggleOff
              title="InActive"
              onClick={() =>
                props.setConfirmAlert({
                  open: true,
                  title: "Active",
                  msg: "Are you sure to Active ?",
                  button: { confirmText: "Active", abortText: "Cancel" },
                  onConfirm: () => props.onSubmitCityStatus(props.data, "YES"),
                })
              }
              style={{ fontSize: "17px", color: "#ff0000" }}
            />
          ) : (
            <></>
          )}
        </span>
      </div>
    </React.Fragment>
  );
};
