import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import classNames from "classnames";
import "./DataGrid.scss";

function DataGrid(props) {
  const { className, children, loader, theme, rowSelection, defaultColDef, rowActions, ...restProps } = props;
  return (
    <div className={classNames("Biz_CustomizedAgGrid", "ag-theme-blue", className)}>
      {loader}
      <AgGridReact
        enableRangeSelection={true}
        rowSelection={rowSelection}
        defaultColDef={{
          sortable: true,
          resizable: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        }}
        {...restProps}
      >
        {children}
      </AgGridReact>
    </div>
  );
}

export default DataGrid;

// function DataGridColumn(props) {
//   const { children, ...restProps } = props;
//   return <AgGridColumn {...restProps}>{children}</AgGridColumn>;
// }

// DataGrid.Column = DataGridColumn;
