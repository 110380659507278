import React, { useState, useEffect } from "react";
import BizClass from "./Common_Master.module.scss";
import { BiLoaderAlt, BiRefresh } from "react-icons/bi";
import { HiPlusCircle } from "react-icons/hi";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaTimes, FaAngleRight, FaCog, FaEye, FaFilter, FaSync, FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import { getCommonCode, getCommonValue, addCommonMasterCode, addCommonMasterValue } from "./Resources/CommonMasterService";
import Select from "react-select";
import { FormSelectStyle, PageTitleSelectStyle } from "../../../Framework/Components/Widgets/SelectStyle/SelectStyle";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import classNames from "classnames";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import { AiOutlineFileAdd } from "react-icons/ai";
import { ImEye } from "react-icons/im";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import AddNewMaster from "./Views/AddNewMaster/AddNewMaster";
import AddNewCommonValue from "./Views/AddNewMasterValue/AddNewCommonValue";
import { publicIpv4 } from "public-ip";

function Common_Master() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });

  const [commonCodeList, setCommonCodeList] = useState([]);
  const [filteredCommonCodeList, setFilteredCommonCodeList] = useState([]);
  const [commonValueList, setCommonValueList] = useState([]);
  const [filteredCommonValueList, setFilteredCommonValueList] = useState([]);
  const [selectedCommonMasterCode, setSelectedCommonMasterCode] = useState({});
  const [isLoadingCommonValues, setIsLoadingCommonValues] = useState(false);
  const [isLoadingCommonCode, setIsLoadingCommonCode] = useState(false);
  const [addNewMasterPopupActive, setAddNewMasterPopupActive] = useState(false);
  const [addNewMasterValuePopupActive, setAddNewMasterValuePopupActive] = useState(false);
  const [addedMasterValuePopupActiveId, setAddedMasterValuePopupActiveId] = useState();
  const [editedMasterValuePopupActiveId, setEditedMasterValuePopupActiveId] = useState();
  const [selectedModule, setSelectedModule] = useState({});

  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const getCommonCodeList = async () => {
    try {
      const ip = await publicIpv4();
      setIsLoadingCommonCode(true);
      const formData = {
        action: "GETLIST",
        commonMasterCode: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getCommonCode(formData);
      setIsLoadingCommonCode(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          if (searchTextCommonCode && searchTextCommonCode.toLowerCase().includes("#")) {
            searchCommonCode("");
          }
          setCommonCodeList(result.responseData);
          setFilteredCommonCodeList(result.responseData);
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const getCommonValueList = async (commonMasterCode) => {
    try {
      const ip = await publicIpv4();
      setIsLoadingCommonValues(true);
      const formData = {
        action: "GETLIST",
        commonMasterCode: commonMasterCode,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getCommonValue(formData);
      setIsLoadingCommonValues(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setCommonValueList(result.responseData);
          // setFilteredCommonValueList(result.responseData);
        } else {
          setCommonValueList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        setCommonValueList([]);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getCommonCodeList();
  }, []);

  const [commonCodeGridApi, setCommonCodeGridApi] = useState();
  const onModuleMasterGridReady = (params) => {
    setCommonCodeGridApi(params.api);
  };
  const [searchTextCommonCode, setSearchTextCommonCode] = useState("");
  const searchCommonCode = (val) => {
    setSearchTextCommonCode(val);
    commonCodeGridApi.setQuickFilter(val);
    commonCodeGridApi.refreshCells();
  };

  const [commonValueGridApi, setCommonValueGridApi] = useState();
  const OnSetupMenuListGridReady = (params) => {
    setCommonValueGridApi(params.api);
  };
  const [searchTextCommonValue, setSearchTextCommonValue] = useState("");
  const searchCommonValue = (val) => {
    setSearchTextCommonValue(val);
    commonValueGridApi.setQuickFilter(val);
    commonValueGridApi.refreshCells();
  };

  const onRefreshClick = () => {
    getCommonCodeList();
  };

  const onRefreshCommonCode = () => {
    getCommonCodeList();
  };

  const [AddActive, setAddActive] = useState(false);
  const onDisplayClick = (data) => {
    debugger;
    setAddActive(true);
    setSelectedCommonMasterCode(data);
    getCommonValueList(data.CommonMasterCode);
  };

  const onRefreshCommonValue = () => {
    if (selectedCommonMasterCode !== "") {
      let commonMasterCode = selectedCommonMasterCode.CommonMasterCode;
      getCommonValueList(commonMasterCode);
    } else {
      return;
    }
  };

  const toggleAddNewMasterPopupActive = () => {
    setAddNewMasterPopupActive(!addNewMasterPopupActive);
  };

  const toggleAddNewMasterValuePopupActive = () => {
    setAddNewMasterValuePopupActive(!addNewMasterValuePopupActive);
  };

  const updateCommonCodeList = (commonCode) => {
    filteredCommonCodeList.unshift(commonCode);
    setFilteredCommonCodeList(filteredCommonCodeList);
    if (commonCodeGridApi) {
      commonCodeGridApi.setRowData(filteredCommonCodeList);
    }
    // if (commonCodeGridApi) {
    //   const rowData = [];
    //   if (commonCode && commonCode.length > 0) {
    //     commonCode.forEach((data) => {
    //       rowData.push(data);
    //     });
    //   }
    //   commonCodeGridApi.forEachNode((node) => rowData.push(node.data));
    //   commonCodeGridApi.setRowData(rowData);
    // }
  };

  const updateCommonValueList = (commonValue) => {
    debugger;
    commonValueList.unshift(commonValue);
    setCommonValueList(commonValueList);
    console.log(commonValueGridApi, commonValueList, "commonValueGridApi,commonValueList");
    if (commonValueGridApi) {
      // commonValueGridApi.applyTransaction({
      //   add: [commonValue],
      //   addIndex: 0,
      // });
      commonValueGridApi.setRowData(commonValueList);
    }
  };
  useEffect(() => {
    if (msgAlert.open) {
      const timer = setTimeout(() => {
        setMsgAlert({ open: false, type: "", msg: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [msgAlert]);

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    }
    return { background: "" };
  };

  const menuListGridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 120,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellRendererParams: { onDisplayClick, setSelectedModule },
    },

    {
      field: "CommonMasterCode",
      headerName: "Code",
      width: 150,
      lockPosition: 2,
    },
    {
      field: "CommonMasterName",
      headerName: "Name",
      width: 180,
      lockPosition: 2,
    },
    {
      field: "ValueDataType",
      headerName: "Data Type",
      width: 120,
    },
    {
      field: "AllowedCharacter",
      headerName: "Character Allowed",
      width: 150,
    },
  ];

  const masterValueColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 120,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
    },
    {
      field: "CommonMasterValueID",
      headerName: "Code",
      width: 120,
      pinned: "left",
      lockPosition: 2,
    },
    {
      field: "CommonMasterValue",
      headerName: "Name",
      width: 180,
    },
    // {
    //   field: "ShortCode",
    //   headerName: "Short Code",
    //   width: 120,
    // },
  ];

  const onClickSearchBar = (data) => {
    if (data) {
      onDisplayClick(data);
    } else {
      setMsgAlert({ open: true, type: "warning", msg: "Select Module" });
    }
  };

  return (
    <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {addNewMasterPopupActive ? (
        <AddNewMaster setMsgAlert={setMsgAlert} updateCommonCodeList={updateCommonCodeList} toggleAddNewMasterPopupActive={toggleAddNewMasterPopupActive} />
      ) : null}
      {addNewMasterValuePopupActive ? (
        <AddNewCommonValue
          data={selectedCommonMasterCode}
          onDisplayClick={onDisplayClick}
          setMsgAlert={setMsgAlert}
          selectedModule={selectedModule}
          toggleAddNewMasterValuePopupActive={toggleAddNewMasterValuePopupActive}
          updateCommonValueList={updateCommonValueList}
        />
      ) : null}
      <div className={BizClass.Biz_Bm_Manage_Menu_ContentBox}>
        <Splitter varient="column" template="1fr 9px 0.84fr">
          <div className={BizClass.MainSection}>
            <PageBar title="Common Master">
              <PageBar.Search focus={true} onClick={() => onRefreshCommonCode()} value={searchTextCommonCode} onChange={(e) => searchCommonCode(e.target.value)} />
              <PageBar.Button onClick={() => toggleAddNewMasterPopupActive()}>Add</PageBar.Button>
            </PageBar>

            <DataGrid
              rowData={filteredCommonCodeList}
              columnDefs={menuListGridColumnDefs}
              onGridReady={onModuleMasterGridReady}
              onDisplayClick={onDisplayClick}
              setSelectedModule={setSelectedModule}
              animateRows={true}
              loader={isLoadingCommonCode ? <Loader /> : null}
              getRowStyle={getRowStyle}
              frameworkComponents={{
                actionTemplate: cellTemplate,
              }}
            />
          </div>
          <div className={BizClass.MainSection}>
            <PageBar title={` ${selectedModule && selectedModule.CommonMasterName ? `${selectedModule.CommonMasterName} - Menu List` : "Master value"}`}>
              {AddActive ? (
                <>
                  <PageBar.Search
                    onClick={() => {
                      console.log(selectedModule, "selectedModule");
                      onDisplayClick(selectedModule);
                    }}
                    value={searchTextCommonValue}
                    onChange={(e) => searchCommonValue(e.target.value)}
                  />
                  <PageBar.Button onClick={() => toggleAddNewMasterValuePopupActive()}>Add</PageBar.Button>
                </>
              ) : (
                ""
              )}
            </PageBar>
            <DataGrid
              rowData={commonValueList}
              getRowStyle={getRowStyle}
              onGridReady={OnSetupMenuListGridReady}
              columnDefs={masterValueColumnDefs}
              loader={isLoadingCommonValues ? <Loader /> : null}
              frameworkComponents={{
                actionTemplate: actionTemplate,
              }}
            />
          </div>
        </Splitter>
        <Footer />
      </div>
    </div>
  );
}

export default Common_Master;

const cellTemplate = (props) => {
  const onDisplayClick = () => {
    console.log("***********Selected Data***********", props.data);
    props.onDisplayClick(props.data);
    props.setSelectedModule(props.data);
  };
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View File"
      >
        <ImEye style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => onDisplayClick()} />
      </span>
      {/* <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="Delete File"
      >
        <AiOutlineFileAdd
          style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }}
          // onClick={() => handleDeleteFile()}
        />
      </span> */}
    </div>
  );
};

const actionTemplate = (props) => {
  let menuData = props.data;
  const onDeleteClick = () => {};
  const onAddClick = () => {
    debugger;
    props.agGridReact.props.openSubmenuPopup({
      open: true,
      data: menuData,
      isEditMode: false,
    });
  };
  const onEditClick = () => {
    props.agGridReact.props.openSubmenuPopup({
      open: true,
      data: menuData,
      isEditMode: true,
    });
  };

  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View File"
      >
        <FaEdit
          style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }}
          // onClick={() => handleDeleteFile()}
        />
      </span>
      {/* <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="Delete File"
      >
        <FaTrashAlt
          style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }}
          //   onClick={() => onDisplayClick()}
        />
      </span> */}
    </div>
  );
};
