import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginSession.scss";
import Session_Timeout from "../../../../Framework/Assets/images/Logo/BizNextLogo";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FaExclamationTriangle } from "react-icons/fa";
import moment from "moment";
import { CustomMsg } from "../../../../Configration/Utilities/customMessage";
import ErrorMessage from "../../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { checkAuthExist, getSessionStorage, setSessionStorage } from "../../../../APIMethods/Auth/auth";
import { authenticate } from "../Login/Services/authenticateService";
import BiznextLogo from "../../../../Framework/Assets/Images_Components/biznext-logo";

const LoginSession = (props) => {
  const navigate = useNavigate();
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!checkAuthExist()) {
        if (!isSessionExpired) {
          const user = getSessionStorage("user");
          if (user === null) {
            navigate("/");
          } else {
            setUserName(user.UserName);
            setCompanyCode(user.CompanyCode);
            setIsSessionExpired(true);
          }
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const togglePassword = () => {
    setRevealPassword(!revealPassword);
  };

  const onSetPassword = (val) => {
    setPassword(val);
    if (val.trim() === "") {
      setValidationMessage("Password is required!");
    } else {
      setValidationMessage("");
    }
  };

  const onSubmitNewBMC = async () => {
    debugger;
    try {
      setBtnloaderActive(true);
      const result = await authenticate(userName, password);
      console.log("Result", result);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        if (result.responseData) {
          if (!result.responseData.token) {
            setMsgAlert({
              open: true,
              type: "error",
              msg: "Token is missing in the response",
            });
            console.log(result.responseData.token);
          } else {
            let currentDate = new Date();
            let minutes = 28;
            if (result.responseData.expirationTime) {
              minutes = Number(result.responseData.expirationTime) - 2;
            }
            let user = {
              User: result.responseData,
              UserName: userName,
              Token: result.responseData.token,
              ...result.responseData,
              Expiry: new Date(currentDate.getTime() + minutes * 60000),
            };
            sessionStorage.clear();
            setSessionStorage("user", user);
            setIsSessionExpired(false);
            props.loginHandler(true);
          }
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setBtnloaderActive(false);
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {isSessionExpired ? (
        <React.Fragment>
          <div className="Biz_Login__SessionTimoutPopup_Overlay Biz_Login__SessionTimoutPopup_OverlayOn"></div>
          <div className="Biz_Login_SessionTimoutPopup Biz_Login_SessionTimoutPopup_On">
            {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
            {/* <header className="DynBiz_Popup_Header">
              <h2>Session Time Out</h2>
            </header> */}
            <div className="Biz_Login_SessionTimoutPopup_ImgBox">
              <Session_Timeout className="Biz_Login_SessionTimoutPopup_Img" />

              <div>
                <h3 className="Biz_Login_SessionTimoutPopup_HeadTxt">Your Session has Timed Out</h3>
                <p className="Biz_Login_SessionTimoutPopup_Txt">Please Login again to Continue</p>
              </div>
            </div>
            <form className="Biz_Login_SessionTimoutPopup_Form">
              <div className="Biz_Login_SessionTimoutPopup_FormGroup">
                <p>
                  <span>{userName}</span>
                </p>
              </div>
              <div className={validationMessage ? "Biz_Login_SessionTimoutPopup_FormGroup Sessionlogin__Form_error" : "Biz_Login_SessionTimoutPopup_FormGroup"}>
                <div className="Biz_Login_SessionTimoutPopup_FormInpBox">
                  <input type={revealPassword ? "text" : "password"} autoComplete="current-password" placeholder="Password" onChange={(e) => onSetPassword(e.target.value)} />
                  <span onClick={togglePassword}>
                    {revealPassword ? <AiFillEyeInvisible className="Biz_Login_SessionTimoutPopup_pass-icon" /> : <AiFillEye className="Biz_Login_SessionTimoutPopup_pass-icon" />}
                  </span>
                </div>
                {validationMessage ? (
                  <div className="Sessionlogin___ErrorDiv">
                    <div className="Sessionlogin___ErrorIcon">
                      <FaExclamationTriangle className="Biz_Login__Icon" />
                    </div>
                    <div className="Sessionlogin___ErrorTxt">{validationMessage}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="Biz_Login_SessionTimoutPopup_FormButtonBox">
                <button
                  type="button"
                  onClick={() => onSubmitNewBMC()}
                  className={btnloaderActive ? "Biz_Login_SessionTimoutPopup_FormButtonBoxLgt FormBtnActive" : "Biz_Login_SessionTimoutPopup_FormButtonBoxLgt"}
                >
                  <div className="login__BtnTxt"> Log In </div>
                  <div className="login__btn__spinner">
                    <div className="login__btn__bounce1"></div>
                    <div className="login__btn__bounce2"></div>
                    <div className="login__btn__bounce3"></div>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default LoginSession;
