import React, { useState, useEffect, useRef } from "react";
import { publicIpv4 } from "public-ip";
import { CreateCity, CreateCountry, UpdateCountry } from "../../Resources/GeographicDataService";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../Framework/OldFramework/Button/Button";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";

const AddCountry = (props) => {
  let toggleAddCountryPopup = props.toggleAddCountryPopup;
  let setMsgAlert = props.setMsgAlert;
  let updateCountryList = props.updateCountryList;
  let setConfirmAlert = props.setConfirmAlert;
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  let _updateListing = props._updateListing;
  const [btnloaderActive, setBtnloaderActive] = useState(false);

  const [formValues, setFormValues] = useState({
    txtCountryName: "",
    txtAlpha3Code: "",
    txtNumericCode: "",
    txtAllowState: 0,
    txtStateLabel: "",
    txtFirstLevelName: "",
    txtSecondLevelName: "",
    txtThirdLevelName: "",
    txtFourthLevelName: "",
  });

  const [formValidationError, setFormValidationError] = useState({});
  const [editCountry, setEditCountry] = useState();
  const firstTextInput = useRef();

  useEffect(() => {
    if (isEditMode) {
      if (popupData) {
        setEditCountry(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (editCountry) {
      formValues.txtCountryName = editCountry.CountryName;
      formValues.txtAlpha3Code = editCountry.Alpha3Code;
      formValues.txtNumericCode = editCountry.NumericCode;
      formValues.txtAllowState = editCountry.AllowState === 1 ? 1 : 0;
      formValues.txtStateLabel = editCountry.StateLabel;
      formValues.txtFirstLevelName = editCountry.FirstLevelName;
      formValues.txtSecondLevelName = editCountry.SecondLevelName;
      formValues.txtThirdLevelName = editCountry.ThirdLevelName;
      formValues.txtFourthLevelName = editCountry.FourthLevelName;
    }
    setFormValues({ ...formValues });
  }, [editCountry]);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtCountryName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Country Name";
      }
    }
    if (name === "txtAlpha3Code") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Alpha code";
      }
    }
    if (name === "txtFirstLevelName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter FirstLevel Name ";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtCountryName"] = validateField("txtCountryName", formValues.txtCountryName);
      errors["txtAlpha3Code"] = validateField("txtAlpha3Code", formValues.txtAlpha3Code);
      errors["txtFirstLevelName"] = validateField("txtFirstLevelName", formValues.txtFirstLevelName);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const onSubmit = async (e) => {
    try {
      setBtnloaderActive(true);
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      let formData = {};
      if (isEditMode) {
        formData = {
          countryMasterID: popupData && popupData.CountryMasterID ? popupData.CountryMasterID : 0,
          countryName: formValues.txtCountryName ? formValues.txtCountryName : "",
          alpha3Code: formValues.txtAlpha3Code ? formValues.txtAlpha3Code : "",
          numericCode: formValues.txtNumericCode ? formValues.txtNumericCode : "",
          allowState: formValues.txtAllowState ? 1 : 0,
          stateLabel: formValues.txtStateLabel ? formValues.txtStateLabel : "",
          firstLevelName: formValues.txtFirstLevelName ? formValues.txtFirstLevelName : "",
          secondLevelName: formValues.txtSecondLevelName ? formValues.txtSecondLevelName : "",
          thirdLevelName: formValues.txtThirdLevelName ? formValues.txtThirdLevelName : "",
          fourthLevelName: formValues.txtFourthLevelName ? formValues.txtFourthLevelName : "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          // CountryMasterID: isEditMode ? (popupData && popupData.CountryMasterID ? popupData.CountryMasterID : 0) : "",
          countryName: formValues.txtCountryName ? formValues.txtCountryName : "",
          alpha3Code: formValues.txtAlpha3Code ? formValues.txtAlpha3Code : "",
          numericCode: formValues.txtNumericCode ? formValues.txtNumericCode : "",
          allowState: formValues.txtAllowState ? 1 : 0,
          stateLabel: formValues.txtStateLabel ? formValues.txtStateLabel : "",
          firstLevelName: formValues.txtFirstLevelName ? formValues.txtFirstLevelName : "",
          secondLevelName: formValues.txtSecondLevelName ? formValues.txtSecondLevelName : "",
          thirdLevelName: formValues.txtThirdLevelName ? formValues.txtThirdLevelName : "",
          fourthLevelName: formValues.txtFourthLevelName ? formValues.txtFourthLevelName : "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }

      let fun = isEditMode ? UpdateCountry : CreateCountry;
      let result = await fun(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          if (result && result.responseData) {
            console.log(result.responseData);
            let addedCity = {
              CountryMasterID: isEditMode ? popupData.CountryMasterID : result.responseData && result.responseData.CountryMasterID ? result.responseData.CountryMasterID : 0,
              CountryName: formValues.txtCountryName ? formValues.txtCountryName : "",
              Alpha3Code: formValues.txtAlpha3Code ? formValues.txtAlpha3Code : "",
              NumericCode: formValues.txtNumericCode ? formValues.txtNumericCode : "",
              AllowState: formValues.txtAllowState ? 1 : 0,
              StateLabel: formValues.txtStateLabel ? formValues.txtStateLabel : "",
              FirstLevelName: formValues.txtFirstLevelName ? formValues.txtFirstLevelName : "",
              SecondLevelName: formValues.txtSecondLevelName ? formValues.txtSecondLevelName : "",
              ThirdLevelName: formValues.txtThirdLevelName ? formValues.txtThirdLevelName : "",
              FourthLevelName: formValues.txtFourthLevelName ? formValues.txtFourthLevelName : "",
              ActiveStatus: "YES",
              IsNewlyAdded: true,
            };
            console.log(addedCity, "addedCity");
            if (isEditMode) {
              _updateListing({ ...addedCity, IsNewlyAdded: false, IsUpdated: true });
            } else {
              updateCountryList(addedCity);
            }

            clearForm();
            toggleAddCountryPopup(false, null);
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to Update ?" : "Are you sure to Save ?",
        button: {
          confirmText: isEditMode ? "Update" : "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const clearForm = () => {
    setFormValues({
      txtCountryName: "",
      txtAlpha3Code: "",
      txtNumericCode: "",
      txtAllowState: 0,
      txtStateLabel: "",
      txtFirstLevelName: "",
      txtSecondLevelName: "",
      txtThirdLevelName: "",
      txtFourthLevelName: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal
      varient={"center"}
      title={isEditMode ? `Update ${popupData && popupData.CountryName ? popupData.CountryName : ""}` : "Add New Country"}
      onSubmit={handleSave}
      show={() => toggleAddCountryPopup(false, null)}
    >
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"550px"}>
            <Form.InputGroup errorMsg={formValidationError["txtCountryName"]} label="Country Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtCountryName"
                type="text"
                value={formValues.txtCountryName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Country Name"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtAlpha3Code"]} label="Alpha3 Code" req={true}>
              <Form.InputControl
                control="input"
                name="txtAlpha3Code"
                value={formValues.txtAlpha3Code}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Alpha3 code"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtNumericCode"]} label="Numeric Code" req={true}>
              <Form.InputControl
                control="input"
                name="txtNumericCode"
                value={formValues.txtNumericCode}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Numeric code"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtAllowState"]} label="Allow State" req={false}>
              <Form.InputControl
                control="switch"
                name="txtAllowState"
                value={formValues.txtAllowState}
                onChange={(e) => updateState(e.target.name, e.target.checked)}
                label="Alpha3 code"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtStateLabel"]} label="State Label" req={false}>
              <Form.InputControl
                control="input"
                name="txtStateLabel"
                value={formValues.txtStateLabel}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="State Label"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtFirstLevelName"]} label="First Level Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtFirstLevelName"
                value={formValues.txtFirstLevelName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="FirstLevelName"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtSecondLevelName"]} label="Second Level Name" req={false}>
              <Form.InputControl
                control="input"
                name="txtSecondLevelName"
                value={formValues.txtSecondLevelName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Second Level Name"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtThirdLevelName"]} label="Third Level Name" req={false}>
              <Form.InputControl
                control="input"
                name="txtThirdLevelName"
                value={formValues.txtThirdLevelName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Third Level Name"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtFourthLevelName"]} label="Fourth Level Name" req={false}>
              <Form.InputControl
                control="input"
                name="txtFourthLevelName"
                value={formValues.txtFourthLevelName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Fourth Level Name"
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          {isEditMode ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCountry;
