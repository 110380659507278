import { publicIpv4 } from "public-ip";
import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const GetPageMasterData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.PageMaster.GetPageMasterData);
  return response;
};

export const getCommonValue = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.CommonMaster.master);
  return response;
};

export const CreatePageMasterData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.PageMaster.CreatePageMasterData);
  return response;
};

export const UpdatePageMasterData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.PageMaster.UpdatePageMasterData);
  return response;
};

export const GetAssignedParameterData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.AssignedParameter.GetAssignedParameterData);
  return response;
};

export const AssignParameterData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.AssignedParameter.AssignParameterData);
  return response;
};

export const DeleteParameterData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.AssignedParameter.DeleteParameterData);
  return response;
};
