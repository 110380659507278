import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Customer_Registration.css";
import Select from "react-select";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Empty_data from "../../../Framework/Assets/images/empty_data.svg";
import { BsThreeDots } from "react-icons/bs";
import { FaExternalLinkAlt, FaSync, FaSearch, FaTimes } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { FormSelectStyle, PageTitleSelectStyle } from "../../../Framework/Components/Widgets/SelectStyle/SelectStyle";
import {
  SkeletonCustomerList,
  SkeletonCustomerDataV1,
  SkeletonCustomerDataV2,
  SkeletonCustomerTaxComData,
  SkeletonDtboxData,
} from "../../../Framework/Components/Widgets/Skeleton/skeleton";
import { getCustomerMasterSelect, createCustomer } from "../Resources/customerRegistrationService";
import { getCountry, getCommonValue, getCommonMasterData } from "../Resources/commonService";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import { checkAuthExist } from "../../../APIMethods/Auth/auth";
import { formatDate } from "../../../Configration/Utilities/dateFormat";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import AddCustomerPopup from "./View/AddCustomerPopup/AddCustomerPopup";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { getCompanyMasterSelect } from "../Resources/companyRegistrationService";

function Customer_Registration() {
  let navigate = useNavigate();
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [isLoadingCustomerList, setIsLoadingCustomerList] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [activeTabId, setActiveTabId] = useState("CM");

  const [searchVal, setSearchVal] = useState("");
  const [alphaFilter, setAlphaSelect] = useState([]);

  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerCommunication, setCustomerCommunication] = useState([]);
  const [customerTaxDetails, setCustomerTaxDetails] = useState([]);
  const [selectedCustomerMaster, setSelectedCustomerMaster] = useState("");
  const [isLoadingCustomerDetails, setIsLoadingCustomerDetails] = useState(false);

  const [customerViewResponseMessage, setCustomerViewResponseMessage] = useState("");

  const [addresses, setAddresses] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [bankAccount, setBankAccount] = useState([]);

  const [AddCustomerformPopupActive, setCustomerAddformPopupActive] = useState(false);
  const toggleAddCustomerformPopup = () => {
    setCustomerAddformPopupActive(!AddCustomerformPopupActive);
  };
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const getCustomerList = async () => {
    try {
      setIsLoadingCustomerList(true);
      let result = await getCustomerMasterSelect("0");
      console.log("getCustomerList", result);
      setIsLoadingCustomerList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          console.log(result.responseData, "result.responseData");
          setCustomerList(result.responseData);
          setFilteredCustomerList(result.responseData);
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [AddCustomerPopupActive, setAddCustomerPopupActive] = useState(false);
  const toggleCustomerAddPopup = () => {
    setAddCustomerPopupActive(!AddCustomerPopupActive);
  };

  const onClickCustomerMaster = async (data) => {
    if (selectedCustomerMaster && selectedCustomerMaster.CustomerMasterID === data.CustomerMasterID) {
      return;
    }
    if (activeTabId === "CM") {
      getCompanyList(data.CustomerMasterID);
    }
    setSelectedCustomerMaster(data);
    console.log(selectedCustomerMaster, "selectedCustomerMaster");
  };

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  const updateCustomerList = (customer) => {
    customerList.push(customer);
    setCustomerList([]);
    setCustomerList(customerList);
    setFilteredCustomerList([]);
    setFilteredCustomerList(customerList);
  };
  const updateSelectedCustomerList = (customer) => {
    setSelectedCustomerMaster("");
    setSelectedCustomerMaster(customer);
  };

  const onClickSearch = () => {
    getCustomerList();
  };

  const onSearchCustomer = (e) => {
    let searchValue = e.target.value;
    if (searchValue !== "") {
      const filtereddata = customerList.filter((data) => {
        return Object.values(data).join(" ").toLowerCase().includes(searchValue.toLowerCase());
      });
      setFilteredCustomerList(filtereddata);
    } else {
      setFilteredCustomerList(customerList);
    }
  };

  const [isLoadingCompanyList, setIsLoadingCompanyList] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const getCompanyList = async (customerId) => {
    try {
      setIsLoadingCompanyList(true);
      // "SELECT", "", customerId, 0;
      // let result = await getCommonMasterData("COMPANYLIST", customerId);
      let result = await getCompanyMasterSelect("COMPANYLIST", "#ALL", customerId, 0);
      setIsLoadingCompanyList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          setCompanyList(result.responseData);
        } else {
          setCompanyList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedCustomerMaster) {
      console.log(selectedCustomerMaster, activeTabId, "selectedCustomerMaster", "activeTabId");
      if (activeTabId == "CM") {
        getCompanyList(selectedCustomerMaster.CustomerMasterID);
      }
      if (activeTabId == "AD") {
      }
      if (activeTabId == "CP") {
      }
    }
  }, [activeTabId]);

  const CompanyColumnDef = [
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: "1" },
    { field: "CompanyName", headerName: "Company Name", width: 150 },
    { field: "AppVersion", headerName: "App Version", width: 120 },
    { field: "NoOfAdmin", headerName: "No Of Admin", width: 120 },
    { field: "NoOfUsers", headerName: "No Of Users", width: 120 },
    { field: "SessionTime", headerName: "Session Time", width: 120 },
    { field: "RenewSessionTime", headerName: "Renew Session Time", width: 160 },
    { field: "InsertDateTime", headerName: "Date & Time", width: 120 },
    { field: "Industry", headerName: "Industry", width: 150 },
    { field: "CountryName", headerName: "Country", width: 120 },
    { field: "InsertDateTime", headerName: "Date & Time", width: 180 },
  ];
  {
  }
  return (
    <div className="Biz_Bm__Customer Biz_Bm__PageStart">
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {AddCustomerPopupActive && (
        <AddCustomerPopup
          setMsgAlert={setMsgAlert}
          updateSelectedCustomerList={updateSelectedCustomerList}
          updateCustomerList={updateCustomerList}
          setConfirmAlert={setConfirmAlert}
          toggleCustomerAddPopup={toggleCustomerAddPopup}
        />
      )}
      <div className="Biz_Bm__CustomerDash">
        <div className="Biz_Bm__CustomerList_Div">
          <div className="Biz_Bm__CustomerList_SearchBox">
            <div className="Customer__FormSearchDiv">
              <input
                type="text"
                onChange={(e) => onSearchCustomer(e)}
                placeholder="Search..."
                className="Customer__FormSearchBox"
                onClick={() => getCustomerList()}
                autoComplete="false"
              />
              {alphaFilter.length > 0 ? (
                <React.Fragment>
                  <a className="Customer__FormSearchIcon">
                    <FaSearch className="Biz_Bm__Icon" />
                  </a>
                  <a className="Customer__CanceSearchlIcon">
                    <FaTimes className="Biz_Bm__Icon" />{" "}
                  </a>
                </React.Fragment>
              ) : (
                <a className="Customer__FormSearchAlIcon" onClick={() => onClickSearch()} style={{ position: "absolute" }}>
                  <FaSearch className="Biz_Bm__Icon" />
                </a>
              )}
            </div>
          </div>
          <div className="Biz_Bm__CustomerList">
            <div className="Biz_Bm__CustomerListDiv">
              {!isLoadingCustomerList ? (
                filteredCustomerList.length > 0 ? (
                  filteredCustomerList.map((data, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          selectedCustomerMaster && selectedCustomerMaster.CustomerMasterID === data.CustomerMasterID
                            ? "Biz_Bm__CustomerListBox Active__CustomerListBox"
                            : "Biz_Bm__CustomerListBox"
                        }
                        onClick={() => onClickCustomerMaster(data)}
                      >
                        <h2>{data.CustomerName ? `${data.CustomerName}` : ""}</h2>
                        <h2>{data.Industry ? `${data.Industry}` : ""}</h2>
                        <p>{`${data.LanguageName ? data.LanguageName : "N/A"} || ${data.CountryName ? data.CountryName : "N/A"} || ${data.DOR ? formatDate(data.DOR) : "N/A"}`}</p>
                      </div>
                    );
                  })
                ) : customerViewResponseMessage !== "" ? (
                  <div className="Biz_Bm_Customer_Empty_dataBox">
                    <img src={Empty_data} />
                    <p>{customerViewResponseMessage}</p>
                  </div>
                ) : (
                  <SkeletonCustomerList />
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
        <div className="Biz_Bm__CustomerDiv">
          <div className="Customer__Pagetitle">
            <span>Customer Details</span>
            <div className="Customer__Pagetitle__box">
              <button
                onClick={() => setAddCustomerPopupActive(!AddCustomerPopupActive)}
                className="Customer__Pagetitle_btn Customer__Pagetitle_btnSecd"
                biz-toltp="Add"
                biz-toltp-modifier="bottom"
              >
                <GoPlus />
                Add
              </button>
            </div>
          </div>
          <div className="Customer__DetailBox">
            <div className="Customer__DetailTxt">
              <div className="Customer__DContentBox">
                <label>Customer Name</label>
                <p>{selectedCustomerMaster && selectedCustomerMaster.CustomerName ? selectedCustomerMaster.CustomerName : <SkeletonCustomerDataV1 />}</p>
              </div>
              <div className="Customer__DContentBox">
                <label>Country</label>
                <p>{selectedCustomerMaster && selectedCustomerMaster.CountryName ? selectedCustomerMaster.CountryName : <SkeletonCustomerDataV1 />}</p>
              </div>
              <div className="Customer__DContentBox">
                <label>Industry Type</label>
                <p>{selectedCustomerMaster && selectedCustomerMaster.Industry ? selectedCustomerMaster.Industry : <SkeletonCustomerDataV2 />}</p>
              </div>
              <div className="Customer__DContentBox">
                <label>Currency</label>
                <p>{selectedCustomerMaster && selectedCustomerMaster.CurrencyName ? selectedCustomerMaster.CurrencyName : <SkeletonCustomerDataV2 />}</p>
              </div>
              <div className="Customer__DContentBox">
                <label>Language</label>
                <p>{selectedCustomerMaster && selectedCustomerMaster.LanguageName ? selectedCustomerMaster.LanguageName : <SkeletonCustomerDataV1 />}</p>
              </div>
              <div className="Customer__DContentBox">
                <label>Registration Date</label>
                <p>{selectedCustomerMaster && selectedCustomerMaster.DOR ? formatDate(selectedCustomerMaster.DOR) : <SkeletonCustomerDataV1 />}</p>
              </div>
            </div>
          </div>
          <div className="Customer__MultiDetailBox">
            <div className="Customer__MDB_BtnPanel">
              <ul className="Customer__MDb_TabPanel">
                <li onClick={() => setActiveTabId("CM")} className={activeTabId === "CM" ? "Customer__MDb_TabList Active__MDb_TabList" : "Customer__MDb_TabList "}>
                  Company
                </li>
                <li onClick={() => setActiveTabId("AD")} className={activeTabId === "AD" ? "Customer__MDb_TabList Active__MDb_TabList" : "Customer__MDb_TabList "}>
                  Address
                </li>
                <li onClick={() => setActiveTabId("CP")} className={activeTabId === "CP" ? "Customer__MDb_TabList Active__MDb_TabList" : "Customer__MDb_TabList "}>
                  Contacts
                </li>
              </ul>
            </div>
            <div className="Customer__MDB_ContentBox">
              <div className="Customer__MDB_ContentMDiv">
                <div className={activeTabId === "CM" ? "Customer__MDB_ContentDiv ActiveCustomer__MDB_ContentDiv" : "Customer__MDB_ContentDiv"}>
                  <DataGrid
                    rowData={companyList}
                    columnDefs={CompanyColumnDef}
                    onGridReady={onGridReady}
                    loader={isLoadingCompanyList ? <Loader /> : null}
                    getRowStyle={function (data) {
                      if (data.data.IsSelected) {
                        return { background: "#ffc176" };
                      } else {
                        if (data.data.IsNewlyAdded) {
                          return { background: "#d5a10e" };
                        }
                        return { background: "" };
                      }
                    }}
                  />
                </div>
                <div className={activeTabId === "AD" ? "Customer__MDB_ContentDiv ActiveCustomer__MDB_ContentDiv" : "Customer__MDB_ContentDiv"}>
                  <SkeletonDtboxData />2
                </div>
                <div className={activeTabId === "CP" ? "Customer__MDB_ContentDiv ActiveCustomer__MDB_ContentDiv" : "Customer__MDB_ContentDiv "}>
                  <SkeletonDtboxData />3
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Customer_Registration;
