import React, { useState } from "react";
import classNames from "classnames";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import BizClass from "./APICallingSummary.module.scss";
import moment from "moment";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import ApiCalling from "../../../APIMethods/Utilities/ApiCalling/ApiCalling";
import ModuleWiseApiCallingSummary from "./modals/ModuleWiseApiCallSummary";

const ApiCallingSummary = () => {
  const [rowData, setRowData] = useState([]);
  const [isLoadingRowData, setIsLoadingRowData] = useState(false);

  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [moduleSummaryModal, setModuleSummaryModal] = useState(false);

  const [requestParams, setRequestParams] = useState({});

  const defaultFromDate = moment().subtract(8, "days").format("YYYY-MM-DD");
  const defaultToDate = moment().subtract(1, "days").format("YYYY-MM-DD");

  const cellStyleRules = (params) => {
    const footer = params && params.node && params.node.footer;
    if (footer) {
      return { backgroundColor: "#f9e79f" };
    }
    return null;
  };

  const defaultColumnDefs = [
    {
      field: "RecordID",
      headerName: "Sr No.",
      width: 80,
      pinned: "left",
      cellStyle: cellStyleRules,
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 320,
      pinned: "left",
      cellRenderer: (props) => {
        const value = props && props.value ? props.value : "";
        const footer = props && props.node && props.node.footer;
        if (footer) {
          return <b>Total</b>;
        } else {
          return value;
        }
      },
      cellStyle: cellStyleRules,
    },
  ];

  const [columnDefs, setColumnDefs] = useState([]);

  const defaultInnputValues = {
    txtFromDate: defaultFromDate,
    txtToDate: defaultToDate,
  };
  const [inputValues, setInputValues] = useState(defaultInnputValues);

  const updateInputValue = (name, value) => {
    setInputValues({ ...inputValues, [name]: value });
  };

  const validateInputValues = async () => {
    if (!inputValues.txtFromDate) {
      setMsgAlert({ open: true, type: "error", msg: "From Date Required" });
      return false;
    } else if (!inputValues.txtToDate) {
      setMsgAlert({ open: true, type: "error", msg: "To Date Required" });
      return false;
    } else if (inputValues.txtToDate < inputValues.txtFromDate) {
      setMsgAlert({ open: true, type: "error", msg: "To Date Should Be Greater Than Or Equal To From Date!" });
      return false;
    } else {
      return true;
    }
  };

  const getApiCallingSummaryData = async () => {
    const validated = await validateInputValues();
    if (!validated) {
      await getGridColumns([]);
      setRowData([]);
      return;
    }

    setIsLoadingRowData(true);

    const fromDateValue = inputValues && inputValues.txtFromDate ? moment(inputValues.txtFromDate).format("YYYY-MM-DD") : "";
    const toDateValue = inputValues && inputValues.txtToDate ? moment(inputValues.txtToDate).format("YYYY-MM-DD") : "";
    const payload = {
      fromDate: fromDateValue,
      toDate: toDateValue,
    };

    const result = await ApiCalling(payload, "report/company/api-calling-summary");
    if (result && result.responseCode === 1) {
      if (result.responseData && result.responseData.length > 0) {
        await getGridColumns(result.responseData[0]);
        setRowData(result.responseData);
      } else {
        await getGridColumns([]);
        setRowData([]);
      }
    } else {
      await getGridColumns([]);
      setRowData([]);
      setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
    }

    setIsLoadingRowData(false);
  };

  const getModuleSummaryData = (params) => {
    if (params && params.value > 0) {
      const txtCompanyName = params.data && params.data.CompanyName ? params.data.CompanyName : "";
      const txtCompanyMasterID = params.data && params.data.CompanyMasterID ? params.data.CompanyMasterID : 0;
      const txtDate = params.colDef && params.colDef.field ? params.colDef.field : "";

      if (txtCompanyMasterID && txtDate) {
        setRequestParams({
          txtCompanyName: txtCompanyName,
          txtCompanyMasterID: txtCompanyMasterID,
          txtDate: txtDate,
        });

        setModuleSummaryModal(true);
      }
    }
  };

  const getGridColumns = async (data) => {
    const columnData = data;
    const newColumnDefs = [...defaultColumnDefs];

    const cellMStyleRules = (params) => {
      const footer = params && params.node && params.node.footer;
      if (params.value > 0 && !footer) {
        return { textDecoration: "underline" };
      } else if (footer) {
        return { backgroundColor: "#f9e79f" };
      }
      return null;
    };

    if (columnData && Object.values(columnData) && Object.values(columnData).length > 0) {
      for (const a in columnData) {
        if (a !== "RecordID" && a !== "CompanyMasterID" && a !== "CompanyName") {
          const row = {
            field: a,
            headerName: moment(a).format("DD-MMM"),
            width: 100,
            aggFunc: "sum",
            cellStyle: cellMStyleRules,
            onCellClicked: getModuleSummaryData,
          };
          newColumnDefs.push(row);
        }
      }
      setColumnDefs(newColumnDefs);
    } else {
      setColumnDefs([]);
    }
  };

  return (
    <>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {moduleSummaryModal ? <ModuleWiseApiCallingSummary requestParams={requestParams} setMsgAlert={setMsgAlert} setModuleSummaryModal={setModuleSummaryModal} /> : null}
      <div className={classNames(BizClass.Biz_Bm_Login_Summary_page, BizClass.Biz_Bm__PageStart)}>
        <PageBar title="Api Calling Summary">
          <PageBar.Input type="date" style={{ width: 140 }} name="txtFromDate" value={inputValues.txtFromDate} onChange={(e) => updateInputValue(e.target.name, e.target.value)} />
          <PageBar.Input type="date" style={{ width: 140 }} name="txtToDate" value={inputValues.txtToDate} onChange={(e) => updateInputValue(e.target.name, e.target.value)} />
          <PageBar.Search onClick={getApiCallingSummaryData} />
        </PageBar>
        <DataGrid
          columnDefs={columnDefs}
          rowData={rowData}
          groupIncludeTotalFooter={true}
          groupTotalRow={"bottom"}
          grandTotalRow={"bottom"}
          loader={isLoadingRowData ? <Loader /> : null}
        />
      </div>
      <Footer />
    </>
  );
};

export default ApiCallingSummary;
