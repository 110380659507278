import React, { useState, useEffect, useRef } from "react";
import { CustomMsg } from "../../../../Configration/Utilities/customMessage";
import Button from "../../../../Framework/Components/Widgets/Button/Button";
import Modal from "../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CreateDivision } from "../../Department/Resources/DeptService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";

const AddDivision = (props) => {
  let toggleAddDivision = props.toggleAddDivision;
  let setMsgAlert = props.setMsgAlert;
  let updateDivisionList = props.updateDivisionList;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtDivisionName: "",
    txtShortDivCode: "",
  });

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtDivisionName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter DivisionName";
      }
    }
    if (name === "txtShortDivCode") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Division Code";
      }
    }

    return errorsMsg;
  };

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtDivisionName"] = validateField("txtDivisionName", formValues.txtDivisionName);
      errors["txtShortDivCode"] = validateField("txtShortDivCode", formValues.txtShortDivCode);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      let formData = {
        divisionName: formValues.txtDivisionName,
        shortDivCode: formValues.txtShortDivCode,
        activeStatus: "Y",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await CreateDivision(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          if (result && result.responseData) {
            let addedDivision = {
              ActiveStatus: formValues.activeStatus,
              DivisionID: result.responseData.DivisionID,
              DivisionName: formValues.txtDivisionName,
              ShortDivCode: formValues.txtShortDivCode,
              IsNewlyAdded: true,
            };
            updateDivisionList(addedDivision);
            clearForm();
            toggleAddDivision();
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtDivisionName: "",
      txtShortDivCode: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title="Add New Master" onSubmit={onSubmit} show={() => toggleAddDivision()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtDivisionName"]} label="Division Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtDivisionName"
                type="text"
                value={formValues.txtDivisionName}
                onChange={(e) => {
                  formValidationError["txtDivisionName"] = validateField("txtDivisionName", e);
                  updateState(e.target.name, e.target.value);
                }}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtShortDivCode"]} label="Short Division Code" req={true}>
              <Form.InputControl
                control="input"
                name="txtShortDivCode"
                type="text"
                value={formValues.txtShortDivCode}
                onChange={(e) => {
                  formValidationError["txtShortDivCode"] = validateField("txtShortDivCode", e);
                  updateState(e.target.name, e.target.value);
                }}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDivision;
