import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const getCountry = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.GetCountry);
  return response;
};

export const getStates = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.GetStates);
  return response;
};

export const getCities = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.GetCities);
  return response;
};

export const CreateCity = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.CreateCity);
  return response;
};

export const UpdateCity = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.UpdateCity);
  return response;
};

export const CreateState = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.CreateState);
  return response;
};

export const UpdateState = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.UpdateState);
  return response;
};

export const CreateCountry = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.CreateCountry);
  return response;
};

export const UpdateCountry = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.UpdateCountry);
  return response;
};

export const UpdateCountryStatusData = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.UpdateCountryStatus);
  return response;
};

export const UpdateStateStatusData = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.UpdateStateStatusData);
  return response;
};

export const UpdateCityStatusData = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.GeographicalData.UpdateCityStatusData);
  return response;
};
