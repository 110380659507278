/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
import React, { useState, useEffect, useRef } from "react";
import "../../../Company_Registration/Company_Registration.css";
import Select from "react-select";
import { FormSelectStyle } from "../../../../../Framework/Components/Widgets/SelectStyle/SelectStyle";
import { createCompany, getCompanyMasterSelect, updateCompany } from "../../../Resources/companyRegistrationService";
import { getCommonValue, getCommonMasterData } from "../../../Resources/commonService";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import { formatDate } from "../../../../../Configration/Utilities/dateFormat";
import { getCustomerMasterSelect } from "../../../Resources/customerRegistrationService";
import { FaTimes } from "react-icons/fa";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";

const AddCompanyPopup = (props) => {
  let getCompanyList = props.getCompanyList;
  let isEditMode = props.isEditMode;
  let selectedCompanyMaster = props.selectedCompanyMaster;
  let popupData = props.popupData;
  let toggleAddCompanyPopup = props.toggleAddCompanyPopup;
  let setMsgAlert = props.setMsgAlert;
  let updateCompanyList = props.updateCompanyList;
  let updateSelectedCompanyList = props.updateSelectedCompanyList;
  const [customerList, setCustomerList] = useState([]);
  const [weightUOMList, setWeightUOMList] = useState([]);
  const [industryTypeList, setIndustryTypeList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [dateFormatList, setDateFormatList] = useState([]);
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [maxDateOfReg, setMaxDateOfReg] = useState("");
  const [formValues, setFormValues] = useState({
    txtCustomer: null,
    txtCompanyName: "",
    txtCompanyCode: "",
    txtNoOfAdmin: "",
    txtNoOfUser: "",
    txtAppVersion: "",
    txtImplementationDate: "",
    txtLanguage: "",
    txtIndustryType: null,
    isActive: "YES",

    txtDateShort: null,
    txtDateLong: null,
    txtInventoryDecimalPoints: null,
    txtInventoryRoundOff: "0",
    txtQuantityType: null,
    txtInventoryAllocationCriteria: "",
    txtSessionTime: 0,
    txtRenewSessionTime: 0,
    isIPRestriction: false,
    isGRNInspectionCheck: false,
    isPRInspectionCheck: false,
    isAllowGRNWithoutOutward: false,
    txtMoveAllocationTonavigate: null,
    txtWeightUOM: null,
    txtMobileAppHost: "",
    txtMobileAppURL: "",
    txtSaleBudgetBy: null,
    txtWelcomeImage: "",
    txtCompanyLogo: "",
    txtCurrencyMasterID: null,
    txtCountry: null,
    isActiveSupplierPortal: false,
    isActiveCustomerPortal: false,
  });

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const [isModalAnimOpen, setModalAnimOpen] = useState(false);
  const toggleModalAnimOpen = () => {
    setModalAnimOpen(true);
    setTimeout(() => {
      setModalAnimOpen(false);
    }, 100);
  };

  const [editCompanyMaster, setEditCompanyMaster] = useState();
  const getCompanyMaster = async () => {
    try {
      let result = await getCompanyMasterSelect("COMPANYLIST", "", 0, selectedCompanyMaster.CompanyMasterID);
      console.log(result, "result");
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setEditCompanyMaster(result.responseData[0]);
        } else {
          setEditCompanyMaster(null);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const getCustomerList = async () => {
    try {
      setIsLoadingCustomer(true);
      let result = await getCustomerMasterSelect("0");
      console.log("getCustomerList", result);
      setIsLoadingCustomer(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          console.log(result.responseData, "result.responseData");
          setCustomerList(result.responseData);
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingLanguage, setIsLoadingLanguage] = useState(false);
  const getLanguageList = async () => {
    try {
      setIsLoadingIndustryType(true);
      let result = await getCommonValue(104);
      setIsLoadingIndustryType(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setLanguageList(result.responseData);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingIndustryType, setIsLoadingIndustryType] = useState(false);
  const getIndustryTypeList = async () => {
    try {
      setIsLoadingIndustryType(true);
      let result = await getCommonValue(103);
      setIsLoadingIndustryType(false);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          setIndustryTypeList(result.responseData);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const getCountryList = async () => {
    try {
      setIsLoadingCountry(true);
      let result = await getCommonMasterData("GETCOUNTRYLIST");
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCountryList(result.responseData);
          setIsLoadingCountry(false);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingCurrency, setIsLoadingCurrency] = useState(false);
  const getCurrencyList = async () => {
    try {
      setIsLoadingCurrency(true);
      let result = await getCommonValue(123);
      console.log("CurrencyList", result);
      setIsLoadingCurrency(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCurrencyList(result.responseData);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingUOM, setIsLoadingUOM] = useState(false);
  const getUOMList = async () => {
    try {
      setIsLoadingUOM(true);
      let result = await getCommonValue(111);
      setIsLoadingUOM(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setWeightUOMList(result.responseData);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingDateFormat, setIsLoadingDateFormat] = useState(false);

  const quantityTypeOptions = [
    { Name: "UOM", Value: "UOM" },
    { Name: "PU", Value: "PU" },
    { Name: "BOTH", Value: "BOTH" },
  ];

  const saleBudgetByOptions = [
    { Name: "Top To Bottom", Value: "TTB" },
    { Name: "Bottom To Top", Value: "BTT" },
  ];
  const moveAllocationByOptions = [
    { Name: "CHALLAN", Value: "CHALLAN" },
    { Name: "INVOICE", Value: "INVOICE" },
  ];

  const decimalPointsOptions = [
    { Name: "1", Value: "1" },
    { Name: "2", Value: "2" },
    { Name: "3", Value: "3" },
    { Name: "4", Value: "4" },
    { Name: "5", Value: "5" },
  ];

  const textNoOfAdminInput = useRef();

  const [isLoadingEditData, setIsLoadingEditData] = useState(false);
  useEffect(() => {
    if (!isEditMode) {
      getCustomerList();
      getIndustryTypeList();
      getCurrencyList();
      getCountryList();
      getLanguageList();
      getUOMList();
    } else {
      setIsLoadingEditData(true);
      getCustomerList();
      getIndustryTypeList();
      getCurrencyList();
      getLanguageList();
      getCountryList();
      getUOMList();
      if (selectedCompanyMaster) {
        getCompanyMaster();
      }
    }
    setIsLoadingEditData(false);
  }, []);

  useEffect(() => {
    if (selectedCompanyMaster) {
      getCompanyMaster();
    }
  }, []);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  useEffect(() => {
    if (!isEditMode) {
      setFormValues({
        txtCustomer: null,
        txtCompanyName: "",
        txtCompanyCode: "",
        txtNoOfAdmin: "",
        txtNoOfUser: "",
        txtAppVersion: "",
        txtImplementationDate: "",
        txtLanguage: "",
        txtIndustryType: null,
        isActive: "YES",
        isIPRestriction: false,
        txtDateShort: null,
        txtDateLong: null,
        txtInventoryDecimalPoints: null,
        txtInventoryRoundOff: "0",
        txtQuantityType: null,
        txtInventoryAllocationCriteria: "",
        txtSessionTime: 0,
        txtRenewSessionTime: 0,

        isGRNInspectionCheck: false,
        isPRInspectionCheck: false,
        isAllowGRNWithoutOutward: false,
        txtMoveAllocationTonavigate: null,
        txtWeightUOM: null,
        txtMobileAppHost: "",
        txtMobileAppURL: "",
        txtSaleBudgetBy: null,
        txtWelcomeImage: "",
        txtCompanyLogo: "",
        txtCurrencyMasterID: null,
        txtCountry: null,
        isActiveSupplierPortal: false,
        isActiveCustomerPortal: false,
      });
    } else if (editCompanyMaster && editCompanyMaster.CompanyMasterID) {
      console.log("languageList", languageList);
      console.log("editCompanyMaster", editCompanyMaster);
      setFormValues({
        txtCustomer: editCompanyMaster.CustomerMasterID && customerList.length > 0 ? customerList.find((x) => x.CustomerMasterID === editCompanyMaster.CustomerMasterID) : null,
        txtCompanyName: editCompanyMaster.CompanyName,
        txtCompanyCode: editCompanyMaster.CompanyCode,
        txtNoOfAdmin: editCompanyMaster.NoOfAdmin,
        txtNoOfUser: editCompanyMaster.NoOfUsers,
        txtAppVersion: editCompanyMaster.AppVersion,
        txtImplementationDate: editCompanyMaster.ImplementationDate ? formatDate(editCompanyMaster.ImplementationDate) : "",
        txtLanguage: editCompanyMaster.LanguageID && languageList.length > 0 ? languageList.find((x) => x.CommonMasterValueID === editCompanyMaster.LanguageID) : null,
        txtCurrencyMasterID:
          editCompanyMaster.CurrencyMasterID && currencyList.length > 0 ? currencyList.find((x) => x.CommonMasterValueID === editCompanyMaster.CurrencyMasterID) : null,
        txtIndustryType:
          editCompanyMaster.IndustryType && industryTypeList.length > 0 ? industryTypeList.find((x) => x.CommonMasterValueID === editCompanyMaster.IndustryType) : null,
        // isIPRestriction: editCompanyMaster.IPRestriction === 1 ? true : false,
        txtSessionTime: editCompanyMaster.SessionTime ? editCompanyMaster.SessionTime : 0,
        txtRenewSessionTime: editCompanyMaster.RenewSessionTime ? editCompanyMaster.RenewSessionTime : 0,
        isIPRestriction: editCompanyMaster.AutoRenewSession && editCompanyMaster.AutoRenewSession.toLowerCase() === "yes" ? true : false,
        txtCountry: editCompanyMaster.CountryMasterID && countryList.length > 0 ? countryList.find((x) => x.CountryMasterID === editCompanyMaster.CountryMasterID) : null,
      });
    }
  }, [editCompanyMaster]);

  const validateField = (name, value) => {
    let errorsMsg = "";
    let regex = new RegExp("^[0-9\b]+$");
    if (
      name === "txtCustomer" ||
      name === "txtAppVersion" ||
      name === "txtCompanyName" ||
      name === "txtCompanyCode" ||
      name === "txtIndustryType" ||
      name === "txtImplementationDate" ||
      name === "txtCountry" ||
      name === "txtLanguage" ||
      name === "txtInventoryDecimalPoints" ||
      name === "txtQuantityType" ||
      name === "txtWeightUOM" ||
      name === "txtSaleBudgetBy" ||
      name === "txtMoveAllocationTonavigate"
    ) {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtRenewSessionTime") {
      if (formValues.isIPRestriction === true) {
        if (!value || value === null || typeof value === "undefined") {
          errorsMsg = "Cannot be empty";
        }
      }
      if (value && typeof value !== "undefined" && !regex.test(value)) {
        errorsMsg = "Enter number only";
      }
    } else if (name === "txtInventoryRoundOff") {
      if (value && typeof value !== "undefined" && !regex.test(value)) {
        errorsMsg = "Enter number only";
      }
    } else if (name === "txtInventoryAllocationCriteria" || name === "txtSessionTime") {
      if (!value || value === null || typeof value === "undefined" || value.toString() === "0") {
        errorsMsg = "Cannot be empty";
      } else if (value && typeof value !== "undefined" && !regex.test(value)) {
        errorsMsg = "Enter number only";
      }
    } else if (name === "txtNoOfAdmin") {
      if (!value || value === null || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      } else if (value && typeof value !== "undefined" && !regex.test(value)) {
        errorsMsg = "Enter number only";
      }
    } else if (name === "txtNoOfUser") {
      if (!value || value === null || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      } else if (value && typeof value !== "undefined" && !regex.test(value)) {
        errorsMsg = "Enter number only";
      }
    }
    return errorsMsg;
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    errors["txtCustomer"] = validateField("txtCustomer", formValues.txtCustomer);
    errors["txtCompanyName"] = validateField("txtCompanyName", formValues.txtCompanyName);

    errors["txtIndustryType"] = validateField("txtIndustryType", formValues.txtIndustryType);
    errors["txtImplementationDate"] = validateField("txtImplementationDate", formValues.txtImplementationDate);
    errors["txtLanguage"] = validateField("txtLanguage", formValues.txtLanguage);
    errors["txtCountry"] = validateField("txtCountry", formValues.txtCountry);
    errors["txtNoOfAdmin"] = validateField("txtNoOfAdmin", formValues.txtNoOfAdmin);
    errors["txtNoOfUser"] = validateField("txtNoOfUser", formValues.txtNoOfUser);
    errors["txtAppVersion"] = validateField("txtAppVersion", formValues.txtAppVersion);
    if (Object.values(errors).join("").toString()) {
      formIsValid = false;
    }
    setFormValidationError(errors);
    console.log("errors", errors);
    return formIsValid;
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async (e) => {
    debugger;
    try {
      const ip = await publicIpv4();
      setBtnloaderActive(true);
      console.log(formValues, "Value");
      let user = getSessionStorage("user");
      let updateFormData = {
        companyMasterId: isEditMode ? editCompanyMaster.CompanyMasterID : 0,
        customerMasterId: isEditMode
          ? editCompanyMaster.CustomerMasterID.toString()
          : formValues.txtCustomer && formValues.txtCustomer.CustomerMasterID
          ? formValues.txtCustomer.CustomerMasterID.toString()
          : "",
        companyName: formValues.txtCompanyName,
        noOfAdmin: formValues.txtNoOfAdmin,
        noOfUsers: formValues.txtNoOfUser,
        implementationDate: formValues.txtImplementationDate,
        appVersion: formValues.txtAppVersion,
        languageId: formValues.txtLanguage && formValues.txtLanguage.CommonMasterValueID ? formValues.txtLanguage.CommonMasterValueID : 0,
        industryType: isEditMode
          ? editCompanyMaster.IndustryType
            ? editCompanyMaster.IndustryType.toString()
            : ""
          : formValues.txtIndustryType && formValues.txtIndustryType.CommonMasterValueID
          ? formValues.txtIndustryType.CommonMasterValueID.toString()
          : "",
        ipRestriction: formValues.isIPRestriction ? "1" : "0",
        sessionTime: formValues.txtSessionTime ? formValues.txtSessionTime : 0,
        renewSessionTime: formValues.txtRenewSessionTime ? formValues.txtRenewSessionTime : 0,
        autoRenewSession: formValues.isIPRestriction ? "YES" : "NO",
        countryMasterId: formValues.txtCountry && formValues.txtCountry.CountryMasterID ? formValues.txtCountry.CountryMasterID : 0,
        currencyMasterId: formValues.txtCurrencyMasterID && formValues.txtCurrencyMasterID.CommonMasterValueID ? formValues.txtCurrencyMasterID.CommonMasterValueID : 0,
        activeStatus: isEditMode ? editCompanyMaster.ActiveStatus : 0,
        objCommon: {
          insertUserId: user && user.User && user.User.user && user.User.user.AppAccessID ? user.User.user.AppAccessID : 0,
          insertIpAddress: ip,
        },
      };
      let formData = {
        customerMasterId: formValues.txtCustomer && formValues.txtCustomer.CustomerMasterID ? formValues.txtCustomer.CustomerMasterID : 0,
        companyName: formValues.txtCompanyName ? formValues.txtCompanyName : "",
        countryMasterId: formValues.txtCountry && formValues.txtCountry.CountryMasterID ? formValues.txtCountry.CountryMasterID : 0,
        languageId: formValues.txtLanguage && formValues.txtLanguage.CommonMasterValueID ? formValues.txtLanguage.CommonMasterValueID : "",
        currencyMasterId: formValues.txtCurrencyMasterID && formValues.txtCurrencyMasterID.CommonMasterValueID ? formValues.txtCurrencyMasterID.CommonMasterValueID : "",
        sessionTime: formValues.txtSessionTime ? formValues.txtSessionTime : 0,
        autoRenewSession: formValues.isIPRestriction ? "YES" : "NO",
        renewSessionTime: formValues.txtRenewSessionTime ? formValues.txtRenewSessionTime : 0,
        appVersion: formValues.txtAppVersion,
        noOfAdmin: formValues.txtNoOfAdmin,
        noOfUsers: formValues.txtNoOfUser,
        implementationDate: formValues.txtImplementationDate,
        industryType: formValues.txtIndustryType && formValues.txtIndustryType.CommonMasterValueID ? formValues.txtIndustryType.CommonMasterValueID.toString() : "",
        ipRestriction: formValues.isIPRestriction ? "1" : "0",
        activeStatus: 0,
        objCommon: {
          insertUserId: user && user.User && user.User.user && user.User.user.AppAccessID ? user.User.user.AppAccessID : 0,
          insertIpAddress: ip,
        },
      };
      let fun = isEditMode ? updateCompany : createCompany;
      let result = await fun(isEditMode ? updateFormData : formData);
      setBtnloaderActive(false);
      console.log("result", result);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          let addedCompany = {
            CompanyCode: formValues.txtCompanyCode,
            CompanyMasterID: result.responseData[0].CompanyMasterID ? result.responseData[0].CompanyMasterID : null,
            CompanyName: formValues.txtCompanyName,
            CountryMasterID: formValues.txtCountry && formValues.txtCountry.CountryCode ? formValues.txtCountry.CountryCode : null,
            CountryName: formValues.txtCountry ? formValues.txtCountry.CountryName : null,
            ImplementationDate: formValues.txtImplementationDate,
            Languages: formValues.txtLanguage.CommonMasterValue,
            LanguageID: formValues.txtLanguage.CommonMasterValueID,
            CurrencyID: formValues.txtCurrencyMasterID.CommonMasterValueID,
            AppVersion: formValues.txtAppVersion,
            Currency: formValues.txtCurrencyMasterID.CommonMasterValue,
            Industry: formValues.txtIndustryType ? formValues.txtIndustryType.CommonMasterValue : "",
            IndustryType: formValues.txtIndustryType && formValues.txtIndustryType.CommonMasterValueID ? formValues.txtIndustryType.CommonMasterValueID : null,
            IsNewlyAdded: true,
          };
          console.log(addedCompany, "addedCompany");

          updateCompanyList(addedCompany, isEditMode);
          getCompanyList();
          updateSelectedCompanyList(addedCompany);
        }
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        if (!isEditMode) {
          clearForm();
        } else {
          toggleAddCompanyPopup(false, null);
        }
      } else {
        setMsgAlert({
          open: true,
          type: "error",
          msg: result.responseMessage,
        });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtCustomer: null,
      txtCompanyName: "",
      txtCompanyCode: "",
      txtNoOfAdmin: "",
      txtNoOfUser: "",
      txtAppVersion: "",
      txtImplementationDate: "",
      txtLanguage: "",
      txtIndustryType: null,
      isActive: "YES",

      txtDateShort: null,
      txtDateLong: null,
      txtInventoryDecimalPoints: null,
      txtInventoryRoundOff: 0,
      txtQuantityType: null,
      txtInventoryAllocationCriteria: "",
      txtSessionTime: 0,
      txtRenewSessionTime: 0,
      txtMoveAllocationTonavigate: null,
      isIPRestriction: false,
      isGRNInspectionCheck: false,
      isPRInspectionCheck: false,
      isAllowGRNWithoutOutward: false,
      txtWeightUOM: null,
      txtMobileAppHost: "",
      txtMobileAppURL: "",
      txtSaleBudgetBy: null,
      txtWelcomeImage: "",
      txtCompanyLogo: "",
      txtCurrencyMasterID: null,
      txtCountry: null,
      isActiveSupplierPortal: false,
      isActiveCustomerPortal: false,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  const [AddFormActiveTabId, setAddFormActiveTabId] = useState("ST");

  return (
    <React.Fragment>
      <div onClick={toggleModalAnimOpen} className="Biz_Bm__Company_AddPopup_overlay"></div>
      <div className={isModalAnimOpen ? "Biz_Bm__Company_AddPopup_Box Biz_Bm__Company_AddPopup_overlay_PopupAnim" : "Biz_Bm__Company_AddPopup_Box"}>
        <header className="Biz_Bm__Company_AddPopup_Header">
          <h2>Add New Company</h2>
          <a onClick={() => toggleAddCompanyPopup(false, null)} className="Biz_Bm__Company_AddPopup_HeaderCloseBtn">
            <FaTimes className="Biz_Bm_Icon" />
          </a>
        </header>
        <form method="post" onSubmit={(e) => handleSave(e)} className="Biz_Bm__Company_AddPopup_Content">
          {isLoadingEditData ? <Loader /> : null}
          <div className="Biz_Bm__Company_AddPopup_FormContent">
            {/* <div className="Biz_Bm__Company_AddPopup_FormGroup"> */}
            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Customer</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <Select
                name="txtCustomer"
                ref={firstTextInput}
                isDisabled={isEditMode}
                menuPlacement="auto"
                isClearable={true}
                getOptionLabel={(option) => `${option.CustomerName}`}
                value={formValues.txtCustomer}
                getOptionValue={(option) => `${option}`}
                options={customerList}
                onChange={(e) => updateState("txtCustomer", e)}
                isSearchable={true}
                isLoading={isLoadingCustomer}
                styles={FormSelectStyle}
                placeholder="Select Customer"
                noOptionsMessage={() => "No Result Found"}
              ></Select>
              <span className="validation_error">{formValidationError["txtCustomer"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Company Name</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="text"
                // disabled={isEditMode}
                name="txtCompanyName"
                value={formValues.txtCompanyName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Company Name"
              />
              <span className="validation_error">{formValidationError["txtCompanyName"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Industry Type</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <Select
                name="txtIndustryType"
                isDisabled={isEditMode}
                menuPlacement="auto"
                isClearable={true}
                getOptionLabel={(option) => `${option.CommonMasterValue}`}
                value={formValues.txtIndustryType}
                getOptionValue={(option) => `${option}`}
                options={industryTypeList}
                onChange={(e) => updateState("txtIndustryType", e)}
                isSearchable={true}
                styles={FormSelectStyle}
                isLoading={isLoadingIndustryType}
                placeholder="Select Industry Type"
                noOptionsMessage={() => "No Result Found"}
              ></Select>
              <span className="validation_error">{formValidationError["txtIndustryType"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt ">Country</label>
            <div className="Biz_Bm__Customer_AddPopup_InputGroup">
              <Select
                name="txtCountry"
                menuPlacement="auto"
                isDisabled={isEditMode}
                isClearable={true}
                getOptionLabel={(option) => `${option.CountryName}`}
                value={formValues.txtCountry}
                getOptionValue={(option) => `${option}`}
                options={countryList}
                onChange={(e) => updateState("txtCountry", e)}
                isSearchable={true}
                styles={FormSelectStyle}
                placeholder="Select Country"
                isLoading={isLoadingCountry}
                noOptionsMessage={() => "No Result Found"}
              ></Select>

              <span className="validation_error">{formValidationError["txtCountry"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt ">Currency</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <Select
                name="txtCurrencyMasterID"
                isDisabled={isEditMode}
                menuPlacement="auto"
                isClearable={true}
                getOptionLabel={(option) => `${option.CommonMasterValue}`}
                value={formValues.txtCurrencyMasterID}
                getOptionValue={(option) => `${option}`}
                options={currencyList}
                onChange={(e) => updateState("txtCurrencyMasterID", e)}
                isSearchable={true}
                styles={FormSelectStyle}
                isLoading={isLoadingCurrency}
                placeholder="Select Currency"
                noOptionsMessage={() => "No Result Found"}
              ></Select>
              <span className="validation_error">{formValidationError["txtCurrencyMasterID"]}</span>
            </div>

            <label className="Biz_Bm__Customer_AddPopup_LabelTxt Biz_Bm__Customer_AddPopup_Req">App Version</label>

            <div className="Biz_Bm__Customer_AddPopup_InputGroup">
              <input
                name="txtAppVersion"
                value={formValues.txtAppVersion}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Customer_AddPopup_Inputbox"
                placeholder="App Version"
              />

              <span className="validation_error">{formValidationError["txtAppVersion"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Number Of Admin</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="text"
                ref={textNoOfAdminInput}
                name="txtNoOfAdmin"
                value={formValues.txtNoOfAdmin}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Number Of Admin"
              />
              <span className="validation_error">{formValidationError["txtNoOfAdmin"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Number Of Users</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="text"
                name="txtNoOfUser"
                value={formValues.txtNoOfUser}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Number Of Users"
              />
              <span className="validation_error">{formValidationError["txtNoOfUser"]}</span>
            </div>
            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Language</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <Select
                name="txtLanguage"
                isDisabled={isEditMode}
                menuPlacement="auto"
                isClearable={true}
                getOptionLabel={(option) => `${option.CommonMasterValue}`}
                value={formValues.txtLanguage}
                getOptionValue={(option) => `${option}`}
                options={languageList}
                onChange={(e) => updateState("txtLanguage", e)}
                isSearchable={true}
                styles={FormSelectStyle}
                isLoading={isLoadingLanguage}
                placeholder="Select Language"
                noOptionsMessage={() => "No Result Found"}
              ></Select>

              <span className="validation_error">{formValidationError["txtLanguage"]}</span>
            </div>
            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Implementation Date</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="date"
                disabled={isEditMode}
                name="txtImplementationDate"
                max={maxDateOfReg}
                value={formValues.txtImplementationDate}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
              />
              <span className="validation_error">{formValidationError["txtImplementationDate"]}</span>
            </div>
            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Session Time (Min)</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="text"
                name="txtSessionTime"
                value={formValues.txtSessionTime}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Session Time"
              />
              <span className="validation_error">{formValidationError["txtSessionTime"]}</span>
            </div>
            <label
              className={
                formValues.isIPRestriction === true
                  ? "Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req"
                  : "Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup"
              }
            >
              Renew Session Time (Min)
            </label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="text"
                name="txtRenewSessionTime"
                value={formValues.txtRenewSessionTime}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Renew Session Time"
              />
              {/* <span className="validation_error">{(formValues.isIPRestriction === true) && (formValues.txtRenewSessionTime.toString() === '0'|| formValues.txtRenewSessionTime.toString() === '')? 'cannot be empty' : ''}</span> */}
              <span className="validation_error">{formValidationError["txtRenewSessionTime"]}</span>
            </div>
            <label className="Biz_Bm__Company_AddPopup_LabelTxt" htmlFor="isIPRestriction">
              IP Restriction
            </label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <label className="Biz_BM__panel_Switch">
                <input
                  className="Biz_BM__panel_Switch-input"
                  type="checkbox"
                  id="isIPRestriction"
                  checked={formValues.isIPRestriction}
                  name="isIPRestriction"
                  onChange={(e) => updateState(e.target.name, !formValues.isIPRestriction)}
                />
                <span className="Biz_BM__panel_Switch-label" data-on="Yes" data-off="No"></span>
                <span className="Biz_BM__panel_Switch-handle"></span>
              </label>
            </div>
            {/* </div> */}
          </div>
          <footer className="Biz_Bm__Company_AddPopup_Footer">
            <button type="submit" className={btnloaderActive ? "FormBtnActive" : ""}>
              <span className="BtnTxt">{isEditMode ? "Update" : "Save"}</span>
              <div className="btn__spinner">
                <div className="btn__bounce1"></div>
                <div className="btn__bounce2"></div>
                <div className="btn__bounce3"></div>
              </div>
            </button>
            <button type="button" onClick={() => toggleAddCompanyPopup(false, null)}>
              Cancel
            </button>
          </footer>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddCompanyPopup;
