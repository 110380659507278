import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const getCommonCode = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.CommonMaster.CommonMasterCode);
  return response;
};

export const getCommonValue = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.CommonMaster.CommonMasterDataValue);
  return response;
};

export const addCommonMasterCode = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.CommonMaster.CreateMasterData);
  return response;
};

export const addCommonMasterValue = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.CommonMaster.CreateDataValue);
  return response;
};
