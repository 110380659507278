import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";
import { publicIpv4 } from "public-ip";

export const getCompanyMenuUserAssignSelect = async (companyMasterId, moduleCode, companyUserId, viewMode) => {
  const ip = await publicIpv4();
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  let requestData = {
    companyMasterId: companyMasterId,
    moduleCode: moduleCode,
    viewMode: viewMode,
    companyUserId: companyUserId,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CompanyMasterUserMenu);
  return response;
};

export const companyMenuUserAssignOrUnAssigned = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CreateUserMenu);
  return response;
};

export const CreateUserMenu = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.CreateUserMenu);
  return response;
};

export const companyRightsAssignOrUnAssigned = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.User.RightsAssignOrUnAssigned);
  return response;
};

export const companyProfileRightsAssignOrUnAssigned = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.User.RightsProfileAssignOrUnAssigned);
  return response;
};

export const getRightsList = async (ViewMode, RightMasterID, companyMasterId, companyUserId) => {
  const requestData = {
    viewMode: ViewMode,
    companyMasterId: companyMasterId,
    companyUserId: companyUserId,
    rightMasterId: RightMasterID,
  };
  const response = await ApiCalling(requestData, APIEndpoints.User.GetRightsList);
  return response;
};

export const getUserHistory = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.User.UserHistory);
  return response;
};
