import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BiRefresh } from "react-icons/bi";
import { FaEdit, FaFileAlt, FaFileImport, FaTimes, FaTrashAlt } from "react-icons/fa";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import PageTitle, { PageButton, PageSearch } from "../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, { FooterBox } from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { SupportUsersData, getdeleteSupportUser } from "../Resource/SupportService";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";
import AddSupportUser from "./Views/AddSupportUser/AddSupportUser";
import ResetPasswordPopup from "./Views/AddSupportUser/ResetPasswordPopup/ResetPasswordPopup";
import AssignCompany from "./Views/AssignCompany/AssignCompany";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import { getCompanyMasterSelect } from "../../CRM/Resources/companyRegistrationService";

const SupportUsers = () => {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });
  const firstTextInput = useRef();
  const [formValues, setFormValues] = useState({
    txtCompany: null,
  });
  const [AddformPopupActive, setAddformPopupActive] = useState(false);

  const [SupportUsersList, setSupportUsersList] = useState([]);
  const [isLoadingDesgnList, setIsLoadingDesgnList] = useState(false);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const updateState = (name, value) => {
    if (name === "txtCompany") {
      if (!formValues.txtCompany || typeof formValues.txtCompany === "undefined" || formValues.txtCompany !== value) {
        setSupportUsersList([]);
      }
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const getSupportUsersList = async (data) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingDesgnList(true);
      const formData = {
        companyMasterId: data,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await SupportUsersData(formData);
      console.log(result, "result");
      setIsLoadingDesgnList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setSupportUsersList(result.responseData);
        } else {
          setSupportUsersList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  const [isLoadingCompanyList, setIsLoadingCompanyList] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const getCompanyList = async () => {
    debugger;
    try {
      setIsLoadingCompanyList(true);
      let result = await getCompanyMasterSelect("COMPANYLIST");
      setIsLoadingCompanyList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setCompanyList(result.responseData);
        } else {
          setCompanyList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  const OnclickSearch = () => {
    if (formValues.txtCompany) {
      getSupportUsersList(formValues.txtCompany.CompanyMasterID);
    } else {
      firstTextInput.current.focus();
      setMsgAlert({ open: true, type: "warning", msg: "Please Select A Company!" });
    }
  };

  const [AddSupportUsersPopup, setAddSupportUsersPopup] = useState(false);
  const toggleAddSupportUsers = () => {
    setAddSupportUsersPopup(!AddSupportUsersPopup);
  };
  const deleteSupportUser = async (data) => {
    debugger;
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      const ip = await publicIpv4();
      let formData = {
        bsAppAccessId: data.BSAppAccessID,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getdeleteSupportUser(formData);

      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        OnclickSearch();
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error, "error");
    }
  };

  const [changePasswodPopupActive, setChangePasswodPopupActive] = useState({
    IsOpen: false,
    UserData: null,
  });

  const toggleResetPasswordPopup = (userData) => {
    console.log("toggleResetPasswordPopup", userData);
    setChangePasswodPopupActive({
      IsOpen: !changePasswodPopupActive.IsOpen,
      UserData: userData,
    });
  };

  const [companyAssignmentPopupActive, setCompanyAssignmentPopupActive] = useState({
    IsOpen: false,
    popupData: null,
  });

  const toggleCompanyAssignmentPopup = (userData) => {
    console.log("toggleResetPasswordPopup", userData);
    setCompanyAssignmentPopupActive({
      IsOpen: !companyAssignmentPopupActive.IsOpen,
      popupData: userData,
    });
  };

  const gridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 100,
      pinned: "left",
      lockPosition: "1",
      cellRenderer: "actionTemplate",
      cellRendererParams: { toggleAddSupportUsers, setConfirmAlert, deleteSupportUser, toggleResetPasswordPopup, toggleCompanyAssignmentPopup },
    },
    {
      field: "SupportUserName",
      headerName: "UserName",
      width: 180,
    },
    {
      field: "UserDisplayName",
      headerName: "Display Name",
      width: 180,
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 180,
    },
    {
      field: "UserLoginID",
      headerName: "Biznext Login ID",
      width: 180,
    },
  ];

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const updateSupportUsersList = (data) => {
    debugger;
    setSupportUsersList([]);
    SupportUsersList.unshift(data);
    setSupportUsersList(SupportUsersList);
    if (gridApi) {
      gridApi.setRowData(SupportUsersList);
    }
  };

  return (
    <React.Fragment>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {AddSupportUsersPopup ? (
        <AddSupportUser setMsgAlert={setMsgAlert} updateSupportUsersList={updateSupportUsersList} toggleAddSupportUsers={toggleAddSupportUsers} setConfirmAlert={setConfirmAlert} />
      ) : null}
      {changePasswodPopupActive.IsOpen ? (
        <ResetPasswordPopup
          popupData={changePasswodPopupActive.UserData}
          toggleResetPasswordPopup={toggleResetPasswordPopup}
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      {companyAssignmentPopupActive.IsOpen ? (
        <AssignCompany
          popupData={companyAssignmentPopupActive.popupData}
          toggleCompanyAssignmentPopup={toggleCompanyAssignmentPopup}
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      <div className={`Biz_Listing_PageStart`}>
        <PageTitle Title="Support Users">
          <PageBar.Select
            getOptionLabel={(option) => `${option.CompanyName}`}
            value={formValues.txtCompany}
            getOptionValue={(option) => `${option}`}
            options={companyList}
            onChange={(e) => updateState("txtCompany", e)}
            isSearchable={true}
            isLoading={isLoadingCompanyList}
            label="Company"
            ref={firstTextInput}
          />
          <PageSearch focus={true} onClick={() => OnclickSearch()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
          <PageButton onClick={() => toggleAddSupportUsers()}>Add</PageButton>
        </PageTitle>
        <DataGrid
          rowData={SupportUsersList}
          columnDefs={gridColumnDefs}
          onGridReady={onGridReady}
          loader={isLoadingDesgnList ? <Loader /> : null}
          getRowStyle={function (data) {
            if (data.data.IsSelected) {
              return { background: "#ffc176" };
            } else {
              if (data.data.IsNewlyAdded) {
                return { background: "#d5a10e" };
              }
              return { background: "" };
            }
          }}
          frameworkComponents={{
            actionTemplate: cellTemplate,
          }}
        />

        <Footer>
          <FooterBox Aligned={"right"}></FooterBox>
        </Footer>
      </div>
    </React.Fragment>
  );
};

export default SupportUsers;
const cellTemplate = (props) => {
  let menuData = props.data;
  const setConfirmAlert = props.setConfirmAlert;
  const toggleResetPasswordPopup = () => {
    props.toggleResetPasswordPopup(menuData);
  };

  const toggleCompanyAssignmentPopup = () => {
    console.log(menuData, "menuData");
    props.toggleCompanyAssignmentPopup(menuData);
  };

  const deleteSupportUser = () => {
    props.deleteSupportUser(props.data);
  };

  return (
    <div style={{ display: "flex", paddingLeft: "6px", gap: "2px" }}>
      <span title="Reset Password" style={{ cursor: "pointer", paddingRight: "8px" }}>
        <FaFileAlt className="Biz_Bm__Icon" onClick={() => toggleResetPasswordPopup()} />{" "}
      </span>
      <span title="Assign Company" style={{ cursor: "pointer", paddingRight: "8px" }}>
        <FaFileImport className="Biz_Bm__Icon" onClick={() => toggleCompanyAssignmentPopup()} />{" "}
      </span>
      <span title="Delete" style={{ cursor: "pointer", paddingRight: "8px" }}>
        <FaTrashAlt
          className="Biz__Icon"
          style={{ fontSize: "15px", color: "#f54e60" }}
          onClick={() =>
            setConfirmAlert({
              open: true,
              title: "Delete",
              msg: "Are you sure to delete ?",
              button: {
                confirmText: "Delete",
                abortText: "Cancel",
                Color: "Danger",
              },
              onConfirm: () => deleteSupportUser(),
            })
          }
        />
      </span>
    </div>
  );
};
