import { useEffect, useRef, useState } from "react";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { companyMenuAssignOrUnAssigned } from "../../Resources/CustomerAccessService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const UpdateMenu = (props) => {
  let setUpdateMenuPopup = props.setUpdateMenuPopup;
  let menuData = props.menuData;
  let setMsgAlert = props.setMsgAlert;
  let updateMenuList = props.updateMenuList;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtWebUrl: "",
    txtWinUrl: "",
    txtAppUrl: "",
    txtApiUrl: "",
    txtWpfUrl: "",
    txtReactUrl: "",
  });

  const firstTextInput = useRef();
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
    if (menuData) {
      setFormValues({
        txtWebUrl: menuData.WebURL,
        txtWinUrl: menuData.WinURL,
        txtAppUrl: menuData.AppURL,
        txtApiUrl: menuData.APIURL,
        txtWpfUrl: menuData.WPFURL,
        txtReactUrl: menuData.ReactURL,
      });
    }
  }, []);

  const [formValidationError, setFormValidationError] = useState({});

  const updateState = (name, val) => {
    setFormValues({ ...formValues, [name]: val });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (typeof formValues["txtWpfUrl"] !== "undefined" && formValues["txtWpfUrl"]) {
      var count = (formValues["txtWpfUrl"].match(/\./g) || []).length;
      if (!(count >= 2)) {
        formIsValid = false;
        errors["txtWpfUrl"] = "Please enter a valid Url. Ex:- MIS.Finance.AccountStatementPage";
      }
    }
    setFormValidationError(errors);
    return formIsValid;
  };

  const handleSave = async (e) => {
    debugger;
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Update",
        msg: "Are you sure to update ?",
        button: {
          confirmText: "Update",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      setBtnloaderActive(true);
      let formData = {
        companyMenuId: menuData.CompanyMenuID.toString(),
        companyMasterId: 0,
        menuMasterId: 0,
        companyMenuName: menuData.MenuName,
        webUrl: formValues.txtWebUrl,
        winUrl: formValues.txtWinUrl,
        appUrl: formValues.txtAppUrl,
        apiUrl: formValues.txtApiUrl,
        wpfUrl: formValues.txtWpfUrl,
        reactUrl: formValues.txtReactUrl,
        action: "EDITURL",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };

      let result = await companyMenuAssignOrUnAssigned(formData);
      if (result.responseCode === 1) {
        debugger;
        console.log(result);
        // menuData.WebURL = formData.webUrl;
        // menuData.WinURL = formData.winUrl;
        // menuData.AppURL = formData.appUrl;
        // menuData.APIURL = formData.apiUrl;
        // menuData.WPFURL = formData.wpfUrl;
        // menuData.ReactURL = formData.reactUrl;
        // menuData.isUpdated = true;
        // updateMenuList(menuData);
        let subMenu = {
          ...menuData,
          WebURL: formData.webUrl,
          WinURL: formData.winUrl,
          AppURL: formData.appUrl,
          APIURL: formData.apiUrl,
          WPFURL: formData.wpfUrl,
          ReactURL: formData.reactUrl,
          isUpdated: true,
        };
        updateMenuList(subMenu);
        setUpdateMenuPopup({ open: false, data: null });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  return (
    <Modal
      varient={"center"}
      title={`Update Menu (${menuData.MenuName ? menuData.MenuName : ""})`}
      onSubmit={handleSave}
      show={() => setUpdateMenuPopup({ open: false, data: null })}
    >
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"520px"}>
            <Form.InputGroup errorMsg={formValidationError["txtWebUrl"]} label="Web URL" req={false}>
              <Form.InputControl
                control="input"
                label="Web URL"
                name="txtWebUrl"
                type="text"
                value={formValues.txtWebUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtWinUrl"]} label="Win URL" req={false}>
              <Form.InputControl
                label="Win URL"
                control="input"
                name="txtWinUrl"
                type="text"
                value={formValues.txtWinUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtAppUrl"]} label="APP URL" req={false}>
              <Form.InputControl
                label="APP URL"
                control="input"
                name="txtAppUrl"
                type="text"
                value={formValues.txtAppUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtApiUrl"]} label="APP URL" req={false}>
              <Form.InputControl
                label="APP URL"
                control="input"
                name="txtApiUrl"
                type="text"
                value={formValues.txtApiUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtWpfUrl"]} label="WPF URL" req={false}>
              <Form.InputControl
                label="WPF URL"
                control="input"
                name="txtWpfUrl"
                type="text"
                value={formValues.txtWpfUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtReactUrl"]} label="React URL" req={true}>
              <Form.InputControl
                label="React URL"
                control="input"
                name="txtReactUrl"
                type="text"
                value={formValues.txtReactUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default UpdateMenu;
