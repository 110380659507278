import axios from "axios";
import APIEndpoints from "../APIEndpoints/APIEndpoints";
import { Success } from "../../Configration/Utilities/Constants";
import { Buffer } from "buffer";
import { publicIpv4 } from "public-ip";

export const authenticate = async (userName, password, companyCode) => {
  debugger;
  const ip = await publicIpv4();
  try {
    const buff = Buffer.from(`${userName}:${password}`);
    const base64String = buff.toString("base64");
    const requestData = {
      UserIP: ip,
      UserAppType: "1",
      UserIMEI: "testimeinumber",
      UserPhone: "9911491058",
      UserMacAddress: "testmacaddress",
    };
    console.log(JSON.stringify(requestData));
    console.log(base64String);
    const response = await axios.post(APIEndpoints.Authenticate, requestData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        CompanyCode: companyCode,
        Authorization: "Basic " + base64String,
        MenuType: "101",
      },
    });
    console.log(response, "response");
    if (response.status === 200) {
      const result = await response.data;
      return {
        responseCode: 1,
        responseData: result,
        responseMessage: "Success",
      };
    }
    if (
      response.status === 401 &&
      response &&
      response.Unauthorized &&
      response.Unauthorized.Errors &&
      response.Unauthorized.Errors[0] &&
      response.Unauthorized.Errors[0].ErrorMessage
    ) {
      return {
        responseCode: 0,
        responseData: null,
        responseMessage: response.Unauthorized.Errors[0].ErrorMessage,
      };
    }
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: "Login Error",
    };
  } catch (error) {
    console.log(error, "error");
    if (
      error.response.status === 401 &&
      error &&
      error.response &&
      error.response.data &&
      error.response.data.Unauthorized &&
      error.response.data.Unauthorized.Errors &&
      error.response.data.Unauthorized.Errors[0] &&
      error.response.data.Unauthorized.Errors[0].ErrorMessage
    ) {
      return {
        responseCode: 0,
        responseData: null,
        responseMessage: error.response.data.Unauthorized.Errors[0].ErrorMessage,
      };
    }
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: "Login Error",
    };
  }
};

export const bmAuthenticate = async (userName, password, companyCode) => {
  debugger;
  const ip = await publicIpv4();
  try {
    debugger;
    const buff = Buffer.from(`${userName}:${password}`);
    const base64String = buff.toString("base64");
    const requestData = {
      // accessMode: "BMC",
      // applicationTypeId: 1,
      // companyCode: companyCode,
      // companyAccessId: "",
      // companyAccessKey: "",
      // companyUserName: userName,
      // userPassword: password,
      // userAccessToken: "",
      // userImei: "testimeinumber",
      // userIpAddress: "43.254.176.242",
      // userMacAddress: "testmacaddress",
      // userMobile: "9911491058",
      accessMode: "BMC",
      appTypeId: 101,
      menuTypeId: 101,
      companyCode: companyCode,
      companyAccessId: "",
      companyAccessKey: "",
      userLoginId: userName,
      userPassword: password,
      userAccessToken: "",
      userImeiNo: "",
      userIpAddress: ip ? ip.toString() : "",
      userMacAddress: "",
      userMobileNo: "",
    };
    console.log(JSON.stringify(requestData));
    console.log(base64String);
    const response = await axios.post(APIEndpoints.CheckClientUserCredentails, requestData, {
      // headers: {
      //   "Content-Type": "application/json",
      //   Accept: "application/json",
      //   CompanyCode: companyCode,
      //   Authorization: "Basic " + base64String,
      //   MenuType: "101",
      // },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
        companyUserId: "",
        userLoginId: "",
        companyCode: "",
        token: "",
        rightCode: "",
        apikey: "",
      },
    });
    if (response.status === 200) {
      debugger;
      const result = await response.data;
      return {
        responseCode: 1,
        responseData: result,
        responseMessage: "Success",
      };
    }
    if (
      response.status === 401 &&
      response &&
      response.Unauthorized &&
      response.Unauthorized.Errors &&
      response.Unauthorized.Errors[0] &&
      response.Unauthorized.Errors[0].ErrorMessage
    ) {
      return {
        responseCode: 0,
        responseData: null,
        responseMessage: response.Unauthorized.Errors[0].ErrorMessage,
      };
    }
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: "Login Error",
    };
  } catch (error) {
    debugger;
    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 401 &&
      error &&
      error.response &&
      error.response.data &&
      error.response.data.Unauthorized &&
      error.response.data.Unauthorized.Errors &&
      error.response.data.Unauthorized.Errors[0] &&
      error.response.data.Unauthorized.Errors[0].ErrorMessage
    ) {
      return {
        responseCode: 0,
        responseData: null,
        responseMessage: error.response.data.Unauthorized.Errors[0].ErrorMessage,
      };
    }
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: "Login Error",
    };
  }
};
