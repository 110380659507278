import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { publicIpv4 } from "public-ip";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { CreateUserMenu } from "../../Resources/MenuToUserService";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddUserMenuPopup = (props) => {
  let toggleAddUserMenu = props.toggleAddUserMenu;
  let setMsgAlert = props.setMsgAlert;

  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtMenuName: "",
  });

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!formValues.txtMenuName || typeof formValues.txtMenuName === "undefined") {
      formIsValid = false;
      errors["txtMenuName"] = "Cannot be empty";
    }
    setFormValidationError(errors);
    return formIsValid;
  };

  const onSubmit = async (e) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const ip = await publicIpv4();
      let formData = {
        designationName: formValues.txtMenuName,
        shortCode: formValues.txtShortCode,
        activeStatus: "Y",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await CreateUserMenu(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result && result.responseData) {
          let addedDesignation = {
            ActiveStatus: result.responseData.ActiveStatus,
            DesignationID: result.responseData.DesignationCode,
            DesignationName: formValues.txtMenuName,
            ShortCode: formValues.txtShortCode,
            IsNewlyAdded: true,
          };
          //   updateDesignationList(addedDesignation);
          clearForm();
          toggleAddUserMenu(null);
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtMenuName: "",
      txtShortCode: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title="Add User Menu" onSubmit={onSubmit} show={() => toggleAddUserMenu(null)}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtMenuName"]} label="Menu Name" req={true}>
              <Form.InputControl control="input" name="txtMenuName" type="text" value={formValues.txtMenuName} onChange={(e) => updateState(e.target.name, e.target.value)} />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUserMenuPopup;
