import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const GetPageConfigData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.PageConfig.GetPageConfigData);
  return response;
};

export const CreatePageConfigData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.PageConfig.CreatePageConfigData);
  return response;
};

export const UpdatePageConfigData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.PageConfig.UpdatePageConfigData);
  return response;
};
