import React, { useState, useEffect, useRef } from "react";
import "../../Company_Registration.css";
import { createCompanyUser, getCompanyMasterConfigSelect } from "../../../Resources/companyRegistrationService";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { checkAuthExist, getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import APIEndpoints from "../../../../../APIMethods/APIEndpoints/APIEndpoints";
import axios from "axios";
import { Buffer } from "buffer";
import * as Sentry from "@sentry/react";
import useDebounce from "../../../../../Configration/Utilities/debounce";
import BMLoginSession from "../../../../Layout/BMLoginPopup/BM_Login";

const pako = require("pako");

const AddCompanyUserPopup = (props) => {
  let isEditMode = props.isEditMode;
  let selectedCompanyMaster = props.selectedCompanyMaster;
  let popupData = props.popupData;
  let toggleAddCompanyUserPopup = props.toggleAddCompanyUserPopup;
  let setMsgAlert = props.setMsgAlert;
  let updateCompanyUserList = props.updateCompanyUserList;

  const [userReferenceList, setUserReferenceList] = useState([]);
  const [isLoadingUserReferance, setIsLoadingUserReferance] = useState(false);
  const [searchItemTerm, setSearchItemTerm] = useState("");
  const debouncedSearchItemTerm = useDebounce(searchItemTerm, 500);
  const [saveMore, setSaveMore] = useState(false);
  const [formValues, setFormValues] = useState({
    txtDisplayName: "",
    txtLoginName: "",
    txtPassword: "",
    txtEmailId: "",
    txtAppAccessLevel: null,
    txtUserType: null,
    txtReferenceType: null,
    txtBRHeadType: null,
    isRequiredBRHead: false,
    txtUserReference: null,
    isWebApp: false,
    isWinApp: false,
    isMobileApp: false,
    isWebApi: false,
    isIpAllowed: 0,
    isImeiAllowed: 0,
    isMacAllowed: 0,
    isMobileAllowed: 0,
  });

  const [oldFormValues, setOldFormValues] = useState({
    txtDisplayName: "",
    txtLoginName: "",
    txtPassword: "",
    txtEmailId: "",
    txtAppAccessLevel: null,
    txtUserType: null,
    txtReferenceType: null,
    txtBRHeadType: null,
    isRequiredBRHead: false,
    txtUserReference: null,
    isWebApp: false,
    isWinApp: false,
    isMobileApp: false,
    isWebApi: false,
    isIpAllowed: 0,
    isImeiAllowed: 0,
    isMacAllowed: 0,
    isMobileAllowed: 0,
  });

  const brHeadTypeOptions = [
    { Name: "Customer", Value: 124001 },
    { Name: "Supplier", Value: 124002 },
    { Name: "E-Commerce Channel", Value: 124003 },
    { Name: "Shipping Company", Value: 124004 },
    { Name: "Dealer", Value: 124005 },
  ];

  const userTypeOptions = [
    { Name: "Admin", Value: "472" },
    { Name: "User", Value: "503" },
  ];

  const AccessLevelTypeOptions = [
    { Name: "APP", Value: "APP" },
    { Name: "API", Value: "API" },
  ];

  const referenceTypeOptions = [
    { Name: "EMP", Value: "EMP" },
    { Name: "BR", Value: "BR" },
  ];

  const UserReferanceApiCalling = async (requestApiData, apiPath, ConfigAPIURL, commonName, header) => {
    debugger;
    const session = getSessionStorage(selectedCompanyMaster.CompanyMasterID);
    console.log(session, "&&&&&&&&&&&sessiion details&&&&&&&&&&&");
    const requestData = {
      ...requestApiData,
    };

    try {
      const response = await axios.post(ConfigAPIURL + apiPath, requestData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          userloginid: session && session.AppAccessUID ? session.AppAccessUID : "",
          companycode: session && session.CompanyCode ? session.CompanyCode : "",
          companyuserid: session && session.UserData && session.UserData.UserID ? session.UserData.UserID : "",
          token: session && session.ApiToken && session.ApiToken.ApiToken ? session.ApiToken.ApiToken : "",
          rightCode: "9999",
          apiKey: "",
          ...header,
        },
      });

      if (response && response.status === 200) {
        const result = await response.data;
        if (result.responseCode.toString() === "1") {
          if (result.responseDynamic === "" || result.responseDynamic === null) {
            return {
              responseCode: result.responseCode,
              responseData: [],
              responseMessage: result.responseMessage,
            };
          } else if (result.responseByte !== "" && result.responseByte !== null && result.responseByte !== undefined) {
            const buff = Buffer.from(result.responseByte ? result.responseByte : "", "base64");
            if (buff.length !== 0) {
              const Data = JSON.parse(pako.inflate(buff, { to: "string" }));
              return { responseCode: result.responseCode.toString(), responseData: Data, responseMessage: result.responseMessage };
            }
            return { responseCode: result.responseCode, responseData: [], responseMessage: result.responseMessage };
          } else {
            const buff = Buffer.from(result.responseDynamic ? result.responseDynamic : "", "base64");
            if (buff.length !== 0) {
              const Data = JSON.parse(pako.inflate(buff, { to: "string" }));
              return { responseCode: result.responseCode.toString(), responseData: Data, responseMessage: result.responseMessage };
            }
            return { responseCode: result.responseCode, responseData: [], responseMessage: result.responseMessage };
          }
        } else if (result.responseCode.toString() === "2") {
          if (result.responseDynamic === "" || result.responseDynamic === null) {
            return {
              responseCode: result.responseCode,
              responseData: [],
              responseMessage: result.responseMessage,
            };
          } else if (result.responseByte !== "" && result.responseByte !== null && result.responseByte !== undefined) {
            const buff = Buffer.from(result.responseByte ? result.responseByte : "", "base64");
            if (buff.length !== 0) {
              const Data = JSON.parse(pako.inflate(buff, { to: "string" }));
              return { responseCode: result.responseCode.toString(), responseData: Data, responseMessage: result.responseMessage };
            }
            return { responseCode: result.responseCode, responseData: [], responseMessage: result.responseMessage };
          } else {
            const buff = Buffer.from(result.responseDynamic ? result.responseDynamic : "", "base64");
            if (buff.length !== 0) {
              const Data = JSON.parse(pako.inflate(buff, { to: "string" }));
              return { responseCode: result.responseCode.toString(), responseData: Data, responseMessage: result.responseMessage };
            }
            return { responseCode: result.responseCode, responseData: [], responseMessage: result.responseMessage };
          }
        }
        return { responseCode: result.responseCode, responseData: null, responseMessage: result.responseMessage };
      }
      return { responseCode: 0, responseData: null, responseMessage: CustomMsg.Error };
    } catch (error) {
      Sentry.captureException(error);
      if (!checkAuthExist() && error && error.response && error.response.status === 401) {
        return {
          responseCode: 401,
          responseData: null,
          responseMessage: CustomMsg.UnAuthorized,
        };
      } else if (error && error.response && error.response.status === 400) {
        return {
          ResponseCode: 0,
          responseData: null,
          responseMessage: error && error.response && error.response.data && error.response.data.message ? error.response.data.message : CustomMsg.Error,
        };
      }

      return {
        ResponseCode: 0,
        responseData: null,
        responseMessage: error && error.response && error.response.data && error.response.data.message ? error.response.data.message : CustomMsg.Error,
      };
    }
  };

  const getUserReferanceSelect = async (formData, ConfigAPIURL) => {
    const response = await UserReferanceApiCalling(formData, APIEndpoints.User.GetUserReference, ConfigAPIURL);
    return response;
  };

  const getUserReferanceList = async (ConfigAPIURL, searchText) => {
    debugger;
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      const ip = await publicIpv4();
      setIsLoadingUserReferance(true);
      const formData = {
        userRelationType: formValues.txtReferenceType.Value ? formValues.txtReferenceType.Value : "",
        brHeadID: formValues.txtBRHeadType && formValues.txtBRHeadType.Value ? formValues.txtBRHeadType.Value : 0,
        searchText: searchText,
        filter1: 0,
        filter2: "",
        objCommon: {
          insertedUserID: insertUserID.toString(),
          insertedIPAddress: ip,
          dateShort: "",
          dateLong: "",
        },
      };
      console.log(formData, "formdata user referance");
      let result = await getUserReferanceSelect(formData, ConfigAPIURL);
      setIsLoadingUserReferance(false);
      console.log(result, "referance type");
      if (result && result.responseCode === "1") {
        debugger;
        if (result.responseData && result.responseData.UserReference && result.responseData.UserReference.length > 0) {
          setUserReferenceList(result.responseData.UserReference);
        } else {
          setUserReferenceList([]);
        }
      } else {
        setUserReferenceList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const _conformGetUserReference = (searchText) => {
    debugger;
    let companyData = getSessionStorage(selectedCompanyMaster.CompanyMasterID);
    console.log(companyData);
    if (companyData) {
      if (companyConfigList && companyConfigList[0] && companyConfigList[0].ConfigAPIURL) {
        console.log(companyConfigList, "companyConfigList");
        getUserReferanceList(companyConfigList[0].ConfigAPIURL, searchText);
      }
    } else {
      ToggleLoginPopup();
    }
  };

  const [isLoadingCompanyConfigList, setIsLoadingCompanyConfigList] = useState(false);
  const [companyConfigList, setCompanyConfigList] = useState([]);
  const getCompanyMasterConfig = async (companyMasterId) => {
    try {
      setIsLoadingCompanyConfigList(true);
      let result = await getCompanyMasterConfigSelect(companyMasterId, "0", "CONFIGLIST");
      setIsLoadingCompanyConfigList(false);
      console.log(result, "configList");
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCompanyConfigList(result.responseData);
        } else {
          setCompanyConfigList(null);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyMasterConfig(selectedCompanyMaster.CompanyMasterID);
  }, []);

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();

  const [editCompanyUser, setEditCompanyUser] = useState();

  useEffect(() => {
    if (isEditMode) {
      if (selectedCompanyMaster) {
        setEditCompanyUser(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (editCompanyUser) {
      formValues.txtDisplayName = editCompanyUser.UserDisplayName;
      formValues.txtLoginName = editCompanyUser.UserLoginID;
      formValues.txtUserReference =
        editCompanyUser.UserRelationID && editCompanyUser.UserRelationName
          ? { UserReferenceID: editCompanyUser.UserRelationID, UserReferenceName: editCompanyUser.UserRelationName }
          : null;
      formValues.txtUserType = editCompanyUser.UserType && userTypeOptions.length > 0 ? userTypeOptions.find((x) => x.Name === editCompanyUser.UserType) : null;
      formValues.txtAppAccessLevel =
        editCompanyUser.AppAccessLevel && AccessLevelTypeOptions.length > 0 ? AccessLevelTypeOptions.find((x) => x.Name === editCompanyUser.AppAccessLevel) : null;
      formValues.txtReferenceType =
        editCompanyUser.UserRelationType && referenceTypeOptions.length > 0 ? referenceTypeOptions.find((x) => x.Value === editCompanyUser.UserRelationType) : null;
      formValues.txtBRHeadType = editCompanyUser.BRHeadTypeID && brHeadTypeOptions.length > 0 ? brHeadTypeOptions.find((x) => x.Value === editCompanyUser.BRHeadTypeID) : null;
      formValues.isWebApp = editCompanyUser.Web_App ? editCompanyUser.Web_App : false;
      formValues.isWinApp = editCompanyUser.Window_App == 1 ? true : false;
      formValues.isMobileApp = editCompanyUser.Mobile_App == 1 ? true : false;
      formValues.isWebApi = editCompanyUser.Web_API == 1 ? true : false;
      formValues.isIpAllowed = editCompanyUser.IPAllowed == 1 ? true : false;
      formValues.isImeiAllowed = editCompanyUser.IMEIAllowed == 1 ? true : false;
      formValues.isMacAllowed = editCompanyUser.MacAddAllowed == 1 ? true : false;
      formValues.isMobileAllowed = editCompanyUser.MobileAllowed == 1 ? true : false;

      oldFormValues.txtDisplayName = editCompanyUser.UserDisplayName;
      oldFormValues.txtLoginName = editCompanyUser.UserLoginID;
      oldFormValues.txtUserType = editCompanyUser.UserType && userTypeOptions.length > 0 ? userTypeOptions.find((x) => x.Name === editCompanyUser.UserType) : null;
      oldFormValues.txtAppAccessLevel =
        editCompanyUser.AppAccessLevel && AccessLevelTypeOptions.length > 0 ? AccessLevelTypeOptions.find((x) => x.Name === editCompanyUser.AppAccessLevel) : null;
      oldFormValues.txtReferenceType =
        editCompanyUser.UserRelationType && referenceTypeOptions.length > 0 ? referenceTypeOptions.find((x) => x.Value === editCompanyUser.UserRelationType) : null;
      oldFormValues.txtBRHeadType = editCompanyUser.BRHeadTypeID && brHeadTypeOptions.length > 0 ? brHeadTypeOptions.find((x) => x.Value === editCompanyUser.BRHeadTypeID) : null;
      oldFormValues.isWebApp = editCompanyUser.Web_App ? editCompanyUser.Web_App : false;
      oldFormValues.isWinApp = editCompanyUser.Window_App == 1 ? true : false;
      oldFormValues.isMobileApp = editCompanyUser.Mobile_App == 1 ? true : false;
      oldFormValues.isWebApi = editCompanyUser.Web_API == 1 ? true : false;
      oldFormValues.isIpAllowed = editCompanyUser.IPAllowed == 1 ? true : false;
      oldFormValues.isImeiAllowed = editCompanyUser.IMEIAllowed == 1 ? true : false;
      oldFormValues.isMacAllowed = editCompanyUser.MacAddAllowed == 1 ? true : false;
      oldFormValues.isMobileAllowed = editCompanyUser.MobileAllowed == 1 ? true : false;
    }
    setFormValues({ ...formValues });
    setOldFormValues({ ...formValues });
  }, [editCompanyUser]);

  useEffect(() => {
    if (debouncedSearchItemTerm) {
      if (debouncedSearchItemTerm.length >= 3) {
        if (debouncedSearchItemTerm.toLowerCase() === "#all") {
          setSearchItemTerm("");
        }

        _conformGetUserReference(debouncedSearchItemTerm);
      }
    }
  }, [debouncedSearchItemTerm]);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    console.log(isLoadingUserReferance);
    formValidationError[name] = validateField(name, value);
    if (name === "txtReferenceType") {
      formValues.txtBRHeadType = null;
      formValues.txtUserReference = null;
      setUserReferenceList([]);
      if (value) {
        if (formValues.txtReferenceType) {
          if (formValues.txtReferenceType.Value !== value.Value) {
            formValues.txtUserReference = null;
            setUserReferenceList([]);
          }
        } else {
          setUserReferenceList([]);
        }
        if (value.Value === "BR") {
          formValues.isRequiredBRHead = true;
          formValidationError["txtBRHeadType"] = "cannot be empty";
        } else {
          formValues.txtBRHeadType = null;
          formValues.isRequiredBRHead = false;
          formValidationError["txtBRHeadType"] = "";
        }
      } else {
        formValues.txtUserReference = null;
        setUserReferenceList([]);
        formValues.txtBRHeadType = null;
        formValues.isRequiredBRHead = false;
        formValidationError["txtBRHeadType"] = "";
      }
    } else if (name === "txtBRHeadType") {
      if (value && value.Value) {
        if (JSON.stringify(formValues.txtBRHeadType) !== JSON.stringify(value)) {
          formValues.txtBRHeadType = null;
          // if (companyConfigList && companyConfigList[0] && companyConfigList[0].ConfigAPIURL) {
          //   getUserReferanceList(companyConfigList[0].ConfigAPIURL);
          // }
        }
      } else {
        formValues.txtBRHeadType = null;
        setUserReferenceList([]);
      }
      if (!formValues.txtCompany || typeof formValues.txtCompany === "undefined" || JSON.stringify(formValues.txtCompany) !== JSON.stringify(value)) {
        setUserReferenceList([]);
      }
    } else if (name === "txtUserReference") {
      debugger;
      setFormValues((exist) => ({
        ...exist,
        txtDisplayName: "",
        txtLoginName: "",
      }));

  
      
      if (value && value.UserReferenceName ) {
        console.log(value.UserReferenceName, "value.UserLoginID");
        console.log(formValues.txtDisplayName ,formValues.txtLoginName,"formValues.txtDisplayName ,formValues.txtLoginName" );
    const    isDisplayNameExist = formValues.txtDisplayName !== "" ;
    const    isLoginNameExist = formValues.txtLoginName !== "" ;
        setFormValues((exist) => ({
          ...exist,
          txtDisplayName:isDisplayNameExist ? formValues.txtDisplayName: String(value.UserReferenceName),
          txtLoginName:isLoginNameExist ? formValues.txtLoginName : String(value.UserReferenceID),
        }));
      }
    }
  };

  const validateField = (name, value) => {
    let errorsMsg = "";

    if (["txtDisplayName", "txtLoginName", "txtCountry", "txtUserType", "txtReferenceType", "txtAppAccessLevel"].includes(name)) {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (!isEditMode) {
      if (name === "txtPassword") {
        if (!value || typeof value === "undefined") {
          errorsMsg = "Cannot be empty";
        }
      }
    } else if (name === "txtBRHeadType") {
      if (formValues.isRequiredBRHead) {
        if (!value || typeof value === "undefined") {
          errorsMsg = "Cannot be empty";
        }
      }
    }
    return errorsMsg;
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    errors["txtDisplayName"] = validateField("txtDisplayName", formValues.txtDisplayName);
    errors["txtPassword"] = validateField("txtPassword", formValues.txtPassword);
    errors["txtUserType"] = validateField("txtUserType", formValues.txtUserType);
    errors["txtAppAccessLevel"] = validateField("txtAppAccessLevel", formValues.txtAppAccessLevel);
    errors["txtReferenceType"] = validateField("txtReferenceType", formValues.txtReferenceType);
    errors["txtUserReference"] = validateField("txtUserReference", formValues.txtUserReference);
    if (Object.values(errors).join("")) {
      formIsValid = false;
    }
    console.log(errors, "errors");
    setFormValidationError(errors);
    return formIsValid;
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to update ?" : "Are you sure to Save ?",
        button: {
          confirmText: isEditMode ? "Update" : "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async (e) => {
    debugger;
    console.log(formValues, "formValues");
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      const ip = await publicIpv4();

      let formData = {
        appAccessCode: isEditMode ? popupData.BMCAppAccessCode : 0,
        companyUserId: isEditMode ? popupData.CompanyUserID : 0,
        companyMasterId: selectedCompanyMaster.CompanyMasterID,
        displayName: formValues.txtDisplayName,
        username: formValues.txtLoginName,
        password: formValues.txtPassword,
        appAccessTypeId: formValues.txtUserType ? formValues.txtUserType.Value : 0,
        appAccessLevel: formValues.txtAppAccessLevel ? formValues.txtAppAccessLevel.Value : 0,
        webApp: formValues.isWebApp ? 1 : 0,
        windowApp: formValues.isWinApp ? 1 : 0,
        mobileApp: formValues.isMobileApp ? 1 : 0,
        webApi: formValues.isWebApi ? 1 : 0,
        userRelationType: formValues.txtReferenceType && formValues.txtReferenceType.Value ? formValues.txtReferenceType.Value.toString() : "",
        brHeadTypeId: formValues.txtBRHeadType && formValues.txtBRHeadType.Value ? formValues.txtBRHeadType.Value : 0,
        userRelationId: formValues.txtUserReference && formValues.txtUserReference.UserReferenceID ? formValues.txtUserReference.UserReferenceID : 0,
        userRelationName: isEditMode
          ? formValues.txtUserReference && formValues.txtUserReference.UserReferenceName
          : formValues.txtUserReference && formValues.txtUserReference.UserReferenceName && formValues.txtUserReference.ReferenceCode
          ? `${formValues.txtUserReference.UserReferenceName}-(${formValues.txtUserReference.ReferenceCode})`
          : "",
        // formValues.txtUserReference && formValues.txtUserReference.UserReferenceName ? formValues.txtUserReference.UserReferenceName : "",
        activeStatus: "Y",
        ipAllowed: formValues.isIpAllowed ? 1 : 0,
        imeiAllowed: formValues.isImeiAllowed ? 1 : 0,
        macAllowed: formValues.isMacAllowed ? 1 : 0,
        mobileAllowed: formValues.isMobileAllowed ? 1 : 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      debugger;
      let result = await createCompanyUser(formData);

      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });

        let addedCode = {
          UserDisplayName: formValues.txtDisplayName,
          UserType: formValues.txtUserType && formValues.txtUserType.Name ? formValues.txtUserType.Name : "",
          AppAccessLevel: formValues.txtAppAccessLevel ? formValues.txtAppAccessLevel.Value : "",
          UserRelationType: formValues.txtReferenceType && formValues.txtReferenceType.Value ? formValues.txtReferenceType.Value : "",
          UserLoginID: formValues.txtLoginName,
          UserRelationName: isEditMode
            ? formValues.txtUserReference && formValues.txtUserReference.UserReferenceName
            : formValues.txtUserReference && formValues.txtUserReference.UserReferenceName && formValues.txtUserReference.ReferenceCode
            ? `${formValues.txtUserReference.UserReferenceName}-(${formValues.txtUserReference.ReferenceCode})`
            : "",
          AccessToken: isEditMode ? editCompanyUser.AccessToken : result && result.responseData && result.responseData.AccessToken ? result.responseData.AccessToken : "",
          ActiveStatus: "Y",
          IsNewlyAdded: true,
        };
        if (!isEditMode) {
          updateCompanyUserList(addedCode);
          setSaveMore(true);
          clearForm();
        } else {
          props.getCompanyUser("0", "0", selectedCompanyMaster.CompanyMasterID, "SELECT", "#All");
          toggleAddCompanyUserPopup(false, null);
        }

        // toggleAddCompanyUserPopup(false, null);
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtDisplayName: "",
      txtLoginName: "",
      txtPassword: "",
      txtEmailId: "",
      txtUserType: null,
      txtAppAccessLevel: null,
      txtReferenceType: null,
      txtBRHeadType: null,
      isRequiredBRHead: false,
      txtUserReference: null,
      isWebApp: false,
      isWinApp: false,
      isMobileApp: false,
      isWebApi: false,
      isIpAllowed: false,
      isImeiAllowed: false,
      isMacAllowed: false,
      isMobileAllowed: false,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };
  const [bmSessionPopupActive, setBMSessionPopupActive] = useState(false);

  const ToggleLoginPopup = () => {
    const selectedCompanyProfileToken = getSessionStorage(selectedCompanyMaster.CompanyMasterID);
    console.log(selectedCompanyProfileToken, "selectedCompanyProfileToken");
    if (!selectedCompanyProfileToken) {
      setBMSessionPopupActive(!bmSessionPopupActive);
    } else {
      toggleAddCompanyUserPopup(false, null);
    }
  };

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && popupData) {
      return true;
    }
    return false;
  };

  return (
    <>
      {bmSessionPopupActive && (
        <BMLoginSession
          selectedCompanyMaster={selectedCompanyMaster}
          setMsgAlert={setMsgAlert}
          _conformGetUserReference={_conformGetUserReference}
          toggleAddCompanyUserPopup={toggleAddCompanyUserPopup}
          setBMSessionPopupActive={setBMSessionPopupActive}
          ToggleLoginPopup={ToggleLoginPopup}
        />
      )}
      <Modal
        varient="bottom"
        onSubmit={handleSave}
        right={0}
        title={isEditMode ? "Update company User" : "Add company User"}
        show={() => toggleAddCompanyUserPopup(false, null)}
        index={2}
        width="883px"
      >
        <Modal.Body>
          <Form>
            <Form.Group controlwidth={"320px"} column={2}>
              <Form.InputGroup errorMsg={formValidationError["txtDisplayName"]} label="Display Name" req={true}>
                <Form.InputControl
                  control="input"
                  label="Display Name"
                  name="txtDisplayName"
                  type="text"
                  value={formValues.txtDisplayName}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["txtLoginName"]} label="Login Name" req={true}>
                <Form.InputControl
                  control="input"
                  label="Login Name"
                  disabled={isEditMode ? true : false}
                  name="txtLoginName"
                  type="text"
                  value={formValues.txtLoginName}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["txtPassword"]} label="Password" req={true}>
                <Form.InputControl
                  control="input"
                  label="Password"
                  disabled={isEditMode ? true : false}
                  name="txtPassword"
                  type={isEditMode ? "password" : "text"}
                  value={formValues.txtPassword}
                  // disabled={isEditMode}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["txtAppAccessLevel"]} label="App Access Level" req={true}>
                <Form.InputControl
                  control="select"
                  label="App Access Level"
                  type="text"
                  value={formValues.txtAppAccessLevel}
                  onChange={(e) => updateState("txtAppAccessLevel", e)}
                  getOptionLabel={(option) => `${option.Name}`}
                  getOptionValue={(option) => `${option}`}
                  options={AccessLevelTypeOptions}
                  isDisabled={isEditMode ? true : false}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["txtUserType"]} label="User Type" req={true}>
                <Form.InputControl
                  control="select"
                  label="User Type"
                  type="text"
                  isDisabled={isEditMode ? true : false}
                  value={formValues.txtUserType}
                  onChange={(e) => updateState("txtUserType", e)}
                  getOptionLabel={(option) => `${option.Name}`}
                  getOptionValue={(option) => `${option}`}
                  options={userTypeOptions}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["txtReferenceType"]} label="Reference Type" req={true}>
                <Form.InputControl
                  control="select"
                  label="Reference Type"
                  name="txtReferenceType"
                  isDisabled={isEditMode ? true : false}
                  menuPlacement="auto"
                  isClearable={true}
                  getOptionLabel={(option) => `${option.Name}`}
                  value={formValues.txtReferenceType}
                  getOptionValue={(option) => `${option}`}
                  options={referenceTypeOptions}
                  onChange={(e) => updateState("txtReferenceType", e)}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["txtBRHeadType"]} label="BRHead Type" req={formValues.isRequiredBRHead}>
                <Form.InputControl
                  control="select"
                  label=" Select BRHead Type"
                  name="txtBRHeadType"
                  getOptionLabel={(option) => `${option.Name}`}
                  getOptionValue={(option) => `${option}`}
                  value={formValues.txtBRHeadType}
                  options={brHeadTypeOptions}
                  onChange={(e) => updateState("txtBRHeadType", e)}
                  isDisabled={formValues["txtReferenceType"] && formValues["txtReferenceType"].Value && formValues["txtReferenceType"].Value === "BR" ? false : true}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["txtUserReference"]} label="User Reference" req={true}>
                <Form.InputControl
                  control="select"
                  label="User Reference"
                  inputValue={searchItemTerm}
                  onInputChange={(e) => {
                    if (e !== null) {
                      setSearchItemTerm(e);
                    }
                  }}
                  isDisabled={
                    formValues["txtReferenceType"] && formValues["txtReferenceType"].Value && formValues["txtReferenceType"].Value === "BR"
                      ? !formValues["txtBRHeadType"]
                      : !formValues["txtReferenceType"]
                  }
                  name="txtUserReference"
                  getOptionLabel={(option) => (isEditMode ? option.UserReferenceName : `${option.UserReferenceName}-(${option.ReferenceCode ? option.ReferenceCode : ""})`)}
                  value={formValues.txtUserReference}
                  getOptionValue={(option) => `${option}`}
                  options={userReferenceList}
                  onChange={(e) => updateState("txtUserReference", e)}
                />
              </Form.InputGroup>
            </Form.Group>
            <Form.BoxGroup title="Login In" column={4} controlwidth="70px" minwidth="111px">
              <Form.InputGroup errorMsg={formValidationError["isWebApp"]} label="Web App">
                <Form.InputControl control="switch" label="Web App" name="isWebApp" checked={formValues.isWebApp} onChange={(e) => updateState(e.target.name, e.target.checked)} />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["isMobileApp"]} label="Mobile App">
                <Form.InputControl
                  control="switch"
                  label="Mobile App"
                  name="isMobileApp"
                  checked={formValues.isMobileApp}
                  onChange={(e) => updateState(e.target.name, e.target.checked)}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["isWinApp"]} label="Win App">
                <Form.InputControl control="switch" label="isWinApp" name="isWinApp" checked={formValues.isWinApp} onChange={(e) => updateState(e.target.name, e.target.checked)} />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["isWebApi"]} label="Web Api">
                <Form.InputControl control="switch" label="isWebApi" name="isWebApi" checked={formValues.isWebApi} onChange={(e) => updateState(e.target.name, e.target.checked)} />
              </Form.InputGroup>
            </Form.BoxGroup>

            <Form.BoxGroup title="Restrict Login On" column={4} controlwidth="70px" minwidth="111px">
              <Form.InputGroup errorMsg={formValidationError["isIpAllowed"]} label="IP Address">
                <Form.InputControl
                  control="switch"
                  label="IP Address"
                  name="isIpAllowed"
                  checked={formValues.isIpAllowed}
                  disabled={isEditMode ? true : false}
                  onChange={(e) => updateState(e.target.name, e.target.checked)}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["isImeiAllowed"]} label="MAC Address">
                <Form.InputControl
                  control="switch"
                  abel="MAC Address"
                  name="isImeiAllowed"
                  disabled={isEditMode ? true : false}
                  checked={formValues.isImeiAllowed}
                  onChange={(e) => updateState(e.target.name, e.target.checked)}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["isMacAllowed"]} label="IMEI">
                <Form.InputControl
                  control="switch"
                  label="IMEI"
                  name="isMacAllowed"
                  disabled={isEditMode ? true : false}
                  checked={formValues.isMacAllowed}
                  onChange={(e) => updateState(e.target.name, e.target.checked)}
                />
              </Form.InputGroup>

              <Form.InputGroup errorMsg={formValidationError["isMobileAllowed"]} label="Mobile No">
                <Form.InputControl
                  control="switch"
                  label="Mobile No"
                  disabled={isEditMode ? true : false}
                  name="isMobileAllowed"
                  checked={formValues.isMobileAllowed}
                  onChange={(e) => updateState(e.target.name, e.target.checked)}
                />
              </Form.InputGroup>
            </Form.BoxGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button varient="secondary" type="submit" disabled={checkEditFormControlValues()} trigger={false}>
            {isEditMode ? "Update" : saveMore ? "Save More" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCompanyUserPopup;
