/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */

import React, { useState, useEffect } from "react";
import "./UnAssignedCompnay.scss";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { AssignCompanyToUserUser } from "../../../Resource/SupportService";
import PageTitle from "../../../../../Framework/OldFramework/PageTitle/PageTitle";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";

const AssignCompany = (props) => {
  let toggleCompanyUnAssignmentPopup = props.toggleCompanyUnAssignmentPopup;
  let setMsgAlert = props.setMsgAlert;
  let listData = props.listData;

  const [gridApi, setGridApi] = useState(null);

  const [formValidationError, setFormValidationError] = useState({});

  const [unassignedCompanyList, setUnAssignedCompanyList] = useState([]);
  const [isLoadingAssignedCompany, setIsLoadingAssignedCompany] = useState(false);
  const [unassignedFilteredCompanyList, setUnassignedFilteredCompanyList] = useState([]);

  const getunAssignedCompanyData = async (action) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      setIsLoadingAssignedCompany(true);
      let formData = {
        action: action,
        companyMasterId: "",
        userCompanyAssignmentId: listData && listData.BSAppAccessID ? listData.BSAppAccessID.toString() : "",
        appAccessId: listData && listData.BMCAppAccessCode ? listData.BMCAppAccessCode : "",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      const result = await AssignCompanyToUserUser(formData);
      setIsLoadingAssignedCompany(false);
      console.log(result, "Unassigned LIst");
      if (result.responseCode === 1) {
        if (result && result.responseData && result.responseData.length > 0) {
          setUnAssignedCompanyList(result.responseData);
          setUnassignedFilteredCompanyList(result.responseData);
          console.log(result.responseData, "result.responseData");
        } else {
          setUnAssignedCompanyList([]);
          console.log(result.responseMessage);
        }
      }
    } catch (error) {
      setMsgAlert({
        open: true,
        type: "error",
        msg: error.responseData && error.responseData.responseMessage ? error.responseData.responseMessage : CustomMsg.Error,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    getunAssignedCompanyData("GETUNASSIGN");
  }, []);

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    // if (!formValues.txtCompany || typeof formValues.txtCompany === "undefined") {
    //   formIsValid = false;
    //   errors["txtCompany"] = "Select A Company";
    // }
    setFormValidationError(errors);
    return formIsValid;
  };

  const getSelectedRowData = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    return selectedData;
  };

  const handleSave = async (e) => {
    debugger;
    try {
      if (e) e.preventDefault();
      const checkedIds = unassignedCompanyList.filter((row) => row.isChecked).map((row) => row.CompanyMasterID);
      if (checkedIds.length === 0) {
        setMsgAlert({
          open: true,
          type: "warning",
          msg: "Please select Company.",
        });
        return;
      }

      props.setConfirmAlert({
        open: true,
        title: "Assign Company",
        msg: `Are you sure want to Assign Company ?`,
        button: {
          confirmText: "Assign",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(checkedIds),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async (checkedData) => {
    debugger;
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    let companyIds = checkedData.map((e) => e.CompanyMasterID).join(",");
    try {
      setIsLoadingAssignedCompany(true);

      let formData = {
        action: "ASSIGN",
        companyMasterId: checkedData.toString(),
        userCompanyAssignmentId: listData && listData.BSAppAccessID ? listData.BSAppAccessID.toString() : "",
        appAccessId: listData && listData.BMCAppAccessCode ? listData.BMCAppAccessCode : "",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await AssignCompanyToUserUser(formData);
      setIsLoadingAssignedCompany(false);
      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });

        getunAssignedCompanyData("GETUNASSIGN");
        props.getAssignedCompanyData("GETASSIGN");
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const gridCompanyColumnDefs = [
    {
      lockPosition: "1",
      pinned: "left",
      headerName: "Select",
      field: "",
      width: 100,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1" },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 180,
    },
  ];

  const handleRowChange = (event, CompanyMasterID) => {
    const updatedData = unassignedCompanyList.map((row) => {
      if (row.CompanyMasterID === CompanyMasterID) {
        return { ...row, isChecked: event.target.checked };
      }
      return row;
    });
    setUnAssignedCompanyList(updatedData);
  };

  const onSearchUnAssignedEmployees = (val) => {
    if (val) {
      console.log(val);
      const filteredData = unassignedCompanyList.filter((data) => {
        return data.CompanyName.toLowerCase().includes(val.toLowerCase());
      });
      setUnAssignedCompanyList(filteredData);
    } else {
      setUnAssignedCompanyList(unassignedFilteredCompanyList);
    }
  };

  return (
    <>
      <div style={{ display: "grid", gridTemplateRows: "auto" }}>
        <Modal varient={"half"} left={0} title="UnAssigned Companies" onSubmit={handleSave} show={() => toggleCompanyUnAssignmentPopup(null)}>
          <Modal.Body>
            <div>
              <PageTitle>
                <PageBar.Search onChange={(e) => onSearchUnAssignedEmployees(e.target.value)} onClick={() => getunAssignedCompanyData("UNASSIGNED")} />
              </PageTitle>
              <div className="TeamManagement__Data_ContentBox" style={{ width: "100%" }}>
                <table className="TeamManagement__Data_ContentBoxTable">
                  <thead className="TeamManagement__Data_ContentBoxThead">
                    <tr>
                      <th>Action</th>
                      <th>Sr No.</th>
                      <th>User Name</th>
                    </tr>
                  </thead>

                  <tbody>
                    {unassignedCompanyList.map((row, i) => (
                      <>
                        <tr key={i}>
                          <td style={{ textAlign: "center" }}>
                            <input type="checkbox" checked={row.isChecked} onChange={(e) => handleRowChange(e, row.CompanyMasterID)} />
                          </td>
                          <td style={{ textAlign: "center" }}>{i + 1}</td>
                          <td style={{ textAlign: "center" }}>{row.CompanyName}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button varient="secondary" type="submit" trigger={false}>
              Assign
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AssignCompany;
