import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { UpdateTaxData, addTaxData } from "../../Resources/TaxService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddTaxPopup = (props) => {
  const firstTextInput = useRef();
  let toggleaddTaxPopup = props.toggleaddTaxPopup;
  let setMsgAlert = props.setMsgAlert;
  let updateTaxList = props.updateTaxList;
  let _updateListingTaxCategory = props._updateListingTaxCategory;
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtCategoryName: "",
    isActive: false,
    txtTaxRules: "",
    txtTaxType: null,
    txtApplyOnSale: "",
    txtApplyOnSalary: false,
    txtApplyOnPurchase: false,
    txtApplyOnSale: false,
    isWithHold: false,
  });
  const [oldFormValues, setOldFormValues] = useState({
    txtCategoryName: "",
    isActive: false,
    txtTaxRules: "",
    txtTaxType: null,
    txtApplyOnSale: "",
    txtApplyOnSalary: false,
    txtApplyOnPurchase: false,
    txtApplyOnSale: false,
    isWithHold: false,
  });
  const TaxTypeOptions = [
    { Name: "Direct", Value: 1 },
    { Name: "In-Direct", Value: 2 },
  ];

  const validateField = (name, value) => {
    let errorMsg = "";
    if (name === "txtCategoryName" || name === "txtCategoryNameValueField" || "txtCategoryNameDisplayField" || "txtTaxRules" || "txtTaxType" || "txtApplyOnSale") {
      if (!value || typeof value === "undefined") {
        errorMsg = "Cannot be empty";
      }
    }
    return errorMsg;
  };

  const [formValidationError, setFormValidationError] = useState({});
  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtCategoryName"] = validateField("txtCategoryName", formValues.txtCategoryName);
      // errors["txtTaxRules"] = validateField("txtTaxRules", formValues.txtTaxRules);
      // errors["txtTaxType"] = validateField("txtTaxType", formValues.txtTaxType);
      // errors["txtApplyOnSale"] = validateField("txtApplyOnSale", formValues.txtApplyOnSale);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);
      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "error", message: "Something went wrong!" });
      return false;
    }
  };

  useEffect(() => {
    if (isEditMode) {
      if (popupData) {
        setEditBankMaster(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);
  const [editBankMaster, setEditBankMaster] = useState();
  useEffect(() => {
    if (editBankMaster) {
      formValues.txtCategoryName = editBankMaster.TaxCategoryName;
      formValues.txtTaxRules = editBankMaster.TaxRules;
      formValues.txtTaxType = { Name: editBankMaster.TaxType === 1 ? "Direct" : "In-Direct", Value: editBankMaster.TaxType };
      formValues.txtApplyOnSale = editBankMaster.ApplyOnSale && editBankMaster.ApplyOnSale === 1 ? true : false;
      formValues.txtApplyOnSalary = editBankMaster.ApplyONSalary && editBankMaster.ApplyONSalary === 1 ? true : false;
      formValues.txtApplyOnPurchase = editBankMaster.ApplyONPurchase && editBankMaster.ApplyONPurchase === 1 ? true : false;
      formValues.isWithHold = editBankMaster.isWithhold && editBankMaster.isWithhold === 1 ? true : false;
    }
    setFormValues({ ...formValues });
    setOldFormValues({ ...formValues });
  }, [editBankMaster]);

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const handleSave = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to Update" : "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };
  const [saveMore, setSaveMore] = useState(false);
  const onSubmit = async () => {
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;

      const ip = await publicIpv4();
      let formData = {};
      if (isEditMode) {
        formData = {
          TaxCategoryID: popupData && popupData.TaxCategoryID ? popupData.TaxCategoryID : 0,
          TaxCategoryName: formValues.txtCategoryName.toString(),
          TaxType: formValues?.txtTaxType?.Value || 1,
          ApplyOnSale: formValues.txtApplyOnSale ? 1 : 0,
          ApplyONPurchase: formValues.txtApplyOnPurchase ? 1 : 0,
          ApplyONSalary: formValues.txtApplyOnSalary ? 1 : 0,
          IsWithhold: formValues.isWithHold ? 1 : 0,
          TaxRules: formValues?.txtTaxRules || "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          TaxCategoryName: formValues.txtCategoryName.toString(),
          TaxType: formValues?.txtTaxType?.Value || 0,
          ApplyOnSale: formValues.txtApplyOnSale ? 1 : 0,
          ApplyONPurchase: formValues.txtApplyOnPurchase ? 1 : 0,
          ApplyONSalary: formValues.txtApplyOnSalary ? 1 : 0,
          IsWithhold: formValues.isWithHold ? 1 : 0,
          TaxRules: formValues?.txtTaxRules || "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }
      setBtnloaderActive(true);
      let fun = isEditMode ? UpdateTaxData : addTaxData;
      let result = await fun(formData);
      setBtnloaderActive(false);
      debugger;
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        console.log(result.responseData, "result.responseData");
        console.log(result.responseData.data, "result.responseData.data");
        if (result.responseData) {
          let addedCode = {
            IsActive: 1,
            TaxCategoryName: formValues.txtCategoryName.toString(),
            TaxType: formValues?.txtTaxType?.Name || 0,
            ApplyOnSale: formValues.txtApplyOnSale ? 1 : 0,
            ApplyONPurchase: formValues.txtApplyOnPurchase ? 1 : 0,
            ApplyONSalary: formValues.txtApplyOnSalary ? 1 : 0,
            IsWithhold: formValues.isWithHold ? 1 : 0,
            TaxRules: formValues?.txtTaxRules || 0,
            TaxCategoryID: isEditMode
              ? popupData.TaxCategoryID
              : result.responseData && result.responseData.data && result.responseData.data.TaxCategoryID
              ? result.responseData.data.TaxCategoryID
              : 0,
            IsNewlyAdded: true,
          };

          if (isEditMode) {
            _updateListingTaxCategory({ ...addedCode, IsNewlyAdded: false, IsUpdated: true });
            toggleaddTaxPopup(false, null);
          } else {
            setSaveMore(true);
            updateTaxList(addedCode);
          }

          console.log(addedCode, "addedCode");
          clearForm();
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtCategoryName: "",
      isActive: false,
      txtTaxRules: "",
      txtTaxType: null,
      txtApplyOnSale: "",
      isSecondTableCommon: false,
      txtApplyOnSalary: false,
      txtApplyOnPurchase: false,
      txtApplyOnSale: false,
      isWithHold: false,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && popupData) {
      return true;
    }

    return false;
  };

  return (
    <Modal varient={"center"} title={isEditMode ? "Update Tax Category" : "Add Tax Category"} onSubmit={handleSave} show={() => toggleaddTaxPopup(false, null)}>
      <Modal.Body>
        <Form>
          <Form.Group column={2} controlwidth={"180px"}>
            <Form.InputGroup column={3} errorMsg={formValidationError["txtCategoryName"]} label="Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtCategoryName"
                type="text"
                value={formValues.txtCategoryName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                maxLength={100}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtTaxType"]} label="Type" req={false}>
              <Form.InputControl
                control="select"
                name="txtTaxType"
                type="text"
                value={formValues.txtTaxType}
                onChange={(e) => updateState("txtTaxType", e)}
                getOptionLabel={(option) => `${option.Name}`}
                getOptionValue={(option) => `${option}`}
                options={TaxTypeOptions}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtTaxRules"]} label="Tax Rules" req={false}>
              <Form.InputControl
                control="input"
                name="txtTaxRules"
                type="text"
                value={formValues.txtTaxRules}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtApplyOnSale"]} label="Apply On sale" req={true}>
              <Form.InputControl control="switch" name="txtApplyOnSale" checked={formValues.txtApplyOnSale} onChange={(e) => updateState(e.target.name, e.target.checked)} />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtApplyOnPurchase"]} label="Apply On Purchase" req={true}>
              <Form.InputControl
                control="switch"
                name="txtApplyOnPurchase"
                checked={formValues.txtApplyOnPurchase}
                onChange={(e) => updateState(e.target.name, e.target.checked)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtApplyOnSalary"]} label="Apply On Salary" req={true}>
              <Form.InputControl control="switch" name="txtApplyOnSalary" checked={formValues.txtApplyOnSalary} onChange={(e) => updateState(e.target.name, e.target.checked)} />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["isWithHold"]} label="is With Hold" req={true}>
              <Form.InputControl control="switch" name="isWithHold" checked={formValues.isWithHold} onChange={(e) => updateState(e.target.name, e.target.checked)} />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" disabled={checkEditFormControlValues() || btnloaderActive} type="submit" trigger={false}>
          {isEditMode ? "Update" : saveMore ? "Save More" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddTaxPopup;
