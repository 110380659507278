/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BizClass from "../../Tax.module.scss";
import { getCountry } from "../../../GeographicData/Resources/GeographicDataService";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import Button from "../../../../../Framework/OldFramework/Button/Button";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";

import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";
import { publicIpv4 } from "public-ip";
import { createTaxLocalization, getTaxLocalization } from "../../Resources/TaxService";
import DataGrid from "../../../../Layout/Common/DataGrid/DataGrid";

function AddTaxLocalizationUnassigned(props) {
  let toggleImportTaxLocalizationPopup = props.toggleImportTaxLocalizationPopup;
  let setMsgAlert = props.setMsgAlert;
  let updateTaxList = props.updateTaxList;
  let popupData = props.popupData;
  let setConfirmAlert = props.setConfirmAlert;

  const [gridApi, setGridApi] = useState(null);
  const [search, setSearch] = useState("");

  const _onGridReady = (params) => {
    setGridApi(params.api);
  };

  const _onSearch = (val) => {
    setSearch(val);
    if (gridApi) {
      gridApi.setQuickFilter(val);
    }
  };

  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const getCountryList = async () => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingCountry(true);
      const formData = {
        Action: "GETUNASSIGNCOUNTRY",
        TaxCategoryID: popupData.TaxCategoryID,
        CountryMasterID: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getTaxLocalization(formData);
      console.log(result, "result");
      setIsLoadingCountry(false);
      if (result.responseCode === 1) {
        console.log(result);
        if (result && result.responseData && result.responseData.length > 0) {
          if (search && search.toLowerCase().includes("#")) {
            _onSearch("");
          }
          setCountryList(result.responseData);
        } else {
          setCountryList([]);
        }
      } else {
        setCountryList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onclickGetcountryList = () => {
    getCountryList();
  };

  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [btnLoaderActive, setBtnLoaderActive] = useState(false);
  const getSelectedRowData = () => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    console.log(selectedData, "data");
    return selectedData;
  };

  const deleteItem = async (data) => {
    console.log(data, "deletedata");
    try {
      if (gridApi) {
        gridApi.applyTransaction({ remove: [...data] });

        const filterdItem = countryList.filter((x) => String(x.BankMasterID) !== String(data.BankMasterID));
        setCountryList(filterdItem);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onClickDelete = async (data) => {
    console.log(data, "deletedata");
    try {
      if (gridApi) {
        gridApi.applyTransaction({ remove: data }); // data should be an array

        const filteredItems = countryList.filter((x) => !data.some((d) => String(x.CountryMasterID) === String(d.CountryMasterID)));
        setCountryList(filteredItems);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      setBtnLoaderActive(true);
      let checkedItem = getSelectedRowData();
      if (checkedItem.length === 0) {
        setMsgAlert({ open: true, type: "warning", msg: "Please select at least one country." });
        setBtnLoaderActive(false);
        return;
      }
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      const ip = await publicIpv4();
      let CountyMasterIDList = checkedItem.map((e) => e.CountryMasterID).join(",");
      const formData = {
        TaxCategoryID: popupData.TaxCategoryID ? popupData.TaxCategoryID : 0,
        CountyMasterIDs: CountyMasterIDList,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await createTaxLocalization(formData);
      setBtnLoaderActive(false);
      console.log(result);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        console.log(result.responseData, "result.responseData");
        updateTaxList(result.responseData);
        onClickDelete(checkedItem);
      } else if (result.responseCode === "0") {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseData, "result.responseData");
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      setBtnLoaderActive(false);
      console.log(error);
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      let checkedItem = getSelectedRowData();
      if (checkedItem.length === 0) {
        setMsgAlert({ open: true, type: "warning", msg: "Please select atleast one bank." });
        setBtnLoaderActive(false);
        return;
      }
      setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Assign ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const columnDefs = [
    {
      headerName: "",
      field: "#",
      width: 50,
      pinned: "left",
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: false,
      headerCheckboxSelection: false,
    },
    {
      field: "CountryName",
      headerName: "Country Name",
      width: 400,
    },
    {
      field: "AllowState",
      headerName: "Allow State",
      width: 160,
      valueFormatter: (param) => (param.value && param.value === 1 ? "YES" : "NO"),
    },
  ];

  return (
    <>
      <Modal varient="half" width={"40vw"} right={0} index={1} title="Tax Localization Unassigned" onSubmit={handleSave} show={() => toggleImportTaxLocalizationPopup()}>
        <Modal.Body>
          <div className={BizClass.modal_main_section}>
            <PageBar>
              <PageBar.Search focus={true} onChange={(e) => _onSearch(e.target.value)} value={search} onClick={() => onclickGetcountryList()} />
            </PageBar>
            <DataGrid
              rowData={countryList}
              onGridReady={_onGridReady}
              loader={isLoadingCountry ? <Loader /> : null}
              animateRows={true}
              columnDefs={columnDefs}
              rowSelection="multiple"
              suppressRowClickSelection={false}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" varient="secondary" trigger={btnLoaderActive}>
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddTaxLocalizationUnassigned;
