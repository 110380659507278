import React, { useState } from "react";
import BizClass from "./CommonRelation.module.scss";
import { HiArrowCircleRight } from "react-icons/hi";
import { getCommonRelation, getCommonRelationFirstValue } from "./Resources/CommonRelationService";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import classNames from "classnames";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import AddCommonRelationPopup from "./Views/AddCommonRelation/AddCommonRelation";
import AddCommonRelationValue from "./Views/AddCommonRelationValue/AddCommonRelationValue";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";

function CommonRelation() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });

  const [addCommonRelationPopup, setAddCommonRelationPopup] = useState(false);
  const toggleaddCommonRelationPopup = () => {
    setAddCommonRelationPopup(!addCommonRelationPopup);
  };

  const [addCommonRelationValuePopup, setAddCommonRelationValuePopup] = useState(false);
  const toggleaddCommonRelationValuePopup = () => {
    setAddCommonRelationValuePopup(!addCommonRelationValuePopup);
  };
  const [commonRelationList, setCommonRelationList] = useState([]);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const getCommonRelationList = async () => {
    try {
      setIsLoadingStatus(true);
      const result = await getCommonRelation();
      console.log("result", result);
      setIsLoadingStatus(false);
      setFirstValueList([]);

      setSelectedFirstValue(null);
      debugger;
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          if (searchTextCommonRelation && searchTextCommonRelation.toLowerCase().includes("#")) {
            searchCommonRelation("");
          }
          setCommonRelationList(result.responseData);
        } else {
          setCommonRelationList([]);
        }
      } else {
        setCommonRelationList([]);

        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [firstValueList, setFirstValueList] = useState([]);
  const [isLoadingFirstValue, setIsLoadingFirstValue] = useState(false);
  const getFirstValue = async (data) => {
    console.log("getFirstValue", data);
    if (data && data.TableRelationID) {
      try {
        setIsLoadingFirstValue(true);
        const result = await getCommonRelationFirstValue(data.TableRelationID);
        console.log("getFirstValue", result);
        setIsLoadingFirstValue(false);
        debugger;
        if (result.responseCode === 1) {
          if (result && result.responseData.length > 0) {
            setFirstValueList(result.responseData);
            console.log("onClickGetCommonRelation", result.responseData);
          } else {
            setFirstValueList([]);
          }
        } else {
          setFirstValueList([]);
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMsgAlert({
        open: true,
        type: "warning",
        msg: "Please Select CommonRelation",
      });
      return;
    }
  };

  const [selectedCommonRelation, setSelectedCommonRelation] = useState([]);
  const [relationActive, setRelationActive] = useState(false);
  const onClickGetCommonRelation = (data) => {
    console.log("onClickGetCommonRelation", data);
    setRelationActive(true);
    setSelectedCommonRelation(data);
    getFirstValue(data);
    setFirstValueList([]);
  };

  const [selectedFirstValue, setSelectedFirstValue] = useState();
  const onClickSearchRelationMaster = () => {
    console.log(selectedFirstValue, "selectedFirstValue");
    getCommonRelationList();
    setFirstValueList([]);
  };

  const newAddedDataCellStyle = (data) => {
    if (data.data.IsNewlyAdded) {
      return { background: "#ffc176", color: "rgba(52, 73, 94)" };
    }
    return null;
  };

  const [commonRelationGridApi, setCommonRelationGridApi] = useState();
  const OnCommonRelationGridReady = (params) => {
    setCommonRelationGridApi(params.api);
  };

  const [commonValueGridApi, setCommonValueGridApi] = useState();
  const OnCommonRelationValueGridReady = (params) => {
    setCommonValueGridApi(params.api);
  };

  const [searchTextCommonRelation, setSearchTextCommonRelation] = useState("");
  const searchCommonRelation = (val) => {
    setSearchTextCommonRelation(val);
    commonRelationGridApi.setQuickFilter(val);
    commonRelationGridApi.refreshCells();
  };

  const [searchTextCommonRelationValue, setSearchTextCommonRelationValue] = useState("");
  const searchCommonRelationValue = (val) => {
    setSearchTextCommonRelationValue(val);
    commonValueGridApi.setQuickFilter(val);
    commonValueGridApi.refreshCells();
  };

  const updateCommonRelationList = (data) => {
    commonRelationList.unshift(data);
    if (commonRelationGridApi) {
      commonRelationGridApi.setRowData(commonRelationList);
    }
  };

  const updateCommonRelationValueList = (commonValue) => {
    firstValueList.unshift(commonValue);
    setFirstValueList(firstValueList);
    console.log(commonValueGridApi, "commonValueGridApi");
    if (commonValueGridApi) {
      commonValueGridApi.applyTransaction({
        add: [commonValue],
        addIndex: 0,
      });
    }
  };

  const CommonRelationColDef = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellStyle: { newAddedDataCellStyle },
      cellRendererParams: { onClickGetCommonRelation },
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: 1 },
    {
      field: "TableRelationID",
      headerName: "Relation ID",
      width: 150,
      lockPosition: 2,
    },
    {
      field: "FirstTableName",
      headerName: "First Table",
      width: 180,
      lockPosition: 2,
    },
    {
      field: "SecondTableName",
      headerName: "Second Table",
      width: 180,
    },
  ];

  const CommonRelationValueColDef = [
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: 1 },
    {
      field: "CommonMasterValueID",
      headerName: "Value ID",
      width: 150,
      lockPosition: 2,
    },
    {
      field: "CommonMasterValue",
      headerName: "Master Value",
      width: 180,
      lockPosition: 2,
    },
  ];

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    }
    return { background: "" };
  };

  const onClickSearchBar = (data) => {
    if (data) {
      onClickGetCommonRelation(data);
    } else {
      setMsgAlert({ open: true, type: "warning", msg: "Select Module" });
    }
  };
  return (
    <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {addCommonRelationPopup ? (
        <AddCommonRelationPopup
          setMsgAlert={setMsgAlert}
          updateCommonRelationList={updateCommonRelationList}
          setConfirmAlert={setConfirmAlert}
          toggleaddCommonRelationPopup={toggleaddCommonRelationPopup}
        />
      ) : null}
      {addCommonRelationValuePopup ? (
        <AddCommonRelationValue
          setMsgAlert={setMsgAlert}
          updateCommonRelationValueList={updateCommonRelationValueList}
          toggleaddCommonRelationValuePopup={toggleaddCommonRelationValuePopup}
          data={selectedCommonRelation}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      <div className={BizClass.Biz_Bm_Manage_Menu_ContentBox}>
        <Splitter varient="column" template="1fr 9px 1fr">
          <div className={BizClass.MainSection}>
            <PageBar title="Common Relation">
              <PageBar.Search value={searchTextCommonRelation} onClick={() => onClickSearchRelationMaster()} onChange={(e) => searchCommonRelation(e.target.value)} />
              <PageBar.Button onClick={() => toggleaddCommonRelationPopup()}>Add</PageBar.Button>
            </PageBar>
            <DataGrid
              rowData={commonRelationList}
              getRowStyle={getRowStyle}
              onGridReady={OnCommonRelationGridReady}
              columnDefs={CommonRelationColDef}
              onClickGetCommonRelation={onClickGetCommonRelation}
              loader={isLoadingStatus ? <Loader /> : null}
              frameworkComponents={{
                actionTemplate: CommonRelationActionTemplate,
              }}
            />
          </div>
          <div className={BizClass.MainSection}>
            <PageBar title="Common Realtion value">
              {relationActive ? (
                <PageBar>
                  <PageBar.Search
                    value={searchTextCommonRelationValue}
                    onClick={() => onClickSearchBar(selectedCommonRelation)}
                    onChange={(e) => searchCommonRelationValue(e.target.value)}
                  />
                  <PageBar.Button onClick={() => toggleaddCommonRelationValuePopup()}>Add</PageBar.Button>
                </PageBar>
              ) : (
                ""
              )}
            </PageBar>

            <DataGrid
              rowData={firstValueList}
              getRowStyle={getRowStyle}
              columnDefs={CommonRelationValueColDef}
              onGridReady={OnCommonRelationValueGridReady}
              loader={isLoadingFirstValue ? <Loader /> : null}
            />
          </div>
        </Splitter>
        <Footer />
      </div>
    </div>
  );
}

export default CommonRelation;
const CommonRelationActionTemplate = (props) => {
  const onClickCommonRelation = () => {
    props.onClickGetCommonRelation(props.data);
    console.log("props.data", props.data);
  };

  return (
    <React.Fragment>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiArrowCircleRight style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onClickCommonRelation()} />
      </span>
    </React.Fragment>
  );
};
