/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */

import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import PageTitle, { PageSearch } from "../../../../../Framework/OldFramework/PageTitle/PageTitle";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import { getProfileList } from "../../../../Access_Control/ProfileManagement/Resources/ProfileManagementService";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { UserProfileAssign } from "../../../Resources/companyRegistrationService";
import { publicIpv4 } from "public-ip";

const UserProfileAssignPopup = (props) => {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [btnLoaderActive, setBtnLoaderActive] = useState(false);
  let selectedCompanyMaster = props.selectedCompanyMaster;
  let toggleAssignProfile = props.toggleAssignProfile;
  const [userProfileAssignPopupList, setUserProfileAssignPopupList] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingAssignList] = useState(false);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getUserProfileAssignPopupList = async () => {
    try {
      setIsLoadingAssignList(true);
      let result = await getProfileList("Select", 0, selectedCompanyMaster.CompanyMasterID, "#ALL");
      console.log(result, "result");
      setIsLoadingAssignList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setUserProfileAssignPopupList(result.responseData);
        } else {
          setUserProfileAssignPopupList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getUserProfileAssignPopupList();
  }, []);

  const [AddUserProfileAssignPopup, setAddUserProfileAssignPopup] = useState(false);
  const toggleAddDepartMent = () => {
    setAddUserProfileAssignPopup(!AddUserProfileAssignPopup);
  };

  const ProfileGridColumnDefs = [
    {
      lockPosition: "1",
      pinned: "left",
      headerName: "Select",
      field: "",
      width: 100,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      field: "ProfileName",
      headerName: "Profile Name",
      width: 180,
    },
    {
      field: "ProfileDescription",
      headerName: "Profile Description",
    },
  ];
  const getSelectedRowData = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    return selectedData;
  };

  const handleSave = async (e) => {
    debugger;
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      if (e) e.preventDefault();
      const ip = await publicIpv4();
      const checkedItem = getSelectedRowData();
      if (checkedItem && checkedItem.length === 0) {
        setMsgAlert({
          open: true,
          type: "warning",
          msg: "Please select atleast one location.",
        });
        return;
      } else {
        debugger;

        const aasignID = checkedItem.map((data) => data.CompanyAddressID).join(",");
        console.log(props.selectedLocationType, "props.selectedLocationType");
        let formData = {};

        const result = await UserProfileAssign(formData);

        console.log("result", result);

        setBtnLoaderActive(false);
        if (result.responseCode === 1) {
          console.log(result.responseData);

          setMsgAlert({
            open: true,
            type: "success",
            msg: result.responseMessage,
          });
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          return;
        }
      }
    } catch (error) {
      //  setMsgAlert({ open: true, type: 'success', msg: result.responseMessage });
      console.log(error);
    }
  };

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const updateUserProfileAssignPopupList = (department) => {
    userProfileAssignPopupList.unshift(department);
    setUserProfileAssignPopupList(userProfileAssignPopupList);
    if (gridApi) {
      gridApi.setRowData(userProfileAssignPopupList);
    }
  };

  return (
    <Modal onSubmit={handleSave} varient={"bottom"} title="Assign User Profile" show={() => toggleAssignProfile(null)} left={0} width={"49.5vw"} top={0}>
      <Modal.Body>
        <div className="Card">
          <PageTitle Title="User Profile Assign">
            <PageSearch focus={true} onClick={() => getUserProfileAssignPopupList()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
          </PageTitle>
          <DataGrid
            rowData={userProfileAssignPopupList}
            columnDefs={ProfileGridColumnDefs}
            onGridReady={onGridReady}
            loader={isLoadingModuleMasterData ? <Loader /> : null}
            getRowStyle={function (data) {
              if (data.data.IsSelected) {
                return { background: "#ffc176" };
              } else {
                if (data.data.IsNewlyAdded) {
                  return { background: "#d5a10e" };
                }
                return { background: "" };
              }
            }}
            frameworkComponents={{
              actionTemplate: cellTemplate,
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button varient={"secondary"} type="submit" trigger={btnLoaderActive}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserProfileAssignPopup;
const cellTemplate = (props) => {
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View List"
      >
        <FaEdit style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => props.toggleAddDepartMent(props.data)} />
      </span>
    </div>
  );
};
