import React, { useEffect, useState } from "react";
import BizClass from "./PageParameter.module.scss";
import { HiArrowCircleRight, HiOfficeBuilding, HiPencilAlt } from "react-icons/hi";
import { getpageParameter, GetPageParamterValueData, pageParameterCategoryStatusUpdate, pageParameterDefinitionStatusUpdate } from "./Resources/PageParameterService";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import classNames from "classnames";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";

import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { GetpageParamterData } from "./Resources/PageParameterService";
import AddPageParameterValue from "./Views/AddPageParameterValue/AddPageParameterrValue";
import AddPageParameter from "./Views/AddPageParamter/AddPageParameter";
import ParameterAssigned from "./Views/AssignParameter/ParameterAssigned";
import AssignedPage from "./Views/AssignPage/PageAssigned";

function PageParameter() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });

  const [addpageParameterPopup, setAddpageParameterPopup] = useState({
    isOpen: false,
    popupData: null,
    isEditMode: "",
  });
  const toggleaddpageParameterPopup = (isEditMode, data) => {
    setAddpageParameterPopup({
      isOpen: !addpageParameterPopup.isOpen,
      popupData: data,
      isEditMode: isEditMode,
    });
  };

  const [AddpageParameterValuePopup, setAddpageParameterValuePopup] = useState({
    isOpen: false,
    popupData: null,
    isEditMode: "",
  });
  const toggleAddpageParameterValuePopup = (isEditMode, data) => {
    setAddpageParameterValuePopup({
      isOpen: !AddpageParameterValuePopup.isOpen,
      popupData: data,
      isEditMode: isEditMode,
    });
  };
  const [addpageParameterAssignPopup, setAddpageParameterAssignPopup] = useState({
    isOpen: false,
    popupData: null,
  });
  const toggleAddpageParameterAssignPopup = (data) => {
    setAddpageParameterAssignPopup({
      isOpen: !addpageParameterAssignPopup.isOpen,
      popupData: data,
    });
  };
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const [pageParameterList, setpageParameterList] = useState([]);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const getpageParameterList = async () => {
    const ip = await publicIpv4();
    try {
      const formData = {
        PageParameterID: 0,
        SearchBy: searchTextpageParameter,

        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setIsLoadingStatus(true);
      const result = await GetpageParamterData(formData);
      console.log("result", result);
      setIsLoadingStatus(false);
      setParameterValueList([]);
      setSelectedFirstValue(null);
      debugger;
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          if (searchTextpageParameter && searchTextpageParameter.toLowerCase().includes("#")) {
            searchpageParameter("");
          }
          setpageParameterList(result.responseData);
        } else {
          setpageParameterList([]);
        }
      } else {
        setpageParameterList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [ParameterValueList, setParameterValueList] = useState([]);
  const [isLoadingParameterValue, setIsLoadingParameterValue] = useState(false);
  const getParameterValue = async (data) => {
    console.log("getParameterValue", data);
    const ip = await publicIpv4();
    try {
      const formData = {
        PageParameterValueID: 0,
        PageParameterID: data?.PageParameterID || 0,
        SearchBy: searchTextpageParameterValue,

        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setIsLoadingParameterValue(true);
      const result = await GetPageParamterValueData(formData);
      console.log("getParameterValue", result);
      setIsLoadingParameterValue(false);
      debugger;
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          if (searchTextpageParameterValue && searchTextpageParameterValue.toLowerCase().includes("#")) {
            searchpageParameterValue("");
          }
          setParameterValueList(result.responseData);
          console.log("onClickGetpageParameter", result.responseData);
        } else {
          setParameterValueList([]);
        }
      } else {
        setParameterValueList([]);
        setMsgAlert({
          open: true,
          type: "error",
          msg: result.responseMessage,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedpageParameter, setSelectedpageParameter] = useState([]);
  const [relationActive, setRelationActive] = useState(false);
  const onClickGetpageParameter = (data) => {
    console.log("onClickGetpageParameter", data);
    setRelationActive(true);
    setSelectedpageParameter(data);
    getParameterValue(data);
    setParameterValueList([]);
  };

  const [selectedFirstValue, setSelectedFirstValue] = useState();
  const onClickSearchRelationMaster = () => {
    getpageParameterList();
    setParameterValueList([]);
  };

  const newAddedDataCellStyle = (data) => {
    if (data.data.IsNewlyAdded) {
      return { background: "#ffc176", color: "rgba(52, 73, 94)" };
    }
    return null;
  };

  const [pageParameterGridApi, setpageParameterGridApi] = useState();
  const OnpageParameterGridReady = (params) => {
    setpageParameterGridApi(params.api);
  };

  const [pageParameterDefinitionGridApi, setpageParameterDefinitionGridApi] = useState();
  const OnpageParameterDefinitionGridReady = (params) => {
    setpageParameterDefinitionGridApi(params.api);
  };

  const [searchTextpageParameter, setSearchTextpageParameter] = useState("");
  const searchpageParameter = (val) => {
    setSearchTextpageParameter(val);
    pageParameterGridApi.setQuickFilter(val);
    pageParameterGridApi.refreshCells();
  };

  const [searchTextpageParameterValue, setSearchTextpageParameterValue] = useState("");
  const searchpageParameterValue = (val) => {
    setSearchTextpageParameterValue(val);
    pageParameterDefinitionGridApi.setQuickFilter(val);
    pageParameterDefinitionGridApi.refreshCells();
  };

  const updatepageParameterList = (data) => {
    pageParameterList.unshift(data);
    if (pageParameterGridApi) {
      pageParameterGridApi.setRowData(pageParameterList);
    }
  };

  const _updatepageParameterCategoryListing = (record) => {
    console.log(record, "record");
    if (pageParameterGridApi) {
      const itemsToUpdate = [];
      pageParameterGridApi.forEachNode(function (rowNode) {
        if (rowNode.data.BankMasterID === record.BankMasterID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      pageParameterGridApi.applyTransaction({
        update: itemsToUpdate,
      });
      pageParameterGridApi.refreshCells();
    }
    let newData = pageParameterList.map((x) => {
      if (x.BankMasterID === record.BankMasterID) return record;
      return x;
    });
    setpageParameterList([...newData]);
  };

  const updatepageParameterValueList = (commonValue) => {
    ParameterValueList.unshift(commonValue);
    setParameterValueList(ParameterValueList);
    console.log(pageParameterDefinitionGridApi, "pageParameterDefinitionGridApi");
    if (pageParameterDefinitionGridApi) {
      pageParameterDefinitionGridApi.applyTransaction({
        add: [commonValue],
        addIndex: 0,
      });
    }
  };

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    }
    return { background: "" };
  };

  const onClickSearchBar = (data) => {
    if (data) {
      onClickGetpageParameter(data);
    } else {
      setMsgAlert({ open: true, type: "warning", msg: "Select Parameter" });
    }
  };

  // const _updateListingpageParameterCategory = (record) => {
  //   console.log(record, "record");
  //   if (pageParameterGridApi) {
  //     const itemsToUpdate = [];
  //     pageParameterGridApi.forEachNode(function (rowNode) {
  //       if (rowNode.data.pageParameterCategoryID === record.pageParameterCategoryID) {
  //         itemsToUpdate.push(record);
  //         rowNode.setData(record);
  //       }
  //     });
  //     pageParameterGridApi.applyTransaction({
  //       update: itemsToUpdate,
  //     });
  //     pageParameterGridApi.refreshCells();
  //   }
  //   let newData = pageParameterList.map((x) => {
  //     if (x.pageParameterCategoryID === record.pageParameterCategoryID) return record;
  //     return x;
  //   });
  //   setpageParameterList([...newData]);
  // };

  const _updateListingpageParameterDefinition = (record) => {
    console.log(record, "record");
    if (pageParameterDefinitionGridApi) {
      const itemsToUpdate = [];
      pageParameterDefinitionGridApi.forEachNode(function (rowNode) {
        if (rowNode.data.pageParameterDefinitionID === record.pageParameterDefinitionID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      pageParameterDefinitionGridApi.applyTransaction({
        update: itemsToUpdate,
      });
      pageParameterDefinitionGridApi.refreshCells();
    }
    let newData = ParameterValueList.map((x) => {
      if (x.pageParameterDefinitionID === record.pageParameterDefinitionID) return record;
      return x;
    });
    setParameterValueList([...newData]);
  };

  const pageParameterColDef = [
    {
      field: "#",
      headerName: "Action",
      width: 100,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellStyle: { newAddedDataCellStyle },
      cellRendererParams: { onClickGetpageParameter, toggleaddpageParameterPopup, setConfirmAlert },
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: 1 },

    {
      field: "ParameterName",
      headerName: "Name",
      width: 350,
      lockPosition: 2,
    },
  ];

  const pageParameterValueColDef = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellStyle: { newAddedDataCellStyle },
      cellRendererParams: { toggleAddpageParameterValuePopup, setConfirmAlert },
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: 1 },

    {
      field: "ParameterValue",
      headerName: "Value",
      width: 350,
      lockPosition: 2,
    },
    // {
    //   field: "pageParameterDefinitionType",
    //   headerName: "Definition Type",
    //   width: 160,
    //   lockPosition: 2,
    //   valueFormatter: (param) => (param.value && param.value === 1 ? "Percentage" : "Fixed"),
    // },
    // {
    //   field: "pageParameterParameterValue",
    //   headerName: "Definition Value",
    //   width: 160,
    //   lockPosition: 2,
    // },
  ];

  const _updateListingpageParamter = (record) => {
    if (pageParameterGridApi) {
      const itemsToUpdate = [];
      pageParameterGridApi.forEachNode(function (rowNode) {
        if (rowNode.data.PageParameterID === record.PageParameterID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      pageParameterGridApi.applyTransaction({
        update: itemsToUpdate,
      });
      pageParameterGridApi.refreshCells();
    }
    let newData = pageParameterList.map((x) => {
      if (x.PageParameterID === record.PageParameterID) return record;
      return x;
    });
    setpageParameterList([...newData]);
  };

  const _updateListingpageParamterValue = (record) => {
    if (pageParameterDefinitionGridApi) {
      const itemsToUpdate = [];
      pageParameterDefinitionGridApi.forEachNode(function (rowNode) {
        if (rowNode.data.PageParameterValueID === record.PageParameterValueID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      pageParameterDefinitionGridApi.applyTransaction({
        update: itemsToUpdate,
      });
      pageParameterDefinitionGridApi.refreshCells();
    }
    let newData = ParameterValueList.map((x) => {
      if (x.PageParameterValueID === record.PageParameterValueID) return record;
      return x;
    });
    setParameterValueList([...newData]);
  };

  const [importPageUnAssignedPopup, setImportPageUnAssignedPopup] = useState(false);
  const toggleImportPageUnAssignedPopup = () => {
    setImportPageUnAssignedPopup(!importPageUnAssignedPopup);
  };

  return (
    <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {addpageParameterPopup.isOpen ? (
        <AddPageParameter
          popupData={addpageParameterPopup.popupData}
          data={selectedpageParameter}
          setMsgAlert={setMsgAlert}
          updatepageParamterList={updatepageParameterList}
          setConfirmAlert={setConfirmAlert}
          toggleaddpageParameterPopup={toggleaddpageParameterPopup}
          isEditMode={addpageParameterPopup.isEditMode}
          _updateListingpageParamter={_updateListingpageParamter}
        />
      ) : null}
      {AddpageParameterValuePopup.isOpen ? (
        <AddPageParameterValue
          popupData={AddpageParameterValuePopup.popupData}
          setMsgAlert={setMsgAlert}
          updatepageParameterValueList={updatepageParameterValueList}
          toggleAddpageParameterValuePopup={toggleAddpageParameterValuePopup}
          selectedpageParameter={selectedpageParameter}
          setConfirmAlert={setConfirmAlert}
          isEditMode={AddpageParameterValuePopup.isEditMode}
          _updateListingpageParamterValue={_updateListingpageParamterValue}
        />
      ) : null}
      {importPageUnAssignedPopup && (
        <AssignedPage
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
          toggleImportPageUnAssignedPopup={toggleImportPageUnAssignedPopup}
          // updateParameterList={updateParameterList}

          selectedpageParameter={selectedpageParameter}
        />
      )}

      <div className={BizClass.Biz_Bm_Manage_Menu_ContentBox}>
        <Splitter varient="column" template="1fr 9px 1fr">
          <div className={BizClass.MainSection}>
            <PageBar title="Page Paramter">
              <PageBar.Search value={searchTextpageParameter} onClick={() => onClickSearchRelationMaster()} onChange={(e) => searchpageParameter(e.target.value)} />
              <PageBar.Button onClick={() => toggleaddpageParameterPopup(false, null)}>Add</PageBar.Button>
            </PageBar>
            <DataGrid
              rowData={pageParameterList}
              getRowStyle={getRowStyle}
              onGridReady={OnpageParameterGridReady}
              columnDefs={pageParameterColDef}
              loader={isLoadingStatus ? <Loader /> : null}
              frameworkComponents={{
                actionTemplate: pageParameterActionTemplate,
              }}
            />
          </div>
          <div className={BizClass.MainSection}>
            <PageBar title="Page Paramter Value">
              {relationActive ? (
                <PageBar>
                  <PageBar.Search
                    value={searchTextpageParameterValue}
                    onClick={() => onClickSearchBar(selectedpageParameter)}
                    onChange={(e) => searchpageParameterValue(e.target.value)}
                  />
                  <PageBar.Button onClick={() => toggleAddpageParameterValuePopup(false, null)}>Add</PageBar.Button>
                  <PageBar.Button onClick={() => toggleImportPageUnAssignedPopup()}>Assign</PageBar.Button>
                </PageBar>
              ) : (
                ""
              )}
            </PageBar>

            <DataGrid
              rowData={ParameterValueList}
              getRowStyle={getRowStyle}
              columnDefs={pageParameterValueColDef}
              onGridReady={OnpageParameterDefinitionGridReady}
              frameworkComponents={{
                actionTemplate: pageParameterDefinitionActionTemplate,
              }}
              loader={isLoadingParameterValue ? <Loader /> : null}
            />
          </div>
        </Splitter>
        <Footer />
      </div>
    </div>
  );
}

export default PageParameter;

const pageParameterActionTemplate = (props) => {
  const onClickpageParameter = () => {
    props.onClickGetpageParameter(props.data);
    console.log("props.data", props.data);
  };

  const onEditpageParameter = () => {
    props.toggleaddpageParameterPopup(true, props.data);
    console.log("props.data", props.data);
  };

  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "5px" }}>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiArrowCircleRight title="Get Definition" style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onClickpageParameter()} />
      </span>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiPencilAlt title="Edit Category" style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onEditpageParameter()} />
      </span>
    </div>
  );
};

const pageParameterDefinitionActionTemplate = (props) => {
  const onEditpageParameter = () => {
    props.toggleAddpageParameterValuePopup(true, props.data);
    console.log("props.data", props.data);
  };

  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "10px" }}>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiPencilAlt title="Edit Category" style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onEditpageParameter()} />
      </span>
    </div>
  );
};
