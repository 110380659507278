import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Module.module.scss";
import { BiRefresh } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { checkAuthExist } from "../../../APIMethods/Auth/auth";
import { getModuleMaster } from "./Resources/moduleService";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import PageTitle, { PageButton, PageSearch } from "../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, { FooterBox } from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";

function Module_Entity(props) {
  let navigate = useNavigate();
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const [AddformPopupActive, setAddformPopupActive] = useState(false);

  const [moduleMasterData, setModuleMasterData] = useState([]);
  const [filterModuleMasterData, setFilterModuleMasterData] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingModuleMasterData] = useState(false);

  useMemo(async () => {
    if (!checkAuthExist()) {
      navigate("/");
    }
  }, []);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getModuleMasterData = async () => {
    try {
      setIsLoadingModuleMasterData(true);
      let result = await getModuleMaster();
      setIsLoadingModuleMasterData(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setModuleMasterData(result.responseData);
          setFilterModuleMasterData(result.responseData);
        } else {
          setModuleMasterData([]);
          setFilterModuleMasterData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getModuleMasterData();
  }, []);

  const gridColumnDefs = [
    {
      field: "ModuleCode",
      headerName: "Module Code",
      width: 180,
      pinned: "left",
      lockPosition: "1",
    },
    {
      field: "ModuleName",
      headerName: "Module Name",
      width: 150,
      pinned: "left",
    },
    { field: "ModuleDescription", headerName: "Module Description", width: 280 },
  ];

  const onRefreshClick = () => {
    getModuleMasterData();
  };

  useEffect(() => {
    if (msgAlert.open) {
      const timer = setTimeout(() => {
        setMsgAlert({ open: false, type: "", msg: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [msgAlert]);

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const toggleAddformPopupActive = () => {
    if (AddformPopupActive) {
      setAddformPopupActive(false);
    } else {
      setAddformPopupActive(true);
    }
  };

  return (
    <React.Fragment>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      <div className={`Biz_Listing_PageStart`}>
        <PageTitle Title="Module Master">
          <PageSearch focus={true} onClick={() => getModuleMasterData()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
          {/* <PageButton onClick={() => onRefreshClick()}>Refresh</PageButton> */}
          <PageButton onClick={toggleAddformPopupActive}>Add</PageButton>
        </PageTitle>
        <DataGrid
          rowData={filterModuleMasterData}
          columnDefs={gridColumnDefs}
          onGridReady={onGridReady}
          loader={isLoadingModuleMasterData ? <Loader /> : null}
          getRowStyle={function (data) {
            if (data.data.IsSelected) {
              return { background: "#ffc176" };
            } else {
              if (data.data.IsNewlyAdded) {
                return { background: "#d5a10e" };
              }
              return { background: "" };
            }
          }}
        />

        <Footer>
          <FooterBox Aligned={"right"}></FooterBox>
        </Footer>
      </div>
    </React.Fragment>
  );
}
export default Module_Entity;

const AddModulePopup = (props) => {
  let toggleAddformPopupActive = props.toggleAddformPopupActive;
  const [isModalAnimOpen, setModalAnimOpen] = useState(false);

  const toggleModalAnimOpen = () => {
    setModalAnimOpen(true);
    setTimeout(() => {
      setModalAnimOpen(false);
    }, 100);
  };

  const firstTextInput = useRef();

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  return (
    <div
      onClick={toggleModalAnimOpen}
      className={isModalAnimOpen ? "Biz_Bm_Module_Entity_AddPopup_overlay Biz_Bm_Module_Entity_AddEntity_PopupAnimBM" : "Biz_Bm_Module_Entity_AddPopup_overlay"}
    >
      <div className="Biz_Bm_Module_Entity_AddPopup_Box Biz_Bm_Module_Entity_AddPopup_BoxOn">
        <header className="Biz_Bm_Module_Entity_AddPopup_Header">
          <h2>Add New Module</h2>
          <a onClick={toggleAddformPopupActive} className="Biz_Bm_Module_Entity_AddPopup_HeaderCloseBtn">
            <FaTimes className="Biz_Bm_Icon" />
          </a>
        </header>
        <form className="Biz_Bm_Module_Entity_AddPopup_Content">
          <div className="Biz_Bm_Module_Entity_AddPopup_FormContent">
            <div className="Biz_Bm_Module_Entity_AddPopup_FormGroup">
              <label className="Biz_Bm_Module_Entity_AddPopup_Txt">Module Code</label>
              <div className="Biz_Bm_Module_Entity_AddPopup_InputGroup">
                <input ref={firstTextInput} className="Biz_Bm_Module_Entity_AddPopup_Inputbox" />
              </div>
            </div>
            <div className="Biz_Bm_Module_Entity_AddPopup_FormGroup">
              <label className="Biz_Bm_Module_Entity_AddPopup_Txt">Module Name</label>
              <div className="Biz_Bm_Module_Entity_AddPopup_InputGroup">
                <input className="Biz_Bm_Module_Entity_AddPopup_Inputbox" />
              </div>
            </div>
          </div>
          <footer className="Biz_Bm_Module_Entity_AddPopup_Footer">
            <button type="button">Save</button>
            <button type="button" onClick={toggleAddformPopupActive}>
              Cancel
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};
