import React, { useEffect, useState } from "react";
import BizClass from "./Tax.module.scss";
import { HiArrowCircleRight, HiOfficeBuilding, HiPencilAlt } from "react-icons/hi";
import { getTax, getTaxDefinitionValue, taxCategoryStatusUpdate, taxDefinitionStatusUpdate } from "./Resources/TaxService";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import classNames from "classnames";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import AddTaxPopup from "./Views/AddTax/AddTax";
import AddTaxDefinition from "./Views/AddTaxDefinition/AddTaxDefinition";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import AddTaxLocalizationUnassigned from "./Views/AddTaxLocalization/AddTaxLocalizationUnassigned";
import AddTaxLocalizationAssigned from "./Views/AddTaxLocalization/TaxLocalizationAssigned";

function Tax() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });

  const [addTaxPopup, setAddTaxPopup] = useState({
    isOpen: false,
    popupData: null,
    isEditMode: "",
  });
  const toggleaddTaxPopup = (isEditMode, data) => {
    setAddTaxPopup({
      isOpen: !addTaxPopup.isOpen,
      popupData: data,
      isEditMode: isEditMode,
    });
  };

  const [addTaxDefinitionPopup, setAddTaxDefinitionPopup] = useState({
    isOpen: false,
    popupData: null,
    isEditMode: "",
  });
  const toggleAddTaxDefinitionPopup = (isEditMode, data) => {
    setAddTaxDefinitionPopup({
      isOpen: !addTaxDefinitionPopup.isOpen,
      popupData: data,
      isEditMode: isEditMode,
    });
  };
  const [addTaxLocalizatioPopup, setAddTaxLocalizatioPopup] = useState({
    isOpen: false,
    popupData: null,
  });
  const toggleAddTaxLocalizationPopup = (data) => {
    setAddTaxLocalizatioPopup({
      isOpen: !addTaxLocalizatioPopup.isOpen,
      popupData: data,
    });
  };
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const [TaxList, setTaxList] = useState([]);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const getTaxList = async () => {
    const ip = await publicIpv4();
    try {
      const formData = {
        SearchText: "#ALL",
        TaxCategoryID: 0,
        TaxType: 0,

        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setIsLoadingStatus(true);
      const result = await getTax(formData);
      console.log("result", result);
      setIsLoadingStatus(false);
      setDefinitionValueList([]);
      setSelectedFirstValue(null);
      debugger;
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          if (searchTextTax && searchTextTax.toLowerCase().includes("#")) {
            searchTax("");
          }
          setTaxList(result.responseData);
        } else {
          setTaxList([]);
        }
      } else {
        setTaxList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [definitionValueList, setDefinitionValueList] = useState([]);
  const [isLoadingDefinitionValue, setIsLoadingDefinitionValue] = useState(false);
  const getDefinitionValue = async (data) => {
    console.log("getDefinitionValue", data);
    const ip = await publicIpv4();
    try {
      const formData = {
        SearchText: "#ALL",
        TaxDefinitionID: 0,
        TaxCategoryID: data?.TaxCategoryID || 0,
        TaxDefinitionType: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setIsLoadingDefinitionValue(true);
      const result = await getTaxDefinitionValue(formData);
      console.log("getDefinitionValue", result);
      setIsLoadingDefinitionValue(false);
      debugger;
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setDefinitionValueList(result.responseData);
          console.log("onClickGetTax", result.responseData);
        } else {
          setDefinitionValueList([]);
        }
      } else {
        setDefinitionValueList([]);
        setMsgAlert({
          open: true,
          type: "error",
          msg: result.responseMessage,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedTax, setSelectedTax] = useState([]);
  const [relationActive, setRelationActive] = useState(false);
  const onClickGetTax = (data) => {
    console.log("onClickGetTax", data);
    setRelationActive(true);
    setSelectedTax(data);
    getDefinitionValue(data);
    setDefinitionValueList([]);
  };

  const [selectedFirstValue, setSelectedFirstValue] = useState();
  const onClickSearchRelationMaster = () => {
    getTaxList();
    setDefinitionValueList([]);
  };

  const newAddedDataCellStyle = (data) => {
    if (data.data.IsNewlyAdded) {
      return { background: "#ffc176", color: "rgba(52, 73, 94)" };
    }
    return null;
  };

  const [taxGridApi, setTaxGridApi] = useState();
  const OnTaxGridReady = (params) => {
    setTaxGridApi(params.api);
  };

  const [taxDefinitionGridApi, setTaxDefinitionGridApi] = useState();
  const OnTaxDefinitionGridReady = (params) => {
    setTaxDefinitionGridApi(params.api);
  };

  const [searchTextTax, setSearchTextTax] = useState("");
  const searchTax = (val) => {
    setSearchTextTax(val);
    taxGridApi.setQuickFilter(val);
    taxGridApi.refreshCells();
  };

  const [searchTextTaxValue, setSearchTextTaxValue] = useState("");
  const searchTaxValue = (val) => {
    setSearchTextTaxValue(val);
    taxDefinitionGridApi.setQuickFilter(val);
    taxDefinitionGridApi.refreshCells();
  };

  const updateTaxList = (data) => {
    TaxList.unshift(data);
    if (taxGridApi) {
      taxGridApi.setRowData(TaxList);
    }
  };

  const _updateTaxCategoryListing = (record) => {
    console.log(record, "record");
    if (taxGridApi) {
      const itemsToUpdate = [];
      taxGridApi.forEachNode(function (rowNode) {
        if (rowNode.data.BankMasterID === record.BankMasterID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      taxGridApi.applyTransaction({
        update: itemsToUpdate,
      });
      taxGridApi.refreshCells();
    }
    let newData = TaxList.map((x) => {
      if (x.BankMasterID === record.BankMasterID) return record;
      return x;
    });
    setTaxList([...newData]);
  };

  const updateTaxValueList = (commonValue) => {
    definitionValueList.unshift(commonValue);
    setDefinitionValueList(definitionValueList);
    console.log(taxDefinitionGridApi, "taxDefinitionGridApi");
    if (taxDefinitionGridApi) {
      taxDefinitionGridApi.applyTransaction({
        add: [commonValue],
        addIndex: 0,
      });
    }
  };

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    }
    return { background: "" };
  };

  const onClickSearchBar = (data) => {
    if (data) {
      onClickGetTax(data);
    } else {
      setMsgAlert({ open: true, type: "warning", msg: "Select Module" });
    }
  };

  // const _updateListingTaxCategory = (record) => {
  //   console.log(record, "record");
  //   if (taxGridApi) {
  //     const itemsToUpdate = [];
  //     taxGridApi.forEachNode(function (rowNode) {
  //       if (rowNode.data.TaxCategoryID === record.TaxCategoryID) {
  //         itemsToUpdate.push(record);
  //         rowNode.setData(record);
  //       }
  //     });
  //     taxGridApi.applyTransaction({
  //       update: itemsToUpdate,
  //     });
  //     taxGridApi.refreshCells();
  //   }
  //   let newData = TaxList.map((x) => {
  //     if (x.TaxCategoryID === record.TaxCategoryID) return record;
  //     return x;
  //   });
  //   setTaxList([...newData]);
  // };

  const _updateListingTaxCategory = (record) => {
    if (taxGridApi) {
      const itemsToUpdate = [];
      taxGridApi.forEachNode(function (rowNode) {
        if (rowNode.data.TaxCategoryID === record.TaxCategoryID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      taxGridApi.applyTransaction({
        update: itemsToUpdate,
      });
      taxGridApi.refreshCells();
    }
    let newData = TaxList.map((x) => {
      if (x.TaxCategoryID === record.TaxCategoryID) return record;
      return x;
    });
    setTaxList([...newData]);
  };

  const _updateListingTaxDefinition = (record) => {
    console.log(record, "record");
    if (taxDefinitionGridApi) {
      const itemsToUpdate = [];
      taxDefinitionGridApi.forEachNode(function (rowNode) {
        if (rowNode.data.TaxDefinitionID === record.TaxDefinitionID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      taxDefinitionGridApi.applyTransaction({
        update: itemsToUpdate,
      });
      taxDefinitionGridApi.refreshCells();
    }
    let newData = definitionValueList.map((x) => {
      if (x.TaxDefinitionID === record.TaxDefinitionID) return record;
      return x;
    });
    setDefinitionValueList([...newData]);
  };

  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const onTaxCategoryStatusUpdate = async (data, status) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      let formData = {
        TaxCategoryID: data.TaxCategoryID,
        IsActive: status,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await taxCategoryStatusUpdate(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        // onSearchClick();
        let addedDepartment = {
          ...data,
          IsActive: status,
        };
        console.log(addedDepartment, "addedDepartment");
        _updateListingTaxCategory({ ...addedDepartment, IsNewlyAdded: false, IsUpdated: true });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onTaxCategoryDefinitionStatusUpdate = async (data, status) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      let formData = {
        TaxDefinitionID: data.TaxDefinitionID,
        IsActive: status,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await taxDefinitionStatusUpdate(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        let addedDepartment = {
          ...data,
          IsActive: status,
        };
        console.log(addedDepartment, "addedDepartment");
        _updateListingTaxDefinition({ ...addedDepartment, IsNewlyAdded: false, IsUpdated: true });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const TaxColDef = [
    {
      field: "#",
      headerName: "Action",
      width: 100,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellStyle: { newAddedDataCellStyle },
      cellRendererParams: { onClickGetTax, toggleAddTaxLocalizationPopup, toggleaddTaxPopup, onTaxCategoryStatusUpdate, setConfirmAlert },
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: 1 },

    {
      field: "TaxCategoryName",
      headerName: "Name",
      width: 150,
      lockPosition: 2,
    },
    {
      field: "TaxType",
      headerName: "Type",
      width: 120,
      lockPosition: 2,
      valueFormatter: (param) => (param.value && param.value === 1 ? "Direct" : "In-Direct"),
    },
    {
      field: "ApplyOnSale",
      headerName: "Apply On sale",
      valueFormatter: (param) => (param.value && param.value === 1 ? "YES" : "NO"),
      width: 150,
    },
    {
      field: "ApplyONPurchase",
      headerName: "Apply On Purchase",
      valueFormatter: (param) => (param.value && param.value === 1 ? "YES" : "NO"),
      width: 150,
    },
    {
      field: "ApplyONSalary",
      headerName: "Apply On Salary",
      valueFormatter: (param) => (param.value && param.value === 1 ? "YES" : "NO"),
      width: 150,
    },
    {
      field: "TaxRules",
      headerName: "Tax Rules",
      width: 120,
    },

    {
      field: "IsWithhold",
      headerName: "is With Hold",
      width: 120,
      valueFormatter: (param) => (param.value && param.value === 1 ? "YES" : "NO"),
    },
  ];

  const TaxValueColDef = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellStyle: { newAddedDataCellStyle },
      cellRendererParams: { toggleAddTaxDefinitionPopup, onTaxCategoryDefinitionStatusUpdate, setConfirmAlert },
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: 1 },

    {
      field: "TaxDefinitionName",
      headerName: "Definition",
      width: 150,
      lockPosition: 2,
    },
    {
      field: "TaxDefinitionType",
      headerName: "Definition Type",
      width: 160,
      lockPosition: 2,
      valueFormatter: (param) => (param.value && param.value === 1 ? "Percentage" : "Fixed"),
    },
    {
      field: "TaxDefinitionValue",
      headerName: "Definition Value",
      width: 160,
      lockPosition: 2,
    },
  ];

  return (
    <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {addTaxPopup.isOpen ? (
        <AddTaxPopup
          popupData={addTaxPopup.popupData}
          data={selectedTax}
          setMsgAlert={setMsgAlert}
          updateTaxList={updateTaxList}
          setConfirmAlert={setConfirmAlert}
          toggleaddTaxPopup={toggleaddTaxPopup}
          isEditMode={addTaxPopup.isEditMode}
          _updateListingTaxCategory={_updateListingTaxCategory}
        />
      ) : null}
      {addTaxDefinitionPopup.isOpen ? (
        <AddTaxDefinition
          popupData={addTaxDefinitionPopup.popupData}
          setMsgAlert={setMsgAlert}
          updateTaxValueList={updateTaxValueList}
          toggleAddTaxDefinitionPopup={toggleAddTaxDefinitionPopup}
          selectedCategory={selectedTax}
          setConfirmAlert={setConfirmAlert}
          isEditMode={addTaxDefinitionPopup.isEditMode}
          _updateListingTaxDefinition={_updateListingTaxDefinition}
        />
      ) : null}
      {addTaxLocalizatioPopup.isOpen && (
        <AddTaxLocalizationAssigned
          setMsgAlert={setMsgAlert}
          updateTaxList={updateTaxList}
          setConfirmAlert={setConfirmAlert}
          toggleAddTaxLocalizationPopup={toggleAddTaxLocalizationPopup}
          popupData={addTaxLocalizatioPopup.popupData}
          data={selectedTax}
        />
      )}
      <div className={BizClass.Biz_Bm_Manage_Menu_ContentBox}>
        <Splitter varient="column" template="1fr 9px 1fr">
          <div className={BizClass.MainSection}>
            <PageBar title="Tax Category">
              <PageBar.Search value={searchTextTax} onClick={() => onClickSearchRelationMaster()} onChange={(e) => searchTax(e.target.value)} />
              <PageBar.Button onClick={() => toggleaddTaxPopup(false, null)}>Add</PageBar.Button>
            </PageBar>
            <DataGrid
              rowData={TaxList}
              getRowStyle={getRowStyle}
              onGridReady={OnTaxGridReady}
              columnDefs={TaxColDef}
              loader={isLoadingStatus ? <Loader /> : null}
              frameworkComponents={{
                actionTemplate: TaxActionTemplate,
              }}
            />
          </div>
          <div className={BizClass.MainSection}>
            <PageBar title="Tax Definition">
              {relationActive ? (
                <PageBar>
                  <PageBar.Search value={searchTextTaxValue} onClick={() => onClickSearchBar(selectedTax)} onChange={(e) => searchTaxValue(e.target.value)} />
                  <PageBar.Button onClick={() => toggleAddTaxDefinitionPopup(false, null)}>Add</PageBar.Button>
                </PageBar>
              ) : (
                ""
              )}
            </PageBar>

            <DataGrid
              rowData={definitionValueList}
              getRowStyle={getRowStyle}
              columnDefs={TaxValueColDef}
              onGridReady={OnTaxDefinitionGridReady}
              frameworkComponents={{
                actionTemplate: TaxDefinitionActionTemplate,
              }}
              loader={isLoadingDefinitionValue ? <Loader /> : null}
            />
          </div>
        </Splitter>
        <Footer />
      </div>
    </div>
  );
}

export default Tax;
const TaxActionTemplate = (props) => {
  const onClickTax = () => {
    props.onClickGetTax(props.data);
    console.log("props.data", props.data);
  };

  const onEditTax = () => {
    props.toggleaddTaxPopup(true, props.data);
    console.log("props.data", props.data);
  };

  const toggleAddTaxLocalizationPopup = () => {
    props.toggleAddTaxLocalizationPopup(props.data);
  };

  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "5px" }}>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiArrowCircleRight title="Get Definition" style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onClickTax()} />
      </span>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiPencilAlt title="Edit Category" style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onEditTax()} />
      </span>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiOfficeBuilding title="Tax Definition Localization" style={{ fontSize: "16px", color: "#34495E" }} onClick={() => toggleAddTaxLocalizationPopup()} />
      </span>
      <span style={{ cursor: "pointer", display: "flex", paddingTop: "1px", gap: "6px", alignItems: "center" }}>
        {props.data && props.data.IsActive === 1 ? (
          <BsToggleOn
            title="Active"
            onClick={() =>
              props.setConfirmAlert({
                open: true,
                title: "InActive",
                msg: "Are you sure to InActive ?",
                button: { confirmText: "InActive", abortText: "Cancel", Color: "Danger" },
                onConfirm: () => props.onTaxCategoryStatusUpdate(props.data, 0),
              })
            }
            style={{ fontSize: "17px", color: "#4caf50" }}
          />
        ) : props.data && props.data.IsActive === 0 ? (
          <BsToggleOff
            title="InActive"
            onClick={() =>
              props.setConfirmAlert({
                open: true,
                title: "Active",
                msg: "Are you sure to Active ?",
                button: { confirmText: "Active", abortText: "Cancel" },
                onConfirm: () => props.onTaxCategoryStatusUpdate(props.data, 1),
              })
            }
            style={{ fontSize: "17px", color: "#ff0000" }}
          />
        ) : (
          <></>
        )}
      </span>
    </div>
  );
};

const TaxDefinitionActionTemplate = (props) => {
  const onEditTax = () => {
    props.toggleAddTaxDefinitionPopup(true, props.data);
    console.log("props.data", props.data);
  };

  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "10px" }}>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiPencilAlt title="Edit Category" style={{ fontSize: "16px", color: "#34495E" }} onClick={() => onEditTax()} />
      </span>
      <span style={{ cursor: "pointer", display: "flex", paddingTop: "1px", gap: "6px", alignItems: "center" }}>
        {props.data && props.data.IsActive === 1 ? (
          <BsToggleOn
            title="Active"
            onClick={() =>
              props.setConfirmAlert({
                open: true,
                title: "InActive",
                msg: "Are you sure to InActive ?",
                button: { confirmText: "InActive", abortText: "Cancel", Color: "Danger" },
                onConfirm: () => props.onTaxCategoryDefinitionStatusUpdate(props.data, 0),
              })
            }
            style={{ fontSize: "17px", color: "#4caf50" }}
          />
        ) : props.data && props.data.IsActive === 0 ? (
          <BsToggleOff
            title="InActive"
            onClick={() =>
              props.setConfirmAlert({
                open: true,
                title: "Active",
                msg: "Are you sure to Active ?",
                button: { confirmText: "Active", abortText: "Cancel" },
                onConfirm: () => props.onTaxCategoryDefinitionStatusUpdate(props.data, 1),
              })
            }
            style={{ fontSize: "17px", color: "#ff0000" }}
          />
        ) : (
          <></>
        )}
      </span>
    </div>
  );
};
