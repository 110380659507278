import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const GetpageParamterData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.pageParamter.GetpageParamterData);
  return response;
};

export const CreatePageParamterData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.pageParamter.CreatepageParamterData);
  return response;
};

export const UpdatepageParamterData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.pageParamter.UpdatepageParamterData);
  return response;
};

export const GetPageParamterValueData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.pageParamter.GetpageParamterValueData);
  return response;
};

export const CreatepageParamterValuerData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.pageParamter.CreatepageParamterValuerData);
  return response;
};

export const UpdatepageParamterValueData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.pageParamter.UpdatepageParamterValueData);
  return response;
};

export const DeleteAssignedParameter = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.pageParamter.DeleteAssignedParameter);
  return response;
};

export const GetAssignedParameter = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.pageParamter.GetAssignedParameter);
  return response;
};

export const SaveAssignParameter = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.pageParamter.SaveAssignParameter);
  return response;
};
