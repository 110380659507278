import React, { useState, useEffect, useRef } from "react";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { getLoginHistory } from "../../../Resources/companyRegistrationService";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import PageBar1 from "../../../../../Framework/Components/Layout/Pagebar/PageBar1";
import moment from "moment";

const LoginHistoryPopup = (props) => {
  let toggleLoginHistoryPopup = props.toggleLoginHistoryPopup;
  let setMsgAlert = props.setMsgAlert;
  let popupData = props.popupData;

  const [loginHistoryList, setLoginHistoryList] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingLoginDetails] = useState(false);
  const [formValues, setFormValues] = useState({
    txtPage: 0,
    txtPerPage: "",
  });

  const listOptions = [
    { Name: "10", Value: 10 },
    { Name: "20", Value: 20 },
    { Name: "30", Value: 30 },
    { Name: "40", Value: 40 },
    { Name: "50", Value: 50 },
  ];

  const firstTextInput = useRef();
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getLoginDetails = async (data) => {
    try {
      setIsLoadingLoginDetails(true);
      const formData = {
        bmcAppAccessCode: data.BMCAppAccessCode,
        page: 1,
        perPage: formValues.txtPerPage.Value ? formValues.txtPerPage.Value : 10,
      };
      let result = await getLoginHistory(formData);
      console.log(result.responseData.rows, "result.responseData.rows");
      setIsLoadingLoginDetails(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.rows.length > 0) {
          setLoginHistoryList(result.responseData.rows);
          clearForm();
        } else {
          setLoginHistoryList([]);
        }
      } else {
        setIsLoadingLoginDetails(false);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setIsLoadingLoginDetails(false);
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtPage: "",
      txtPerPage: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  const [searchTextModuleMaster, setSearchTextModuleMaster] = useState("");
  const onSearchMasterCommonCode = (val) => {
    setSearchTextModuleMaster(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
    setLoginHistoryList([]);
  };

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    }
    return { background: "" };
  };

  const gridColumnDefs = [
    {
      field: "accessType",
      headerName: "Access Type",
      width: 180,
    },
    {
      field: "dateTime",
      headerName: "Login Time",
      width: 180,
      valueFormatter: (param) => (param && param.data ? (param.value ? moment(param.value).format("DD-MM-YYYY hh:mm A") : "--") : ""),
    },
    {
      field: "applicationName",
      headerName: "Application Name",
      width: 180,
    },
    { field: "ipAddress", headerName: "IP Address", width: 180 },
    { field: "mobile", headerName: "Mobile", width: 180 },
    {
      field: "imei",
      headerName: "IMEI",
      width: 150,
    },

    { field: "macAddress", headerName: "Mac Address", width: 180 },
  ];

  return (
    <Modal varient="center" width="70vw" height="70vh" title="Login History" show={() => toggleLoginHistoryPopup()}>
      <Modal.Body>
        <div style={{ display: "grid", gridTemplateRows: "max-content" }}>
          <PageBar title="">
            <PageBar.Select
              type="text"
              name="txtPerPage"
              width={"180px"}
              getOptionLabel={(option) => `${option.Name}`}
              value={formValues.txtPerPage}
              getOptionValue={(option) => `${option.value}`}
              options={listOptions}
              label="List Size"
              onChange={(e) => updateState("txtPerPage", e)}
            />
            <PageBar1.Search
              width={"80px"}
              focus={true}
              onClick={() => getLoginDetails(popupData)}
              value={searchTextModuleMaster}
              onChange={(e) => onSearchMasterCommonCode(e.target.value)}
            />
          </PageBar>

          <DataGrid
            rowData={loginHistoryList}
            columnDefs={gridColumnDefs}
            onGridReady={onGridReady}
            animateRows={true}
            loader={isLoadingModuleMasterData ? <Loader /> : null}
            getRowStyle={getRowStyle}
          />
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default LoginHistoryPopup;
