import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { addCommonRelationValueData } from "../../Resources/CommonRelationService";
import { publicIpv4 } from "public-ip";

const AddCommonRelationValue = (props) => {
  const firstTextInput = useRef();
  let masterData = props.data;
  let toggleaddCommonRelationValuePopup = props.toggleaddCommonRelationValuePopup;
  let setMsgAlert = props.setMsgAlert;
  let updateCommonRelationValueList = props.updateCommonRelationValueList;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtFirstTableValueField: "",
    txtSecondTableValueField: "",
  });

  const validateField = (name, value) => {
    let errorMsg = "";
    if (
      name === "txtFirstTable" ||
      name === "txtFirstTableValueField" ||
      "txtFirstTableDisplayField" ||
      "txtSecondTable" ||
      "txtSecondTableValueField" ||
      "txtSecondTableDisplayField"
    ) {
      if (!value || typeof value === "undefined") {
        errorMsg = "Cannot be empty";
      }
    }
    return errorMsg;
  };

  const [formValidationError, setFormValidationError] = useState({});
  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtFirstTableValueField"] = validateField("txtFirstTableValueField", formValues.txtFirstTableValueField);
      errors["txtSecondTableValueField"] = validateField("txtSecondTableValueField", formValues.txtSecondTableValueField);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);
      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "error", message: "Something went wrong!" });
      return false;
    }
  };

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const ip = await publicIpv4();
      let formData = {
        tableRelationId: masterData.TableRelationID,
        firstTableFieldId: Number(formValues.txtFirstTableValueField),
        secondTableFieldId: Number(formValues.txtSecondTableValueField),
        displaySequence: 0,
        objCommon: {
          insertUserId: 1,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await addCommonRelationValueData(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData && result.responseData.length > 0) {
          let addedCode = {
            CommonMasterValue: result.responseData.CommonMasterValue,
            CommonMasterValueID: result.responseData.CommonMasterValueID,
            IsNewlyAdded: true,
          };
          updateCommonRelationValueList(addedCode);
          clearForm();
          toggleaddCommonRelationValuePopup();
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtFirstTableValueField: "",
      txtSecondTableValueField: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  return (
    <Modal varient={"center"} title="Add Common Relation Value" onSubmit={onSubmit} show={() => toggleaddCommonRelationValuePopup()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtFirstTableValueField"]} label="First Table Field" req={true}>
              <Form.InputControl
                control="input"
                name="txtFirstTableValueField"
                label="Insert First Table ID"
                type="text"
                value={formValues.txtFirstTableValueField}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtSecondTableValueField"]} label="Second Table Field" req={true}>
              <Form.InputControl
                control="input"
                name="txtSecondTableValueField"
                label="Insert Second Table ID"
                type="text"
                value={formValues.txtSecondTableValueField}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddCommonRelationValue;
