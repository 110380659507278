import React, { useState, useEffect } from "react";
import "./BM_Login.scss";
import Button from "../../../Framework/OldFramework/Button/Button";
import { bmAuthenticate } from "../../../APIMethods/Auth/authAdapter";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { getSessionStorage, setSessionStorage } from "../../../APIMethods/Auth/auth";
import { FaExclamationTriangle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import { publicIpv4 } from "public-ip";
import { Success } from "../../../Configration/Utilities/Constants";
import { getCompanyMasterConfigSelect } from "../../CRM/Resources/companyRegistrationService";
import moment from "moment/moment";

const BMLoginSession = (props) => {
  const setMsgAlert = props.setMsgAlert;
  const toggleAddCompanyUserPopup = props.toggleAddCompanyUserPopup;
  const selectedCompanyMaster = props.selectedCompanyMaster;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [companyCode, setCompanyCode] = useState(selectedCompanyMaster.CompanyConfigCode);
  const [validationMessage, setValidationMessage] = useState("");
  const [uservalidationMessage, setUserValidationMessage] = useState("");
  const [revealPassword, setRevealPassword] = useState(false);

  const togglePassword = () => {
    setRevealPassword(!revealPassword);
  };

  const onSetUserName = (val) => {
    setUserName(val);
    if (val.trim() === "") {
      setUserValidationMessage("UserName is required!");
    } else {
      setUserValidationMessage("");
    }
  };

  const onSetPassword = (val) => {
    setPassword(val);
    if (val.trim() === "") {
      setValidationMessage("Password is required!");
    } else {
      setValidationMessage("");
    }
  };

  const onLogin = async () => {
    debugger;
    try {
      setBtnloaderActive(true);
      const result = await bmAuthenticate(userName, password, companyCode);
      setBtnloaderActive(false);
      console.log(result, "result token");
      if (result.responseCode === 1) {
        if (result.responseData.ApiToken && result.responseData.ApiToken.Token && result.responseData.ApiToken.ExpiryTime) {
          const currentDate = new Date();
          let minutes = 29;
          if (result.responseData.ApiToken.ExpiryTime) {
            minutes = Number(result.responseData.ApiToken.ExpiryTime) - 1;
          }
          debugger;
          const session = {
            AppAccessUID: result?.responseData?.UserData?.UserLoginID || "",
            ClientName: result?.responseData?.Settings?.ClientName || "",
            ClientCode: result?.responseData?.Settings?.ClientCode || "",
            BMCAppAccessCode: result?.responseData?.UserData?.BMCAppAccessCode || "",
            LoginType: "",

            ApplicationURL: result?.responseData?.Settings?.WebURL || "",
            ApiToken: {
              ApiToken: result?.responseData?.ApiToken?.Token || "",
              ExpiryDate: new Date(moment().add(result?.responseData?.ApiToken?.ExpiryTime || 1800, "seconds")),
              ExpirationTime: result?.responseData?.ApiToken?.ExpiryTime || 1800,
            },
            UserData: {
              SessionTime: Math.floor(result?.responseData?.ApiToken?.ExpiryTime || 1800),
              ClientLogo: "../Test/Test.png",
              UserID: result?.responseData?.UserData?.CompanyUserID || "",
              UserDisplayName: result?.responseData?.UserData?.UserDisplayName || "",
              EmployeeCode: result?.responseData?.UserData?.UserRelationID || "",
              ...result?.responseData?.Settings,
            },
            CompanyCode: companyCode,
          };
          setSessionStorage(selectedCompanyMaster.CompanyMasterID, session);
          props.setBMSessionPopupActive(false);
          props._conformGetUserReference("#all");
        } else {
          setMsgAlert({ open: true, type: "error", msg: "Token Missing." });
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: "Login Error!" });
      }
    } catch (error) {
      setBtnloaderActive(false);
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="Biz__SessionTimoutPopup_Overlay Biz__SessionTimoutPopup_OverlayOn"></div>
      <div className="Biz_SessionTimoutPopup Biz_SessionTimoutPopup_On">
        <RiCloseCircleFill className="Biz_SessionTimoutPopupCloseIcon" onClick={() => props.ToggleLoginPopup()} />
        <div className="Biz_SessionTimoutPopup_ImgBox">
          <div>
            <h3 className="Biz_SessionTimoutPopup_HeadTxt">BizNext Identity</h3>
          </div>
        </div>
        <form className="Biz_SessionTimoutPopup_Form">
          <div className="Biz_SessionTimoutPopup_FormGroup">
            <label className="Biz_SessionTimoutPopup_FormGroupLblTxt">Company Code</label>
            <p>
              <span>{companyCode}</span>
            </p>
          </div>
          {/* <div className="Biz_SessionTimoutPopup_FormGroup">
            <label className="Biz_SessionTimoutPopup_FormGroupLblTxt">UserName</label>
            <p>
              <span>{userName}</span>
            </p>
          </div> */}
          <div className={uservalidationMessage ? "Biz_SessionTimoutPopup_FormGroup Sessionlogin__Form_error" : "Biz_SessionTimoutPopup_FormGroup"}>
            <label className="Biz_SessionTimoutPopup_FormGroupLblTxt">UserName</label>
            <div className="Biz_SessionTimoutPopup_FormInpBox">
              <input type="text" placeholder="UserName" onChange={(e) => onSetUserName(e.target.value)} />
            </div>
            {uservalidationMessage ? (
              <div className="Sessionlogin___ErrorDiv">
                <div className="Sessionlogin___ErrorIcon">
                  <FaExclamationTriangle className="Biz__Icon" />
                </div>
                <div className="Sessionlogin___ErrorTxt">{uservalidationMessage}</div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={validationMessage ? "Biz_SessionTimoutPopup_FormGroup Sessionlogin__Form_error" : "Biz_SessionTimoutPopup_FormGroup"}>
            <label className="Biz_SessionTimoutPopup_FormGroupLblTxt">Password</label>
            <div className="Biz_SessionTimoutPopup_FormInpBox">
              <input type={revealPassword ? "text" : "password"} placeholder="Password" onChange={(e) => onSetPassword(e.target.value)} />
              <span onClick={togglePassword}>
                {revealPassword ? <AiFillEyeInvisible className="Biz_SessionTimoutPopup_pass-icon" /> : <AiFillEye className="Biz_SessionTimoutPopup_pass-icon" />}
              </span>
            </div>
            {validationMessage ? (
              <div className="Sessionlogin___ErrorDiv">
                <div className="Sessionlogin___ErrorIcon">
                  <FaExclamationTriangle className="Biz__Icon" />
                </div>
                <div className="Sessionlogin___ErrorTxt">{validationMessage}</div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="Biz_SessionTimoutPopup_FormButtonBox">
            <button
              type="button"
              onClick={() => onLogin()}
              className={btnloaderActive ? "Biz_SessionTimoutPopup_FormButtonBoxLgn FormBtnActive" : "Biz_SessionTimoutPopup_FormButtonBoxLgn"}
            >
              <div className="login__BtnTxt"> Log In </div>
              <div className="login__btn__spinner">
                <div className="login__btn__bounce1"></div>
                <div className="login__btn__bounce2"></div>
                <div className="login__btn__bounce3"></div>
              </div>
            </button>
            {/* <Button varient="secondary" type="button" onClick={() => onLogin()} trigger={btnloaderActive}>
              Log In
            </Button> */}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default BMLoginSession;
