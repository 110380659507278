import React, { useState, useEffect, useRef } from "react";
import BizClass from "../../PageParameter.module.scss";
import ErrorMessage from "../../../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import PageTitle, { PageButton, PageSearch } from "../../../../../Framework/OldFramework/PageTitle/PageTitle";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { HiTrash } from "react-icons/hi";
import PageUnassigned from "./PageUnassigned";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import { DeleteParameterData, GetAssignedParameterData } from "../../../PageMaster/Resources/PageMasterService";

const AssignedPage = (props) => {
  const { selectedpageParameter, formValuesPage, toggleImportPageUnAssignedPopup } = props;
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const [AssignedParameterList, setAssignedParameterList] = useState([]);
  const [isLoadingAssignedParameterList, setIsLoadingAssignedParameterList] = useState(false);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getAssignedParameterList = async (selected) => {
    try {
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      setIsLoadingAssignedParameterList(true);
      const formData = {
        Action: "GETASSIGNPAGE",
        PageParameterID: selected?.PageParameterID || 0,
        BizNextPageID: 0,
        SearchText: searchMappedData ? searchMappedData : "#All",
        ModuleMasterID: 0,
        MenuMasterID: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await GetAssignedParameterData(formData);
      console.log(result, "result");
      setIsLoadingAssignedParameterList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          if (searchMappedData && searchMappedData.toLowerCase().includes("#")) {
            onSearchMappingData("");
          }
          setAssignedParameterList(result.responseData);
        } else {
          setAssignedParameterList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [formValues, setFormValues] = useState({
    txtParameterName: null,
  });
  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
  };

  const [AddAssignedParameterPopup, setAddAssignedParameterPopup] = useState(false);
  const toggleAddAssignedParameter = () => {
    setAddAssignedParameterPopup(!AddAssignedParameterPopup);
  };

  const onClickDelete = async (data) => {
    console.log(data, "deletedata");
    try {
      if (gridApi) {
        gridApi.applyTransaction({ remove: [data] });

        const filterdItem = AssignedParameterList.filter((x) => String(x.PageParameterAssignID) !== String(data.PageParameterAssignID));
        setAssignedParameterList(filterdItem);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onClickDeleteParameter = async (data) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      let formData = {
        PageParameterAssignIDs: data && data.PageParameterAssignID ? String(data.PageParameterAssignID) : "",

        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await DeleteParameterData(formData);

      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        onClickDelete(data);
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const gridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellRendererParams: { onClickDeleteParameter, setConfirmAlert },
    },
    {
      field: "BizNextPageName",
      headerName: "Page Name",
      width: 480,
      pinned: "left",
    },
  ];

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const updateAssignedParameterList = (department) => {
    AssignedParameterList.unshift(department);
    setAssignedParameterList(AssignedParameterList);
    if (gridApi) {
      gridApi.setRowData(AssignedParameterList);
    }
  };
  const [importParameterUnAssignedPopup, setImportParameterUnAssignedPopup] = useState(false);
  const toggleImportParameterUnAssignedPopup = () => {
    setImportParameterUnAssignedPopup(!importParameterUnAssignedPopup);
  };

  const updateParameterList = (data) => {
    setAssignedParameterList(data);
    if (gridApi) {
      gridApi.setRowData(AssignedParameterList);
    }
  };

  useEffect(() => {
    if (selectedpageParameter) {
      getAssignedParameterList(selectedpageParameter);
    }
  }, [selectedpageParameter]);

  const onClickSearchPageMaster = () => {
    getAssignedParameterList(selectedpageParameter);
  };

  return (
    <React.Fragment>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {importParameterUnAssignedPopup && (
        <PageUnassigned
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
          toggleImportParameterUnAssignedPopup={toggleImportParameterUnAssignedPopup}
          updateParameterList={updateParameterList}
          formValues={formValues}
          selectedpageParameter={selectedpageParameter}
          formValuesPage={formValuesPage}
        />
      )}
      <Modal varient="half" left={0} title="Assigned Page List" show={() => toggleImportPageUnAssignedPopup()}>
        <Modal.Body>
          <>
            <div className={BizClass.modal_main_section}>
              <PageTitle Title="Page Parameter Assign">
                <PageSearch focus={true} onClick={() => onClickSearchPageMaster()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
                <PageButton onClick={() => toggleImportParameterUnAssignedPopup()}>Assign</PageButton>
              </PageTitle>
              <DataGrid
                rowData={AssignedParameterList}
                columnDefs={gridColumnDefs}
                onGridReady={onGridReady}
                loader={isLoadingAssignedParameterList ? <Loader /> : null}
                getRowStyle={function (data) {
                  if (data.data.IsSelected) {
                    return { background: "#ffc176" };
                  } else {
                    if (data.data.IsNewlyAdded) {
                      return { background: "#d5a10e" };
                    }
                    return { background: "" };
                  }
                }}
                frameworkComponents={{
                  actionTemplate: cellTemplate,
                }}
              />
            </div>
          </>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AssignedPage;
const cellTemplate = (props) => {
  const onClickDeleteParameter = () => {
    props.onClickDeleteParameter(props.data);
    console.log("props.data", props.data);
  };
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "5px" }}>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiTrash
          title="Delete Parameter"
          style={{ fontSize: "16px", color: "#34495E" }}
          onClick={() =>
            props.setConfirmAlert({
              open: true,
              title: "Delete",
              msg: "Are you sure to delete ?",
              button: {
                confirmText: "Delete",
                abortText: "Cancel",
                Color: "Danger",
              },
              onConfirm: () => onClickDeleteParameter(),
            })
          }
        />
      </span>
    </div>
  );
};
