import React, { useState, useEffect } from "react";
import "./Header.css";
import { useNavigate, Link, NavLink } from "react-router-dom";
import BiznextLogo from "../../../../Framework/Assets/Images_Components/biznext-logo.jsx";
import { logout } from "../Login/Services/authenticateService";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";

function Header(props) {
  let navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState({ __html: "" });
  const [activeMenuId, setActiveMenuId] = useState("0");
  const [activeSubMenuId, setActiveSubMenuId] = useState("0");
  const [userName, setUserName] = useState("");
  const [subMenuList, setSubMenuList] = useState([]);

  useEffect(() => {
    let userData = getSessionStorage("session");
    if (userData && userData.User && userData.User.AppAccessDisplayName) {
      setUserName(userData.User.AppAccessDisplayName);
    } else {
      setUserName("");
    }
    let defaultMenu = MenuList.find((x) => x.menuno === "1");
    if (defaultMenu) {
      setActiveMenuId(defaultMenu.menuno);
      setSubMenuList(defaultMenu.submenu);
    }
  }, []);

  const onLogoutClick = async () => {
    await logout();
    sessionStorage.clear();
    navigate("/");
    props.loginHandler(false);
  };

  const MenuList = [
    {
      menuno: "1",
      menuname: "CRM",
      submenu: [
        {
          submenuno: "1-1",
          name: "Customer Registration",
          url: "/customer_registration",
        },
        {
          submenuno: "1-2",
          name: "Company Registration",
          url: "/company_registration",
        },
      ],
    },
    {
      menuno: "2",
      menuname: "Customer Access",
      submenu: [
        {
          submenuno: "2-1",
          name: "Menu to Company",
          url: "/menu_company",
        },
        {
          submenuno: "2-2",
          name: "Menu to User",
          url: "/menu_user",
        },

        {
          submenuno: "2-3",
          name: "Menu Sequence",
          url: "/",
        },
      ],
    },
    {
      menuno: "3",
      menuname: "Access Control",
      submenu: [
        {
          submenuno: "3-1",
          name: "Module",
          url: "/module",
        },
        {
          submenuno: "3-2",
          name: "Access Rights",
          url: "/RightMaster",
        },
        {
          submenuno: "3-3",
          name: "Menu Management",
          url: "/MenuManagement",
        },
        {
          submenuno: "3-4",
          name: "Profile Management",
          url: "/ProfileManagement",
        },
      ],
    },
    {
      menuno: "4",
      menuname: "Master",
      submenu: [
        {
          submenuno: "4-1",
          name: "Common Master",
          url: "/common_master",
        },
        {
          submenuno: "4-2",
          name: "Common Relation",
          url: "/common_relation",
        },

        {
          submenuno: "4-3",
          name: "Geographic Data",
          url: "/geographic_data",
        },
        {
          submenuno: "4-4",
          name: "Bank Master",
          url: "/bankMaster",
        },

        {
          submenuno: "4-5",
          name: "Tax",
          url: "/tax",
        },
        {
          submenuno: "4-6",
          name: "Page Master",
          url: "/pageMaster",
        },
        {
          submenuno: "4-7",
          name: "Page Parameter",
          url: "/pageParameter",
        },
      ],
    },
    {
      menuno: "5",
      menuname: "HRMS",
      submenu: [
        {
          submenuno: "5-1",
          name: "Division",
          url: "/division",
        },
        {
          submenuno: "5-2",
          name: "Department",
          url: "/department",
        },
        {
          submenuno: "5-3",
          name: "Designation",
          url: "/designation",
        },
      ],
    },

    {
      menuno: "7",
      menuname: "Support",
      submenu: [
        {
          submenuno: "7-1",
          name: "Support Menu Assignment",
          url: "/",
        },
        {
          submenuno: "7-2",
          name: "Support Users",
          url: "/support_users",
        },
        {
          submenuno: "7-3",
          name: "Encryption",
          url: "/encryption",
        },
      ],
    },
    {
      menuno: "8",
      menuname: "Reports",
      submenu: [
        {
          submenuno: "8-1",
          name: "User Login Summary",
          url: "/user_login_summary",
        },
        {
          submenuno: "8-2",
          name: "Api Calling Summary",
          url: "/api-calling-summary",
        },
      ],
    },
  ];

  const toggleMenu = (menu) => {
    if (activeMenuId === menu.menuno) {
      return;
    }
    setActiveMenuId(menu.menuno);
    if (menu.submenu && menu.submenu.length > 0) {
      setSubMenuList(menu.submenu);
    } else {
      setSubMenuList([]);
    }
  };

  const onClickSubMenu = (submenu) => {
    setActiveSubMenuId(submenu.submenuno);
  };

  return (
    <React.Fragment>
      <header className="Biz_Bm_Header">
        <div className="Biz_Bm_Header__MainBar">
          <div className="Header__ClientLogoBar">
            <Link to="/">
              <BiznextLogo className="Biz__Icon" />
            </Link>
          </div>
          <div className="Header__MainMenuBar">
            <ul className="Header__UlModule">
              {MenuList &&
                MenuList.map((data, r) => {
                  return (
                    <li onClick={() => toggleMenu(data)} className={activeMenuId === data.menuno ? "Header_ActiveMMenu" : ""}>
                      <a className="Header__MenuTxt">{data.menuname}</a>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="Header__ProfileBar">
            <button className="Btn_Logout" onClick={() => onLogoutClick()}>
              LogOut
            </button>
          </div>
        </div>
        <div className="Biz_Bm_Header__SubBar">
          <ul className="SubMenu__UlModule">
            {subMenuList && subMenuList.length > 0
              ? subMenuList.map((data, i) => {
                  return (
                    <NavLink
                      onClick={() => onClickSubMenu(data)}
                      key={i}
                      to={data.url}
                      activeClassName={activeSubMenuId === data.submenuno ? "SubHeader_ActiveMMenu" : ""}
                      className="SubHeader_MMenu"
                    >
                      <span className="Header__MenuTxt">{data.name}</span>
                    </NavLink>
                  );
                })
              : null}
          </ul>
        </div>
      </header>
    </React.Fragment>
  );
}
export default Header;
