/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
import React, { useState, useEffect, useRef } from "react";
import "../../Company_Registration.css";
import { FormSelectStyle } from "../../../../../Framework/Components/Widgets/SelectStyle/SelectStyle";
import { FaTimes } from "react-icons/fa";
import { createCompanyConfig, checkConnectionStringValid, getCompanyMasterConfigSelect, companyConfigUpdate } from "../../../Resources/companyRegistrationService";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import { publicIpv4 } from "public-ip";
import Select from "react-select";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddCompanyConfigurationPopup = (props) => {
  let isEditMode = props.isEditMode;
  let IsViewMode = props.IsViewMode;
  let selectedCompanyMaster = props.selectedCompanyMaster;
  let toggleAddCompanyConfigurationPopup = props.toggleAddCompanyConfigurationPopup;
  let setMsgAlert = props.setMsgAlert;
  let selectedConfig = props.selectedConfig;
  console.log("selectedConfig", selectedConfig);
  const [isLoadingEditData, setIsLoadingEditData] = useState(false);
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [editCompanyMasterConfig, setEditCompanyMasterConfig] = useState();
  const [viewCompanyMasterConfig, setViewCompanyMasterConfig] = useState();
  const [formValues, setFormValues] = useState({
    txtCompanyConfigID: 0,
    txtCompanyMasterID: 0,
    txtCompanyConfigCode: "",
    txtConfigConnectionWrite: "",
    txtConfigConnectionRead: "",
    txtConfigAPIURL: "",
    txtConfigApplicationURL: "",
    txtApplicationFTPHost: "",
    txtApplicationFTP: "",
    txtApplicationFTPPassword: "",
    txtWinAppFTPURL: "",
    txtWinAppFTPID: "",
    txtWinAppFTPPassword: "",
    isApplyRights: false,
    isActive: false,
    txtAuthRule: null,
  });
  const [oldFormValues, setOldFormValues] = useState({
    txtCompanyConfigID: 0,
    txtCompanyMasterID: 0,
    txtCompanyConfigCode: "",
    txtConfigConnectionWrite: "",
    txtConfigConnectionRead: "",
    txtConfigAPIURL: "",
    txtConfigApplicationURL: "",
    txtApplicationFTPHost: "",
    txtApplicationFTP: "",
    txtApplicationFTPPassword: "",
    txtWinAppFTPURL: "",
    txtWinAppFTPID: "",
    txtWinAppFTPPassword: "",
    isApplyRights: false,
    isActive: false,
    txtAuthRule: null,
  });
  const [isModalAnimOpen, setModalAnimOpen] = useState(false);
  const toggleModalAnimOpen = () => {
    setModalAnimOpen(true);
    setTimeout(() => {
      setModalAnimOpen(false);
      console.log(viewCompanyMasterConfig);
      console.log(isModalAnimOpen, viewCompanyMasterConfig);
    }, 100);
  };

  useEffect(() => {
    debugger;
    if (!isEditMode) {
    } else {
      setIsLoadingEditData(true);
      if (selectedCompanyMaster) {
        setEditCompanyMasterConfig(props.selectedConfig);
      }
    }
    setIsLoadingEditData(false);
  }, []);

  useEffect(() => {
    debugger;
    if (!IsViewMode) {
    } else {
      setIsLoadingEditData(true);
      if (selectedCompanyMaster) {
        setViewCompanyMasterConfig(props.selectedConfig);
      }
    }
    setIsLoadingEditData(false);
  }, []);

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const getCompanyMasterConfig = async (CompanyMasterID, CompanyConfigID, viewMode) => {
    try {
      debugger;
      let result = await getCompanyMasterConfigSelect(CompanyMasterID, CompanyConfigID, viewMode);
      debugger;
      if (result.responseCode === 1) {
        console.log("getCompanyMasterConfig  result", result);
        debugger;
        if (result.responseData && result.responseData.CompanyConfigResponse && result.responseData.CompanyConfigResponse.length > 0) {
          setEditCompanyMasterConfig(result.responseData.CompanyConfigResponse[0]);
        } else {
          setEditCompanyMasterConfig(null);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const authRuleOptions = [
    { Name: "BMC", Value: "BMC" },
    { Name: "BIZNEXT", Value: "BIZNEXT" },
  ];

  useEffect(() => {
    if (editCompanyMasterConfig && editCompanyMasterConfig.CompanyMasterID) {
      console.log("editCompanyMasterConfig", editCompanyMasterConfig);
      setFormValues({
        txtCompanyConfigID: editCompanyMasterConfig.CompanyConfigID,
        txtCompanyMasterID: selectedCompanyMaster.CompanyMasterID,
        txtCompanyConfigCode: editCompanyMasterConfig.CompanyConfigCode,
        isActive: editCompanyMasterConfig.ActiveStatus === 0 ? false : true,
        isApplyRights: editCompanyMasterConfig.ApplyRights === 0 ? false : true,
        txtConfigConnectionWrite: editCompanyMasterConfig.ConfigConnectionWrite,
        txtConfigConnectionRead: editCompanyMasterConfig.ConfigConnectionRead,
        txtConfigApplicationURL: editCompanyMasterConfig.ConfigApplicationURL,
        txtConfigAPIURL: editCompanyMasterConfig.ConfigAPIURL,
        txtApplicationFTPHost: editCompanyMasterConfig.ApplicationFTPHost,
        txtApplicationFTP: editCompanyMasterConfig.ApplicationFTPUser,
        txtApplicationFTPPassword: editCompanyMasterConfig.ApplicationFTPPassword,
        txtWinAppFTPURL: editCompanyMasterConfig.WinAppFTPURL,
        txtWinAppFTPID: editCompanyMasterConfig.WinAppFTPUserID,
        txtWinAppFTPPassword: editCompanyMasterConfig.WinAppFTPPassword,
      });
      setOldFormValues({
        txtCompanyConfigID: editCompanyMasterConfig.CompanyConfigID,
        txtCompanyMasterID: selectedCompanyMaster.CompanyMasterID,
        txtCompanyConfigCode: editCompanyMasterConfig.CompanyConfigCode,
        isActive: editCompanyMasterConfig.ActiveStatus === 0 ? false : true,
        isApplyRights: editCompanyMasterConfig.ApplyRights === 0 ? false : true,
        txtConfigConnectionWrite: editCompanyMasterConfig.ConfigConnectionWrite,
        txtConfigConnectionRead: editCompanyMasterConfig.ConfigConnectionRead,
        txtConfigApplicationURL: editCompanyMasterConfig.ConfigApplicationURL,
        txtConfigAPIURL: editCompanyMasterConfig.ConfigAPIURL,
        txtApplicationFTPHost: editCompanyMasterConfig.ApplicationFTPHost,
        txtApplicationFTP: editCompanyMasterConfig.ApplicationFTPUser,
        txtApplicationFTPPassword: editCompanyMasterConfig.ApplicationFTPPassword,
        txtWinAppFTPURL: editCompanyMasterConfig.WinAppFTPURL,
        txtWinAppFTPID: editCompanyMasterConfig.WinAppFTPUserID,
        txtWinAppFTPPassword: editCompanyMasterConfig.WinAppFTPPassword,
      });
    }
  }, [editCompanyMasterConfig]);

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && props.selectedConfig) {
      return true;
    }
    return false;
  };

  const [validatingConnectionString, setValidatingConnectionString] = useState({});

  const onValidateConnectionString = async (name) => {
    if (formValues[name] && typeof formValues[name] !== "undefined") {
      try {
        validatingConnectionString[name + "_IsValidating"] = true;
        setValidatingConnectionString({ ...validatingConnectionString });
        let result = await checkConnectionStringValid(formValues[name]);
        validatingConnectionString[name + "_IsValidating"] = false;
        setValidatingConnectionString({ ...validatingConnectionString });
        if (result.responseCode === 1) {
          validatingConnectionString[name + "_Msg"] = "Valid";
          setValidatingConnectionString({ ...validatingConnectionString });
        } else {
          validatingConnectionString[name + "_Msg"] = "Not Valid";
          setValidatingConnectionString({ ...validatingConnectionString });
        }
      } catch (error) {
        setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
        console.log(error);
      }
    } else {
      validatingConnectionString[name + "_Msg"] = "";
      formValidationError[name] = "Please enter connection string.";
      setValidatingConnectionString({ ...validatingConnectionString });
    }
  };
  const validateField = (name, value) => {
    let errorsMsg = "";
    let regex = new RegExp("^[0-9\b]+$");
    if (name === "txtConfigConnectionWrite" || name === "txtConfigConnectionRead") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
        validatingConnectionString[name + "_Msg"] = "";
      }
    } else if (name === "txtCompanyConfigCode" || name === "txtConfigApplicationURL" || name === "txtConfigAPIURL") {
      if (!value || value === null || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    }

    return errorsMsg;
  };
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    //connection tab field validation
    let hasConnectionTabError = false;
    errors["txtConfigConnectionWrite"] = validateField("txtConfigConnectionWrite", formValues.txtConfigConnectionWrite);
    if (errors["txtConfigConnectionWrite"]) {
      hasConnectionTabError = true;
    }
    errors["txtConfigConnectionRead"] = validateField("txtConfigConnectionRead", formValues.txtConfigConnectionRead);
    if (errors["txtConfigConnectionRead"]) {
      hasConnectionTabError = true;
    }
    errors["txtCompanyConfigCode"] = validateField("txtCompanyConfigCode", formValues.txtCompanyConfigCode);
    if (errors["txtCompanyConfigCode"]) {
      hasConnectionTabError = true;
    }
    errors["txtConfigApplicationURL"] = validateField("txtConfigApplicationURL", formValues.txtConfigApplicationURL);
    if (errors["txtConfigApplicationURL"]) {
      hasConnectionTabError = true;
    }

    errors["txtConfigAPIURL"] = validateField("txtConfigAPIURL", formValues.txtConfigAPIURL);
    if (errors["txtConfigAPIURL"]) {
      hasConnectionTabError = true;
    }

    if (Object.values(errors).join("").toString()) {
      formIsValid = false;
    }
    setFormValidationError(errors);
    return formIsValid;
  };
  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    debugger;
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      setBtnloaderActive(true);
      const ip = await publicIpv4();
      let requestData = {};
      if (isEditMode) {
        requestData = {
          companyConfigId: editCompanyMasterConfig.CompanyConfigID,
          companyMasterId: selectedCompanyMaster.CompanyMasterID,
          companyConfigCode: formValues.txtCompanyConfigCode,
          configConnectionWrite: formValues.txtConfigConnectionWrite,
          configConnectionRead: formValues.txtConfigConnectionRead,
          configApplicationUrl: formValues.txtConfigApplicationURL,
          applicationFtpHost: formValues.txtApplicationFTPHost,
          applicationFtpUser: formValues.txtApplicationFTP,
          applicationFtpPassword: formValues.txtApplicationFTPPassword,
          winAppFtpUrl: formValues.txtWinAppFTPURL,
          winAppFtpUserId: formValues.txtWinAppFTPID,
          winAppFtpPassword: formValues.txtWinAppFTPPassword,
          configApiUrl: formValues.txtConfigAPIURL,
          applyRights: formValues.isApplyRights ? 1 : 0,
          activeStatus: formValues.isActive ? 1 : 0,

          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        requestData = {
          companyConfigId: 0,
          companyMasterId: selectedCompanyMaster.CompanyMasterID,
          companyConfigCode: formValues.txtCompanyConfigCode,
          configConnectionWrite: formValues.txtConfigConnectionWrite,
          configConnectionRead: formValues.txtConfigConnectionRead,
          configApplicationUrl: formValues.txtConfigApplicationURL,
          applicationFtpHost: formValues.txtApplicationFTPHost,
          applicationFtpUser: formValues.txtApplicationFTP,
          applicationFtpPassword: formValues.txtApplicationFTPPassword,
          winAppFtpUrl: formValues.txtWinAppFTPURL,
          winAppFtpUserId: formValues.txtWinAppFTPID,
          winAppFtpPassword: formValues.txtWinAppFTPPassword,
          configApiUrl: formValues.txtConfigAPIURL,
          applyRights: formValues.isApplyRights ? 1 : 0,
          activeStatus: formValues.isActive ? 1 : 0,
          authRule: formValues.txtAuthRule && formValues.txtAuthRule.Value ? formValues.txtAuthRule.Value : "",
          objCommon: {
            insertUserId: 1,
            insertIpAddress: ip,
          },
        };
      }

      let fun = isEditMode ? companyConfigUpdate : createCompanyConfig;
      let result = await fun(requestData);
      setBtnloaderActive(false);
      console.log("result", result);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setMsgAlert({
            open: true,
            type: "success",
            msg: result.responseMessage,
          });
          props.getCompanyMasterConfig(selectedCompanyMaster.CompanyMasterID);
          if (!isEditMode) {
            clearForm();
          } else toggleAddCompanyConfigurationPopup(false, null, false);
        }
      } else {
        props.setMsgAlert({
          open: true,
          type: "error",
          msg: result.responseMessage,
        });
      }
    } catch (error) {
      props.setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtCompanyConfigID: 0,
      txtCompanyMasterID: 0,
      txtCompanyConfigCode: "",
      txtConfigConnectionWrite: "",
      txtConfigConnectionRead: "",
      txtConfigAPIURL: "",
      txtConfigApplicationURL: "",
      txtApplicationFTPHost: "",
      txtApplicationFTP: "",
      txtApplicationFTPPassword: "",
      txtWinAppFTPURL: "",
      txtWinAppFTPID: "",
      txtWinAppFTPPassword: "",
      isActive: "YES",
      isApplyRights: "YES",
      txtAuthRule: null,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <React.Fragment>
      <div onClick={toggleModalAnimOpen} className="Biz_Bm__Company_AddPopup"></div>

      <div className="Biz_Bm__Company_AddAddressPopup_Box Biz_Bm__Company_AddPopup_overlay_PopupAnim">
        <header className="Biz_Bm__Company_AddPopup_Header">
          {!IsViewMode ? (
            <>
              <h2>{isEditMode ? "Update Company Configuration" : "Add Company Configuration"}</h2>
              <a onClick={() => toggleAddCompanyConfigurationPopup(false, null, false)} className="Biz_Bm__Company_AddAddressPopup_HeaderCloseBtn">
                <FaTimes className="Biz_Bm_Icon" />
              </a>
            </>
          ) : (
            ""
          )}
        </header>
        <form method="post" onSubmit={(e) => handleSave(e)} className="Biz_Bm__Company_AddUserPopup_Content">
          {isLoadingEditData ? <Loader /> : null}
          <div className="Biz_Bm__Company_AddPopup_FormGroup">
            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Company Config Code</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup Biz_Bm__Company_AddPopup_FullWidthInputGrp">
              <input
                type="text"
                name="txtCompanyConfigCode"
                value={formValues.txtCompanyConfigCode}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Company Config Code"
              />
              <span className="validation_error">{formValidationError["txtCompanyConfigCode"]}</span>
            </div>
            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Connection (Write)</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup Biz_Bm__Company_AddPopup_FullWidthInputGrp">
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  ref={firstTextInput}
                  name="txtConfigConnectionWrite"
                  value={formValues.txtConfigConnectionWrite}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddPopup_Inputbox"
                  placeholder="Connection String"
                />
                {/* <button type="button" className="Company__Validation_btn" onClick={() => onValidateConnectionString("txtConfigConnectionWrite")}>
                  {validatingConnectionString["txtConfigConnectionWrite_IsValidating"] ? "Checking.." : "Check"}
                </button> */}
              </div>
              <span className="validation_error">{formValidationError["txtConfigConnectionWrite"]} </span>
              <span style={validatingConnectionString["txtConfigConnectionWrite_Msg"] === "Valid" ? { color: "green", fontSize: "10px" } : { color: "red", fontSize: "10px" }}>
                {validatingConnectionString["txtConfigConnectionWrite_Msg"]}
              </span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Connection (Read)</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup Biz_Bm__Company_AddPopup_FullWidthInputGrp">
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  name="txtConfigConnectionRead"
                  value={formValues.txtConfigConnectionRead}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddPopup_Inputbox"
                  placeholder="Read Connection String"
                />
                {/* <button type="button" className="Company__Validation_btn" onClick={() => onValidateConnectionString("txtConfigConnectionRead")}>
                  {validatingConnectionString["txtConfigConnectionRead_IsValidating"] ? "Checking.." : "Check"}
                </button> */}
              </div>
              <span className="validation_error">{formValidationError["txtConfigConnectionRead"]} </span>
              <span style={validatingConnectionString["txtConfigConnectionRead_Msg"] === "Valid" ? { color: "green", fontSize: "10px" } : { color: "red", fontSize: "10px" }}>
                {validatingConnectionString["txtConfigConnectionRead_Msg"]}
              </span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">API URL</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup Biz_Bm__Company_AddPopup_FullWidthInputGrp">
              <input
                type="text"
                name="txtConfigAPIURL"
                value={formValues.txtConfigAPIURL}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="API URL"
              />
              <span className="validation_error">{formValidationError["txtConfigAPIURL"]}</span>
            </div>
            <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Application URL</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup Biz_Bm__Company_AddPopup_FullWidthInputGrp">
              <input
                type="text"
                name="txtConfigApplicationURL"
                value={formValues.txtConfigApplicationURL}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Application URL"
              />
              <span className="validation_error">{formValidationError["txtConfigApplicationURL"]}</span>
            </div>
            {!isEditMode ? (
              <>
                <label className="Biz_Bm__Company_AddPopup_LabelTxt Biz_Bm__Company_AddPopup_Req">Auth Rule</label>
                <div className="Biz_Bm__Company_AddPopup_InputGroup Biz_Bm__Company_AddPopup_FullWidthInputGrp">
                  <Select
                    name="txtAuthRule"
                    menuPlacement="auto"
                    isClearable={true}
                    getOptionLabel={(option) => `${option.Name}`}
                    value={formValues.txtAuthRule}
                    getOptionValue={(option) => `${option}`}
                    options={authRuleOptions}
                    onChange={(e) => updateState("txtAuthRule", e)}
                    isSearchable={true}
                    styles={FormSelectStyle}
                    placeholder="Select"
                    noOptionsMessage={() => "No Result Found"}
                  ></Select>
                  <span className="validation_error">{formValidationError["txtAuthRule"]}</span>
                </div>
              </>
            ) : null}

            <label className="Biz_Bm__Company_AddPopup_LabelTxt">FTP Host</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="text"
                name="txtApplicationFTPHost"
                value={formValues.txtApplicationFTPHost}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Application FTP Host"
              />
              <span className="validation_error">{formValidationError["txtApplicationFTPHost"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt">FTP </label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="text"
                name="txtApplicationFTP"
                value={formValues.txtApplicationFTP}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Application FTP User"
              />
              <span className="validation_error">{formValidationError["txtApplicationFTP"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt">FTP Password</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="password"
                name="txtApplicationFTPPassword"
                autoComplete="new-password"
                value={formValues.txtApplicationFTPPassword}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Application FTP Password"
              />
              <span className="validation_error">{formValidationError["txtApplicationFTPPassword"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt">Win App URL</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="text"
                name="txtWinAppFTPURL"
                value={formValues.txtWinAppFTPURL}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Win App FTP URL"
              />
              <span className="validation_error">{formValidationError["txtWinAppFTPURL"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt">Win App ID</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="text"
                name="txtWinAppFTPID"
                value={formValues.txtWinAppFTPID}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Win App FTP  User ID"
              />
              <span className="validation_error">{formValidationError["txtWinAppFTPID"]}</span>
            </div>

            <label className="Biz_Bm__Company_AddPopup_LabelTxt">Win App Pass</label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <input
                type="password"
                name="txtWinAppFTPPassword"
                value={formValues.txtWinAppFTPPassword}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                className="Biz_Bm__Company_AddPopup_Inputbox"
                placeholder="Win App FTP Password"
              />
              <span className="validation_error">{formValidationError["txtWinAppFTPPassword"]}</span>
            </div>
            <label className="Biz_Bm__Company_AddPopup_LabelTxt" htmlFor="isApplyRights">
              Apply Rights
            </label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <label className="Biz_BM__panel_Switch">
                <input
                  disabled={isEditMode}
                  className="Biz_BM__panel_Switch-input"
                  type="checkbox"
                  id="isApplyRights"
                  checked={formValues.isApplyRights}
                  name="isApplyRights"
                  onChange={(e) => updateState(e.target.name, !formValues.isApplyRights)}
                />
                <span className="Biz_BM__panel_Switch-label" data-on="Yes" data-off="No"></span>
                <span className="Biz_BM__panel_Switch-handle"></span>
              </label>
            </div>
            <label className="Biz_Bm__Company_AddPopup_LabelTxt" htmlFor="isActive">
              Is Active
            </label>
            <div className="Biz_Bm__Company_AddPopup_InputGroup">
              <label className="Biz_BM__panel_Switch">
                <input
                  disabled={isEditMode}
                  className="Biz_BM__panel_Switch-input"
                  type="checkbox"
                  id="isActive"
                  checked={formValues.isActive}
                  name="isActive"
                  onChange={(e) => updateState(e.target.name, !formValues.isActive)}
                />
                <span className="Biz_BM__panel_Switch-label" data-on="Yes" data-off="No"></span>
                <span className="Biz_BM__panel_Switch-handle"></span>
              </label>
            </div>
          </div>
          <footer className="Biz_Bm__Company_AddAddressPopup_Footer">
            {!IsViewMode ? (
              <button type="submit" disabled={checkEditFormControlValues() || btnloaderActive} className={btnloaderActive ? "Biz_Bm__Company_AddAddressPopupBtnActive" : ""}>
                <span className="Biz_Bm__Company_AddAddressPopupBtnTxt">{isEditMode ? "Update" : "Save"}</span>
                <div className="Biz_Bm__Company_AddAddressPopupbtn__spinner">
                  <div className="Biz_Bm__Company_AddAddressPopupbtn__bounce1"></div>
                  <div className="Biz_Bm__Company_AddAddressPopupbtn__bounce2"></div>
                  <div className="Biz_Bm__Company_AddAddressPopupbtn__bounce3"></div>
                </div>
              </button>
            ) : (
              ""
            )}

            <button type="button" onClick={() => toggleAddCompanyConfigurationPopup(false, null, false)}>
              {IsViewMode ? "Close" : "Cancel"}
            </button>
          </footer>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddCompanyConfigurationPopup;
