import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { checkAuthExist, getSessionStorage } from "../../../APIMethods/Auth/auth";
import { BankMasterStatusUpdate, GetBankMasterData, divisionData } from "./Resources/BankService";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import PageTitle, { PageButton, PageSearch } from "../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, { FooterBox } from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import AddBankMaster from "./Views/AddBankMaster";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import { getCountry } from "../GeographicData/Resources/GeographicDataService";
import { publicIpv4 } from "public-ip";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

const BankMaster = () => {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const [formValues, setFormValues] = useState({
    txtCountry: null,
  });

  const firstTextInput = useRef();
  const [bankMasterList, setBankMasterList] = useState([]);
  const [isLoadingBankList, setIsLoadingBankList] = useState(false);

  const [gridApi, setgridApi] = useState();
  const onGridReady = (params) => {
    setgridApi(params.api);
  };

  const getBankList = async () => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingBankList(true);
      const formData = {
        CountryMasterID: formValues.txtCountry && formValues.txtCountry.CountryMasterID ? formValues.txtCountry.CountryMasterID : 0,
        SearchBy: "#ALL",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await GetBankMasterData(formData);
      console.log(result, "result");
      setIsLoadingBankList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setBankMasterList(result.responseData);
        } else {
          setBankMasterList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [AddBankMasterPopup, setAddBankMasterPopup] = useState({
    isOpen: false,
    popupData: null,
    IsEditMode: "",
  });

  const toggleAddBankMaster = (isEdit, data) => {
    if (formValues.txtCountry) {
      setAddBankMasterPopup({ isOpen: !AddBankMasterPopup.isOpen, popupData: data, IsEditMode: isEdit });
    } else {
      setMsgAlert({
        open: true,
        type: "warning",
        msg: "Please Select Country!",
      });
    }
  };

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const updateBankMasterList = (data) => {
    setBankMasterList([]);
    bankMasterList.unshift(data);
    setBankMasterList(bankMasterList);
    if (gridApi) {
      gridApi.setRowData(bankMasterList);
    }
  };

  const _updateListing = (record) => {
    console.log(record, "record");
    if (gridApi) {
      console.log(gridApi);
      const itemsToUpdate = [];
      gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.BankMasterID === record.BankMasterID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      gridApi.applyTransaction({
        update: itemsToUpdate,
      });
      gridApi.refreshCells();
    }
    let newData = bankMasterList.map((x) => {
      if (x.BankMasterID === record.BankMasterID) return record;
      return x;
    });
    setBankMasterList([...newData]);
  };

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const onSearchClick = () => {
    if (formValues.txtCountry) {
      getBankList();
    } else {
      firstTextInput.current.focus();
      setMsgAlert({ open: true, type: "warning", msg: "Select Country!" });
    }
  };

  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const getCountryList = async () => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingCountry(true);
      const formData = {
        searchText: "",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getCountry(formData);
      console.log(result, "result");
      setIsLoadingCountry(false);
      if (result.responseCode === 1) {
        console.log(result);
        if (result && result.responseData && result.responseData.length > 0) {
          setCountryList(result.responseData);
        } else {
          setCountryList([]);
        }
      } else {
        setCountryList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const onBankMasterStatusUpdate = async (data, status) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      let formData = {
        BankMasterID: data.BankMasterID,
        IsActive: status,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await BankMasterStatusUpdate(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        // onSearchClick();
        let addedDepartment = {
          ...data,
          isActive: status,
        };
        console.log(addedDepartment, "addedDepartment");
        _updateListing({ ...addedDepartment, IsNewlyAdded: false, IsUpdated: true });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const gridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: "1",
      cellRenderer: "actionTemplate",
      cellRendererParams: { toggleAddBankMaster, onBankMasterStatusUpdate, setConfirmAlert },
    },
    {
      field: "BankMasterName",
      headerName: "Bank Name",
      width: 280,
      pinned: "left",
    },
    { field: "BankShortName", headerName: "Short Name", width: 200 },
    { field: "LogoFileName", headerName: "Logo File Name", width: 200 },
  ];

  return (
    <React.Fragment>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {AddBankMasterPopup.isOpen ? (
        <AddBankMaster
          isEditMode={AddBankMasterPopup.IsEditMode}
          popupData={AddBankMasterPopup.popupData}
          setMsgAlert={setMsgAlert}
          updateBankMasterList={updateBankMasterList}
          toggleAddBankMaster={toggleAddBankMaster}
          _updateListing={_updateListing}
          selectedCountry={formValues.txtCountry}
        />
      ) : null}
      <div className={`Biz_Listing_PageStart`}>
        <PageTitle Title="BankMaster">
          <PageBar.Select
            name="txtCountry"
            getOptionLabel={(option) => `${option.CountryName}`}
            value={formValues.txtCountry}
            getOptionValue={(option) => `${option}`}
            options={countryList}
            onChange={(e) => updateState("txtCountry", e)}
            label="Select Country"
            isLoading={isLoadingCountry}
            isSearchable={true}
            ref={firstTextInput}
          />
          <PageSearch focus={true} onClick={() => onSearchClick()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
          <PageButton onClick={() => toggleAddBankMaster(false, null)}>Add</PageButton>
        </PageTitle>
        <DataGrid
          rowData={bankMasterList}
          columnDefs={gridColumnDefs}
          onGridReady={onGridReady}
          loader={isLoadingBankList ? <Loader /> : null}
          getRowStyle={function (data) {
            if (data.data.IsSelected) {
              return { background: "#ffc176" };
            } else {
              if (data.data.IsNewlyAdded) {
                return { background: "#d5a10e" };
              }
              return { background: "" };
            }
          }}
          frameworkComponents={{
            actionTemplate: cellTemplate,
          }}
        />

        <Footer>
          <FooterBox Aligned={"right"}></FooterBox>
        </Footer>
      </div>
    </React.Fragment>
  );
};

export default BankMaster;
const cellTemplate = (props) => {
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View List"
      >
        <FaEdit style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => props.toggleAddBankMaster(true, props.data)} />
      </span>
      <span style={{ cursor: "pointer", display: "flex", paddingTop: "1px", gap: "6px", alignItems: "center" }}>
        {props.data && props.data.isActive === 1 ? (
          <BsToggleOn
            title="Active"
            onClick={() =>
              props.setConfirmAlert({
                open: true,
                title: "InActive",
                msg: "Are you sure to InActive ?",
                button: { confirmText: "InActive", abortText: "Cancel", Color: "Danger" },
                onConfirm: () => props.onBankMasterStatusUpdate(props.data, 0),
              })
            }
            style={{ fontSize: "17px", color: "#4caf50" }}
          />
        ) : props.data && props.data.isActive === 0 ? (
          <BsToggleOff
            title="InActive"
            onClick={() =>
              props.setConfirmAlert({
                open: true,
                title: "Active",
                msg: "Are you sure to Active ?",
                button: { confirmText: "Active", abortText: "Cancel" },
                onConfirm: () => props.onBankMasterStatusUpdate(props.data, 1),
              })
            }
            style={{ fontSize: "17px", color: "#ff0000" }}
          />
        ) : (
          <></>
        )}
      </span>
    </div>
  );
};
