import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { addCommonRelationData } from "../../Resources/CommonRelationService";
import { publicIpv4 } from "public-ip";

const AddCommonRelationPopup = (props) => {
  const firstTextInput = useRef();
  let toggleaddCommonRelationPopup = props.toggleaddCommonRelationPopup;
  let setMsgAlert = props.setMsgAlert;
  let updateCommonRelationList = props.updateCommonRelationList;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtFirstTable: "",
    txtFirstTableValueField: "",
    txtFirstTableDisplayField: "",
    isFirstTableCommon: false,
    txtSecondTable: "",
    txtSecondTableValueField: "",
    txtSecondTableDisplayField: "",
    isSecondTableCommon: false,
  });

  const validateField = (name, value) => {
    let errorMsg = "";
    if (
      name === "txtFirstTable" ||
      name === "txtFirstTableValueField" ||
      "txtFirstTableDisplayField" ||
      "txtSecondTable" ||
      "txtSecondTableValueField" ||
      "txtSecondTableDisplayField"
    ) {
      if (!value || typeof value === "undefined") {
        errorMsg = "Cannot be empty";
      }
    }
    return errorMsg;
  };

  const [formValidationError, setFormValidationError] = useState({});
  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtFirstTable"] = validateField("txtFirstTable", formValues.txtFirstTable);
      errors["txtFirstTableValueField"] = validateField("txtFirstTableValueField", formValues.txtFirstTableValueField);
      errors["txtFirstTableDisplayField"] = validateField("txtFirstTableDisplayField", formValues.txtFirstTableDisplayField);
      errors["txtSecondTable"] = validateField("txtSecondTable", formValues.txtSecondTable);
      errors["txtSecondTableValueField"] = validateField("txtSecondTableValueField", formValues.txtSecondTableValueField);
      errors["txtSecondTableDisplayField"] = validateField("txtSecondTableDisplayField", formValues.txtSecondTableDisplayField);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);
      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "error", message: "Something went wrong!" });
      return false;
    }
  };

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const handleSave = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    try {
      const ip = await publicIpv4();
      let formData = {
        firstTable: formValues.txtFirstTable.toString(),
        firstTableValueField: formValues.txtFirstTableValueField,
        firstTableDisplayField: formValues.txtFirstTableDisplayField,
        isFirstTableCommon: formValues.isFirstTableCommon ? "YES" : "NO",
        secondTable: formValues.txtSecondTable.toString(),
        secondTableValueField: formValues.txtSecondTableValueField,
        secondTableDisplayField: formValues.txtSecondTableDisplayField,
        isSecondTableCommon: formValues.isSecondTableCommon ? "YES" : "NO",
        objCommon: {
          insertUserId: 1,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await addCommonRelationData(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData && result.responseData.length > 0) {
          let addedCode = {
            TableRelationID: result.responseData.TableRelationID,
            FirstTable: formValues.txtFirstTable,
            FirstTableName: formValues.txtFirstTableDisplayField,
            IsFirstTableCommon: formValues.isFirstTableCommon ? "1" : "0",
            IsSecondTableCommon: formValues.isSecondTableCommon ? "1" : "0",
            SecondTable: formValues.txtSecondTable,
            SecondTableName: formValues.txtSecondTableDisplayField,
            IsNewlyAdded: true,
          };
          updateCommonRelationList(addedCode);
          console.log(updateCommonRelationList, "updateCommonRelationList");
          clearForm();
          toggleaddCommonRelationPopup();
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtFirstTable: "",
      txtFirstTableValueField: "",
      txtFirstTableDisplayField: "",
      isFirstTableCommon: false,
      txtSecondTable: "",
      txtSecondTableValueField: "",
      txtSecondTableDisplayField: "",
      isSecondTableCommon: false,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  return (
    <Modal varient={"center"} title="Add Common Relation" onSubmit={handleSave} show={() => toggleaddCommonRelationPopup()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"520px"}>
            <Form.InputGroup errorMsg={formValidationError["txtFirstTable"]} label="First table" req={true}>
              <Form.InputControl
                control="input"
                name="txtFirstTable"
                type="text"
                value={formValues.txtFirstTable}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtFirstTableValueField"]} label="First Table Value Field" req={true}>
              <Form.InputControl
                control="input"
                name="txtFirstTableValueField"
                type="text"
                value={formValues.txtFirstTableValueField}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtFirstTableDisplayField"]} label="First Table Display Field" req={true}>
              <Form.InputControl
                control="input"
                name="txtFirstTableDisplayField"
                type="text"
                value={formValues.txtFirstTableDisplayField}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["isFirstTableCommon"]} label="Is FirstTable Common" req={true}>
              <Form.InputControl
                control="switch"
                name="isFirstTableCommon"
                checked={formValues.isFirstTableCommon}
                onChange={(e) => updateState(e.target.name, e.target.checked)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtSecondTable"]} label="Second table" req={true}>
              <Form.InputControl
                control="input"
                name="txtSecondTable"
                type="text"
                value={formValues.txtSecondTable}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtSecondTableValueField"]} label="Second Table Value" req={true}>
              <Form.InputControl
                control="input"
                name="txtSecondTableValueField"
                type="text"
                value={formValues.txtSecondTableValueField}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtSecondTableDisplayField"]} label="Second Table Display Field" req={true}>
              <Form.InputControl
                control="input"
                name="txtSecondTableDisplayField"
                type="text"
                value={formValues.txtSecondTableDisplayField}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["isSecondTableCommon"]} label="Is SecondTable Common" req={true}>
              <Form.InputControl
                control="switch"
                name="isSecondTableCommon"
                checked={formValues.isSecondTableCommon}
                onChange={(e) => updateState(e.target.name, e.target.checked)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddCommonRelationPopup;
