import React, { useState, useEffect, useRef } from "react";
import { publicIpv4 } from "public-ip";
import { CreateCity, CreateState, UpdateState } from "../../Resources/GeographicDataService";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../Framework/OldFramework/Button/Button";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";

const AddState = (props) => {
  let toggleAddStatePopup = props.toggleAddStatePopup;
  let setMsgAlert = props.setMsgAlert;
  let updateStateList = props.updateStateList;
  let selectedCountryCode = props.selectedCountryCode;
  let setConfirmAlert = props.setConfirmAlert;
  let _updateListing = props._updateListing;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  const [editState, setEditState] = useState();
  const [formValues, setFormValues] = useState({
    txtStateName: "",
    txtStatecode: "",
    txtIsoCode: "",
  });

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();

  useEffect(() => {
    if (isEditMode) {
      if (popupData) {
        setEditState(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (editState) {
      formValues.txtStateName = editState.StateName;
      formValues.txtStatecode = editState.StateTaxCode;
      formValues.txtIsoCode = editState.ISOCode;
    }
    setFormValues({ ...formValues });
  }, [editState]);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtStateName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter State Name";
      }
    }
    if (name === "txtStatecode") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter State Tax Code";
      }
    }
    if (name === "txtIsoCode") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter ISO Code";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtStateName"] = validateField("txtStateName", formValues.txtStateName);
      errors["txtStatecode"] = validateField("txtStatecode", formValues.txtStatecode);
      errors["txtIsoCode"] = validateField("txtIsoCode", formValues.txtIsoCode);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const onSubmit = async (e) => {
    try {
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      let formData = {};
      if (isEditMode) {
        formData = {
          StateMasterID: popupData && popupData.StateMasterID ? popupData.StateMasterID : 0,
          StateName: formValues.txtStateName ? formValues.txtStateName : "",
          StateTaxCode: formValues.txtStatecode ? formValues.txtStatecode : 0,
          CountryMasterID: selectedCountryCode && selectedCountryCode.CountryMasterID ? selectedCountryCode.CountryMasterID : 0,
          ISOCode: formValues.txtIsoCode ? formValues.txtIsoCode : "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          StateName: formValues.txtStateName ? formValues.txtStateName : "",
          StateTaxCode: formValues.txtStatecode ? formValues.txtStatecode : 0,
          CountryMasterID: selectedCountryCode && selectedCountryCode.CountryMasterID ? selectedCountryCode.CountryMasterID : 0,
          ISOCode: formValues.txtIsoCode ? formValues.txtIsoCode : "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }

      let fun = isEditMode ? UpdateState : CreateState;
      let result = await fun(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          if (result && result.responseData) {
            console.log(result.responseData.data, "result.responseData.data");
            let addedCity = {
              StateName: formValues.txtStateName ? formValues.txtStateName : "",
              StateTaxCode: formValues.txtStatecode && formValues.txtStatecode ? formValues.txtStatecode : 0,
              ISOCode: formValues.txtIsoCode ? formValues.txtIsoCode : 0,
              ActiveStatus: "YES",
              IsNewlyAdded: true,
              StateMasterID: result.responseData.data.StateMasterID ? result.responseData.data.StateMasterID : popupData && popupData.StateMasterID ? popupData.StateMasterID : 0,
            };
            debugger;
            if (isEditMode) {
              _updateListing({ ...addedCity, IsNewlyAdded: false, IsUpdated: true });
            } else {
              updateStateList(addedCity);
            }

            clearForm();
            toggleAddStatePopup(false, null);
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to Update ?" : "Are you sure to Save ?",
        button: {
          confirmText: isEditMode ? "Update" : "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const clearForm = () => {
    setFormValues({
      txtStateName: "",
      txtStatecode: "",
      txtIsoCode: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal
      varient={"center"}
      title={isEditMode ? `Update ${popupData && popupData.StateName ? popupData.StateName : ""}` : "Add New State"}
      onSubmit={handleSave}
      show={() => toggleAddStatePopup(false, null)}
    >
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtStateName"]} label="State Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtStateName"
                type="text"
                value={formValues.txtStateName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="State Name"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtStatecode"]} label="State tax code" req={true}>
              <Form.InputControl
                control="input"
                maxLength="6"
                name="txtStatecode"
                value={formValues.txtStatecode}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
                label="State code"
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtIsoCode"]} label="ISO code" req={true}>
              <Form.InputControl
                control="input"
                maxLength="6"
                name="txtIsoCode"
                value={formValues.txtIsoCode}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="ISO code"
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          {isEditMode ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddState;
