import React, { useEffect, useState } from "react";
import "./UnAssignedUserProfile.scss";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import { publicIpv4 } from "public-ip";
import { getDecryptSessionStorage, getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { manageUserProfileAssign } from "../../Resources/ProfileManagementService";
import PageTitle from "../../../../../Framework/OldFramework/PageTitle/PageTitle";

const UnAssignedUserProfile = (props) => {
  const toggleUnAssignedUserProfilePopup = props.toggleUnAssignedUserProfilePopup;
  const address = props.address;
  const selectedLocationType = props.selectedLocationType;
  const deletedAssignedAddress = props.deletedAssignedAddress;
  const setMsgAlert = props.setMsgAlert;
  const updateAssignedAddressList = props.updateAssignedAddressList;
  const selectedCompanyMaster = props.selectedCompanyMaster;
  const [isLoadingUnAssignedAddress, setIsLoadingUnAssignedAddress] = useState(false);
  const [unassignedUserList, setUnassignedUserList] = useState([]);
  const [unassignedFilteredUserList, setUnassignedFilteredUserList] = useState([]);
  const [isLoadingUnassignedList, setIsLoadingUnassignedList] = useState(false);
  const getUnAssignedUserList = async (data) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      setIsLoadingUnassignedList(true);
      console.log(props.selectedCompanyMaster, "selectedCompanyMaster");
      let formData = {
        userProfileId: data && data.UserProfileID ? data.UserProfileID : 0,
        companyMasterId: props.selectedCompanyMaster.CompanyMasterID ? props.selectedCompanyMaster.CompanyMasterID : 0,
        action: "GETUNASSIGNED",
        profileAssignId: "",
        companyUserId: "",
        userProfileType: "",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await manageUserProfileAssign(formData);
      setIsLoadingUnassignedList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setUnassignedUserList(result.responseData);
          setUnassignedFilteredUserList(result.responseData);
        } else {
          setUnassignedUserList([]);
          setUnassignedFilteredUserList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    if (props.selectedProfileRow) {
      getUnAssignedUserList(props.selectedProfileRow);
    }

    console.log(props.selectedModule, "props.selectedModule,");
  }, []);

  const [formValues, setFormValues] = useState({
    txtAccountingBranchList: "",
  });

  const [gridApi, setGridApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const [searchTextUnAssigendAddress, setSearchTextUnAssigendAddress] = useState("");
  const onSearchUnAssignedAddress = (val) => {
    setSearchTextUnAssigendAddress(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const onSearchUnAssignedEmployees = (val) => {
    if (val !== "") {
      const filteredData = unassignedUserList.filter((data) => {
        return data.UserDisplayName.toLowerCase().includes(val.toLowerCase());
      });
      setUnassignedUserList(filteredData);
    } else {
      setUnassignedUserList(unassignedFilteredUserList);
    }
  };

  const getSelectedRowData = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    return selectedData;
  };

  const [btnLoaderActive, setBtnLoaderActive] = useState(false);

  const handleSave = async (e) => {
    debugger;
    try {
      // const checkedItems = getSelectedRowData();
      const checkedIds = unassignedUserList.filter((row) => row.isChecked).map((row) => row.CompanyUserID);
      if (checkedIds.length === 0) {
        setMsgAlert({
          open: true,
          type: "warning",
          msg: "Please select employee.",
        });
        return;
      }
      if (e) {
        e.preventDefault();
      }

      props.setConfirmAlert({
        open: true,
        title: "Assign User",
        msg: `Are you sure want to Assign User ?`,
        button: {
          confirmText: "Assign",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => _assignUserList(checkedIds),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const _assignUserList = async (checkedData) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    let userIds = checkedData.map((e) => e.CompanyUserID).join(",");

    try {
      // const checkedItems = getSelectedRowData();
      const checkedItems = unassignedUserList.filter((row) => row.isChecked).map((row) => row.CompanyUserID);
      console.log(checkedItems, "checkedItems");
      setIsLoadingUnassignedList(true);
      console.log(props.selectedCompanyMaster, "selectedCompanyMaster");
      let formData = {
        userProfileId: props && props.selectedProfileRow && props.selectedProfileRow.UserProfileID ? props.selectedProfileRow.UserProfileID : 0,
        companyMasterId: selectedCompanyMaster && selectedCompanyMaster.CompanyMasterID ? selectedCompanyMaster.CompanyMasterID : 0,
        action: "ASSIGN",
        profileAssignId: "",
        companyUserId: checkedItems.toString(),
        userProfileType: "D",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await manageUserProfileAssign(formData);
      setIsLoadingUnassignedList(false);
      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        const localUpdate = (data) => {
          debugger;
          if (data && data.ProfileAssignID) {
            let splitAssignId = data.ProfileAssignID.split(",");
            let ProfileAssignUserId = [];
            if (splitAssignId && splitAssignId.length > 0) {
              splitAssignId.forEach((element) => {
                let splitIds = element.split("|");
                if (splitIds[0] && splitIds[1]) {
                  ProfileAssignUserId.push({
                    CompanyUserID: splitIds[0],
                    ProfileAssignID: splitIds[1],
                  });
                }
              });
            }

            if (ProfileAssignUserId && ProfileAssignUserId.length > 0) {
              debugger;
              let newArray = [];
              ProfileAssignUserId.forEach((e) => {
                let findData = checkedData.find((r) => r.CompanyUserID == e.CompanyUserID);
                if (findData) {
                  newArray.push({ ...findData, ...e, userProfileType: "D" });
                }
              });
              if (gridApi) {
                gridApi.updateRowData({
                  remove: [...checkedData],
                });
              }
              props._updateUserList(newArray);
            }
          }
        };
        localUpdate(checkedItems);
        props.getAssignedUserList(props.selectedModule.UserProfileID);
        // const results = unassignedUserList.filter((el) => {
        //   return el.CompanyUserID !== userIds.CompanyUserID;
        // });
        // setUnassignedUserList(results);
        getUnAssignedUserList(props.selectedProfileRow);
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const companyAddressColnDef = [
    {
      lockPosition: "1",
      pinned: "left",
      headerName: "Select",
      field: "",
      width: 100,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      valueGetter: "node.rowIndex + 1",
      field: "#",
      headerName: "Sr No.",
      width: 80,
    },
    {
      field: "UserDisplayName",
      headerName: "Display Name",
      width: 180,
    },
  ];
  const handleRowChange = (event, CompanyUserID) => {
    const updatedData = unassignedUserList.map((row) => {
      if (row.CompanyUserID === CompanyUserID) {
        return { ...row, isChecked: event.target.checked };
      }
      return row;
    });
    setUnassignedUserList(updatedData);
  };
  return (
    <Modal onSubmit={handleSave} varient={"bottom"} title="Un-Assigned User Profile List" show={() => toggleUnAssignedUserProfilePopup()} left={0} width={"49.5vw"} top={0}>
      <Modal.Body>
        {/* <div className="Card">
          <PageBar>
            <PageBar.Search value={searchTextUnAssigendAddress} onChange={(e) => onSearchUnAssignedAddress(e.target.value)} />
          </PageBar>
          <DataGrid
            rowData={unassignedUserList}
            suppressRowClickSelection={true}
            rowSelection="multiple"
            onGridReady={onGridReady}
            loader={isLoadingUnassignedList ? <Loader /> : null}
            columnDefs={companyAddressColnDef}
          />
        </div> */}
        <div>
          <PageTitle>
            <PageBar.Search onChange={(e) => onSearchUnAssignedEmployees(e.target.value)} onClick={() => getUnAssignedUserList(props.selectedProfileRow)} />
          </PageTitle>
          <div className="TeamManagement__Data_ContentBox" style={{ width: "100%" }}>
            <table style={{ overflow: "hidden" }} className="TeamManagement__Data_ContentBoxTable">
              <thead className="TeamManagement__Data_ContentBoxThead">
                <tr>
                  <th>Action</th>
                  <th>Sr No.</th>
                  <th>User Name</th>
                </tr>
              </thead>

              <tbody>
                {unassignedUserList.map((row, i) => (
                  <>
                    <tr key={i}>
                      <td style={{ textAlign: "center" }}>
                        <input type="checkbox" checked={row.isChecked} onChange={(e) => handleRowChange(e, row.CompanyUserID)} />
                      </td>
                      <td style={{ textAlign: "center" }}>{i + 1}</td>
                      <td style={{ textAlign: "center" }}>{row.UserDisplayName}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button varient={"secondary"} type="submit" trigger={btnLoaderActive}>
          Assign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnAssignedUserProfile;
