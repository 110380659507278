import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { addCommonMasterValue } from "../../Resources/CommonMasterService";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddNewCommonValue = (props) => {
  let masterData = props.data;
  let onDisplayClick = props.onDisplayClick;
  let toggleAddNewMasterValuePopupActive = props.toggleAddNewMasterValuePopupActive;
  let setMsgAlert = props.setMsgAlert;
  let updateCommonValueList = props.updateCommonValueList;

  const [btnloaderActive, setBtnloaderActive] = useState(false);

  const [formValues, setFormValues] = useState({
    txtMasterValueName: "",
    txtShortCode: "",
  });

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtMasterValueName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Master Value Name";
      }
    }
    if (name === "txtShortCode") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Short Code";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtMasterValueName"] = validateField("txtMasterValueName", formValues.txtMasterValueName);
      // errors["txtShortCode"] = validateField("txtShortCode", formValues.txtShortCode);

      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const onSubmit = async (e) => {
    debugger;
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      let formData = {
        commonMasterCode: masterData.CommonMasterCode,
        commonMasterValue: formValues.txtMasterValueName,
        commonMasterImageId: 0,
        shortCode: formValues.txtShortCode,
        displaySequence: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await addCommonMasterValue(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        debugger;
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          if (result && result.responseData.length > 0) {
            let addValue = {
              CommonMasterValueID: formValues.txtShortCode,
              CommonMasterValue: formValues.txtMasterValueName,
              HasFixValue: 0,
              IsNewlyAdded: true,
            };
            // updateCommonValueList(addValue);
            onDisplayClick(props.selectedModule);
            clearForm();
            toggleAddNewMasterValuePopupActive();
            // onDisplayClick(masterData);
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtMasterValueName: "",
      txtShortCode: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title="Add New Master" onSubmit={onSubmit} show={() => toggleAddNewMasterValuePopupActive()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtMasterValueName"]} label="Master Value Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtMasterValueName"
                type="text"
                value={formValues.txtMasterValueName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtShortCode"]} label="Short Code" req={false}>
              <Form.InputControl control="input" name="txtShortCode" type="text" value={formValues.txtShortCode} onChange={(e) => updateState(e.target.name, e.target.value)} />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewCommonValue;
