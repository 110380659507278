import React, { useState, useEffect, useRef } from "react";
import { CustomMsg } from "../../../../Configration/Utilities/customMessage";
import Button from "../../../../Framework/Components/Widgets/Button/Button";
import Modal from "../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { UpdatePageVariableData, CreatePageVariableData } from "../Resources/PageVariableService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";

const AddPageVariable = (props) => {
  const firstTextInput = useRef();
  let toggleAddPageVariable = props.toggleAddPageVariable;
  let setMsgAlert = props.setMsgAlert;
  let _updateListingPageVariable = props._updateListingPageVariable;
  let updatePageVariableList = props.updatePageVariableList;
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  let selectedPage = props.selectedPage;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtVariableName: "",
  });
  const [oldFormValues, setOldFormValues] = useState({
    txtVariableName: "",
  });

  const validateField = (name, value) => {
    let errorMsg = "";
    if (name === "txtVariableName" || name === "txtVariableNameValueField" || "txtVariableNameDisplayField" || "txtControlMinLength" || "txtTaxType" || "allowOptional") {
      if (!value || typeof value === "undefined") {
        errorMsg = "Cannot be empty";
      }
    }
    return errorMsg;
  };

  const [formValidationError, setFormValidationError] = useState({});
  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;

      errors["txtVariableName"] = validateField("txtVariableName", formValues.txtVariableName);

      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);
      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "error", message: "Something went wrong!" });
      return false;
    }
  };

  useEffect(() => {
    if (isEditMode) {
      if (popupData) {
        setEditBankMaster(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);
  const [editBankMaster, setEditBankMaster] = useState();
  useEffect(() => {
    if (editBankMaster) {
      formValues.txtVariableName = editBankMaster.PageVariableName;
    }
    setFormValues({ ...formValues });
    setOldFormValues({ ...formValues });
  }, [editBankMaster]);

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const handleSave = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to Update" : "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };
  const [saveMore, setSaveMore] = useState(false);
  const onSubmit = async () => {
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;

      const ip = await publicIpv4();
      let formData = {};
      if (isEditMode) {
        formData = {
          PageVariableID: popupData && popupData.PageVariableID ? popupData.PageVariableID : 0,
          PageVariableName: formValues.txtVariableName,
          BizNextPageID: selectedPage?.BizNextPageID || 0,
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          PageVariableName: formValues.txtVariableName,
          BizNextPageID: selectedPage?.BizNextPageID || 0,
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }
      setBtnloaderActive(true);
      let fun = isEditMode ? UpdatePageVariableData : CreatePageVariableData;
      let result = await fun(formData);
      setBtnloaderActive(false);
      debugger;
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        console.log(result.responseData, "result.responseData");
        console.log(result.responseData.data, "result.responseData.data");
        if (result.responseData) {
          let addedCode = {
            IsActive: 1,
            PageVariableName: formValues.txtVariableName,
            BizNextPageID: selectedPage?.BizNextPageID || 0,
            PageVariableID: isEditMode
              ? popupData.PageVariableID
              : result.responseData && result.responseData.data && result.responseData.data.PageVariableID
              ? result.responseData.data.PageVariableID
              : 0,
            IsNewlyAdded: true,
          };

          if (isEditMode) {
            _updateListingPageVariable({ ...addedCode, IsNewlyAdded: false, IsUpdated: true });
            toggleAddPageVariable(false, null);
          } else {
            setSaveMore(true);
            updatePageVariableList(addedCode);
          }

          console.log(addedCode, "addedCode");
          clearForm();
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtVariableName: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && popupData) {
      return true;
    }

    return false;
  };

  return (
    <Modal varient={"center"} title={isEditMode ? "Update Page Variable" : "Add Page Variable"} onSubmit={handleSave} show={() => toggleAddPageVariable(false, null)}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"280px"}>
            <Form.InputGroup errorMsg={formValidationError["txtVariableName"]} label="Variable Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtVariableName"
                type="text"
                value={formValues.txtVariableName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                maxLength={50}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" disabled={checkEditFormControlValues() || btnloaderActive} type="submit" trigger={false}>
          {isEditMode ? "Update" : saveMore ? "Save More" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddPageVariable;
