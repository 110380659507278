import { publicIpv4 } from "public-ip";
import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

const userData = getSessionStorage("user");

export const getModuleMaster = async () => {
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const ip = await publicIpv4();
  const requestData = {
    moduleCode: 0,
    searchText: "#ALL",
    objCommon: {
      insertUserId: insertUserID,
      insertIpAddress: ip,
    },
  };

  const response = await ApiCalling(requestData, APIEndpoints.Module.GetModuleMaster);
  return response;
};
