import React, { useEffect, useRef, useState } from "react";
import BizClass from "../../ProfileManagement.module.scss";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import "../../../../CRM/Company_Registration//Company_Registration.css";
import { companyProfileRightsAssignOrUnAssigned, companyRightsAssignOrUnAssigned } from "../../../../Customer_Access/MenuToUser/Resources/MenuToUserService";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { getCompanyUserSelect } from "../../../../CRM/Resources/companyRegistrationService";
import ProfileHistory from "../ProfileHistory/ProfileHistory";
import { FaCcMastercard } from "react-icons/fa";

const ManageRightsPopup = (props) => {
  let toggleManageLocationTypePopup = props.toggleManageLocationTypePopup;
  let rightsData = props.rightsData;
  let setMsgAlert = props.setMsgAlert;
  let companyMaster = props.companyMaster;
  let companyUser = props.companyUser;
  let onDisplayClick = props.onDisplayClick;
  let selectedModule = props.selectedModule;
  let setConfirmAlert = props.setConfirmAlert;
  let profileListData = props.profileListData;

  const companyInput = useRef();
  const [isModalAnimOpen, setModalAnimOpen] = useState(false);
  const [isLoadingModuleMasterData, setIsLoadingDeptList] = useState(false);
  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const [formValues, setFormValues] = useState({
    txtCompanyUser: null,
  });

  const [isLoadingRightsList, setIsLoadingRightsList] = useState(false);
  const [rightsListData, setRightsListData] = useState([]);
  const getRightsListData = async (companyUserId, companyMasterId, menuMasterID, viewMode, profileMasterID, rightAssignId) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      setIsLoadingRightsList(true);
      let formData = {
        // rightAssignId: rightAssignId.toString(),
        // companyMasterId: companyMasterId,
        // companyUserId: companyUserId,
        // profileMasterID: profileMasterID,

        viewMode: viewMode,
        profileRightId: 0,
        userProfileId: profileMasterID,
        rightMasterId: 0,
        menuMasterId: menuMasterID,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await companyProfileRightsAssignOrUnAssigned(formData);
      console.log(result);
      setIsLoadingRightsList(false);
      if (result.responseCode === 1) {
        if (result.responseData.length > 0) {
          setRightsListData(result.responseData);
          console.log(result.responseData);
        } else {
          setRightsListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [loginHistoryPopup, setLoginHistoryPopup] = useState({
    IsOpen: false,
    popupData: null,
  });
  const toggleLoginHistoryPopup = (data) => {
    setLoginHistoryPopup({
      IsOpen: !loginHistoryPopup.IsOpen,
      popupData: data,
    });
  };

  useEffect(() => {
    if (rightsData) {
      getRightsListData(0, props.selectedCompanyMaster.CompanyMasterID, rightsData.MenuMasterID, "GETPROFILERIGHT", profileListData.UserProfileID, "");
    }
  }, [rightsData]);

  const rightsAssignedOrUnAssigned = async (companyUserId, companyMasterId, rightAssignId, rightMasterId, viewMode, profileMasterID, menuMasterID) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      let formData = {
        // viewMode: viewMode,
        // rightAssignId: rightAssignId ? rightAssignId : "",
        // companyMasterId: companyMasterId,
        // companyUserId: companyUserId,
        // rightMasterId: rightMasterId,
        // profileMasterID: profileMasterID ? profileMasterID : 0,
        // menuMasterID: menuMasterID,
        // companyMasterId: companyMasterId,
        viewMode: viewMode,
        profileRightId: rightAssignId,
        userProfileId: profileMasterID,
        rightMasterId: rightMasterId,
        menuMasterId: menuMasterID,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await companyProfileRightsAssignOrUnAssigned(formData);
      console.log(result);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          if (gridApi) {
            gridApi.forEachNode(function (rowNode) {
              if (rowNode.data.MenuMasterID === rightsData.MenuMasterID) {
                if (viewMode === "ASSIGN" && result.responseData && result.responseData[0].CompanyUserMenuID) {
                  rowNode.data.CompanyUserMenuID = result.responseData[0].CompanyUserMenuID;
                  rowNode.data.AssignmentFlag = 1;
                } else if (viewMode === "UNASSIGN") {
                  rowNode.data.CompanyUserMenuID = null;
                  rowNode.data.AssignmentFlag = 0;
                }
                rowNode.setData(rowNode.data);
              }
            });
          }
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onAssignMenu = (menu) => {
    debugger;
    console.log(rightsData, "rightsData");
    console.log(profileListData, "profileListData");
    if (props.selectedCompanyMaster.CompanyMasterID) {
      rightsAssignedOrUnAssigned(0, props.selectedCompanyMaster.CompanyMasterID, 0, menu.RightMasterID, "PASSIGN", profileListData.UserProfileID, rightsData.MenuMasterID, "");
      getRightsListData(profileListData.UserProfileID, props.selectedCompanyMaster.CompanyMasterID, rightsData.MenuMasterID, "GETPROFILERIGHT", profileListData.UserProfileID, "0");
    }
  };

  const onUnAssignMenu = (menu) => {
    debugger;

    if (props.selectedCompanyMaster.CompanyMasterID) {
      rightsAssignedOrUnAssigned(
        0,
        props.selectedCompanyMaster.CompanyMasterID,
        menu.ProfileRightID.toString(),
        menu.RightMasterID,
        "PUNASSIGN",
        profileListData.UserProfileID,
        rightsData.MenuMasterID,
      );
      getRightsListData(profileListData.UserProfileID, props.selectedCompanyMaster.CompanyMasterID, rightsData.MenuMasterID, "GETPROFILERIGHT", profileListData.UserProfileID, "");
      // onDisplayClick(selectedModule);
    }
  };

  const toggleAssignedRights = (data) => {
    console.log(data);
    console.log("this");
    companyRightsAssignOrUnAssigned(profileListData.UserProfileID, props.selectedCompanyMaster.CompanyMasterID, data.MenuMasterID, "GETPROFILERIGHT");
  };

  const gridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 120,
      cellRenderer: "actionTemplate",
      cellRendererParams: { onAssignMenu, onUnAssignMenu, toggleAssignedRights, setConfirmAlert, toggleLoginHistoryPopup },
    },
    {
      field: "RightCode",
      headerName: "Right Code",
      width: 180,
    },
    {
      field: "RightName",
      headerName: "Right Name",
      width: 180,
    },
    {
      field: "RightAPIURL",
      headerName: "Right APIURL",
      width: 180,
    },
  ];
  return (
    <>
      {loginHistoryPopup.IsOpen ? (
        <ProfileHistory
          setMsgAlert={setMsgAlert}
          selectedCompanyMaster={props.selectedCompanyMaster}
          popupData={loginHistoryPopup.popupData}
          toggleLoginHistoryPopup={toggleLoginHistoryPopup}
          setConfirmAlert={setConfirmAlert}
          profileListData={props.profileListData}
        />
      ) : null}
      <Modal varient={"center"} width={"680px"} height={"300px"} title="Assign Rights" show={() => toggleManageLocationTypePopup(null)}>
        <Modal.Body>
          <DataGrid
            rowData={rightsListData}
            columnDefs={gridColumnDefs}
            onGridReady={onGridReady}
            loader={isLoadingRightsList ? <Loader /> : null}
            getRowStyle={function (data) {
              if (data.data.IsSelected) {
                return { background: "#ffc176" };
              } else {
                if (data.data.IsNewlyAdded) {
                  return { background: "#d5a10e" };
                }
                return { background: "" };
              }
            }}
            frameworkComponents={{
              actionTemplate: ActionTemplate,
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageRightsPopup;
const ActionTemplate = (props) => {
  let menuData = props.data;
  let setConfirmAlert = props.setConfirmAlert;

  const onAssignMenu = (e) => {
    if (e) {
      e.preventDefault();
    }
    props.setConfirmAlert({
      open: true,
      title: "Assign Rights",
      msg: `Are you sure want to Assign ?`,
      button: {
        confirmText: "Assign",
        abortText: "Cancel",
        Color: "Success",
      },
      onConfirm: () => props.onAssignMenu(menuData),
    });
    // props.onAssignMenu(menuData);
  };
  const onUnAssignMenuList = (e) => {
    debugger;
    if (e) {
      e.preventDefault();
    }
    props.setConfirmAlert({
      open: true,
      title: "Assign Rights",
      msg: `Are you sure want to UnAssign ?`,
      button: {
        confirmText: "Yes",
        abortText: "Cancel",
        Color: "Danger",
      },
      onConfirm: () => props.onUnAssignMenu(menuData),
    });
    // props.onUnAssignMenu(menuData);
  };

  const toggleLoginHistoryPopup = () => {
    props.toggleLoginHistoryPopup(menuData);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
        {menuData.AssignmentFlag === 1 ? (
          <React.Fragment>
            <button
              onClick={(e) => {
                console.log("clicked");
                onUnAssignMenuList(e);
              }}
              className="Biz_Bm_Company_ManageLocationTypePopup_AssButton Assigned"
              style={{ cursor: "pointer" }}
            >
              UnAssign
            </button>
          </React.Fragment>
        ) : (
          <button onClick={(e) => onAssignMenu(e)} className="Biz_Bm_Company_ManageLocationTypePopup_AssButton UnAssigned" style={{ cursor: "pointer" }}>
            Assign
          </button>
        )}
        <span title="Profile History" style={{ cursor: "pointer", paddingRight: "8px", marginLeft: "6px", marginTop: "0px" }}>
          {" "}
          <FaCcMastercard className="Biz_Bm__Icon" onClick={() => toggleLoginHistoryPopup()} />
        </span>
      </div>
    </React.Fragment>
  );
};
