import axios from "axios";
import ApiCalling from "../../../APIMethods/Utilities/ApiCalling/ApiCalling";
import APIEndpoints from "../../../APIMethods/APIEndpoints/APIEndpoints";
import { checkAuthExist, getSessionStorage } from "../../../APIMethods/Auth/auth";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";

export const getCountry = async () => {
  const requestData = {
    main: {
      searchText: "",
    },
  };
  const response = await ApiCalling(requestData, APIEndpoints.CommonMaster.GetCountry);
  return response;
};

export const getCity = async (countryId, stateId) => {
  const requestData = {
    countryMasterId: countryId,
    stateMasterId: stateId,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Company.GetCityList);
  return response;
};

export const getCommonMasterData = async (action, filterId = 0) => {
  const requestData = {
    action: action,
    searchText: "#ALL",
    filterId: filterId,
    filterId1: 0,
    filterId2: 0,
    filterId3: "",
    filterId4: "",
    insertUserId: 1,
  };
  const response = await ApiCalling(requestData, APIEndpoints.CommonMaster.CommonMaster);
  return response;
};

export const getMasterDataFile = async (masterDataCode) => {
  const requestData = {
    main: {
      masterDataCode: masterDataCode,
    },
  };
  // const response = await ApiCalling(requestData, APIEndpoints.DocumentManagment.GetMasterDataFile);
  const response = await ApiCalling(requestData, APIEndpoints.CommonMaster.CommonMaster);
  return response;
};

export const getBRMMasterDataBinding = async (formData) => {
  const requestData = {
    main: {
      ...formData,
    },
  };

  const response = await ApiCalling(requestData, APIEndpoints.CommonMaster.GetBRMMasterData);
  return response;
};

export const getCommonValue = async (commonMasterCode) => {
  const requestData = {
    action: "GETLIST",
    commonMasterCode: commonMasterCode,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };
  const response = await ApiCalling(requestData, APIEndpoints.Master.CommonMaster.CommonMasterDataValue);
  return response;
};
