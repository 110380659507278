import React, { useEffect, useState, useMemo, useRef } from "react";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import BizClass from "./MenuToUser.module.scss";
import "../../CRM/Company_Registration/Company_Registration.css";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import { getUserMenuList } from "../MenuToCompany/Resources/CustomerAccessService";
import { getCompanyUserSelect } from "../../CRM/Resources/companyRegistrationService";
import { getModuleMaster } from "../../Access_Control/Module/Resources/moduleService";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { SearchTextLength } from "../../../Configration/Utilities/Constants";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import { ImBin, ImEye } from "react-icons/im";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import classNames from "classnames";
import { FaEdit, FaFileAlt } from "react-icons/fa";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { CgDatabase } from "react-icons/cg";
import { getCustomerMasterSelect } from "../../CRM/Resources/customerRegistrationService";
import { getCommonMasterData } from "../../CRM/Resources/commonService";
import { companyMenuUserAssignOrUnAssigned, getCompanyMenuUserAssignSelect } from "./Resources/MenuToUserService";
import AddUserMenuPopup from "./Views/AddUserMenu/AddUserMenu";
import { AiOutlineFileAdd } from "react-icons/ai";
import { publicIpv4 } from "public-ip";
import PageBar1 from "../../../Framework/Components/Layout/Pagebar/PageBar1";
import ManageRightsPopup from "./Views/ManageRights/ManageRightsPopup";

function MenuToUser() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });
  const companyUserInput = useRef();
  const [moduleMasterData, setModuleMasterData] = useState([]);
  const [filterModuleMasterData, setFilterModuleMasterData] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingModuleMasterData] = useState(false);
  const [menuListData, setMenuListData] = useState([]);
  const [menuTypeListData, setMenuTypeListData] = useState([]);
  const [treeMenuListData, setTreeMenuListData] = useState([]);
  const [isLoadingMenuList, setIsLoadingMenuList] = useState(false);
  const [updateMenuPopup, setUpdateMenuPopup] = useState({ open: false, data: null });
  const [selectedModule, setSelectedModule] = useState({});
  const [submenuPopupData, openSubmenuPopup] = useState({
    open: false,
    data: null,
    isEditMode: false,
  });
  const firstTextInput = useRef();
  const secondTextInput = useRef();
  const thirdTextInput = useRef();
  const [formValues, setFormValues] = useState({
    txtCustomer: null,
    txtCompany: null,
    txtCompanyUser: null,
  });

  const [addUserMenuPopup, setAddUserMenuPopup] = useState({
    isOpen: false,
    data: null,
  });
  const toggleAddUserMenu = (data) => {
    setAddUserMenuPopup({
      isOpen: !addUserMenuPopup.isOpen,
      data: data,
    });
  };

  const customerInput = useRef();
  const companyInput = useRef();
  const [addedMenuPopupActiveId, setAddedMenuPopupActiveId] = useState();
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const getModuleMasterData = async () => {
    try {
      setIsLoadingModuleMasterData(true);
      let result = await getModuleMaster();
      setIsLoadingModuleMasterData(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setModuleMasterData(result.responseData);
          setFilterModuleMasterData(result.responseData);
        } else {
          setModuleMasterData([]);
          setFilterModuleMasterData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [customerList, setCustomerList] = useState([]);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const getCustomerList = async () => {
    try {
      setIsLoadingCustomer(true);
      let result = await getCustomerMasterSelect("0");
      console.log("getCustomerList", result);
      setIsLoadingCustomer(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          console.log(result.responseData, "result.responseData");
          setCustomerList(result.responseData);
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingCompanyList, setIsLoadingCompanyList] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const getCompanyList = async (customerId) => {
    try {
      setIsLoadingCompanyList(true);
      // "SELECT", "", customerId, 0;
      let result = await getCommonMasterData("COMPANYLIST", customerId);
      setIsLoadingCompanyList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          setCompanyList(result.responseData);
        } else {
          setCompanyList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingCompanyUserList, setIsLoadingCompanyUserList] = useState(false);
  const [companyUserList, setCompanyUserList] = useState([]);
  const getCompanyUserList = async (companyMasterId) => {
    try {
      setIsLoadingCompanyUserList(true);
      let result = await getCompanyUserSelect(1, 0, companyMasterId, "SELECT", "#ALL");
      setIsLoadingCompanyUserList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData) {
          setCompanyUserList(result.responseData);
        } else {
          setCompanyUserList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const getCompanyMenuUserAssignListData = async (companyMasterId, moduleCode, companyUserId, viewMode) => {
    debugger;
    try {
      setIsLoadingMenuList(true);
      let result = await getCompanyMenuUserAssignSelect(companyMasterId, moduleCode, companyUserId, viewMode);
      setIsLoadingMenuList(false);
      console.log(result, "result");
      if (result.responseCode === 1) {
        debugger;
        if (result.responseData && result.responseData.length > 0) {
          setMenuListData(result.responseData);
        } else {
          setMenuListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const menuAssignedOrUnAssigned = async (companyMasterId, menu, companyUserId, action) => {
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      const ip = await publicIpv4();
      let formData = {
        companyUserMenuId: action === "ASSIGN" ? "0" : menu.CompanyUserMenuID,
        companyMasterId: companyMasterId,
        menuMasterId: menu.MenuMasterID,
        companyUserId: companyUserId,
        action: action,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await companyMenuUserAssignOrUnAssigned(formData);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          let msg = action === "ASSIGN" ? "Menu Assigned Successfully." : "Menu UnAssigned Successfully.";
          setMsgAlert({ open: true, type: "success", msg: msg });
          // if (gridApi) {
          //   gridApi.forEachNode(function (rowNode) {
          //     if (rowNode.data.MenuMasterID === menu.MenuMasterID) {
          //       const newData = {
          //         ...rowNode.data,
          //         CompanyUserMenuID: result.responseData.CompanyUserMenuID,
          //         AssignmentFlag: action == "ASSIGN" ? 1 : 0,
          //         isUpdated: true,
          //       };

          //       rowNode.setData(newData);
          //     } else {
          //       rowNode.setData(rowNode.data);
          //     }
          //   });
          // }
          if (gridApi) {
            gridApi.forEachNode(function (rowNode) {
              if (rowNode.data.MenuMasterID === menu.MenuMasterID) {
                if (action === "ASSIGN" && result.responseData && result.responseData[0].CompanyUserMenuID) {
                  rowNode.data.CompanyUserMenuID = result.responseData[0].CompanyUserMenuID;
                  rowNode.data.AssignmentFlag = 1;
                } else if (action === "UNASSIGN") {
                  rowNode.data.CompanyUserMenuID = null;
                  rowNode.data.AssignmentFlag = 0;
                }
                rowNode.setData(rowNode.data);
              }
            });
          }
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const updateState = (name, value) => {
    if (name === "txtCustomer") {
      if (value && value.CustomerMasterID) {
        if (JSON.stringify(formValues.txtCustomer) !== JSON.stringify(value)) {
          formValues.txtCompany = null;
          formValues.txtCompanyUser = null;
          getCompanyList(value.CustomerMasterID.toString());
        }
      } else {
        formValues.txtCompany = null;
        formValues.txtCompanyUser = null;
        setCompanyList([]);
        setCompanyUserList([]);
        setFilterModuleMasterData([]);
      }
      if (!formValues.txtCustomer || typeof formValues.txtCustomer === "undefined" || JSON.stringify(formValues.txtCustomer) !== JSON.stringify(value)) {
        setSelectedModule(null);
        setMenuListData([]);
        setFilterModuleMasterData([]);
      }
    } else if (name === "txtCompany") {
      if (value && value.CompanyMasterID) {
        if (JSON.stringify(formValues.txtCompany) !== JSON.stringify(value)) {
          formValues.txtCompanyUser = null;
          getCompanyUserList(value.CompanyMasterID);
        }
      } else {
        formValues.txtCompanyUser = null;
        setCompanyUserList([]);
        setFilterModuleMasterData([]);
      }
      if (!formValues.txtCompany || typeof formValues.txtCompany === "undefined" || JSON.stringify(formValues.txtCompany) !== JSON.stringify(value)) {
        setSelectedModule(null);
        setMenuListData([]);
        setFilterModuleMasterData([]);
      }
    } else if (name === "txtCompanyUser") {
      if (value) {
        getModuleMasterData();
      } else {
        setSelectedModule(null);
        setMenuListData([]);
        setFilterModuleMasterData([]);
      }
      if (!formValues.txtCompanyUser || typeof formValues.txtCompanyUser === "undefined" || JSON.stringify(formValues.txtCompanyUser) !== JSON.stringify(value)) {
        setSelectedModule(null);
        setMenuListData([]);
        setFilterModuleMasterData([]);
      }
    }
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  const onDisplayClick = (moduleData) => {
    getCompanyMenuUserAssignListData(formValues.txtCompany.CompanyMasterID, moduleData.ModuleCode, formValues.txtCompanyUser.CompanyUserID, "MODULECODE");
    setSelectedModule(moduleData);
  };

  const updateMenuList = (menuData) => {
    if (gridApi) {
      gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.MenuMasterID === menuData.MenuMasterID && rowNode.data.CompanyMenuID === menuData.CompanyMenuID) {
          rowNode.setData({ ...menuData, IsNewlyAdded: true });
        }
      });
    }
  };

  const buildHierarchy = () => {
    let menuTreeListData = [];
    menuListData.forEach((menu) => {
      let menuData = {
        ...menu,
        IsNewlyAdded: menu.IsNewlyAdded,
        orgHierarchy: [menu.MenuMasterID],
        MenuTypeName: menu.MenuType === 101 ? "BizNext" : menu.MenuType === 102 ? "MyPortal" : "",
      };
      if (menu.UnderMenuID.toString() !== "0") {
        const parentMenu = menuListData.find((x) => x.MenuMasterID.toString() === menu.UnderMenuID.toString());
        if (parentMenu !== null && parentMenu !== undefined) {
          menuData.orgHierarchy.push(parentMenu.MenuMasterID);
          recHierarchy(menuData, parentMenu);
        }
      }

      menuTreeListData.push(menuData);
    });

    menuTreeListData.forEach((menu) => {
      menu.orgHierarchy = menu.orgHierarchy.reverse();
    });
    console.log(menuTreeListData, "**menuTreeListData**");
    setTreeMenuListData(menuTreeListData);
  };

  const recHierarchy = (menu, parent) => {
    if (parent.UnderMenuID.toString() !== 0) {
      var parentMenu = menuListData.find((x) => x.MenuMasterID.toString() === parent.UnderMenuID.toString());
      if (parentMenu !== null && parentMenu != undefined) {
        menu.orgHierarchy.push(parentMenu.MenuMasterID);
        recHierarchy(menu, parentMenu);
      }
    }
  };

  const onAssignMenu = (menu) => {
    debugger;
    if (!formValues.txtCustomer || typeof formValues.txtCustomer === "undefined") {
      setMsgAlert({ open: true, type: "warning", msg: "Please select customer." });
      if (customerInput.current) {
        customerInput.current.focus();
      }
      return;
    } else if (!formValues.txtCompany || typeof formValues.txtCompany === "undefined") {
      setMsgAlert({ open: true, type: "warning", msg: "Please select company." });
      if (companyInput.current) {
        companyInput.current.focus();
      }
      return;
    }
    if (formValues.txtCompany.CompanyMasterID && menu.MenuMasterID && menu.MenuName) {
      menuAssignedOrUnAssigned(formValues.txtCompany.CompanyMasterID, menu, formValues.txtCompanyUser.CompanyUserID, "ASSIGN");
      // onDisplayClick(selectedModule);
    }
  };

  const onUnAssignMenu = (menu) => {
    debugger;
    if (!formValues.txtCustomer || typeof formValues.txtCustomer === "undefined") {
      setMsgAlert({ open: true, type: "warning", msg: "Please select customer." });
      if (customerInput.current) {
        customerInput.current.focus();
      }
      return;
    } else if (!formValues.txtCompany || typeof formValues.txtCompany === "undefined") {
      setMsgAlert({ open: true, type: "warning", msg: "Please select company." });
      if (companyInput.current) {
        companyInput.current.focus();
      }
      return;
    }
    console.log(
      formValues.txtCompany.CompanyMasterID,
      menu.MenuMasterID,
      menu.CompanyMenuID,
      menu.MenuName,
      "formValues.txtCompany.CompanyMasterID, menu.MenuMasterID,menu.CompanyMenuID, menu.MenuName",
    );
    if (formValues.txtCompany.CompanyMasterID && menu.MenuMasterID && menu.MenuName) {
      menuAssignedOrUnAssigned(formValues.txtCompany.CompanyMasterID, menu, formValues.txtCompanyUser.CompanyUserID, "UNASSIGN");
      // onDisplayClick(selectedModule);
    }
  };
  const [rightAssignmentPopupActive, setChangePasswodPopupActive] = useState({
    IsOpen: false,
    popupData: null,
  });
  const toggleRightAssignment = (userData) => {
    console.log("toggleResetPasswordPopup", userData);
    setChangePasswodPopupActive({
      IsOpen: !rightAssignmentPopupActive.IsOpen,
      popupData: userData,
    });
  };

  useEffect(() => {
    if (menuListData != null && menuListData.length > 0) {
      buildHierarchy();
    } else {
      setTreeMenuListData([]);
    }
  }, [menuListData]);

  const [moduleMasterGridApi, setModuleMasterGridApi] = useState();
  const onModuleMasterGridReady = (params) => {
    setModuleMasterGridApi(params.api);
  };

  const [searchTextModuleMaster, setSearchTextModuleMaster] = useState("");
  const onSearchMasterCommonCode = (val) => {
    setSearchTextModuleMaster(val);
    moduleMasterGridApi.setQuickFilter(val);
    moduleMasterGridApi.refreshCells();
    setModuleMasterData([]);
  };

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const [searchTextMenuList, setSearchTextMenuList] = useState("");
  const onSearchMasterDataFile = (val) => {
    setSearchTextMenuList(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const [rightsData, setRightsData] = useState(null);
  const [manageLocationTypePopupActive, setManageLocationTypePopupActive] = useState(false);
  const toggleManageLocationTypePopup = (data) => {
    setRightsData(data);
    setManageLocationTypePopupActive(!manageLocationTypePopupActive);
  };

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    } else if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    } else if (data.data.isUpdated) {
      return { background: "#ffd40085" };
    } else {
      return { background: "" };
    }
  };

  const onClickSearchBar = (data) => {
    if (formValues.txtCustomer) {
      if (formValues.txtCompany) {
        if (formValues.txtCompanyUser) {
          if (data) {
            onDisplayClick(data);
          } else {
            setMsgAlert({ open: true, type: "warning", msg: "Select Module" });
          }
        } else {
          thirdTextInput.current.focus();
          setMsgAlert({ open: true, type: "warning", msg: "Select User!" });
        }
      } else {
        secondTextInput.current.focus();
        setMsgAlert({ open: true, type: "warning", msg: "Select Company!" });
      }
    } else {
      firstTextInput.current.focus();
      setMsgAlert({ open: true, type: "warning", msg: "Select Customer!" });
    }
  };

  const moduleGridColumnDefs = [
    { field: "#", headerName: "Action", width: 80, pinned: "left", lockPosition: "1", cellRenderer: "actionTemplate", cellRendererParams: { onDisplayClick } },
    {
      field: "ModuleCode",
      headerName: "Module Code",
      width: 180,
      pinned: "left",
      lockPosition: "1",
    },
    {
      field: "ModuleName",
      headerName: "Module Name",
      width: 180,
      pinned: "left",
    },
  ];

  const menuListGridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 130,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellRendererParams: { onAssignMenu, onUnAssignMenu, setUpdateMenuPopup, toggleAddUserMenu, toggleManageLocationTypePopup, toggleRightAssignment },
    },
    {
      field: "MenuName",
      hide: true,
      headerName: "Menu Name",
      width: 280,
      pinned: "left",
      lockPosition: 2,
    },
    {
      field: "MenuTypeName",
      headerName: "Menu Type",
      width: 120,
    },
  ];

  return (
    <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {addUserMenuPopup.isOpen ? <AddUserMenuPopup setConfirmAlert={setConfirmAlert} toggleAddUserMenu={toggleAddUserMenu} /> : null}
      {manageLocationTypePopupActive ? (
        <ManageRightsPopup
          rightsData={rightsData}
          companyMaster={formValues.txtCompany}
          companyUser={formValues.txtCompanyUser}
          setMsgAlert={setMsgAlert}
          onDisplayClick={onDisplayClick}
          selectedModule={selectedModule}
          toggleManageLocationTypePopup={toggleManageLocationTypePopup}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      {/* {rightAssignmentPopupActive.IsOpen ? (
        <ResetPasswordPopup
          popupData={rightAssignmentPopupActive.popupData}
          toggleRightAssignment={toggleRightAssignment}
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null} */}

      <div className={BizClass.Biz_Bm_Manage_Menu_ContentBox}>
        <PageBar title="Menu To User">
          <PageBar.Select
            name="txtCustomer"
            getOptionLabel={(option) => `${option.CustomerName}`}
            value={formValues.txtCustomer}
            getOptionValue={(option) => `${option}`}
            options={customerList}
            onChange={(e) => updateState("txtCustomer", e)}
            isLoading={isLoadingCustomer}
            label="Select Customer"
            ref={firstTextInput}
          />
          <PageBar.Select
            getOptionLabel={(option) => `${option.CompanyName}`}
            value={formValues.txtCompany}
            getOptionValue={(option) => `${option}`}
            options={companyList}
            onChange={(e) => updateState("txtCompany", e)}
            isSearchable={true}
            isLoading={isLoadingCompanyList}
            ref={secondTextInput}
          />
          <PageBar.Select
            name="txtCompanyUser"
            menuPlacement="auto"
            isClearable={true}
            getOptionLabel={(option) => `${option.UserDisplayName} (${option.UserLoginID})`}
            value={formValues.txtCompanyUser}
            getOptionValue={(option) => `${option}`}
            options={companyUserList}
            onChange={(e) => updateState("txtCompanyUser", e)}
            isLoading={isLoadingCompanyUserList}
            ref={thirdTextInput}
            label="Select User"
          />
        </PageBar>
        <Splitter varient="column" template="1fr 9px 2fr">
          <div className={BizClass.MainSection}>
            <PageBar1 title="Module Master">
              {/* <PageBar.Search focus={true} onClick={() => onClickSearchModuleMaster()} value={searchTextModuleMaster} onChange={(e) => onSearchMasterCommonCode(e.target.value)} />
              <PageBar.Button onClick={() => onRefreshClick()}>Reload</PageBar.Button> */}
            </PageBar1>

            <DataGrid
              rowData={filterModuleMasterData}
              columnDefs={moduleGridColumnDefs}
              onGridReady={onModuleMasterGridReady}
              onDisplayClick={onDisplayClick}
              animateRows={true}
              loader={isLoadingModuleMasterData ? <Loader /> : null}
              getRowStyle={function (data) {
                if (data.data.IsSelected) {
                  return { background: "#ffc176" };
                } else {
                  if (data.data.IsNewlyAdded) {
                    return { background: "#d5a10e" };
                  }
                  return { background: "" };
                }
              }}
              frameworkComponents={{
                actionTemplate: cellTemplate,
              }}
            />
          </div>
          <div className={BizClass.MainSection}>
            <PageBar1 title={` ${selectedModule && selectedModule.ModuleName ? `(${selectedModule.ModuleName}) Menu List` : "Menu List"}`}>
              <PageBar.Search value={searchTextMenuList} onClick={() => onClickSearchBar(selectedModule)} onChange={(e) => onSearchMasterDataFile(e.target.value)} />
              <PageBar.Button>Assign All</PageBar.Button>
            </PageBar1>
            <DataGrid
              getRowStyle={getRowStyle}
              onGridReady={onGridReady}
              columnDefs={menuListGridColumnDefs}
              rowData={treeMenuListData}
              autoGroupColumnDef={{
                headerName: "Menu Name",
                minWidth: 300,
                lockPosition: 2,
                cellRendererParams: {
                  innerRenderer: function (params) {
                    return params.data.MenuName;
                  },
                },
              }}
              loader={isLoadingMenuList ? <Loader /> : null}
              frameworkComponents={{
                actionTemplate: ActionTemplate,
              }}
              openSubmenuPopup={openSubmenuPopup}
              treeData={true}
              animateRows={true}
              groupDefaultExpanded={-1}
              getDataPath={(data) => {
                return data.orgHierarchy;
              }}
            />
          </div>
        </Splitter>
        <Footer />
      </div>
    </div>
  );
}

export default MenuToUser;

const cellTemplate = (props) => {
  const onDisplayClick = () => {
    console.log("***********Selected Data***********", props.data);
    props.onDisplayClick(props.data);
  };
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View File"
      >
        <ImEye style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => onDisplayClick()} />
      </span>
      {/* <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="Delete File"
      >
        <AiOutlineFileAdd
          style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }}
          // onClick={() => handleDeleteFile()}
        />
      </span> */}
    </div>
  );
};

const ActionTemplate = (props) => {
  let menuData = props.data;
  console.log(menuData, "menudata");
  const onAssignMenu = () => {
    props.onAssignMenu(menuData);
  };
  const onUnAssignMenuList = () => {
    debugger;
    props.onUnAssignMenu(menuData);
  };

  const toggleManageLocationTypePopup = () => {
    props.toggleManageLocationTypePopup(menuData);
  };

  return (
    <React.Fragment>
      <div>
        {menuData.AssignmentFlag === 1 ? (
          <React.Fragment>
            <button onClick={() => onUnAssignMenuList()} className="Biz_Bm_Company_ManageLocationTypePopup_AssButton Assigned" style={{ cursor: "pointer" }}>
              UnAssign
            </button>
          </React.Fragment>
        ) : (
          <button onClick={() => onAssignMenu()} className="Biz_Bm_Company_ManageLocationTypePopup_AssButton UnAssigned" style={{ cursor: "pointer" }}>
            Assign
          </button>
        )}
        {menuData.HasChild === 0 ? (
          <>
            <span title="" style={{ cursor: "pointer", paddingLeft: "10px" }}>
              <FaFileAlt className="Biz_Bm__Icon" onClick={() => toggleManageLocationTypePopup()} />{" "}
            </span>
          </>
        ) : null}
      </div>
    </React.Fragment>
  );
};
