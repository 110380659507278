/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
import React, { useEffect, useRef, useState } from "react";
import "../../Company_Registration.css";
import { companyAddressLocationAssignment } from "../../../Resources/companyRegistrationService";
import { FaTimes } from "react-icons/fa";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
const ManageLocationTypePopup = (props) => {
  let address = props.address;
  let selectedLocationType = props.selectedLocationType;
  let getCompanyAddress = props.getCompanyAddress;
  let locationTypeList = props.locationTypeList;
  let toggleManageLocationTypePopup = props.toggleManageLocationTypePopup;
  const [isModalAnimOpen, setModalAnimOpen] = useState(false);
  let selectedCompanyMaster = props.selectedCompanyMaster;
  let setMsgAlert = props.setMsgAlert;

  const onCloseToggle = () => {
    // getCompanyAddress(1, selectedCompanyMaster.CompanyMasterID, 0, "GETADDRESS");
    toggleManageLocationTypePopup();
  };

  const toggleModalAnimOpen = () => {
    setModalAnimOpen(true);
    setTimeout(() => {
      setModalAnimOpen(false);
    }, 100);
  };

  const firstTextInput = useRef();

  const [manageLocationTypeList, setManageLocationTypeList] = useState([]);
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
    if (locationTypeList && locationTypeList.length > 0) {
      let assignedLocationType = [];
      if (address.LocationTypeCode) {
        console.log(address, "address");
        assignedLocationType = address.LocationTypeCode.toString().split(",");
      }
      console.log(address.LocationTypeCode, "address.AssignedLocationType");

      let mappedData = locationTypeList.map((data, i) => {
        if (data.LocationTypeID && assignedLocationType.includes(data.LocationTypeID.toString())) {
          return { ...data, IsAssigned: true };
        } else {
          return { ...data, IsAssigned: false };
        }
      });
      console.log(assignedLocationType, "assignedLocationType");
      console.log(mappedData, " mappedData");
      console.log(locationTypeList, "locationTypeList");
      setManageLocationTypeList(mappedData);
      console.log(address, "address");
    }
  }, []);

  const onAssignUnAssignLocationTypeClick = async (locationType) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      let formData = {
        addressCode: address.BMCAddressCode,
        locationTypeCode: locationType.LocationTypeID,
        action: locationType.IsAssigned ? "U" : "A",
        insertUserId: insertUserID,
      };
      let result = await companyAddressLocationAssignment(formData);
      if (result.responseCode === 1) {
        const locationTypeId = selectedLocationType ? selectedLocationType.LocationTypeID : 0;
        getCompanyAddress(1, selectedCompanyMaster.CompanyMasterID, 0, "GETADDRESS");

        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        let mappedData = manageLocationTypeList.map((data, i) => {
          if (locationType.LocationTypeID === data.LocationTypeID) {
            data.IsAssigned = !locationType.IsAssigned;
          }
          return data;
        });
        setManageLocationTypeList(mappedData);
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div onClick={toggleModalAnimOpen} className="Biz_Bm_Company_ManageLocationTypePopup_overlay"></div>
      <div className={isModalAnimOpen ? "Biz_Bm_Company_ManageLocationType_PopupAnimBM" : ""}>
        <div className="Biz_Bm_Company_ManageLocationTypePopup_Box Biz_Bm_Company_ManageLocationTypePopup_BoxOn">
          <header className="Biz_Bm_Company_ManageLocationTypePopup_Header">
            <h2>{`Manage Location Type (${address && address.AddressDisplayName ? address.AddressDisplayName : ""})`}</h2>
            <a onClick={toggleManageLocationTypePopup} className="Biz_Bm_Company_ManageLocationTypePopup_HeaderCloseBtn">
              <FaTimes className="Biz_Bm_Icon" />
            </a>
          </header>
          <form className="Biz_Bm_Company_ManageLocationTypePopup_Content">
            <div className="Biz_BM_CustomDataTable Biz_Bm_Company_ManageLocationTypePopupTableBox">
              <table>
                <thead>
                  <th>Location Type</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {manageLocationTypeList && manageLocationTypeList.length > 0
                    ? manageLocationTypeList.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td>{data.LocationType}</td>
                            <td>
                              <button
                                type="button"
                                onClick={() => onAssignUnAssignLocationTypeClick(data)}
                                className={
                                  !data.IsAssigned ? "Biz_Bm_Company_ManageLocationTypePopup_AssButton UnAssigned" : "Biz_Bm_Company_ManageLocationTypePopup_AssButton Assigned"
                                }
                              >
                                {!data.IsAssigned ? "Assign" : "Un-Assign"}
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
            <footer className="Biz_Bm_Company_ManageLocationTypePopup_Footer">
              <button type="button" onClick={onCloseToggle}>
                Close
              </button>
            </footer>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageLocationTypePopup;
