import APIEndpoints from "../../../APIMethods/APIEndpoints/APIEndpoints";
import ApiCalling from "../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const SupportUsersData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Support.SupportUsersData);
  return response;
};

export const CreateSupportUser = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Support.CreateSupportUser);
  return response;
};

export const AssignCompanyToUserUser = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Support.AssignCompany);
  return response;
};

export const getEncryptdata = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Support.getEncryptdata);
  return response;
};
export const getdeleteSupportUser = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Support.deleteSupportUser);
  return response;
};

export const resetPassword = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.Support.resetPassword);
  return response;
};
