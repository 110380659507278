import axios from "axios";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import MainUrl from "../../../../../APIMethods/APIEndpoints/MainUrl.json";
import { publicIpv4 } from "public-ip";
import { getBaseURL } from "../../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const authenticate = async (userName, password) => {
  try {
    let requestData = {
      username: userName,
      password: password,
    };
    debugger;
    console.log(requestData);
    console.log("MainUrl.Authenticate", MainUrl.Authenticate);
    const url = getBaseURL();
    let response = await axios.post(url.URL + "auth/login", requestData);
    console.log(response, "response");
    if (response.status === 200) {
      const result = await response.data;
      if (result.code === 200) {
        return {
          responseCode: 1,
          responseData: result.data,
          responseMessage: result.message,
        };
      } else {
        return {
          responseCode: 0,
          responseData: result.data,
          responseMessage: result.message,
        };
      }
    }
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: "Login Error",
    };
  } catch (error) {
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: "Login Error",
    };
  }
};

export const logout = async () => {
  try {
    const ip = await publicIpv4();
    let userData = getSessionStorage("session");
    let requestData = {
      AppAccessID: userData.User.AppAccessID,
      AppAccessSessionID: userData.User.AppSessionID,
      commonTrackingDto: {
        InsertUserID: userData.User.AppAccessID,
        InsertIPAddress: ip,
      },
    };

    let response = await axios.post(MainUrl.Logout, requestData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userData.Token,
      },
    });

    if (response.status === 200) {
      const result = await response.data;
      if (result.ResponseCode === 1) {
        return {
          responseCode: 1,
          responseData: result,
          responseMessage: "Success",
        };
      } else {
        return {
          responseCode: 0,
          responseData: result,
          responseMessage: result.ResponseMessage,
        };
      }
    }
    return { responseCode: 0, responseData: null, responseMessage: "" };
  } catch (error) {
    return { responseCode: 0, responseData: null, responseMessage: "" };
  }
};
