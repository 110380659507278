import React, { useState, useEffect, useRef } from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import BizClass from "./PageMaster.module.scss";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import PageTitle, { PageButton, PageSearch } from "../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, { FooterBox } from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { GetPageMasterData } from "./Resources/PageMasterService";
import AddPageMaster from "./Views/AddPageMaster";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import classNames from "classnames";
import PageConfig from "../PageConfig/PageConfig";
import PageParameter from "../PageParameter/PageParameter";
import PageVariable from "../PageVariable/PageVariable";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import AssignedParameter from "./Views/AssignedParameter/AssignedParameter";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";
import { getModuleMaster } from "../../Access_Control/Module/Resources/moduleService";
import { HiArrowCircleRight } from "react-icons/hi";

const PageMaster = () => {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });
  const [activeTabId, setActiveTabId] = useState(1);
  const [PageMasterList, setPageMasterList] = useState([]);
  const [isLoadingPageMasterList, setIsLoadingPageMasterList] = useState(false);

  const toggleActiveTab = (tab) => {
    setActiveTabId(tab);
  };

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getPageMasterList = async () => {
    try {
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      setIsLoadingPageMasterList(true);
      const formData = {
        BizNextPageID: 0,
        SearchBy: searchMappedData,
        ModuleMasterID: formValues?.txtModule?.ModuleCode || 0,
        FilterID: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      console.log(formData);
      let result = await GetPageMasterData(formData);
      console.log(result, "result");
      setIsLoadingPageMasterList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          if (searchMappedData && searchMappedData.toLowerCase().includes("#")) {
            onSearchMappingData("");
          }
          setPageMasterList(result.responseData);
        } else {
          setPageMasterList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const onClickSearchPageMaster = () => {
    if (formValues.txtModule) {
      getPageMasterList();
    } else {
      secondTextInput.current.focus();
      setMsgAlert({ open: true, type: "warning", msg: "Select Module!" });
    }
  };

  const [addPageMasterPopup, setAddPageMasterPopup] = useState({
    isOpen: false,
    popupData: null,
    isEditMode: "",
  });
  const toggleAddPageMaster = (isEditMode, data) => {
    if (formValues.txtModule) {
      setAddPageMasterPopup({ isOpen: !addPageMasterPopup.isOpen, popupData: data, isEditMode: isEditMode });
    } else {
      secondTextInput.current.focus();
      setMsgAlert({ open: true, type: "warning", msg: "Select Module!" });
    }
  };
  const [selectedPage, setSelectedPage] = useState();
  const onClickPageMaster = (data) => {
    debugger;
    console.log("onClickPageMaster", data);
    setSelectedPage(data);
    toggleActiveTab(activeTabId);
  };

  const gridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: "1",
      cellRenderer: "actionTemplate",
      cellRendererParams: { toggleAddPageMaster, onClickPageMaster },
    },
    {
      field: "BizNextPageName",
      headerName: "Page Name",
      width: 200,
      pinned: "left",
    },
    {
      field: "ModuleName",
      headerName: "Module",
      width: 200,
    },
    {
      field: "MenuName",
      headerName: "Menu",
      width: 200,
    },
  ];

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const updatePageMasterList = (department) => {
    PageMasterList.unshift(department);
    setPageMasterList(PageMasterList);
    if (gridApi) {
      gridApi.setRowData(PageMasterList);
    }
  };

  const _updateListingPageMaster = (record) => {
    if (gridApi) {
      const itemsToUpdate = [];
      gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.BizNextPageID === record.BizNextPageID) {
          itemsToUpdate.push(record);
          rowNode.setData(record);
        }
      });
      gridApi.applyTransaction({
        update: itemsToUpdate,
      });
      gridApi.refreshCells();
    }
    let newData = PageMasterList.map((x) => {
      if (x.BizNextPageID === record.BizNextPageID) return record;
      return x;
    });
    setPageMasterList([...newData]);
  };
  const secondTextInput = useRef();
  const [formValues, setFormValues] = useState({
    txtModule: null,
  });
  const updateState = (name, value) => {
    if (name === "txtModule") {
      setPageMasterList([]);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const [moduleMasterData, setModuleMasterData] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingModuleMasterData] = useState(false);
  const getModuleMasterData = async () => {
    try {
      setIsLoadingModuleMasterData(true);
      let result = await getModuleMaster();
      setIsLoadingModuleMasterData(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setModuleMasterData(result.responseData);
        } else {
          setModuleMasterData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getModuleMasterData();
  }, []);

  return (
    <React.Fragment>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {addPageMasterPopup.isOpen ? (
        <AddPageMaster
          setMsgAlert={setMsgAlert}
          _updateListingPageMaster={_updateListingPageMaster}
          updatePageMasterList={updatePageMasterList}
          toggleAddPageMaster={toggleAddPageMaster}
          setConfirmAlert={setConfirmAlert}
          popupData={addPageMasterPopup.popupData}
          isEditMode={addPageMasterPopup.isEditMode}
          formValuesPage={formValues}
        />
      ) : null}
      <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
        <div className={BizClass.Biz_Bm_Manage_Menu_ContentBox}>
          <Splitter varient="column" template="0.7fr 9px 1fr">
            <div className={BizClass.MainSection}>
              <PageTitle Title="Master">
                <PageBar.Select
                  control="select"
                  name="txtModule"
                  type="text"
                  value={formValues.txtModule}
                  onChange={(e) => {
                    updateState("txtModule", e);
                  }}
                  getOptionLabel={(option) => `${option.ModuleName}`}
                  getOptionValue={(option) => `${option}`}
                  options={moduleMasterData}
                  ref={secondTextInput}
                  label="Module"
                />
                <PageSearch focus={true} onClick={() => onClickSearchPageMaster()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
                <PageButton onClick={() => toggleAddPageMaster(false, null)}>Add</PageButton>
              </PageTitle>
              <DataGrid
                rowData={PageMasterList}
                columnDefs={gridColumnDefs}
                onGridReady={onGridReady}
                loader={isLoadingPageMasterList ? <Loader /> : null}
                getRowStyle={function (data) {
                  if (data.data.IsSelected) {
                    return { background: "#ffc176" };
                  } else {
                    if (data.data.IsNewlyAdded) {
                      return { background: "#d5a10e" };
                    }
                    return { background: "" };
                  }
                }}
                frameworkComponents={{
                  actionTemplate: cellTemplate,
                }}
              />
            </div>
            <div className={BizClass.MainSection}>
              <div className={BizClass.Div_Btns}>
                <button className={activeTabId === 1 ? BizClass.Active : ""} onClick={() => toggleActiveTab(1)}>
                  Config
                </button>
                <button className={activeTabId === 2 ? BizClass.Active : ""} onClick={() => toggleActiveTab(2)}>
                  Parameter Assign
                </button>
                <button className={activeTabId === 3 ? BizClass.Active : ""} onClick={() => toggleActiveTab(3)}>
                  Variable
                </button>
              </div>
              {activeTabId && activeTabId === 1 && <PageConfig selectedPage={selectedPage} formValuesPage={formValues} />}
              {activeTabId && activeTabId === 2 && <AssignedParameter selectedPage={selectedPage} formValuesPage={formValues} />}
              {activeTabId && activeTabId === 3 && <PageVariable selectedPage={selectedPage} formValuesPage={formValues} />}
            </div>
          </Splitter>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageMaster;
const cellTemplate = (props) => {
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "12px" }}>
      <span style={{ cursor: "pointer", display: "grid", paddingTop: "2px" }}>
        <HiArrowCircleRight title="Get Value" style={{ fontSize: "16px", color: "#34495E" }} onClick={() => props.onClickPageMaster(props.data)} />
      </span>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View List"
      >
        <FaEdit title="Edit" style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => props.toggleAddPageMaster(true, props.data)} />
      </span>
    </div>
  );
};
