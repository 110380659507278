import React, { useEffect, useState } from "react";
import DataGrid from "../../../../Framework/Components/Common/DataGrid/DataGrid";
import Modal from "../../../../Framework/Components/Layout/Modal/Modal";
import moment from "moment";
import Loader from "../../../../Framework/OldFramework/Loader/Loader";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";
import { getTrimedCharacters } from "../../../../Configration/Utilities/utils";

const UserLoginSummaryUserList = (props) => {
  const { requestParams, setMsgAlert, setUserSummaryModal } = props;
  const [rowData, setRowData] = useState([]);
  const [isLoadingRowData, setIsLoadingRowData] = useState(false);

  const columnDefs = [
    {
      field: "#",
      headerName: "Sr No.",
      width: 80,
      valueGetter: "node.rowIndex + 1",
      pinned: "left",
    },
    {
      field: "UserName",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "UserLoginID",
      headerName: "User ID",
      width: 120,
    },
    {
      field: "FirstLoginTime",
      headerName: "First Login",
      width: 110,
    },
    {
      field: "LastLoginTime",
      headerName: "Last Login",
      width: 100,
    },
  ];

  const getModuleApiCallingSummaryData = async () => {
    setIsLoadingRowData(true);

    const companyMasterIDValue = requestParams && requestParams.txtCompanyMasterID ? requestParams.txtCompanyMasterID : "";
    const dateValue = requestParams && requestParams.txtDate ? moment(requestParams.txtDate).format("YYYY-MM-DD") : "";
    const payload = {
      companyMasterID: companyMasterIDValue,
      date: dateValue,
    };

    const result = await ApiCalling(payload, "report/login-summary/user-list");
    if (result && result.responseCode === 1) {
      if (result.responseData && result.responseData.length > 0) {
        setRowData(result.responseData);
      } else {
        setRowData([]);
      }
    } else {
      setRowData([]);
      setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
    }

    setIsLoadingRowData(false);
  };

  const getModalTitle = () => {
    const titleCompanyName = requestParams.txtCompanyName ? getTrimedCharacters(requestParams.txtCompanyName, 30) : "";
    const titleDate = requestParams.txtDate ? moment(requestParams.txtDate).format("DD-MMM-YYYY") : "";

    return `${titleCompanyName} (${titleDate})`;
  };

  useEffect(() => {
    getModuleApiCallingSummaryData();
  }, []);

  return (
    <Modal varient="half" right="0" width="650px" show={setUserSummaryModal} title={getModalTitle()}>
      <Modal.Body>
        <DataGrid columnDefs={columnDefs} rowData={rowData} loader={isLoadingRowData ? <Loader /> : null} />
      </Modal.Body>
    </Modal>
  );
};

export default UserLoginSummaryUserList;
