/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */

import React, { useEffect, useRef, useState } from "react";
import BizClass from "./ProfileManagement.module.scss";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import classNames from "classnames";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import { getModuleMaster } from "../Module/Resources/moduleService";
import AddProfile from "./Views/AddProfile/AddProfile";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import { getProfileList, manageUserProfileAssign, profileMenuAssignList } from "./Resources/ProfileManagementService";
import { FaFileAlt, FaUser } from "react-icons/fa";
import { getCompanyMasterSelect } from "../../CRM/Resources/companyRegistrationService";
import { ImEye } from "react-icons/im";
import PageBar1 from "../../../Framework/Components/Layout/Pagebar/PageBar1";
import { HiMenuAlt1 } from "react-icons/hi";
import { getDecryptSessionStorage, getSessionStorage } from "../../../APIMethods/Auth/auth";
import UnAssignedUserProfile from "./Views/UnAssignedUserProfile/UnAssignedUserProfile";
import { publicIpv4 } from "public-ip";
import { RiDeleteBin6Fill } from "react-icons/ri";
import ManageRightsPopup from "./Views/ManageRightsPopup/ManageRightsPopup";
const ProfileManagement = () => {
  const [profileMasterData, setProfileMasterData] = useState([]);
  const [isLoadingProfileMasterData, setIsLoadingProfileMasterData] = useState(false);
  const [menuListData, setMenuListData] = useState([]);
  const [treeMenuListData, setTreeMenuListData] = useState([]);
  const [isLoadingMenuList, setIsLoadingMenuList] = useState(false);
  const [selectedModule, setSelectedModule] = useState({});
  const [profileListData, setProfileListData] = useState([]);
  const [selectedCompanyMaster, setSelectedCompanyMaster] = useState([]);
  const [moduleMasterData, setModuleMasterData] = useState([]);
  const [filterModuleMasterData, setFilterModuleMasterData] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingModuleMasterData] = useState(false);
  const [isLoadingCompanyList, setIsLoadingCompanyList] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [activeView, setActiveView] = useState(1);
  const [selectedProfileRow, setSelectedProfileRow] = useState(null);
  const [isLoadingUserList, setIsLoadingUserList] = useState(false);
  const selectedCompanyData = getDecryptSessionStorage("company");
  const [userList, setUserList] = useState([]);
  const menuInput = useRef();
  const companyInput = useRef();
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });
  const [gridApi, setGridApi] = useState();
  const [profilegridApi, setProfileGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
    console.log(isLoadingProfileMasterData, filterModuleMasterData);
  };

  const onProfileGridReady = (params) => {
    setProfileGridApi(params.api);
  };

  const updateState = (name, selData) => {
    if (name === "txtCompany") {
      if (selData && selData.CompanyMasterID !== null) {
        setSelectedCompanyMaster(selData);

        getProfileMasterData(selData.CompanyMasterID);
      } else {
      }
    }
    if (name === "txtModule") {
      setSelectedModule(selData);
      if (selData && selData.ModuleCode) {
        getMenuListData(0, profileListData.UserProfileID, selectedCompanyMaster.CompanyMasterID, 0, selData.ModuleCode, "AssignedMenu");
      }
    }
  };

  const getProfileMasterData = async (CompanyMasterID) => {
    try {
      setProfileMasterData([]);

      setIsLoadingProfileMasterData(true);

      let result = await getProfileList("Select", 0, CompanyMasterID, "#ALL");

      setIsLoadingProfileMasterData(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setProfileMasterData(result.responseData);
          setUserList([]);
          setTreeMenuListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        setUserList([]);
        setTreeMenuListData([]);
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const getMenuListData = async (profileMenuID, userProfileID, CompanyMasterID, MenuMasterID, ModulMasterID, viewMode) => {
    debugger;
    try {
      setIsLoadingMenuList(true);
      let result = await profileMenuAssignList(profileMenuID, userProfileID, CompanyMasterID, MenuMasterID, ModulMasterID, viewMode);
      console.log(result, "result");
      setIsLoadingMenuList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setMenuListData(result.responseData);
          console.log(result.responseData);
        } else {
          setMenuListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setIsLoadingMenuList(false);
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const getModuleMasterData = async () => {
    try {
      setIsLoadingModuleMasterData(true);
      let result = await getModuleMaster();
      setIsLoadingModuleMasterData(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setModuleMasterData(result.responseData);
          setFilterModuleMasterData(result.responseData);
        } else {
          setModuleMasterData([]);
          setFilterModuleMasterData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const getCompanyList = async (customerId) => {
    try {
      setIsLoadingCompanyList(true);
      // "SELECT", "", customerId, 0;
      // let result = await getCommonMasterData("COMPANYLIST", customerId);
      let result = await getCompanyMasterSelect("MASTER", "#ALL", customerId, 0);
      setIsLoadingCompanyList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          setCompanyList(result.responseData);
        } else {
          setCompanyList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    // getProfileMasterData();
    //   getModuleMasterData();
    getCompanyList(1);
    getModuleMasterData();
  }, []);

  useEffect(() => {
    if (menuListData != null && menuListData.length > 0) {
      buildHierarchy();
    } else {
      setTreeMenuListData([]);
    }
  }, [menuListData]);

  const buildHierarchy = () => {
    let menuTreeListData = [];
    menuListData.forEach((menu) => {
      let menuData = {
        ...menu,
        IsNewlyAdded: menu.IsNewlyAdded,
        orgHierarchy: [menu.MenuMasterID],
        HasChild: menu.HasChild.toString(),
        MenuTypeName: menu.MenuType === 1 ? "BizNext" : menu.MenuType === 2 ? "MyPortal" : "",
      };

      if (menu.UnderMenuID.toString() !== "0") {
        const parentMenu = menuListData.find((x) => x.MenuMasterID.toString() === menu.UnderMenuID.toString());
        if (parentMenu !== null && parentMenu !== undefined) {
          menuData.orgHierarchy.push(parentMenu.MenuMasterID);
          recHierarchy(menuData, parentMenu);
        }
      }
      menuTreeListData.push(menuData);
    });

    menuTreeListData.forEach((menu) => {
      menu.orgHierarchy = menu.orgHierarchy.reverse();
    });
    setTreeMenuListData(menuTreeListData);
  };

  const recHierarchy = (menu, parent) => {
    if (parent.UnderMenuID.toString() !== 0) {
      var parentMenu = menuListData.find((x) => x.MenuMasterID.toString() === parent.UnderMenuID.toString());
      if (parentMenu !== null && parentMenu !== undefined) {
        menu.orgHierarchy.push(parentMenu.MenuMasterID);
        recHierarchy(menu, parentMenu);
      }
    }
  };

  const [rightsData, setRightsData] = useState(null);
  const [manageLocationTypePopupActive, setManageLocationTypePopupActive] = useState(false);

  const toggleManageLocationTypePopup = (data) => {
    setRightsData(data);
    setManageLocationTypePopupActive(!manageLocationTypePopupActive);
  };

  const updateProfileMgmt = (data) => {
    debugger;
    profileMasterData.unshift(data);
    setProfileMasterData(profileMasterData);
    if (profilegridApi) {
      profilegridApi.setRowData(profileMasterData);
    }
  };

  const onSearchProfileList = (val) => {
    gridApi.setQuickFilter(val);
  };

  const onAssignMenu = (menu) => {
    if (selectedModule === null || selectedModule === undefined || selectedModule.ModuleCode === undefined) {
      setMsgAlert({ open: true, type: "warning", msg: "Please select Module." });
      if (menuInput.current) {
        menuInput.current.focus();
      }
      return;
    }

    if (selectedCompanyMaster === null || selectedCompanyMaster === undefined || selectedCompanyMaster.CompanyMasterID === undefined) {
      setMsgAlert({ open: true, type: "warning", msg: "Please select Company" });

      if (companyInput.current) {
        menuInput.current.focus();
      }
      return;
    }
    if (profileListData === null || profileListData === undefined || profileListData.UserProfileID === undefined) {
      setMsgAlert({ open: true, type: "warning", msg: "Please select User Profile" });
    }

    console.log("onUnAssignMenu", menu);

    getMenuListData(0, profileListData.UserProfileID, selectedCompanyMaster.CompanyMasterID, menu.MenuMasterID, selectedModule.ModuleCode, "AssignMenu");
  };

  const onGetMenuClick = (moduleData) => {
    debugger;
    console.log("moduleData,", moduleData);
    console.log(selectedCompanyMaster, "selectedCompanyMaster");
    setProfileListData(moduleData);
    if (activeView === 1) {
      if (selectedModule === null || selectedModule === undefined || selectedModule.ModuleCode === undefined) {
        setMsgAlert({ open: true, type: "warning", msg: "Please select Module." });
        if (menuInput.current) {
          menuInput.current.focus();
        }
        return;
      }
    }
    if (selectedCompanyMaster === null || selectedCompanyMaster === undefined || selectedCompanyMaster.CompanyMasterID === undefined) {
      setMsgAlert({ open: true, type: "warning", msg: "Please select Company" });

      if (companyInput.current) {
        menuInput.current.focus();
      }
      return;
    }

    if (moduleData && moduleData.UserProfileID) {
      _updateSelectedRowStyles(moduleData);
      setSelectedProfileRow(moduleData);
      if (activeView === 1) getMenuListData(0, moduleData.UserProfileID, selectedCompanyMaster.CompanyMasterID, 0, selectedModule.ModuleCode, "AssignedMenu");
      if (activeView === 2) getAssignedUserList(moduleData.UserProfileID);
    } else {
      if (activeView === 1) {
        setMsgAlert({ open: true, type: "warning", message: "Please Select Profile .!" });
      }
    }
    setSelectedRowColorUserProfile(moduleData.UserProfileID);
    setSelectedModule(selectedModule);
    console.log(selectedModule, "selectedModule");
  };

  const setSelectedRowColorUserProfile = (UserProfileID) => {
    if (profilegridApi) {
      profilegridApi.forEachNode(function (rowNode) {
        if (rowNode.data.UserProfileID === UserProfileID) {
          const newData = {
            ...rowNode.data,
            IsSelected: true,
          };
          rowNode.setData(newData);
        } else {
          rowNode.data.IsSelected = false;
          rowNode.setData(rowNode.data);
        }
      });
    }
  };

  const onUnAssignMenu = (menu) => {
    if (selectedModule === null || selectedModule === undefined || selectedModule.ModuleCode === undefined) {
      setMsgAlert({ open: true, type: "warning", msg: "Please select Module." });
      if (menuInput.current) {
        menuInput.current.focus();
      }
      return;
    }

    if (selectedCompanyMaster === null || selectedCompanyMaster === undefined || selectedCompanyMaster.CompanyMasterID === undefined) {
      setMsgAlert({ open: true, type: "warning", msg: "Please select Company" });

      if (companyInput.current) {
        menuInput.current.focus();
      }
      return;
    }
    console.log("onUnAssignMenu", menu);

    getMenuListData(menu.ProfileMenuID, profileListData.UserProfileID, selectedCompanyMaster.CompanyMasterID, menu.MenuMasterID, selectedModule.ModuleCode, "UNAssignMenu");
    //   if (formValues.txtCompany.CompanyMasterID && menu.MenuMasterID && menu.CompanyMenuID && menu.MenuName) {
    //     menuAssignedOrUnAssigned(formValues.txtCompany.CompanyMasterID.toString(), menu, "UNASSIGN");
    //}
  };

  const [addProfileManagementformPopupActive, setAddProfileManagementformPopupActive] = useState({
    IsOpen: false,
    IsEditMode: "",
    popupData: null,
  });

  const toggleaddUserProfilePopUp = (isEdit, editData) => {
    setAddProfileManagementformPopupActive({
      IsOpen: !addProfileManagementformPopupActive.IsOpen,
      IsEditMode: isEdit,
      popupData: editData,
    });
  };

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    }
    return { background: "" };
  };

  const updateMenuTreeData = (data, viewMOde, responseData) => {
    if (gridApi) {
      gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.MenuMasterID === data.MenuMasterID) {
          const newData = {
            ...rowNode.data,
            ProfileMenuID: responseData ? responseData : 0,
            // eslint-disable-next-line
            AssignmentFlag: viewMOde == "AssignMenu" ? 1 : 0,
            isUpdated: true,
          };
          rowNode.setData(newData);
        } else {
          rowNode.setData(rowNode.data);
        }
      });
    }
  };

  const assignUnassignMenu = async (data, viewMOde) => {
    console.log(data, "data");
    console.log(profileListData, "profileListData");
    console.log(selectedCompanyMaster, "selectedCompanyMaster.CompanyMasterID");
    try {
      setIsLoadingMenuList(true);
      // getMenuListData(menu.ProfileMenuID, profileListData.UserProfileID, selectedCompanyMaster.CompanyMasterID, menu.MenuMasterID, "UNAssignMenu");
      let result = await profileMenuAssignList(
        data.ProfileMenuID,
        profileListData.UserProfileID,
        selectedCompanyMaster.CompanyMasterID,
        data.MenuMasterID,
        selectedModule.ModuleCode,
        viewMOde,
      );
      setIsLoadingMenuList(false);
      if (result.responseCode === 1) {
        // eslint-disable-next-line
        if (viewMOde == "AssignMenu") {
          updateMenuTreeData(data, viewMOde, result.responseData[0].ProfileMenuID);
        } else {
          updateMenuTreeData(data, viewMOde);
        }
        // eslint-disable-next-line
        setMsgAlert({ open: true, type: "success", msg: viewMOde == "AssignMenu" ? "Menu Successfully Assigned" : "Menu Successfully UnAssigned" });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setIsLoadingMenuList(false);
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [searchTextModuleMaster, setSearchTextModuleMaster] = useState("");
  const onSearchMasterCommonCode = (val) => {
    setSearchTextModuleMaster(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
    setModuleMasterData([]);
  };

  const [searchTextUserMaster, setSearchTextUserMaster] = useState("");
  const onSearchMasterUser = (val) => {
    setSearchTextUserMaster(val);
    gridApi3.setQuickFilter(val);
    gridApi3.refreshCells();
  };

  const [searchTextProfileMaster, setSearchTextProfileMaster] = useState("");
  const onSearchProfileCode = (val) => {
    setSearchTextProfileMaster(val);
    profilegridApi.setQuickFilter(val);
    profilegridApi.refreshCells();
  };

  const [userProfileAssignPopup, setUserAssignProfilePopup] = useState({
    IsOpen: false,
    popupData: null,
  });

  const toggleAssignProfile = (data) => {
    setUserAssignProfilePopup({
      IsOpen: !userProfileAssignPopup.IsOpen,
      popupData: data,
    });
  };

  const deleteUser = async (data) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      let formData = {
        userProfileId: selectedProfileRow && selectedProfileRow.UserProfileID ? selectedProfileRow.UserProfileID : 0,
        companyMasterId: selectedCompanyMaster.CompanyMasterID ? selectedCompanyMaster.CompanyMasterID : 0,
        action: "UNASSIGN",
        profileAssignId: data && data.ProfileAssignID ? data.ProfileAssignID.toString() : "",
        companyUserId: data && data.CompanyUserID ? data.CompanyUserID.toString() : "",
        userProfileType: "D",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await manageUserProfileAssign(formData);

      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        const results = userList.filter((el) => {
          return el.CompanyUserID !== data.CompanyUserID;
        });
        setUserList(results);
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const _updateSelectedRowStyles = (data) => {
    // if (gridApi) {
    //   const itemsToUpdate = [];
    //   gridApi.forEachNode(function (rowNode) {
    //     if (data && data.UserProfileID && rowNode.data.UserProfileID === data.UserProfileID) {
    //       const newData = {
    //         ...rowNode.data,
    //         IsSelected: true,
    //       };
    //       rowNode.setData(newData);
    //       itemsToUpdate.push(newData);
    //     } else if (rowNode.data && rowNode.data.IsSelected) {
    //       rowNode.data.IsSelected = false;
    //       rowNode.setData(rowNode.data);
    //       itemsToUpdate.push(rowNode.data);
    //     }
    //   });
    //   gridApi.updateRowData({
    //     update: itemsToUpdate,
    //   });
    // }
  };

  const [gridApi3, setGridApi3] = useState(null);
  const _onGridReady3 = (params) => {
    setGridApi3(params.api);
  };
  const [unAssignedUserProfilePopup, setUnAssignedUserProfilePopup] = useState(false);
  const toggleUnAssignedUserProfilePopup = () => {
    if (selectedCompanyMaster && selectedCompanyMaster.CompanyMasterID) {
      if (selectedProfileRow && selectedProfileRow.UserProfileID) {
        setUnAssignedUserProfilePopup(!unAssignedUserProfilePopup);
      } else {
        setMsgAlert({ open: true, type: "warning", msg: "Select A profile" });
      }
    } else {
      setMsgAlert({ open: true, type: "warning", msg: "Select A Company" });
    }
  };
  const _updateUserList = (data) => {
    debugger;
    data.forEach((d) => {
      userList.unshift({ ...d, IsNewlyAdded: true });
    });
    setUserList(userList);
    if (gridApi3) {
      gridApi3.setRowData(userList);
    }
  };

  const getAssignedUserList = async (data) => {
    const ip = await publicIpv4();
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      setIsLoadingUserList(true);
      let formData = {
        userProfileId: data,
        companyMasterId: selectedCompanyMaster.CompanyMasterID ? selectedCompanyMaster.CompanyMasterID : 0,
        action: "GETASSIGNED",
        profileAssignId: "",
        companyUserId: "",
        userProfileType: "",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await manageUserProfileAssign(formData);
      setIsLoadingUserList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setUserList(result.responseData);
        } else {
          setUserList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedProfileRow) {
      onGetMenuClick(selectedProfileRow);
    }
  }, [activeView]);

  const ProfileGridColumnDefs = [
    { field: "#", headerName: "Action", width: 80, pinned: "left", lockPosition: "1", cellRenderer: "actionTemplate", cellRendererParams: { onGetMenuClick, toggleAssignProfile } },
    {
      field: "ProfileName",
      headerName: "Profile Name",
      width: 180,
      pinned: "left",
      lockPosition: "1",
    },
    {
      field: "ProfileDescription",
      headerName: "Profile Description",
      width: 280,
      pinned: "left",
    },
  ];

  const menuListGridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 180,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellRendererParams: { onAssignMenu, onUnAssignMenu, assignUnassignMenu, toggleManageLocationTypePopup },
    },
    {
      field: "MenuName",
      hide: true,
      headerName: "Menu Name",
      width: 280,
      pinned: "left",
      lockPosition: 2,
    },
    {
      field: "MenuTypeName",
      headerName: "Menu Type",
      width: 120,
    },
    {
      field: "WebURL",
      headerName: "Web Url",
      width: 150,
    },
    {
      field: "ReactURL",
      headerName: "React Url",
      width: 180,
    },
    {
      field: "WPFURL",
      headerName: "WPF Url",
      width: 120,
    },
  ];

  const listViewColumDef3 = [
    {
      headerName: "Action",
      lockPosition: "1",
      pinned: "left",
      width: 100,
      cellRenderer: "actionCellTemplate",
      cellRendererParams: { deleteUser, setConfirmAlert },
    },
    // { valueGetter: "node.rowIndex + 1", field: "#", headerName: "Sr No.", width: 80 },
    // { headerName: "Profile ID", field: "UserProfileID", width: 120 },
    { headerName: "Display Name", field: "UserDisplayName", width: 120 },
    { headerName: "Profile Name", field: "ProfileName", width: 120 },
    { headerName: "Profile Description", field: "ProfileDescription", width: 150 },
    { headerName: "Profile Type", field: "UserProfileType", width: 120 },
  ];
  return (
    <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {addProfileManagementformPopupActive.IsOpen ? (
        <AddProfile
          selectedCompanyMaster={selectedCompanyMaster}
          toggleaddUserProfilePopUp={toggleaddUserProfilePopUp}
          setMsgAlert={setMsgAlert}
          updateProfileMgmt={updateProfileMgmt}
          masterData={addProfileManagementformPopupActive.popupData}
          IsEditMode={addProfileManagementformPopupActive.IsEditMode}
          setConfirmAlert={setConfirmAlert}
          getProfileMasterData={getProfileMasterData}
        />
      ) : null}
      {unAssignedUserProfilePopup ? (
        <UnAssignedUserProfile
          setConfirmAlert={setConfirmAlert}
          selectedProfileRow={selectedProfileRow}
          _updateUserList={_updateUserList}
          toggleUnAssignedUserProfilePopup={toggleUnAssignedUserProfilePopup}
          setMsgAlert={setMsgAlert}
          selectedCompanyMaster={selectedCompanyMaster}
          getAssignedUserList={getAssignedUserList}
          selectedModule={profileListData}
        />
      ) : null}
      {manageLocationTypePopupActive ? (
        <ManageRightsPopup
          rightsData={rightsData}
          selectedCompanyMaster={selectedCompanyMaster}
          companyUser={userList}
          setMsgAlert={setMsgAlert}
          onDisplayClick={onGetMenuClick}
          selectedModule={selectedModule}
          toggleManageLocationTypePopup={toggleManageLocationTypePopup}
          setConfirmAlert={setConfirmAlert}
          profileListData={profileListData}
        />
      ) : null}
      <div className={BizClass.Biz_Bm_Manage_Menu_ContentBox}>
        <Splitter varient="column" template="0.9fr 9px 1fr">
          <div className={BizClass.MainSection}>
            <PageBar title="Profile Management">
              <PageBar.Select
                width={"250px"}
                getOptionLabel={(option) => `${option.CompanyName}`}
                value={selectedCompanyMaster && selectedCompanyMaster}
                getOptionValue={(option) => `${option}`}
                options={companyList}
                onChange={(e) => updateState("txtCompany", e)}
                isSearchable={true}
                isLoading={isLoadingCompanyList}
              />
              <PageBar1.Search
                focus={true}
                onClick={() => getProfileMasterData(selectedCompanyMaster.CompanyMasterID)}
                value={searchTextProfileMaster}
                onChange={(e) => onSearchProfileCode(e.target.value)}
              />
              <PageBar.Button onClick={() => toggleaddUserProfilePopUp()}>Add</PageBar.Button>
            </PageBar>

            <DataGrid
              rowData={profileMasterData}
              columnDefs={ProfileGridColumnDefs}
              onGridReady={onProfileGridReady}
              autoGroupColumnDef={{
                headerName: "Menu Name",
                minWidth: 300,
                // cellRendererParams: { suppressCount: true },
                cellRendererParams: {
                  innerRenderer: function (params) {
                    return params.data;
                  },
                },
              }}
              getRowStyle={function (data) {
                if (data.data.IsNewlyAdded) {
                  //gridApi.ensureIndexVisible(Number(data.rowIndex));
                  return { background: "#d5a10e" };
                } else if (data.data.IsSelected) {
                  return { background: "#ffc176" };
                } else if (data.data.isUpdated) {
                  return { background: "#ffd40085" };
                } else {
                  return { background: "" };
                }
              }}
              frameworkComponents={{
                actionTemplate: cellTemplate,
              }}
            />
          </div>
          <div className={BizClass.MainSection}>
            <PageBar title={`${activeView === 1 ? "Menu List" : "User List"} ${selectedProfileRow && selectedProfileRow.ProfileName ? `(${selectedProfileRow.ProfileName})` : ""}`}>
              <div className={BizClass.PageTitleBar}>
                <ul className={BizClass.TabBar}>
                  <li>
                    <button type="button" className={activeView === 1 && BizClass.Active} onClick={() => setActiveView(1)}>
                      <HiMenuAlt1 />
                      Menu
                    </button>
                  </li>
                  <li>
                    <button type="button" className={activeView === 2 && BizClass.Active} onClick={() => setActiveView(2)}>
                      <FaUser />
                      User
                    </button>
                  </li>
                </ul>
              </div>
              <PageBar.Select
                name="txtModule"
                ref={menuInput}
                getOptionValue={(option) => `${option}`}
                options={moduleMasterData}
                onChange={(e) => updateState("txtModule", e)}
                getOptionLabel={(option) => `${option.ModuleName}`}
                isLoading={isLoadingModuleMasterData}
                label="Select Module"
              />
              {activeView === 1 ? (
                <PageBar1.Search
                  onClick={() => getMenuListData(0, profileListData.UserProfileID, selectedCompanyMaster.CompanyMasterID, 0, selectedModule.ModuleCode, "AssignedMenu")}
                  value={searchTextModuleMaster}
                  onChange={(e) => onSearchMasterCommonCode(e.target.value)}
                />
              ) : (
                <PageBar1.Search
                  onClick={() => getAssignedUserList(selectedProfileRow.UserProfileID)}
                  value={searchTextUserMaster}
                  onChange={(e) => onSearchMasterUser(e.target.value)}
                />
              )}
              {/* <PageBar1.Search
                onClick={() => getMenuListData(0, profileListData.UserProfileID, selectedCompanyMaster.CompanyMasterID, 0, selectedModule.ModuleCode, "AssignedMenu")}
                value={searchTextUserMaster}
                onChange={(e) => onSearchMasterUser(e.target.value)}
              /> */}
              {activeView === 2 ? <PageBar.Button onClick={() => toggleUnAssignedUserProfilePopup(null)}>Import</PageBar.Button> : null}
            </PageBar>
            {activeView === 1 && (
              <DataGrid
                getRowStyle={getRowStyle}
                onGridReady={onGridReady}
                columnDefs={menuListGridColumnDefs}
                rowData={treeMenuListData}
                autoGroupColumnDef={{
                  headerName: "Menu Name",
                  minWidth: 300,
                  lockPosition: 2,
                  cellRendererParams: {
                    innerRenderer: function (params) {
                      return params.data.MenuName;
                    },
                  },
                }}
                loader={isLoadingMenuList ? <Loader /> : null}
                frameworkComponents={{
                  actionTemplate: cellmenuTemplate,
                }}
                // openSubmenuPopup={openSubmenuPopup}
                treeData={true}
                animateRows={true}
                groupDefaultExpanded={-1}
                getDataPath={(data) => {
                  return data.orgHierarchy;
                }}
              />
            )}
            {activeView === 2 && (
              <DataGrid
                rowData={userList}
                columnDefs={listViewColumDef3}
                loader={isLoadingUserList ? <Loader /> : null}
                onGridReady={_onGridReady3}
                getRowStyle={getRowStyle}
                frameworkComponents={{
                  actionCellTemplate: ActionCellTemplate,
                }}
              />
            )}
          </div>
        </Splitter>
        <Footer />
      </div>
    </div>
  );
};

export default ProfileManagement;
const cellmenuTemplate = (props) => {
  let menuData = props.data;
  const toggleManageLocationTypePopup = () => {
    props.toggleManageLocationTypePopup(menuData);
  };
  return (
    <React.Fragment>
      <div>
        {menuData.AssignmentFlag === 1 ? (
          <React.Fragment>
            <button
              onClick={() => props.assignUnassignMenu(menuData, "UNAssignMenu")}
              className="Biz_Bm_Company_ManageLocationTypePopup_AssButton Assigned"
              style={{ cursor: "pointer" }}
            >
              UnAssign
            </button>
          </React.Fragment>
        ) : (
          <button
            onClick={() => props.assignUnassignMenu(menuData, "AssignMenu")}
            className="Biz_Bm_Company_ManageLocationTypePopup_AssButton UnAssigned"
            style={{ cursor: "pointer" }}
          >
            Assign
          </button>
        )}
        {menuData && menuData.HasChild && menuData.HasChild.toString() === "0" ? (
          <>
            <span title="Rights" style={{ cursor: "pointer", paddingRight: "8px", marginLeft: "6px", marginTop: "5px" }}>
              <FaFileAlt className="Biz_Bm__Icon" onClick={() => toggleManageLocationTypePopup()} />{" "}
            </span>
          </>
        ) : null}
      </div>
    </React.Fragment>
  );
};

const cellTemplate = (props) => {
  let menuData = props.data;
  const toggleAssignProfile = () => {
    props.toggleAssignProfile(menuData);
  };

  return (
    <React.Fragment>
      <div>
        <React.Fragment>
          <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
            <span
              style={{
                cursor: "pointer",
                display: "grid",
                marginRight: "7px",
                marginTop: "1px",
              }}
              title="View File"
            >
              <ImEye style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => props.onGetMenuClick(props.data)} />
            </span>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

const ActionCellTemplate = (props) => {
  const setConfirmAlert = props.setConfirmAlert;

  const deleteUser = () => {
    props.deleteUser(props.data);
  };

  return (
    <React.Fragment>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          paddingTop: "2px",
          // display: "flex",
        }}
      >
        <RiDeleteBin6Fill
          className="Biz__Icon"
          style={{ fontSize: "15px", color: "#f54e60" }}
          onClick={() =>
            setConfirmAlert({
              open: true,
              title: "Delete",
              msg: "Are you sure to delete ?",
              button: {
                confirmText: "Delete",
                abortText: "Cancel",
                Color: "Danger",
              },
              onConfirm: () => deleteUser(),
            })
          }
        />
      </span>
    </React.Fragment>
  );
};

// const ActionCellTemplate3 = (props) => {
//   let cellData = props.data;
//   const _onConfirmDeleteUser = () => {
//     _onConfirmDeleteUser(cellData);
//   };
//   const buttonStyles = {
//     display: "flex",
//     fontSize: "16px",
//     background: "transparent",
//     border: "0px solid transparent",
//     padding: "3px 0px 5px 0px",
//   };
//   const dFlexStyles = {
//     display: "flex",
//     gap: "8px",
//     alignItems: "center",
//     marginTop: "1.5px",
//   };

//   return (
//     <div style={dFlexStyles}>
//       <button type="button" style={buttonStyles} title="Delete" onClick={() => _onConfirmDeleteUser()}>
//         <GoTrashcan style={{ fontSize: "16px", color: "rgb(52, 73, 94)", color: "red" }} />
//       </button>
//     </div>
//   );
// };
