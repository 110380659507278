import React, { useState, useEffect, useRef } from "react";
import { CustomMsg } from "../../../../Configration/Utilities/customMessage";
import Button from "../../../../Framework/Components/Widgets/Button/Button";
import Modal from "../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { UpdatePageConfigData, CreatePageConfigData } from "../Resources/PageConfigService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import { getCommonValue } from "../../PageMaster/Resources/PageMasterService";

const AddPageConfig = (props) => {
  const firstTextInput = useRef();
  let toggleAddPageConfig = props.toggleAddPageConfig;
  let setMsgAlert = props.setMsgAlert;
  let updatePageConfigList = props.updatePageConfigList;
  let _updateListingPageConfig = props._updateListingPageConfig;
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  let selectedPage = props.selectedPage;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtControlType: null,
    txtControlName: "",
    txtControlPlaceHolder: "",
    txtControlMinLength: "",
    txtControlMaxLength: "",
    txtControlMinValue: "",
    txtControlMaxValue: "",
    txtTextExpression: "",
    txtAllowedCharacter: "",
    txtAllowedMaxLength: "",
    isRequired: true,
    allowOptional: true,
  });
  const [oldFormValues, setOldFormValues] = useState({
    txtModule: null,
    txtPageName: null,
    txtControlType: null,
    txtControlName: "",
    txtControlPlaceHolder: "",
    txtControlMinLength: "",
    txtControlMaxLength: "",
    txtControlMinValue: "",
    txtControlMaxValue: "",
    txtTextExpression: "",
    txtAllowedCharacter: "",
    txtAllowedMaxLength: "",
    isRequired: true,
    allowOptional: true,
  });
  const TaxTypeOptions = [
    { Name: "Direct", Value: 1 },
    { Name: "In-Direct", Value: 2 },
  ];

  const validateField = (name, value) => {
    let errorMsg = "";
    if (name === "txtControlName" || name === "txtControlType") {
      if (!value || typeof value === "undefined") {
        errorMsg = "Cannot be empty";
      }
    }
    return errorMsg;
  };

  const [formValidationError, setFormValidationError] = useState({});
  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtControlName"] = validateField("txtControlName", formValues.txtControlName);
      errors["txtControlType"] = validateField("txtControlType", formValues.txtControlType);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);
      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "error", message: "Something went wrong!" });
      return false;
    }
  };

  useEffect(() => {
    if (isEditMode) {
      if (popupData) {
        setEditBankMaster(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);
  const [editBankMaster, setEditBankMaster] = useState();
  useEffect(() => {
    if (editBankMaster) {
      formValues.txtPageName = editBankMaster?.BizNextPageName || "";
      formValues.txtControlType = { CommonMasterValue: editBankMaster?.ControlType || "", CommonMasterValueID: editBankMaster?.ControlTypeID || 0 };
      formValues.txtControlName = editBankMaster?.PageControlName || "";
      formValues.txtControlPlaceHolder = editBankMaster?.ControlPlaceholder || "";
      formValues.txtControlMinLength = editBankMaster?.ControlMinLength || 0;
      formValues.txtControlMaxLength = editBankMaster?.ControlMaxLength || 0;
      formValues.txtControlMinValue = editBankMaster?.ControlMaxValue || 0;
      formValues.txtControlMaxValue = editBankMaster?.ControlMinValue || 0;
      formValues.txtTextExpression = editBankMaster?.TextExpression || "";
      formValues.txtAllowedCharacter = editBankMaster?.AllowedCharacter || "";
      formValues.txtAllowedMaxLength = editBankMaster?.AllowedMaxLength || 0;
      formValues.isRequired = editBankMaster.isRequired && editBankMaster.isRequired === "YES" ? true : false;
      formValues.allowOptional = editBankMaster.AllowOptional && editBankMaster.AllowOptional === "YES" ? true : false;
    }
    setFormValues({ ...formValues });
    setOldFormValues({ ...formValues });
  }, [editBankMaster]);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
    if (name === "allowOptional") {
      if (!value) {
        setFormValues((exist) => ({
          ...exist,
          isRequired: true,
        }));
      }
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to Update" : "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };
  const [saveMore, setSaveMore] = useState(false);
  const onSubmit = async () => {
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;

      const ip = await publicIpv4();
      let formData = {};
      if (isEditMode) {
        formData = {
          BizNextPageConfigID: popupData && popupData.BizNextPageConfigID ? popupData.BizNextPageConfigID : 0,
          BizNextPageID: selectedPage?.BizNextPageID || 0,
          ControlTypeID: formValues?.txtControlType?.CommonMasterValueID || 0,
          PageControlName: formValues?.txtControlName || "",
          ControlPlaceholder: formValues?.txtControlPlaceHolder || "",
          AllowOptional: formValues.allowOptional ? "YES" : "NO",
          isRequired: formValues.isRequired ? "YES" : "NO",
          ControlMinLength: formValues?.txtControlMinLength || 0,
          ControlMaxLength: formValues?.txtControlMinLength || 0,
          ControlMinValue: formValues?.txtControlMinValue || 0,
          ControlMaxValue: formValues?.txtControlMaxValue || 0,
          AllowedMaxLength: formValues?.txtAllowedMaxLength || 0,
          TextExpression: formValues?.txtTextExpression || "",
          AllowedCharacter: formValues?.txtAllowedCharacter || "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          BizNextPageID: selectedPage?.BizNextPageID || 0,
          ControlTypeID: formValues?.txtControlType?.CommonMasterValueID || 0,
          PageControlName: formValues?.txtControlName || "",
          ControlPlaceholder: formValues?.txtControlPlaceHolder || "",
          AllowOptional: formValues.allowOptional ? "YES" : "NO",
          isRequired: formValues.isRequired ? "YES" : "NO",
          ControlMinLength: formValues?.txtControlMinLength || 0,
          ControlMaxLength: formValues?.txtControlMinLength || 0,
          ControlMinValue: formValues?.txtControlMinValue || 0,
          ControlMaxValue: formValues?.txtControlMaxValue || 0,
          AllowedMaxLength: formValues?.txtAllowedMaxLength || 0,
          TextExpression: formValues?.txtTextExpression || "",
          AllowedCharacter: formValues?.txtAllowedCharacter || "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }
      setBtnloaderActive(true);
      let fun = isEditMode ? UpdatePageConfigData : CreatePageConfigData;
      let result = await fun(formData);
      setBtnloaderActive(false);
      debugger;
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        console.log(result.responseData, "result.responseData");
        console.log(result.responseData.data, "result.responseData.data");
        if (result.responseData) {
          let addedCode = {
            BizNextPageConfigID: isEditMode
              ? popupData.BizNextPageConfigID
              : result.responseData && result.responseData.data && result.responseData.data.BizNextPageConfigID
              ? result.responseData.data.BizNextPageConfigID
              : 0,
            BizNextPageID: selectedPage?.BizNextPageID || 0,
            ControlTypeID: formValues?.txtControlType?.CommonMasterValueID || 0,
            PageControlName: formValues?.txtControlName || "",
            ControlPlaceholder: formValues?.txtControlPlaceHolder || "",
            AllowOptional: formValues.allowOptional ? "YES" : "NO",
            isRequired: formValues.isRequired ? "YES" : "NO",
            ControlMinLength: formValues?.txtControlMinLength || 0,
            ControlMaxLength: formValues?.txtControlMinLength || 0,
            ControlMinValue: formValues?.txtControlMinValue || 0,
            ControlMaxValue: formValues?.txtControlMaxValue || 0,
            AllowedMaxLength: formValues?.txtAllowedMaxLength || 0,
            TextExpression: formValues?.txtTextExpression || "",
            AllowedCharacter: formValues?.txtAllowedCharacter || "",
            ControlType: formValues?.txtControlType?.CommonMasterValue || "",
            IsNewlyAdded: true,
          };

          if (isEditMode) {
            _updateListingPageConfig({ ...addedCode, IsNewlyAdded: false, IsUpdated: true });
            toggleAddPageConfig(false, null);
          } else {
            setSaveMore(true);
            updatePageConfigList(addedCode);
          }

          console.log(addedCode, "addedCode");
          clearForm();
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtControlType: null,
      txtControlName: "",
      txtControlPlaceHolder: "",
      txtControlMinLength: "",
      txtControlMaxLength: "",
      txtControlMinValue: "",
      txtControlMaxValue: "",
      txtTextExpression: "",
      txtAllowedCharacter: "",
      txtAllowedMaxLength: "",
      isRequired: true,
      allowOptional: true,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && popupData) {
      return true;
    }

    return false;
  };
  const [controlType, setControlType] = useState([]);
  const [isLoadingControlType, setIsLoadingControlType] = useState(false);

  const getControlType = async (data) => {
    try {
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      setIsLoadingControlType(true);
      const formData = {
        action: "GETLIST",
        commonMasterCode: 122,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getCommonValue(formData);
      console.log(result, "result");
      setIsLoadingControlType(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setControlType(result.responseData);
        } else {
          setControlType([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getControlType();
  }, []);

  return (
    <Modal varient={"center"} title={isEditMode ? "Update Page Config" : "Add Page Config"} onSubmit={handleSave} show={() => toggleAddPageConfig(false, null)}>
      <Modal.Body>
        <Form>
          <Form.Group column={2} controlwidth={"180px"}>
            {/* <Form.InputGroup column={3} errorMsg={formValidationError["txtModule"]} label="Module" req={true}>
              <Form.InputControl
                control="select"
                name="txtModule"
                type="text"
                value={formValues.txtModule}
                onChange={(e) => {
                  formValidationError["txtModule"] = validateField("txtModule", e);
                  updateState("txtModule", e);
                }}
                getOptionLabel={(option) => `${option.ModuleName}`}
                getOptionValue={(option) => `${option}`}
                options={moduleMasterData}
              />
            </Form.InputGroup>
            <Form.InputGroup column={3} errorMsg={formValidationError["txtPageName"]} label="Page Name" req={true}>
              <Form.InputControl
                control="select"
                name="txtPageName"
                type="text"
                value={formValues.txtPageName}
                onChange={(e) => updateState("txtPageName", e)}
                getOptionLabel={(option) => `${option.BizNextPageName}`}
                getOptionValue={(option) => `${option}`}
                options={PageMasterList}
              />
            </Form.InputGroup> */}

            <Form.InputGroup column={3} errorMsg={formValidationError["txtControlType"]} label="Control Type" req={true}>
              <Form.InputControl
                control="select"
                name="txtControlType"
                type="text"
                value={formValues.txtControlType}
                onChange={(e) => updateState("txtControlType", e)}
                getOptionLabel={(option) => `${option.CommonMasterValue}`}
                getOptionValue={(option) => `${option}`}
                options={controlType}
              />
            </Form.InputGroup>

            <Form.InputGroup column={3} errorMsg={formValidationError["txtControlName"]} label="Control Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtControlName"
                type="text"
                value={formValues.txtControlName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                maxLength={50}
              />
            </Form.InputGroup>

            <Form.InputGroup column={3} errorMsg={formValidationError["txtControlPlaceHolder"]} label="Control PlaceHolder" req={false}>
              <Form.InputControl
                control="input"
                name="txtControlPlaceHolder"
                type="text"
                value={formValues.txtControlPlaceHolder}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                maxLength={50}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["allowOptional"]} label="Allow Optional" req={false}>
              <Form.InputControl control="switch" name="allowOptional" onChange={(e) => updateState(e.target.name, e.target.checked)} />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["isRequired"]} label="Is Required" req={formValues.allowOptional === false ? true : false}>
              <Form.InputControl
                control="switch"
                disabled={formValues.allowOptional === false ? true : false}
                name="isRequired"
                checked={formValues.isRequired}
                onChange={(e) => updateState(e.target.name, e.target.checked)}
              />
            </Form.InputGroup>

            <Form.InputGroup column={3} errorMsg={formValidationError["txtAllowedMaxLength"]} label="Allowed Max Length" req={false}>
              <Form.InputControl
                control="input"
                name="txtAllowedMaxLength"
                type="text"
                value={formValues.txtAllowedMaxLength}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtControlMinLength"]} label="Control Min Length" req={false}>
              <Form.InputControl
                control="input"
                name="txtControlMinLength"
                type="text"
                value={formValues.txtControlMinLength}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtControlMaxLength"]} label="Control Max Length" req={false}>
              <Form.InputControl
                control="input"
                name="txtControlMaxLength"
                type="text"
                value={formValues.txtControlMaxLength}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtControlMinValue"]} label="Control Min Value" req={false}>
              <Form.InputControl
                control="input"
                name="txtControlMinValue"
                type="text"
                value={formValues.txtControlMinValue}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtControlMaxValue"]} label="Control Max Value" req={false}>
              <Form.InputControl
                control="input"
                name="txtControlMaxValue"
                type="text"
                value={formValues.txtControlMaxValue}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
              />
            </Form.InputGroup>

            <Form.InputGroup column={3} errorMsg={formValidationError["txtAllowedCharacter"]} label="Allowed Character" req={false}>
              <Form.InputControl
                control="input"
                name="txtAllowedCharacter"
                type="text"
                value={formValues.txtAllowedCharacter}
                maxLength={20}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup column={3} errorMsg={formValidationError["txtTextExpression"]} label="Text Expression" req={false}>
              <Form.InputControl
                control="input"
                name="txtTextExpression"
                type="text"
                value={formValues.txtTextExpression}
                maxLength={150}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" disabled={checkEditFormControlValues() || btnloaderActive} type="submit" trigger={false}>
          {isEditMode ? "Update" : saveMore ? "Save More" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddPageConfig;
