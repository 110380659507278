/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";
import { PageSearch } from "../../../../../Framework/OldFramework/PageTitle/PageTitle";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { GetHistoryData } from "../../../Resources/companyRegistrationService";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";

function ViewAddressRelationHistory(props) {
  const { show, setMsgAlert, popupData, viewMode, address, selectedCompanyMaster, selectedLocationType } = props;
  const [historyList, setHistoryList] = useState([]);
  const [isLoadingHistoryList, setIsLoadingHistoryList] = useState([]);
  console.log(popupData, "popupData");
  const getHistoryList = async () => {
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      const ip = await publicIpv4();
      console.log(viewMode, selectedLocationType.value, "viewMode,selectedLocationType.value");
      let formData = {
        ViewMode: selectedLocationType?.value || "",
        CompanyMasterID: selectedCompanyMaster?.CompanyMasterID || 0,
        AccountingBranchID:
          viewMode && viewMode === "Assigned"
            ? selectedLocationType.value && selectedLocationType.value === "ACCWAR"
              ? popupData.AccountingBranchID
              : 0
            : viewMode && viewMode === "UnAssigned" && selectedLocationType.value && selectedLocationType.value === "ACCWAR" && address && address.CompanyAddressID
            ? address.CompanyAddressID
            : 0,
        ProductionPlantID:
          viewMode && viewMode === "Assigned"
            ? selectedLocationType && selectedLocationType.value && selectedLocationType.value === "PRDWAR"
              ? popupData.ProductionPlantID
              : 0
            : viewMode && viewMode === "UnAssigned" && selectedLocationType.value && selectedLocationType.value === "PRDWAR" && address && address.CompanyAddressID
            ? address.CompanyAddressID
            : 0,
        WarehouseMasterID: popupData?.WarehouseMasterID || 0,
      };

      setIsLoadingHistoryList(true);
      let result = await GetHistoryData(formData);
      setIsLoadingHistoryList(false);
      if (result.responseCode === 1) {
        console.log(result.responseData, " result.responseData");
        if (result && result.responseData && result.responseData.length > 0) {
          setHistoryList(result.responseData);
        } else {
          setHistoryList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getHistoryList();
  }, []);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const [searchUnAssignItems, setSearchUnAssignItems] = useState("");
  const onSearchUnAssignItems = (val) => {
    if (val.length > 10) {
      setMsgAlert({ open: true, type: "warning", msg: "Please Enter Maximum 10 character." });
    } else {
      setSearchUnAssignItems(val);
      gridApi.setQuickFilter(val);
      gridApi.refreshCells();
    }
  };

  const companyAddressColnDef = [
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: "1" },
    { field: "AddressDisplayName", headerName: "Address", width: 150 },
    { field: "CreatedBy", headerName: "Created By", width: 150 },
    { field: "CreatedOn", headerName: "Created On", width: 200 },
    { field: "UpdatedBy", headerName: "Updated By", width: 150 },
    { field: "UpdatedOn", headerName: "Updated On", width: 200 },
    { field: "ActiveStatus", headerName: "Active Status", width: 150 },
  ];

  return (
    <React.Fragment>
      {viewMode && viewMode === "Assigned" ? (
        <>
          <Modal varient={"half"} title={"History"} show={show} left={0} width={"49.5vw"}>
            <Modal.Body>
              <div className={"Card"}>
                <PageBar>
                  <PageSearch withoutButton={true} value={searchUnAssignItems} onChange={(e) => onSearchUnAssignItems(e.target.value)} maxLength={10} />
                </PageBar>
                <DataGrid
                  rowData={historyList}
                  columnDefs={companyAddressColnDef}
                  loader={isLoadingHistoryList ? <Loader /> : null}
                  onGridReady={onGridReady}
                  getRowStyle={function (data) {
                    if (data.data.IsSelected) {
                      return { background: "#ffc176" };
                    } else {
                      if (data.data.IsNewlyAdded) {
                        return { background: "#d5a10e" };
                      }
                      return { background: "" };
                    }
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </>
      ) : (
        <>
          <Modal varient={"half"} title={"History"} show={show} right={0} width={"49.5vw"}>
            <Modal.Body>
              <div className={"Card"}>
                <PageBar>
                  <PageSearch withoutButton={true} value={searchUnAssignItems} onChange={(e) => onSearchUnAssignItems(e.target.value)} maxLength={10} />
                </PageBar>
                <DataGrid
                  rowData={historyList}
                  columnDefs={companyAddressColnDef}
                  loader={isLoadingHistoryList ? <Loader /> : null}
                  onGridReady={onGridReady}
                  getRowStyle={function (data) {
                    if (data.data.IsSelected) {
                      return { background: "#ffc176" };
                    } else {
                      if (data.data.IsNewlyAdded) {
                        return { background: "#d5a10e" };
                      }
                      return { background: "" };
                    }
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </>
      )}
    </React.Fragment>
  );
}

export default ViewAddressRelationHistory;
