import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { publicIpv4 } from "public-ip";
import Modal from "../../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../../Framework/Components/Widgets/Button/Button";
import { CustomMsg } from "../../../../../../Configration/Utilities/customMessage";
import { addMenuOrSubMenu, getMenuType } from "../../../Resources/MenuMangementService";
import { getSessionStorage } from "../../../../../../APIMethods/Auth/auth";

const AddMenu = (props) => {
  let menuData = props.menuData;
  let updateMenuList = props.updateMenuList;
  let setMsgAlert = props.setMsgAlert;
  let toggleAddMenu = props.toggleAddMenu;
  let getMenuListData = props.getMenuListData;
  const [formValidationError, setFormValidationError] = useState({});
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [isLoadingMenuTypeList, setIsLoadingMenuTypeList] = useState(false);
  const [formValues, setFormValues] = useState({
    txtMenuName: "",
    txtMenuType: null,
    txtWebUrl: "",
    txtWinUrl: "",
    txtAppUrl: "",
    txtApiUrl: "",
    txtWpfUrl: "",
    txtReactUrl: "",
    txtMainName: "",
  });

  const firstTextInput = useRef();
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const [menuTypeListData, setMenuTypeListData] = useState([]);
  const getMenuTypeListData = async () => {
    debugger;
    try {
      setIsLoadingMenuTypeList(true);
      let result = await getMenuType();
      setIsLoadingMenuTypeList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          setMenuTypeListData(result.responseData);
          console.log(result.responseData);
        } else {
          setMenuTypeListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getMenuTypeListData();
  }, []);
  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!formValues.txtMenuName || typeof formValues.txtMenuName === "undefined") {
      formIsValid = false;
      errors["txtMenuName"] = "Cannot be empty";
    }
    setFormValidationError(errors);
    return formIsValid;
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      const ip = await publicIpv4();
      setBtnloaderActive(true);
      let formData = {
        moduleMasterId: menuData.ModuleCode,
        menuMasterId: menuData.ModuleCode,
        menuName: formValues.txtMenuName,
        menuSequence: 1,
        menuType: formValues && formValues.txtMenuType.MenuTypeID ? formValues.txtMenuType.MenuTypeID : 0,
        underMenuId: 0,
        apiUrl: formValues.txtApiUrl,
        appUrl: "",
        reactUrl: formValues.txtReactUrl,
        webUrl: formValues.txtWebUrl,
        wpFUrl: "",
        winUrl: "",
        hasChild: 1,
        isDeleteFlag: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await addMenuOrSubMenu(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        if (result && result.responseData.length > 0) {
          let subMenu = {
            AppURL: "",
            HasChild: 1,
            InsertDateTime: "",
            InsertUser: "",
            MenuMasterID: "",
            MenuName: formValues.txtMenuName,
            MenuSequence: 1,
            MenuType: 101,
            MenuTypeName: "",
            ModuleMasterID: menuData.ModuleCode,
            ModuleName: "",
            ReactURL: formValues.txtReactUrl,
            UnderMenuID: 0,
            WPFURL: "",
            WebURL: formValues.txtWebUrl,
            WinURL: "",
            IsNewlyAdded: true,
          };
          updateMenuList(subMenu);
          getMenuListData(menuData.ModuleCode, "0", "MENULIST");
          clearForm();
          toggleAddMenu(false, null);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtMenuName: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title={`Add Menu - ${menuData.ModuleName}`} onSubmit={handleSave} show={() => toggleAddMenu(false, null)}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"520px"}>
            <Form.InputGroup label=" Selected Module">
              <Form.InputControl control="input" name="txtMainName" type="text" value={menuData.ModuleName} isDisabled={true} />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtMenuName"]} label="Menu Name" req={true}>
              <Form.InputControl
                control="input"
                label="Menu Name"
                name="txtMenuName"
                type="text"
                value={formValues.txtMenuName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtMenuType"]} label="Menu Type" req={true}>
              <Form.InputControl
                control="select"
                label="Menu Type"
                type="text"
                value={formValues.txtMenuType}
                onChange={(e) => updateState("txtMenuType", e)}
                options={menuTypeListData}
                isLoading={isLoadingMenuTypeList}
                getOptionValue={(option) => `${option}`}
                getOptionLabel={(option) => `${option.MenuTypeName}`}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtWebUrl"]} label="Web URL">
              <Form.InputControl
                control="input"
                label="Web URL"
                name="txtWebUrl"
                type="text"
                value={formValues.txtWebUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtApiUrl"]} label="API URL">
              <Form.InputControl
                label="API URL"
                control="input"
                name="txtApiUrl"
                type="text"
                value={formValues.txtApiUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtReactUrl"]} req={true} label="React URL">
              <Form.InputControl
                label="React URL"
                control="input"
                name="txtReactUrl"
                type="text"
                value={formValues.txtReactUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddMenu;
