import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { CreateCompanyParameter, getCompanyParameterValueSelect } from "../../../Resources/companyRegistrationService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddParameter = (props) => {
  let masterData = props.data;
  let toggleAddNewParameterPopupActive = props.toggleAddNewParameterPopupActive;
  let setMsgAlert = props.setMsgAlert;
  let updateParameterList = props.updateParameterList;
  let parameterList = props.parameterList;
  let isLoadingParameterList = props.isLoadingParameterList;
  const getParameterMasterList = props.getParameterMasterList;

  const [btnloaderActive, setBtnloaderActive] = useState(false);

  const [formValues, setFormValues] = useState({
    txtParameterName: null,
    txtParameterValue: null,
  });

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();
  const [parameterValueList, setParameterValueList] = useState([]);
  const [isLoadingParameterValue, setIsLoadingParameterValue] = useState(false);
  const [parameterValueName, setParameterValueName] = useState([]);
  const getCompanyParameterValue = async (biznextParameterId) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      const ip = await publicIpv4();
      setIsLoadingParameterValue(true);
      const formData = {
        searchText: "#ALL",
        biznextParameterId: biznextParameterId,
        filterId: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getCompanyParameterValueSelect(formData);
      setIsLoadingParameterValue(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setParameterValueList(result.responseData);
        } else {
          setParameterValueList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const updateState = (name, value) => {
    setFormValues((existingValues) => ({
      ...existingValues,
      [name]: value,
    }));
    if (name === "txtParameterName") {
      setFormValues((existingValues) => ({
        ...existingValues,
        txtParameterValue: null,
      }));
      if (value) {
        getCompanyParameterValue(value.BiznextParameterID);
      }
    }
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtParameterName") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtParameterValue") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtParameterName"] = validateField("txtParameterName", formValues.txtParameterName);
      errors["txtParameterValue"] = validateField("txtParameterValue", formValues.txtParameterValue);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);
      return formIsValid;
    } catch (error) {
      setMsgAlert({
        type: "error",
        message: "Something Went Wrong",
      });
      return false;
    }
  };
  const [addMore, setAddMore] = useState(false);
  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      const ip = await publicIpv4();
      let formData = {
        companyMasterId: masterData.CompanyMasterID,
        biznextParameterName: formValues.txtParameterName.BiznextParameterName,
        parameterValue: formValues.txtParameterValue.ParamAvailableValue,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setBtnloaderActive(true);
      let result = await CreateCompanyParameter(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          if (result && result.responseData.length > 0) {
            setAddMore(true);
            console.log(formValues.txtParameterValue.ParamAvailableValue, "formValues.txtParameterValue.ParamAvailableValue");
            console.log(formValues.txtParameterName.BiznextParameterName, "formValues.txtParameterName.BiznextParameterName");
            let addValue = {
              ParameterValue: formValues.txtParameterValue.ParamAvailableValue,
              BiznextParameterName: formValues.txtParameterName.BiznextParameterName,
              IsNewlyAdded: true,
            };
            updateParameterList(addValue);
            clearForm();
            // toggleAddNewParameterPopupActive();
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtParameterName: "",
      txtParameterValue: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title="Add New Parameter" onSubmit={handleSave} show={() => toggleAddNewParameterPopupActive()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtParameterName"]} label="Parameter Name" req={true}>
              <Form.InputControl
                control="select"
                type="text"
                value={formValues.txtParameterName}
                onChange={(e) => updateState("txtParameterName", e)}
                options={parameterList}
                isLoading={isLoadingParameterList}
                getOptionValue={(option) => `${option}`}
                getOptionLabel={(option) => `${option.BiznextParameterName}`}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtParameterValue"]} label="Parameter value" req={true}>
              <Form.InputControl
                control="select"
                type="text"
                value={formValues.txtParameterValue}
                onChange={(e) => updateState("txtParameterValue", e)}
                options={parameterValueList}
                isLoading={isLoadingParameterValue}
                getOptionValue={(option) => `${option}`}
                getOptionLabel={(option) => `${option.ParamAvailableValue}`}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {addMore ? (
          <Button varient="secondary" type="submit" trigger={false}>
            Add More
          </Button>
        ) : (
          <Button varient="secondary" type="submit" trigger={false}>
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddParameter;
