/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
import React, { useEffect, useState, useRef } from "react";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import BizClass from "./MenuToCompany.module.scss";
import "../../CRM/Company_Registration/Company_Registration.css";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import { companyMenuAssignOrUnAssigned, getMenuList, getMenuType } from "./Resources/CustomerAccessService";
import { getModuleMaster } from "../../Access_Control/Module/Resources/moduleService";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import { ImEye } from "react-icons/im";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import classNames from "classnames";
import { FaEdit } from "react-icons/fa";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { getCustomerMasterSelect } from "../../CRM/Resources/customerRegistrationService";
import UpdateMenu from "./Views/UpadateMenu/UpdateMenu";
import { getCommonMasterData } from "../../CRM/Resources/commonService";
import { publicIpv4 } from "public-ip";
import PageBar1 from "../../../Framework/Components/Layout/Pagebar/PageBar1";

function MenuToCompany() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });
  const [moduleMasterData, setModuleMasterData] = useState([]);
  const [filterModuleMasterData, setFilterModuleMasterData] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingModuleMasterData] = useState(false);
  const [menuListData, setMenuListData] = useState([]);
  const [menuTypeListData, setMenuTypeListData] = useState([]);
  const [treeMenuListData, setTreeMenuListData] = useState([]);
  const [isLoadingMenuList, setIsLoadingMenuList] = useState(false);
  const [updateMenuPopup, setUpdateMenuPopup] = useState({ open: false, data: null });
  const [selectedModule, setSelectedModule] = useState({});
  const [submenuPopupData, openSubmenuPopup] = useState({
    open: false,
    data: null,
    isEditMode: false,
  });
  const [formValues, setFormValues] = useState({
    txtCustomer: null,
    txtCompany: null,
  });
  const firstTextInput = useRef();
  const secondTextInput = useRef();
  const customerInput = useRef();
  const companyInput = useRef();
  const [addedMenuPopupActiveId, setAddedMenuPopupActiveId] = useState();
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const getModuleMasterData = async () => {
    try {
      setIsLoadingModuleMasterData(true);
      let result = await getModuleMaster();
      setIsLoadingModuleMasterData(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setModuleMasterData(result.responseData);
          setFilterModuleMasterData(result.responseData);
        } else {
          setModuleMasterData([]);
          setFilterModuleMasterData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const getMenuListData = async (companyMasterId, moduleCode, viewMode) => {
    try {
      setIsLoadingMenuList(true);
      const formData = {
        viewMode: viewMode,
        companyMasterId: companyMasterId,
        moduleCode: moduleCode,
        userId: 11,
      };
      let result = await getMenuList(formData);
      setIsLoadingMenuList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setMenuListData(result.responseData);
          console.log(result.responseData);
        } else {
          setMenuListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  const [customerList, setCustomerList] = useState([]);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const getCustomerList = async () => {
    try {
      setIsLoadingCustomer(true);
      let result = await getCustomerMasterSelect("0");
      console.log("getCustomerList", result);
      setIsLoadingCustomer(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          console.log(result.responseData, "result.responseData");
          setCustomerList(result.responseData);
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [isLoadingCompanyList, setIsLoadingCompanyList] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const getCompanyList = async (customerId) => {
    try {
      setIsLoadingCompanyList(true);
      // "SELECT", "", customerId, 0;
      let result = await getCommonMasterData("COMPANYLIST", customerId);
      setIsLoadingCompanyList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          setCompanyList(result.responseData);
        } else {
          setCompanyList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const menuAssignedOrUnAssigned = async (companyMasterId, menu, action) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      const ip = await publicIpv4();
      let formData = {
        companyMenuId: action === "ASSIGN" ? 0 : menu.CompanyMenuID,
        companyMasterId: companyMasterId,
        menuMasterId: menu.MenuMasterID,
        companyMenuName: menu.MenuName,
        webUrl: menu.WebURL,
        winUrl: menu.WinURL,
        appUrl: menu.AppURL,
        apiUrl: menu.APIURL,
        wpfUrl: menu.WPFURL,
        reactUrl: menu.ReactURL,
        action: action,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await companyMenuAssignOrUnAssigned(formData);
      console.log(result, "this");
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          let msg = action === "ASSIGN" ? "Menu Assigned Successfully." : "Menu UnAssigned Successfully.";
          setMsgAlert({ open: true, type: "success", msg: msg });
          console.log(gridApi);

          if (gridApi) {
            gridApi.forEachNode(function (rowNode) {
              if (rowNode.data.MenuMasterID === menu.MenuMasterID) {
                if (action === "ASSIGN" && result.responseData && result.responseData.CompanyMenuID) {
                  rowNode.data.CompanyMenuID = result.responseData.CompanyMenuID;
                  rowNode.data.AssignmentFlag = 1;
                } else if (action === "UNASSIGN") {
                  rowNode.data.CompanyMenuID = null;
                  rowNode.data.AssignmentFlag = 0;
                }
                rowNode.setData(rowNode.data);
              }
            });
          }
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const updateState = (name, value) => {
    if (name === "txtCustomer") {
      if (value && value.CustomerMasterID) {
        if (formValues.txtCustomer !== value) {
          formValues.txtCompany = null;
          getCompanyList(value.CustomerMasterID);
        }
      } else {
        formValues.txtCompany = null;
        setCompanyList([]);
      }
      if (!formValues.txtCustomer || typeof formValues.txtCustomer === "undefined" || formValues.txtCustomer !== value) {
        setSelectedModule(null);
        setMenuListData([]);
        setFilterModuleMasterData([]);
      }
    } else if (name === "txtCompany") {
      if (!formValues.txtCompany || typeof formValues.txtCompany === "undefined" || formValues.txtCompany !== value) {
        setSelectedModule(null);
        setMenuListData([]);
        setFilterModuleMasterData([]);
      }
      // if (!(moduleMasterData && moduleMasterData.length > 0)) {
      getModuleMasterData();
      // }
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const onClickSearchBar = (data) => {
    if (formValues.txtCustomer) {
      if (formValues.txtCompany) {
        if (data) {
          onDisplayClick(data);
        } else {
          setMsgAlert({ open: true, type: "warning", msg: "Select Module" });
        }
      } else {
        secondTextInput.current.focus();
        setMsgAlert({ open: true, type: "warning", msg: "Select Company!" });
      }
    } else {
      firstTextInput.current.focus();
      setMsgAlert({ open: true, type: "warning", msg: "Select Customer!" });
    }
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  const onDisplayClick = (moduleData) => {
    console.log(moduleData, "&&&&&&&&&&&&&&&&&moduleData&&&&&&&&&&&&&&&");
    getMenuListData(formValues.txtCompany.CompanyMasterID, moduleData.ModuleCode, "MODULECODE");
    setSelectedModule(moduleData);
  };

  const updateMenuList = (menuData) => {
    debugger;
    if (gridApi) {
      gridApi.forEachNode(function (rowNode) {
        if (rowNode.data.MenuMasterID === menuData.MenuMasterID) {
          rowNode.setData({ ...menuData, isUpdated: true });
        } else {
          rowNode.setData(rowNode.data);
        }
      });
    }
  };

  const buildHierarchy = () => {
    let menuTreeListData = [];
    menuListData.forEach((menu) => {
      let menuData = {
        ...menu,
        orgHierarchy: [menu.MenuMasterID],
      };
      if (menu.UnderMenuID.toString() !== "0") {
        const parentMenu = menuListData.find((x) => x.MenuMasterID.toString() === menu.UnderMenuID.toString());
        if (parentMenu !== null && parentMenu !== undefined) {
          menuData.orgHierarchy.push(parentMenu.MenuMasterID);
          recHierarchy(menuData, parentMenu);
        }
      }

      menuTreeListData.push(menuData);
    });

    menuTreeListData.forEach((menu) => {
      menu.orgHierarchy = menu.orgHierarchy.reverse();
    });
    console.log(menuTreeListData, "**menuTreeListData**");
    setTreeMenuListData(menuTreeListData);
  };

  const recHierarchy = (menu, parent) => {
    if (parent.UnderMenuID.toString() !== 0) {
      var parentMenu = menuListData.find((x) => x.MenuMasterID.toString() === parent.UnderMenuID.toString());
      if (parentMenu !== null && parentMenu !== undefined) {
        menu.orgHierarchy.push(parentMenu.MenuMasterID);
        recHierarchy(menu, parentMenu);
      }
    }
  };

  const onAssignMenu = (menu) => {
    if (!formValues.txtCustomer || typeof formValues.txtCustomer === "undefined") {
      setMsgAlert({ open: true, type: "warning", msg: "Please select customer." });
      if (customerInput.current) {
        customerInput.current.focus();
      }
      return;
    } else if (!formValues.txtCompany || typeof formValues.txtCompany === "undefined") {
      setMsgAlert({ open: true, type: "warning", msg: "Please select company." });
      if (companyInput.current) {
        companyInput.current.focus();
      }
      return;
    }
    if (formValues.txtCompany.CompanyMasterID && menu.MenuMasterID && menu.MenuName) {
      menuAssignedOrUnAssigned(formValues.txtCompany.CompanyMasterID, menu, "ASSIGN");
    }
  };

  const onUnAssignMenu = (menu) => {
    if (!formValues.txtCustomer || typeof formValues.txtCustomer === "undefined") {
      setMsgAlert({ open: true, type: "warning", msg: "Please select customer." });
      if (customerInput.current) {
        customerInput.current.focus();
      }
      return;
    } else if (!formValues.txtCompany || typeof formValues.txtCompany === "undefined") {
      setMsgAlert({ open: true, type: "warning", msg: "Please select company." });
      if (companyInput.current) {
        companyInput.current.focus();
      }
      return;
    }
    if (formValues.txtCompany.CompanyMasterID && menu.MenuMasterID && menu.CompanyMenuID && menu.MenuName) {
      menuAssignedOrUnAssigned(formValues.txtCompany.CompanyMasterID, menu, "UNASSIGN");
    }
  };
  useEffect(() => {
    if (menuListData != null && menuListData.length > 0) {
      buildHierarchy();
    } else {
      setTreeMenuListData([]);
    }
  }, [menuListData]);

  const [moduleMasterGridApi, setModuleMasterGridApi] = useState();
  const onModuleMasterGridReady = (params) => {
    setModuleMasterGridApi(params.api);
  };

  const [searchTextModuleMaster, setSearchTextModuleMaster] = useState("");
  const onSearchMasterCommonCode = (val) => {
    setSearchTextModuleMaster(val);
    moduleMasterGridApi.setQuickFilter(val);
    moduleMasterGridApi.refreshCells();
    setModuleMasterData([]);
  };

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const [searchTextMenuList, setSearchTextMenuList] = useState("");
  const onSearchMasterDataFile = (val) => {
    setSearchTextMenuList(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isUpdated) {
      return { background: "#ffd40085" };
    }
    return { background: "" };
  };

  const moduleGridColumnDefs = [
    { field: "#", headerName: "Action", width: 80, pinned: "left", lockPosition: "1", cellRenderer: "actionTemplate", cellRendererParams: { onDisplayClick } },
    {
      field: "ModuleCode",
      headerName: "Module Code",
      width: 180,
      pinned: "left",
      lockPosition: "1",
    },
    {
      field: "ModuleName",
      headerName: "Module Name",
      width: 180,
      pinned: "left",
    },
  ];

  const menuListGridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 180,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellRendererParams: { onAssignMenu, onUnAssignMenu, setUpdateMenuPopup },
    },
    {
      field: "MenuName",
      hide: true,
      headerName: "Menu Name",
      width: 280,
      pinned: "left",
      lockPosition: 2,
    },
    {
      field: "MenuTypeName",
      headerName: "Menu Type",
      width: 120,
    },
    {
      field: "WebURL",
      headerName: "Web Url",
      width: 150,
    },
    {
      field: "ReactURL",
      headerName: "React Url",
      width: 180,
    },
    {
      field: "WPFURL",
      headerName: "WPF Url",
      width: 120,
    },
  ];

  return (
    <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {updateMenuPopup.open ? (
        <UpdateMenu
          menuData={updateMenuPopup.data}
          updateMenuList={updateMenuList}
          setUpdateMenuPopup={setUpdateMenuPopup}
          setConfirmAlert={setConfirmAlert}
          setMsgAlert={setMsgAlert}
        />
      ) : null}
      <div className={BizClass.Biz_Bm_Manage_Menu_ContentBox}>
        <PageBar title="Menu To Company">
          <PageBar.Select
            name="txtCustomer"
            getOptionLabel={(option) => `${option.CustomerName}`}
            value={formValues.txtCustomer}
            getOptionValue={(option) => `${option}`}
            options={customerList}
            onChange={(e) => updateState("txtCustomer", e)}
            isLoading={isLoadingCustomer}
            label="Select Customer"
            ref={firstTextInput}
          />
          <PageBar.Select
            getOptionLabel={(option) => `${option.CompanyName}`}
            value={formValues.txtCompany}
            getOptionValue={(option) => `${option}`}
            options={companyList}
            onChange={(e) => updateState("txtCompany", e)}
            isSearchable={true}
            isLoading={isLoadingCompanyList}
            ref={secondTextInput}
          />
        </PageBar>
        <Splitter varient="column" template="1fr 9px 2fr">
          <div className={BizClass.MainSection}>
            <PageBar1 title="Module Master"></PageBar1>

            <DataGrid
              rowData={filterModuleMasterData}
              columnDefs={moduleGridColumnDefs}
              onGridReady={onModuleMasterGridReady}
              onDisplayClick={onDisplayClick}
              animateRows={true}
              loader={isLoadingModuleMasterData ? <Loader /> : null}
              getRowStyle={function (data) {
                if (data.data.IsSelected) {
                  return { background: "#ffc176" };
                } else {
                  if (data.data.IsNewlyAdded) {
                    return { background: "#d5a10e" };
                  }
                  return { background: "" };
                }
              }}
              frameworkComponents={{
                actionTemplate: cellTemplate,
              }}
            />
          </div>
          <div className={BizClass.MainSection}>
            <PageBar1 title={` ${selectedModule && selectedModule.ModuleName ? `(${selectedModule.ModuleName}) Menu List` : "Menu List"}`}>
              <PageBar.Search value={searchTextMenuList} onClick={() => onClickSearchBar(selectedModule)} onChange={(e) => onSearchMasterDataFile(e.target.value)} />
              <PageBar.Button>Assign All</PageBar.Button>
            </PageBar1>
            <DataGrid
              getRowStyle={getRowStyle}
              onGridReady={onGridReady}
              columnDefs={menuListGridColumnDefs}
              rowData={treeMenuListData}
              autoGroupColumnDef={{
                headerName: "Menu Name",
                minWidth: 300,
                lockPosition: 2,
                cellRendererParams: {
                  innerRenderer: function (params) {
                    return params.data.MenuName;
                  },
                },
              }}
              loader={isLoadingMenuList ? <Loader /> : null}
              frameworkComponents={{
                actionTemplate: ActionTemplate,
              }}
              openSubmenuPopup={openSubmenuPopup}
              treeData={true}
              animateRows={true}
              groupDefaultExpanded={-1}
              getDataPath={(data) => {
                return data.orgHierarchy;
              }}
            />
          </div>
        </Splitter>
        <Footer />
      </div>
    </div>
  );
}

export default MenuToCompany;

const cellTemplate = (props) => {
  const onDisplayClick = () => {
    console.log("***********Selected Data***********", props.data);
    props.onDisplayClick(props.data);
  };
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View File"
      >
        <ImEye style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => onDisplayClick()} />
      </span>
    </div>
  );
};

const ActionTemplate = (props) => {
  let menuData = props.data;

  const onAssignMenu = () => {
    props.onAssignMenu(menuData);
  };
  const onUnAssignMenu = () => {
    props.onUnAssignMenu(menuData);
  };

  const onEditClick = () => [props.setUpdateMenuPopup({ open: true, data: menuData })];

  return (
    <React.Fragment>
      <div>
        {menuData.AssignmentFlag === 1 ? (
          <React.Fragment>
            <button onClick={() => onUnAssignMenu()} className="Biz_Bm_Company_ManageLocationTypePopup_AssButton Assigned" style={{ cursor: "pointer" }}>
              UnAssign
            </button>
            <span style={{ cursor: "pointer", paddingLeft: "10px" }}>
              <FaEdit onClick={() => onEditClick()} />
            </span>
            {/* <span style={{ cursor: "pointer", paddingLeft: "10px" }}>
              <CgDatabase onClick={() => props.toggleManageLocationTypePopup()} />
            </span> */}
          </React.Fragment>
        ) : (
          <button onClick={() => onAssignMenu()} className="Biz_Bm_Company_ManageLocationTypePopup_AssButton UnAssigned" style={{ cursor: "pointer" }}>
            Assign
          </button>
        )}
      </div>
    </React.Fragment>
  );
};
