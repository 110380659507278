import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BiRefresh } from "react-icons/bi";
import { FaEdit, FaTimes } from "react-icons/fa";
import { checkAuthExist } from "../../../APIMethods/Auth/auth";
import { departmentData, divisionData, getDesignation } from "./Resources/DeptService";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import PageTitle, { PageButton, PageSearch } from "../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, { FooterBox } from "../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import AddDepartment from "./Views/AddDepartment";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";

const Department = () => {
  let navigate = useNavigate();
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const [AddformPopupActive, setAddformPopupActive] = useState(false);
  const firstTextInput = useRef();
  const [DepartmentList, setDeptList] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingDeptList] = useState(false);

  useMemo(async () => {
    if (!checkAuthExist()) {
      navigate("/");
    }
  }, []);

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getDepartmentList = async () => {
    try {
      setIsLoadingDeptList(true);
      const formData = {
        viewMode: "DO",
        divisionId: formValues.txtDivision && formValues.txtDivision.DivisionID ? formValues.txtDivision.DivisionID : 0,
      };
      let result = await departmentData(formData);
      console.log(result, "result");
      setIsLoadingDeptList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setDeptList(result.responseData);
        } else {
          setDeptList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    getDivisionList();
  }, []);

  const [AddDepartmentPopup, setAddDepartmentPopup] = useState(false);
  const toggleAddDepartMent = () => {
    setAddDepartmentPopup(!AddDepartmentPopup);
  };

  const gridColumnDefs = [
    { field: "#", headerName: "Action", width: 80, pinned: "left", lockPosition: "1", cellRenderer: "actionTemplate", cellRendererParams: { toggleAddDepartMent } },
    {
      field: "DepartmentCode",
      headerName: "Department Code",
      width: 150,
      pinned: "left",
      lockPosition: "1",
    },
    {
      field: "DepartmentName",
      headerName: "DepartMent Name",
      width: 280,
      pinned: "left",
    },
    { field: "DivisionName", headerName: "Division Name", width: 200 },
  ];

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const updateDepartmentList = (department) => {
    DepartmentList.unshift(department);
    setDeptList(DepartmentList);
    if (gridApi) {
      gridApi.setRowData(DepartmentList);
    }
  };

  const [DivisionList, setDivisionList] = useState([]);
  const [isLoadingDivisionList, setIsLoadingDivisionList] = useState(false);
  const getDivisionList = async () => {
    debugger;
    try {
      setIsLoadingDivisionList(true);
      const formData = {
        viewMode: "LIST",
        divisionId: 0,
      };
      let result = await divisionData(formData);
      console.log(result, "result");
      setIsLoadingDivisionList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setDivisionList(result.responseData);
        } else {
          setDivisionList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [formValues, setFormValues] = useState({
    txtDivision: null,
  });

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const onSearchClick = () => {
    // if (formValues.txtDivision) {
    getDepartmentList();
    // } else {
    //   firstTextInput.current.focus();
    //   setMsgAlert({ open: true, type: "warning", msg: "Select Division!" });
    // }
  };

  return (
    <React.Fragment>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {AddDepartmentPopup ? <AddDepartment setMsgAlert={setMsgAlert} updateDepartmentList={updateDepartmentList} toggleAddDepartMent={toggleAddDepartMent} /> : null}
      <div className={`Biz_Listing_PageStart`}>
        <PageTitle Title="Department">
          <PageBar.Select
            name="txtDivision"
            getOptionLabel={(option) => `${option.DivisionName}`}
            value={formValues.txtDivision}
            getOptionValue={(option) => `${option}`}
            options={DivisionList}
            onChange={(e) => updateState("txtDivision", e)}
            label="Select Division"
            isSearchable={true}
            ref={firstTextInput}
          />
          <PageSearch focus={true} onClick={() => onSearchClick()} value={searchMappedData} onChange={(e) => onSearchMappingData(e.target.value)} />
          <PageButton onClick={() => toggleAddDepartMent()}>Add</PageButton>
        </PageTitle>
        <DataGrid
          rowData={DepartmentList}
          columnDefs={gridColumnDefs}
          onGridReady={onGridReady}
          loader={isLoadingModuleMasterData ? <Loader /> : null}
          getRowStyle={function (data) {
            if (data.data.IsSelected) {
              return { background: "#ffc176" };
            } else {
              if (data.data.IsNewlyAdded) {
                return { background: "#d5a10e" };
              }
              return { background: "" };
            }
          }}
          frameworkComponents={{
            actionTemplate: cellTemplate,
          }}
        />

        <Footer>
          <FooterBox Aligned={"right"}></FooterBox>
        </Footer>
      </div>
    </React.Fragment>
  );
};

export default Department;
const cellTemplate = (props) => {
  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View List"
      >
        <FaEdit style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => props.toggleAddDepartMent(props.data)} />
      </span>
    </div>
  );
};
