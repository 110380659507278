import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { AddTaxDefinitionData, UpdateTaxDefData, getTaxDefinitionValue } from "../../Resources/TaxService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddTaxDefinition = (props) => {
  const firstTextInput = useRef();
  let selectedCategory = props.selectedCategory;
  let toggleAddTaxDefinitionPopup = props.toggleAddTaxDefinitionPopup;
  let setMsgAlert = props.setMsgAlert;
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  let updateTaxValueList = props.updateTaxValueList;
  const _updateListingTaxDefinition = props._updateListingTaxDefinition;
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtDefValue: "",
    txtTaxType: null,
    txtDefinitionName: "",
    txtUnderDefinitionID: "",
  });
  const [oldFormValues, setOldFormValues] = useState({
    txtDefValue: "",
    txtTaxType: null,
    txtDefinitionName: "",
    txtUnderDefinitionID: "",
  });

  const TaxTypeOptions = [
    { Name: "Percentage", Value: 1 },
    { Name: "Fixed", Value: 2 },
  ];

  useEffect(() => {
    if (isEditMode) {
      if (popupData) {
        setEditBankMaster(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);
  const [editBankMaster, setEditBankMaster] = useState();
  useEffect(() => {
    if (editBankMaster) {
      formValues.txtDefValue = editBankMaster.TaxDefinitionValue;
      formValues.txtTaxType = { Name: editBankMaster.TaxType === 1 ? "Percentage" : "Fixed", Value: editBankMaster.TaxType };
      formValues.txtDefinitionName = editBankMaster.TaxDefinitionName;
      formValues.txtUnderDefinitionID = { TaxDefinitionName: editBankMaster.TaxDefinitionName, TaxCategoryID: editBankMaster.TaxCategoryID };
    }
    setFormValues({ ...formValues });
    setOldFormValues({ ...formValues });
  }, [editBankMaster]);

  const validateField = (name, value) => {
    let errorMsg = "";
    if (
      name === "txtFirstTable" ||
      name === "txtFirstTableValueField" ||
      "txtFirstTableDisplayField" ||
      "txtSecondTable" ||
      "txtSecondTableValueField" ||
      "txtSecondTableDisplayField"
    ) {
      if (!value || typeof value === "undefined") {
        errorMsg = "Cannot be empty";
      }
    }
    return errorMsg;
  };

  const [formValidationError, setFormValidationError] = useState({});
  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;

      errors["txtUnderDefinitionID"] = validateField("txtUnderDefinitionID", formValues.txtUnderDefinitionID);
      errors["txtDefValue"] = validateField("txtDefValue", formValues.txtDefValue);
      errors["txtTaxType"] = validateField("txtTaxType", formValues.txtTaxType);
      errors["txtDefinitionName"] = validateField("txtDefinitionName", formValues.txtDefinitionName);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);
      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "error", message: "Something went wrong!" });
      return false;
    }
  };

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };
  const [saveMore, setSaveMore] = useState(false);

  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }

      const ip = await publicIpv4();

      let formData = {};
      if (isEditMode) {
        formData = {
          TaxDefinitionID: popupData && popupData.TaxDefinitionID ? popupData.TaxDefinitionID : 0,
          UnderDefinitionID: formValues?.txtUnderDefinitionID?.UnderDefinitionID || 0,
          TaxDefinitionName: formValues?.txtDefinitionName || "",
          TaxCategoryID: selectedCategory?.TaxCategoryID || 0,
          TaxDefinitionType: formValues?.txtTaxType?.Value || 1,
          TaxDefinitionValue: formValues?.txtDefValue || 0,
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          UnderDefinitionID: formValues?.txtUnderDefinitionID?.UnderDefinitionID,
          TaxDefinitionName: formValues?.txtDefinitionName || "",
          TaxCategoryID: selectedCategory?.TaxCategoryID || 0,
          TaxDefinitionType: formValues?.txtTaxType?.Value || 0,
          TaxDefinitionValue: formValues?.txtDefValue || 0,
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }

      setBtnloaderActive(true);
      let fun = isEditMode ? UpdateTaxDefData : AddTaxDefinitionData;
      let result = await fun(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        if (result.responseData) {
          let addedCode = {
            IsActive: 1,
            UnderDefinitionID: formValues?.txtUnderDefinitionID?.UnderDefinitionID,
            TaxDefinitionName: formValues?.txtDefinitionName || "",
            TaxCategoryID: selectedCategory?.TaxCategoryID || 0,
            TaxDefinitionType: formValues?.txtTaxType?.Name || 0,
            TaxDefinitionValue: formValues?.txtDefValue || 0,
            TaxDefinitionID: isEditMode
              ? popupData.TaxDefinitionID
              : result.responseData && result.responseData.data && result.responseData.data.TaxDefinitionID
              ? result.responseData.data.TaxDefinitionID
              : 0,
            IsNewlyAdded: true,
          };
          if (isEditMode) {
            _updateListingTaxDefinition({ ...addedCode, IsNewlyAdded: false, IsUpdated: true });
            toggleAddTaxDefinitionPopup(false, null);
          } else {
            setSaveMore(true);
            updateTaxValueList(addedCode);
          }

          clearForm();
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to Update" : "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && popupData) {
      return true;
    }

    return false;
  };

  const [definitionValueList, setDefinitionValueList] = useState([]);
  const [isLoadingDefinitionValue, setIsLoadingDefinitionValue] = useState(false);
  const getDefinitionValue = async (data) => {
    console.log("getDefinitionValue", data);
    const ip = await publicIpv4();
    try {
      const formData = {
        SearchText: "#ALL",
        TaxDefinitionID: 0,
        TaxCategoryID: data?.TaxCategoryID || 0,
        TaxDefinitionType: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      setIsLoadingDefinitionValue(true);
      const result = await getTaxDefinitionValue(formData);
      console.log("getDefinitionValue", result);
      setIsLoadingDefinitionValue(false);
      debugger;
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setDefinitionValueList(result.responseData);
          console.log("onClickGetTax", result.responseData);
        } else {
          setDefinitionValueList([]);
        }
      } else {
        setDefinitionValueList([]);
        setMsgAlert({
          open: true,
          type: "error",
          msg: result.responseMessage,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtDefValue: "",
      txtTaxType: null,
      txtDefinitionName: "",
      txtUnderDefinitionID: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  useEffect(() => {
    getDefinitionValue(selectedCategory);
  }, []);

  return (
    <Modal varient={"center"} title={isEditMode ? "Update  Tax Definition" : "Add Tax Definition"} onSubmit={handleSave} show={() => toggleAddTaxDefinitionPopup(false, null)}>
      <Modal.Body>
        <Form>
          <Form.Group style={{ rowGap: "0px" }} controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtUnderDefinitionID"]} label="Definition" req={true}>
              <Form.InputControl
                control="select"
                name="txtUnderDefinitionID"
                getOptionLabel={(option) => `${option.TaxDefinitionName}`}
                getOptionValue={(option) => `${option}`}
                isLoading={isLoadingDefinitionValue}
                options={definitionValueList}
                value={formValues.txtUnderDefinitionID}
                onChange={(e) => updateState("txtUnderDefinitionID", e)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtDefinitionName"]} label="Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtDefinitionName"
                type="text"
                value={formValues.txtDefinitionName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                maxLength={100}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtTaxType"]} label="Type" req={true}>
              <Form.InputControl
                control="select"
                name="txtTaxType"
                type="text"
                value={formValues.txtTaxType}
                onChange={(e) => updateState("txtTaxType", e)}
                getOptionLabel={(option) => `${option.Name}`}
                getOptionValue={(option) => `${option}`}
                options={TaxTypeOptions}
              />{" "}
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtDefValue"]} label="Value" req={true}>
              <Form.InputControl
                control="input"
                name="txtDefValue"
                type="text"
                value={formValues.txtDefValue}
                onChange={(e) => updateState(e.target.name, e.target.value.replace(/[^\d.]/g, ""))}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" disabled={checkEditFormControlValues() || btnloaderActive} type="submit" trigger={false}>
          {isEditMode ? "Update" : saveMore ? "Save More" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddTaxDefinition;
