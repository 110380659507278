import React from 'react';
import Split from 'react-split-grid';
import BizClass from './Splitter.module.scss';
import styled from "styled-components";

function Splitter(props) {
    const { varient, template } = props

    return (
        <Split render={({ getGridProps, getGutterProps }) => (
            <SplitterDash className={BizClass.Splitter_Dash} {...getGridProps()} template={template} varient={varient}>
                {props.children && props.children[1] ? props.children[0] : null}
                {props.children && props.children[1] ? props.children.map((x, i) => {
                    {
                        return (
                            <React.Fragment>
                                {varient === 'column' ? <Splitter.Columm getGutterProps={getGutterProps} column={(i + 2 + i).toString()} /> : null}
                                {varient === 'row' ? <Splitter.Row getGutterProps={getGutterProps} row={(i + 2 + i).toString()} /> : null}
                                {props.children[i + 1]}
                            </React.Fragment>
                        )
                    }
                }) : null}
            </SplitterDash>
        )}
        />
    )
}

export default Splitter;

Splitter.Columm = function SplitterColumn(props) {
    const { column } = props;
    return (
        <ColumnSplitter className={BizClass.Grid_ColumnSplitter} {...props.getGutterProps('column', 1)} column={column} />
    )
}

Splitter.Row = function SplitterRow(props) {
    const { row } = props;
    return (
        <RowSplitter className={BizClass.Grid_RowSplitter} {...props.getGutterProps('row', 1)} row={row} />
    )
}

const SplitterDash = styled.div`
&&& {
    ${props => props.varient === 'column' ? `grid-template-columns:  ${props.template};` : null}
    ${props => props.varient === 'row' ? `grid-template-rows:  ${props.template};` : null}
}`;


const ColumnSplitter = styled.div`
&&& {
    ${props => props.column ? `grid-column:  ${props.column};` : null}
}`;

const RowSplitter = styled.div`
&&& {
    ${props => props.row ? `grid-row:  ${props.row};` : null}
}`;