import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { addProfileList, companyProfileUpdate } from "../../Resources/ProfileManagementService";
import { getCommonMasterData } from "../../../../CRM/Resources/commonService";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";

const AddProfile = (props) => {
  const [formValues, setFormValues] = useState({
    txtCompanyMaster: props.selectedCompanyMaster,
    txtProfileName: "",
    txtProfileDescription: "",
    txtActiveStatus: "Y",
  });
  let masterData = props.masterData;
  let isEditMode = props.isEditMode;
  let updateProfileMgmt = props.updateProfileMgmt;
  let setMsgAlert = props.setMsgAlert;
  let toggleaddUserProfilePopUp = props.toggleaddUserProfilePopUp;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValidationError, setFormValidationError] = useState({});
  const firstTextInput = useRef();
  const [isLoadingCompanyList, setIsLoadingCompanyList] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [filteredCompanyList, setFilteredCompanyList] = useState([]);

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const getCompanyList = async (customerId) => {
    try {
      setIsLoadingCompanyList(true);
      // "SELECT", "", customerId, 0;
      let result = await getCommonMasterData("COMPANYLIST", customerId);
      setIsLoadingCompanyList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          setCompanyList(result.responseData);
        } else {
          setCompanyList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    debugger;
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
    if (isEditMode) {
      setFormValues({
        txtCompanyMaster: props.selectedCompanyMaster,
        txtProfileName: masterData.ProfileName,
        txtProfileDescription: masterData.ProfileDescription,
      });
    }
  }, []);

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtProfileDescription") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Profile Description";
      }
    }
    if (name === "txtProfileName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter Profile Name";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtProfileDescription"] = validateField("txtProfileDescription", formValues.txtProfileDescription);
      errors["txtProfileName"] = validateField("txtProfileName", formValues.txtProfileName);

      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to update ?" : "Are you sure to Save ?",
        button: {
          confirmText: isEditMode ? "Update" : "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => SaveProfileMgmt(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const SaveProfileMgmt = async () => {
    try {
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      setBtnloaderActive(true);

      let requestData = {};
      if (isEditMode) {
        requestData = {
          profileId: 0,
          companyMasterId: formValues.txtCompanyMaster.CompanyMasterID,
          profileName: formValues.txtProfileName,
          profileDesc: formValues.txtProfileDescription,
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        requestData = {
          profileId: 0,
          profileName: formValues.txtProfileName,
          companyMasterId: formValues.txtCompanyMaster.CompanyMasterID,
          profileDesc: formValues.txtProfileDescription,
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }

      let fun = isEditMode ? companyProfileUpdate : addProfileList;
      let result = await fun(requestData);

      setBtnloaderActive(false);
      console.log(result, "result");
      if (result.responseCode === 1) {
        if (result && result.responseData && result.responseData.userProfileID) {
          debugger;
          console.log(result.responseData.data, "result.responseData.data");
          let addprofile = {
            UserProfileID: result && result.responseData && result.responseData.userProfileID ? result.responseData.userProfileID : 0,
            ProfileName: formValues.txtProfileName,
            ProfileDescription: formValues.txtProfileDescription,
            IsNewlyAdded: true,
          };
          console.log(addprofile, "addprofile");
          updateProfileMgmt(addprofile);
          // props.getProfileMasterData(props.selectedCompanyMaster.CompanyMasterID);
        }
        if (firstTextInput.current) {
          firstTextInput.current.focus();
        }
        clearForm();
        toggleaddUserProfilePopUp(false, null);
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };
  const clearForm = () => {
    setFormValues({
      txtCompanyMaster: null,
      txtProfileName: "",
      txtProfileDescription: "",
    });
  };

  return (
    <Modal varient={"center"} title="Add profile" onSubmit={handleSave} show={() => toggleaddUserProfilePopUp(false, null)}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtProfileName"]} label="Profile Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtProfileName"
                label="Profile Name"
                type="text"
                value={formValues.txtProfileName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtProfileDescription"]} label="Profile Description" req={true}>
              <Form.InputControl
                control="input"
                name="txtProfileDescription"
                label="Profile Description"
                type="text"
                value={formValues.txtProfileDescription}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddProfile;
