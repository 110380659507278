import { useEffect, useRef, useState } from "react";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { getRightMasterInsert } from "../../Resources/AccessRightsService";
import { getMenuList } from "../../../Menu_Management/Resources/MenuMangementService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddRights = (props) => {
  let toggleAddRightsMasterformPopup = props.toggleAddRightsMasterformPopup;
  let setMsgAlert = props.setMsgAlert;
  let moduleData = props.moduleData;

  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValidationError, setFormValidationError] = useState({});
  const [isLoadingMenuList, setIsLoadingMenuList] = useState(false);
  const firstTextInput = useRef();
  const [menuListData, setMenuListData] = useState([]);
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }

    getMenuListData(moduleData.ModuleCode, 0, "MENUMASTER");
  }, []);
  const getMenuListData = async (moduleMasterId, menuMasterId, action) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingMenuList(true);
      const formData = {
        action: action,
        searchText: "#ALL",
        moduleMasterId: moduleMasterId,
        menuMasterId: menuMasterId,
        filterId1: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getMenuList(formData);
      setIsLoadingMenuList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setMenuListData(result.responseData);
          console.log(result.responseData);
        } else {
          setMenuListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [formValues, setFormValues] = useState({
    txtRightApi: "",
    txtRightCode: "",
    txtRightName: "",
    txtMenuMasterID: null,
    txtApplyAdmin: false,
    txtSPName: "",
  });

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtRightApi") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtRightCode") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtRightName") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtSPName") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    }
    return errorsMsg;
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!formValues.txtRightApi || typeof formValues.txtRightApi === "undefined") {
      formIsValid = false;
      errors["txtRightApi"] = "Cannot be empty";
    }
    if (!formValues.txtRightCode || typeof formValues.txtRightCode === "undefined") {
      formIsValid = false;
      errors["txtRightCode"] = "Cannot be empty";
    }
    if (!formValues.txtRightName || typeof formValues.txtRightName === "undefined") {
      formIsValid = false;
      errors["txtRightName"] = "Cannot be empty";
    }

    if (!formValues.txtMenuMasterID || typeof formValues.txtMenuMasterID.MenuMasterID === "undefined") {
      formIsValid = false;
      errors["txtMenuMasterID"] = "Cannot be empty";
    }
    if (!formValues.txtSPName || typeof formValues.txtSPName === "undefined") {
      if (formValues.txtSPName.trim() !== "") {
        const regex = new RegExp(/[^A-Za-z_]/gi);
        formIsValid = false;
        if (regex.test(formValues.txtSPName)) {
          errors["txtSPName"] = "Not valid";
        }
      }
    }

    if (!formValues.txtMenuMasterID || typeof formValues.txtMenuMasterID === "undefined") {
      formIsValid = false;
      errors["txtMenuMasterID"] = "Cannot be empty";
    }
    setFormValidationError(errors);
    console.log(errors);
    return formIsValid;
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Save",
        msg: "Are you sure to Save ?",
        button: {
          confirmText: "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    debugger;
    try {
      const ip = await publicIpv4();
      setBtnloaderActive(true);
      debugger;
      let formData = {
        rightMasterId: 0,
        rightApiUrl: formValues.txtRightApi,
        rightCode: formValues.txtRightCode,
        rightName: formValues.txtRightName,
        menuMasterId: formValues.txtMenuMasterID && formValues.txtMenuMasterID.MenuMasterID,
        applyToAdmin: formValues.txtApplyAdmin ? "Y" : "N",
        spName: formValues.txtSPName === "" ? "Not Updated" : formValues.txtSPName,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };

      let result = await getRightMasterInsert(formData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          let addright = {
            RightMasterID: result.responseData.RightMasterID ? result.responseData.RightMasterID : 0,
            RightAPIURL: formValues.txtRightApi,
            RightCode: formValues.txtRightCode,
            RightName: formValues.txtRightName,
            ApplyToAdmin: formValues.txtApplyAdmin ? "Y" : "N",
            SPName: formValues.txtSPName === "" ? "Not Updated" : formValues.txtSPName,
            MenuName: formValues.txtMenuMasterID && formValues.txtMenuMasterID.MenuName,
            MenuMasterID: formValues.txtMenuMasterID && formValues.txtMenuMasterID.MenuMasterID,
            isNewlyAdded: true,
          };
          debugger;
          props.updateRightList(addright);

          clearForm();
          toggleAddRightsMasterformPopup();
          setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      console.log(error, "error");
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const clearForm = () => {
    setFormValues({
      txtRightApi: "",
      txtRightCode: "",
      txtRightName: "",
      txtMenuMasterID: null,
      txtApplyAdmin: "",
      txtSPName: "",
    });

    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title="Add Access Rights" onSubmit={handleSave} show={() => toggleAddRightsMasterformPopup()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"520px"}>
            <Form.InputGroup errorMsg={formValidationError["txtMenuMasterID"]} label="Menu Name" req={true}>
              <Form.InputControl
                control="select"
                label="Menu Type"
                type="text"
                value={formValues.txtMenuMasterID}
                onChange={(e) => updateState("txtMenuMasterID", e)}
                options={menuListData}
                isLoading={isLoadingMenuList}
                getOptionValue={(option) => `${option}`}
                getOptionLabel={(option) => `${option.MenuName}`}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtRightName"]} label="Right Name" req={true}>
              <Form.InputControl
                control="input"
                label="Menu Name"
                name="txtRightName"
                type="text"
                value={formValues.txtRightName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtRightCode"]} label="Right Code">
              <Form.InputControl
                control="input"
                label="Right Code"
                name="txtRightCode"
                type="text"
                value={formValues.txtRightCode}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtApplyAdmin"]} label="Apply To Admin">
              <Form.InputControl control="switch" name="txtApplyAdmin" checked={formValues.txtApplyAdmin} onChange={(e) => updateState(e.target.name, e.target.checked)} />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtRightApi"]} label="API URL" req={true}>
              <Form.InputControl
                control="input"
                label="API URL"
                name="txtRightApi"
                type="text"
                value={formValues.txtRightApi}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtSPName"]} label="SP Name">
              <Form.InputControl
                label="SP Name"
                control="input"
                name="txtSPName"
                type="text"
                value={formValues.txtSPName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddRights;
