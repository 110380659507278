import { publicIpv4 } from "public-ip";
import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

// const userData = getSessionStorage("user");

export const addProfileList = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.AccessControl.createUserProfile);
  return response;
};

export const companyProfileUpdate = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.AccessControl.UpdateCompanyUserProfile);
  return response;
};

export const companyProfileStatusUpdate = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.AccessControl.UpdateUserProfileStatus);
  return response;
};

// const insertUserID = userData.User.user.AppAccessID;

export const getProfileList = async (ViewMode, UserProfileID, CompanyMasterID, SearchText) => {
  const requestData = {
    viewMode: ViewMode,
    userProfileId: UserProfileID,
    companyMasterId: CompanyMasterID,
    accessId: 0,
    searchText: SearchText,
  };

  const response = await ApiCalling(requestData, APIEndpoints.AccessControl.GetCompanyUserProfile);
  return response;
};

export const profileMenuAssignList = async (profileMenuID, userProfileID, CompanyMasterID, MenuMasterID, ModuleCode, viewMode) => {
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const ip = await publicIpv4();
  const requestData = {
    profileMenuId: profileMenuID,
    companyMasterId: CompanyMasterID,
    menuMasterId: MenuMasterID,
    userProfileId: userProfileID,
    moduleMasterId: ModuleCode,
    viewMode: viewMode,
    objCommon: {
      insertUserId: insertUserID,
      insertIpAddress: ip,
    },
  };

  const response = await ApiCalling(requestData, APIEndpoints.AccessControl.userProfileMenuAssignManage);
  return response;
};

export const manageUserProfileAssign = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.User.ManageUserProfileAssign);
  return response;
};

export const getProfileHistory = async (formData) => {
  const response = await ApiCalling(formData, APIEndpoints.User.profileHistory);
  return response;
};
