import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import { addMenuOrSubMenu, getMenuType, upadateMenuOrSubMenu } from "../../Resources/MenuMangementService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";

const AddMenuList = (props) => {
  let isEditMode = props.isEditMode;
  let menuData = props.menuData;
  let moduleData = props.moduleData;
  let updateMenuList = props.updateMenuList;
  let setMsgAlert = props.setMsgAlert;
  let submenuPopupData = props.submenuPopupData;
  let openSubmenuPopup = props.openSubmenuPopup;
  let getMenuListData = props.getMenuListData;

  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [isLoadingMenuTypeList, setIsLoadingMenuTypeList] = useState(false);
  const [menuTypeListData, setMenuTypeListData] = useState([]);
  const [formValues, setFormValues] = useState({
    txtMainName: "",
    hasChild: false,
    txtMenuName: "",
    txtWebUrl: "",
    txtWinUrl: "",
    txtAppUrl: "",
    txtApiUrl: "",
    txtWpfUrl: "",
    txtReactUrl: "",
  });

  const firstTextInput = useRef();

  useEffect(() => {
    debugger;
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
    if (isEditMode) {
      setFormValues({
        txtMenuName: menuData.MenuName,
        txtWebUrl: menuData.WebURL,
        txtWinUrl: menuData.WinURL,
        txtAppUrl: menuData.AppURL,
        txtApiUrl: menuData.APIURL,
        txtWpfUrl: menuData.WPFURL,
        txtReactUrl: menuData.ReactURL,
        hasChild: menuData.HasChild,
        txtMenuType: menuData && menuData.MenuType && menuData.MenuTypeName ? { MenuTypeID: menuData.MenuType, MenuTypeName: menuData.MenuTypeName } : "",
      });
    }
    getMenuTypeListData();
  }, []);

  const getMenuTypeListData = async () => {
    debugger;
    try {
      setIsLoadingMenuTypeList(true);
      let result = await getMenuType();
      setIsLoadingMenuTypeList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData) {
          setMenuTypeListData(result.responseData);
          console.log(result.responseData);
        } else {
          setMenuTypeListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [formValidationError, setFormValidationError] = useState({});

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtMenuName") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtWebUrl") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtWinUrl") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtAppUrl") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtApiUrl") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtWpfUrl") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtReactUrl") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    }

    return errorsMsg;
  };

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!formValues.txtMenuName) {
      formIsValid = false;
      errors["txtMenuName"] = "Cannot be empty";
    }

    if (!formValues.txtMenuType || typeof formValues.txtMenuType === "undefined") {
      formIsValid = false;
      errors["txtMenuType"] = "select menu type";
    }

    if (typeof formValues.txtMenuName === "undefined") {
      formIsValid = false;
      errors["txtMenuName"] = "Cannot be empty";
    }

    if (typeof formValues["txtWpfUrl"] !== "undefined" && formValues["txtWpfUrl"]) {
      var count = (formValues["txtWpfUrl"].match(/\./g) || []).length;
      if (!(count >= 2)) {
        formIsValid = false;
        errors["txtWpfUrl"] = "Please enter a valid Url. Ex:- MIS.Finance.AccountStatementPage";
      }
    }

    if (
      (typeof formValues.txtAppUrl === "undefined" || !formValues.txtWpfUrl) &&
      (typeof formValues.txtWpfUrl === "undefined" || !formValues.txtWpfUrl) &&
      (typeof formValues.txtReactUrl === "undefined" || !formValues.txtReactUrl)
    ) {
      setMsgAlert({ open: true, type: "warning", msg: "Atleast One Mandatory From AppURL, WpfUrl and ReactUrl" });
      formIsValid = false;
    }

    setFormValidationError(errors);
    return formIsValid;
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to update ?" : "Are you sure to Save ?",
        button: {
          confirmText: isEditMode ? "Update" : "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    try {
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      setBtnloaderActive(true);
      const ip = await publicIpv4();
      console.log("formValues.txtMenuType.MenuTypeID", formValues.txtMenuType.MenuTypeID);
      let formData = {
        moduleMasterId: moduleData.ModuleCode,
        menuMasterId: isEditMode ? menuData.MenuMasterID : 0,
        menuName: formValues.txtMenuName,
        menuSequence: isEditMode ? menuData.MenuSequence : 1,
        menuType: formValues && formValues.txtMenuType.MenuTypeID ? formValues.txtMenuType.MenuTypeID : 0,
        underMenuId: isEditMode ? menuData.UnderMenuID : menuData.MenuMasterID,
        apiUrl: formValues.txtApiUrl,
        appUrl: formValues.txtAppUrl,
        reactUrl: formValues.txtReactUrl,
        webUrl: formValues.txtWebUrl,
        wpFUrl: formValues.txtWpfUrl,
        winUrl: formValues.txtWinUrl,
        hasChild: formValues.hasChild ? 1 : 0,
        isDeleteFlag: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = isEditMode ? await upadateMenuOrSubMenu(formData) : await addMenuOrSubMenu(formData);
      console.log(result, "result");
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });

        if (result && result.responseData.length > 0) {
          let subMenu = {
            ModuleMasterID: isEditMode ? moduleData.ModuleCode : "",
            MenuMasterID: isEditMode ? menuData.MenuMasterID : result.responseData[0].MenuMasterID,
            MenuSequence: isEditMode ? menuData.MenuSequence : 1,
            UnderMenuID: isEditMode ? menuData.UnderMenuID : menuData.MenuMasterID,
            ...formData,
          };
          updateMenuList(subMenu, isEditMode);
          getMenuListData(moduleData.ModuleCode, "0", "MENULIST");
          if (isEditMode) {
            openSubmenuPopup({
              open: false,
              data: [],
              isEditMode: false,
            });
          } else {
            clearForm();
            openSubmenuPopup({
              open: false,
              isEditMode: false,
              data: null,
            });
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtMainName: "",
      txtMenuType: null,
      txtMenuName: "",
      hasChild: false,
      txtWebUrl: "",
      txtWinUrl: "",
      txtAppUrl: "",
      txtApiUrl: "",
      txtWpfUrl: "",
      txtReactUrl: "",
    });

    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal
      varient={"center"}
      title="Edit Menu"
      onSubmit={handleSave}
      show={() =>
        openSubmenuPopup({
          open: false,
          isEditMode: false,
          data: null,
        })
      }
    >
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"520px"}>
            <Form.InputGroup errorMsg={formValidationError["txtMainName"]} label="Selected List" req={true}>
              <Form.InputControl control="input" name="txtMainName" type="text" value={menuData.MenuName} isDisabled={true} />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["hasChild"]} label="Has Child">
              <Form.InputControl
                control="switch"
                label="Has Child"
                disabled={isEditMode}
                name="hasChild"
                checked={formValues.hasChild}
                onChange={(e) => updateState(e.target.name, e.target.checked)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtMenuType"]} label="Menu Type" req={true}>
              <Form.InputControl
                control="select"
                label="Menu Type"
                type="text"
                isDisabled={isEditMode}
                value={formValues.txtMenuType}
                onChange={(e) => updateState("txtMenuType", e)}
                options={menuTypeListData}
                isLoading={isLoadingMenuTypeList}
                getOptionValue={(option) => `${option}`}
                getOptionLabel={(option) => `${option.MenuTypeName}`}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtMenuName"]} label="Menu Name" req={true}>
              <Form.InputControl
                control="input"
                label="Menu Name"
                name="txtMenuName"
                type="text"
                value={formValues.txtMenuName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtWebUrl"]} label="Web URL">
              <Form.InputControl
                control="input"
                label="Web URL"
                name="txtWebUrl"
                type="text"
                value={formValues.txtWebUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtWinUrl"]} label="Win URL">
              <Form.InputControl
                label="Win URL"
                control="input"
                name="txtWinUrl"
                type="text"
                value={formValues.txtWinUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtAppUrl"]} label="APP URL">
              <Form.InputControl
                label="APP URL"
                control="input"
                name="txtAppUrl"
                type="text"
                value={formValues.txtAppUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtApiUrl"]} label="API URL">
              <Form.InputControl
                label="API URL"
                control="input"
                name="txtApiUrl"
                type="text"
                value={formValues.txtApiUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtWpfUrl"]} label="WPF URL">
              <Form.InputControl
                label="WPF URL"
                control="input"
                name="txtWpfUrl"
                type="text"
                value={formValues.txtWpfUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtReactUrl"]} req={false} label="React URL">
              <Form.InputControl
                label="React URL"
                control="input"
                name="txtReactUrl"
                type="text"
                value={formValues.txtReactUrl}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddMenuList;
