import React, { useState, useEffect, useRef } from "react";
import { publicIpv4 } from "public-ip";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";

import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import PageBar1 from "../../../../../Framework/Components/Layout/Pagebar/PageBar1";
import moment from "moment";
import { getProfileHistory } from "../../Resources/ProfileManagementService";

const ProfileHistory = (props) => {
  let toggleLoginHistoryPopup = props.toggleLoginHistoryPopup;
  let setMsgAlert = props.setMsgAlert;
  let popupData = props.popupData;
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [profileHistoryList, setProfileHistoryList] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingLoginDetails] = useState(false);
  const [formValues, setFormValues] = useState({
    txtPage: 0,
    txtPerPage: "",
  });

  const listOptions = [
    { Name: "10", Value: 10 },
    { Name: "20", Value: 20 },
    { Name: "30", Value: 30 },
    { Name: "40", Value: 40 },
    { Name: "50", Value: 50 },
  ];

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getHistoryDetails = async (data) => {
    try {
      setIsLoadingLoginDetails(true);
      const formData = {
        viewMode: "H",
        profileRightId: 0,
        userProfileId: props.profileListData.UserProfileID,
        rightMasterId: data.RightMasterID,
        companyMasterID: props.selectedCompanyMaster.CompanyMasterID,
      };
      let result = await getProfileHistory(formData);
      console.log(result.responseData, "result.responseData.rows");
      setIsLoadingLoginDetails(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setProfileHistoryList(result.responseData);
          clearForm();
        } else {
          setProfileHistoryList([]);
        }
      } else {
        setIsLoadingLoginDetails(false);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setIsLoadingLoginDetails(false);
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtPage: "",
      txtPerPage: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  const [searchTextModuleMaster, setSearchTextModuleMaster] = useState("");
  const onSearchMasterCommonCode = (val) => {
    setSearchTextModuleMaster(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
    setProfileHistoryList([]);
  };

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    }
    return { background: "" };
  };

  const gridColumnDefs = [
    {
      field: "RightName",
      headerName: "Right Name",
      width: 150,
    },
    {
      field: "RightCode",
      headerName: "Right Code",
      width: 130,
    },
    { field: "RightAPIURL", headerName: "APIURL", width: 190 },
    {
      field: "SPName",
      headerName: "SP Name",
      width: 180,
    },

    { field: "ApplyToAdmin", headerName: "Apply To Admin", width: 140 },
  ];

  useEffect(() => {
    getHistoryDetails(popupData);
  }, []);

  return (
    <Modal varient="center" width="70vw" height="70vh" title="Profile History" show={() => toggleLoginHistoryPopup()}>
      <Modal.Body>
        <div style={{ display: "grid", gridTemplateRows: "max-content" }}>
          <PageBar title="">
            {/* <PageBar.Input
              type="text"
              name="txtPage"
              value={formValues["txtPage"]}
              onChange={(e) => {
                updateState("txtPage", e.target.value);
              }}
              placeholder="No Of Pages"
            /> */}
            {/* <PageBar.Select
              type="text"
              name="txtPerPage"
              width={"180px"}
              getOptionLabel={(option) => `${option.Name}`}
              value={formValues.txtPerPage}
              getOptionValue={(option) => `${option.value}`}
              options={listOptions}
              label="List Size"
              onChange={(e) => updateState("txtPerPage", e)}
            /> */}
            <PageBar1.Search
              width={"80px"}
              focus={true}
              onClick={() => getHistoryDetails(popupData)}
              value={searchTextModuleMaster}
              onChange={(e) => onSearchMasterCommonCode(e.target.value)}
            />
          </PageBar>

          <DataGrid
            rowData={profileHistoryList}
            columnDefs={gridColumnDefs}
            onGridReady={onGridReady}
            animateRows={true}
            loader={isLoadingModuleMasterData ? <Loader /> : null}
            getRowStyle={getRowStyle}
          />
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default ProfileHistory;
