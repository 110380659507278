import React, { useState, useEffect, useRef } from "react";
import { CustomMsg } from "../../../../Configration/Utilities/customMessage";
import { CreateBankMaster, UpdateBankMaster } from "../Resources/BankService";
import Button from "../../../../Framework/Components/Widgets/Button/Button";
import Modal from "../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../APIMethods/Auth/auth";
import { getCountry } from "../../GeographicData/Resources/GeographicDataService";

const AddBankMaster = (props) => {
  let toggleAddBankMaster = props.toggleAddBankMaster;
  let setMsgAlert = props.setMsgAlert;
  let updateBankMasterList = props.updateBankMasterList;
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  let _updateListing = props._updateListing;
  let selectedCountry = props.selectedCountry;

  const [btnloaderActive, setBtnloaderActive] = useState(false);

  const [formValues, setFormValues] = useState({
    txtBankMasterName: "",
    txtCountry: { CountryName: selectedCountry?.CountryName || "", CountryMasterID: selectedCountry?.CountryMasterID || 0 },
    txtBankShortName: "",
    txtLogoFileName: "",
  });
  const [oldFormValues, setOldFormValues] = useState({
    txtBankMasterName: "",
    txtCountry: { CountryName: selectedCountry?.CountryName || "", CountryMasterID: selectedCountry?.CountryMasterID || 0 },
    txtBankShortName: "",
    txtLogoFileName: "",
  });
  const [editBankMaster, setEditBankMaster] = useState();
  const [formValidationError, setFormValidationError] = useState({});
  const [saveMore, setSaveMore] = useState(false);
  const firstTextInput = useRef();

  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const getCountryList = async () => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    const ip = await publicIpv4();
    try {
      setIsLoadingCountry(true);
      const formData = {
        searchText: "",
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getCountry(formData);
      console.log(result, "result");
      setIsLoadingCountry(false);
      if (result.responseCode === 1) {
        console.log(result);
        if (result && result.responseData && result.responseData.length > 0) {
          setCountryList(result.responseData);
        } else {
          setCountryList([]);
        }
      } else {
        setCountryList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
    getCountryList();
    console.log(isEditMode, "isEditMode");
    console.log(popupData, "popupData");
  }, []);

  useEffect(() => {
    if (isEditMode) {
      if (popupData) {
        setEditBankMaster(popupData);
        console.log(popupData, "popupData");
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (editBankMaster) {
      formValues.txtCountry = { CountryName: editBankMaster.CountryName, CountryMasterID: editBankMaster.CountryMasterID };
      formValues.txtBankMasterName = editBankMaster.BankMasterName;
      formValues.txtBankShortName = editBankMaster.BankShortName;
      formValues.txtLogoFileName = editBankMaster.LogoFileName;
    }
    setFormValues({ ...formValues });
    setOldFormValues({ ...formValues });
  }, [editBankMaster]);

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && popupData) {
      return true;
    }

    return false;
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtBankMasterName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter bank name!";
      }
    }
    if (name === "txtCountry") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Select Country";
      }
    }
    if (name === "txtBankShortName") {
      if (!value || typeof value === "undefined" || value === null) {
        errorsMsg = "Enter bank short name!";
      }
    }

    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtBankMasterName"] = validateField("txtBankMasterName", formValues.txtBankMasterName);
      errors["txtCountry"] = validateField("txtCountry", formValues.txtCountry);
      errors["txtBankShortName"] = validateField("txtBankShortName", formValues.txtBankShortName);
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setFormValidationError(errors);

      return formIsValid;
    } catch (error) {
      setMsgAlert({ type: "warning", message: "something Went wrong" });
      return false;
    }
  };

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const ip = await publicIpv4();
      const userData = getSessionStorage("user");
      const insertUserID = userData.User.user.AppAccessID;
      let formData = {};
      setBtnloaderActive(true);
      if (isEditMode) {
        formData = {
          BankMasterID: popupData && popupData.BankMasterID ? popupData.BankMasterID : 0,
          CountryMasterID: formValues.txtCountry && formValues.txtCountry.CountryMasterID ? formValues.txtCountry.CountryMasterID : 0,
          BankMasterName: formValues.txtBankMasterName ? formValues.txtBankMasterName.trim() : "",
          LogoFileName: formValues.txtLogoFileName ? formValues.txtLogoFileName.trim() : "",
          BankShortName: formValues.txtBankShortName ? formValues.txtBankShortName.trim() : "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        formData = {
          CountryMasterID: formValues.txtCountry && formValues.txtCountry.CountryMasterID ? formValues.txtCountry.CountryMasterID : 0,
          BankMasterName: formValues.txtBankMasterName ? formValues.txtBankMasterName.trim() : "",
          LogoFileName: formValues.txtLogoFileName ? formValues.txtLogoFileName.trim() : "",
          BankShortName: formValues.txtBankShortName ? formValues.txtBankShortName.trim() : "",
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }

      let fun = isEditMode ? UpdateBankMaster : CreateBankMaster;
      let result = await fun(formData);

      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({ open: true, type: "success", msg: result.responseMessage });
        setSaveMore(true);
        if (result.responseData) {
          if (result && result.responseData && result.responseData.data) {
            console.log(result.responseData.data.BankMasterID, "result.responseData.data.BankMasterID");
            let addedDepartment = {
              BankMasterID: isEditMode ? popupData.BankMasterID : result.responseData.data.BankMasterID,
              BankMasterName: formValues.txtBankMasterName,
              BankShortName: formValues.txtBankShortName ? formValues.txtBankShortName : "",
              CountryName: formValues.txtCountry && formValues.txtCountry.CountryName ? formValues.txtCountry.CountryName : null,
              LogoFileName: formValues.txtLogoFileName ? formValues.txtLogoFileName : "",
              CountryMasterID: formValues.txtCountry && formValues.txtCountry.CountryMasterID ? formValues.txtCountry.CountryMasterID : null,
              isActive: 1,
              IsNewlyAdded: true,
            };
            console.log(addedDepartment, "addedDepartment");
            if (isEditMode) {
              _updateListing({ ...addedDepartment, IsNewlyAdded: false, IsUpdated: true });
              toggleAddBankMaster(false, null);
            } else {
              updateBankMasterList(addedDepartment);
            }

            clearForm();
            // toggleAddBankMaster(false, null);
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        } else {
          setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtBankMasterName: "",
      txtCountry: { CountryName: selectedCountry?.CountryName || "", CountryMasterID: selectedCountry?.CountryMasterID || 0 },
      txtBankShortName: "",
      txtLogoFileName: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal varient={"center"} title={isEditMode ? "Update Bank Master" : "Add Bank Master"} onSubmit={onSubmit} show={() => toggleAddBankMaster()}>
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup errorMsg={formValidationError["txtCountry"]} label="Country" req={true}>
              <Form.InputControl
                control="select"
                name="txtCountry"
                getOptionLabel={(option) => `${option.CountryName}`}
                value={formValues.txtCountry}
                getOptionValue={(option) => `${option}`}
                options={countryList}
                isLoading={isLoadingCountry}
                onChange={(e) => updateState("txtCountry", e)}
                label="Country"
                isDisabled={true}
              />
            </Form.InputGroup>
            <Form.InputGroup errorMsg={formValidationError["txtBankMasterName"]} label="Bank Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtBankMasterName"
                type="text"
                value={formValues.txtBankMasterName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Bank Name"
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtBankShortName"]} label="Bank Short Name" req={true}>
              <Form.InputControl
                control="input"
                name="txtBankShortName"
                type="text"
                value={formValues.txtBankShortName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Bank Short Name"
              />
            </Form.InputGroup>

            <Form.InputGroup errorMsg={formValidationError["txtLogoFileName"]} label="Logo File Name" req={false}>
              <Form.InputControl
                control="input"
                name="txtLogoFileName"
                type="text"
                value={formValues.txtLogoFileName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Logo File Name"
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" disabled={checkEditFormControlValues() || btnloaderActive} trigger={false}>
          {isEditMode ? "Update" : saveMore ? "Save More" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBankMaster;
