/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
import React, { useState, useEffect, useRef } from "react";
import "../../Company_Registration.css";
import Select from "react-select";
import { FormSelectStyle } from "../../../../../Framework/Components/Widgets/SelectStyle/SelectStyle";
import { FaTimes } from "react-icons/fa";
import { createCompanyAddress, updateCompanyAddress } from "../../../Resources/companyRegistrationService";
import { getCity, getCommonMasterData } from "../../../Resources/commonService";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";
import useDebounce from "../../../../../Configration/Utilities/debounce";

const AddCompanyAddressPopup = (props) => {
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  let selectedCompanyMaster = props.selectedCompanyMaster;
  let toggleAddCompanyAddressPopup = props.toggleAddCompanyAddressPopup;
  let setMsgAlert = props.setMsgAlert;
  let updateAddressList = props.updateAddressList;

  const [addressTypeList, setAddressTypeList] = useState([]);
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtAddressType: null,
    txtAddressCode: "",
    txtAddressTitle: "",
    txtAddressLine1: "",
    txtAddressLine2: "",
    txtAddressDisplayName: "",
    txtCity: null,
    txtPinCode: "",
    txtLatitude: "",
    txtLongitude: "",
    txtImplementationDate: "",
    txtAddressLink: "",
    txtLandmark: "",
    isDefaultAddress: false,
  });
  const [oldFormValues, setOldFormValues] = useState({
    txtAddressType: null,
    txtAddressCode: "",
    txtAddressTitle: "",
    txtAddressLine1: "",
    txtAddressLine2: "",
    txtAddressDisplayName: "",
    txtCity: null,
    txtPinCode: "",
    txtLatitude: "",
    txtLongitude: "",
    txtImplementationDate: "",
    txtAddressLink: "",
    txtLandmark: "",
    isDefaultAddress: false,
  });
  const [isModalAnimOpen, setModalAnimOpen] = useState(false);
  const toggleModalAnimOpen = () => {
    setModalAnimOpen(true);
    setTimeout(() => {
      setModalAnimOpen(false);
    }, 100);
  };

  const [branchSearchTerms, setBranchSearchText] = useState("");

  const denouncerTerms = useDebounce(branchSearchTerms, 1000);

  useEffect(() => {
    if (!isEditMode) {
      if (denouncerTerms && denouncerTerms.length >= 3) {
        if (denouncerTerms.includes("#")) {
          setBranchSearchText("");
        }
        getCityList(selectedCompanyMaster.CountryMasterID, 0);
      } else {
        setTimeout(() => {
          setBranchSearchText("");
          setCityList([]);
        }, 500);
      }
    }
  }, [denouncerTerms]);

  const [formValidationError, setFormValidationError] = useState({});
  const [editCompanyAddress, setEditCompanyAddress] = useState();

  const getAddressTypeList = async () => {
    try {
      let result = await getCommonMasterData("ADDRESSTYPE");
      if (result && result.responseCode === 1) {
        if (result.responseData) {
          setAddressTypeList(result.responseData);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          console.log(result.responseMessage);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [cityList, setCityList] = useState([]);
  const [isLoadingCity, setIsLoadingCity] = useState(false);
  const getCityList = async (countryId, stateId) => {
    try {
      setIsLoadingCity(true);
      let result = await getCity(countryId, stateId);
      setIsLoadingCity(false);
      console.log(result, "cityList");
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCityList(result.responseData);
        } else {
          setCityList([]);
        }
      } else {
        setCityList([]);
        //setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const firstTextInput = useRef();
  const [isLoadingEditData, setIsLoadingEditData] = useState(false);

  useEffect(() => {
    if (!isEditMode) {
      getAddressTypeList();
      getCityList(selectedCompanyMaster.CountryMasterID, 0);
      console.log(setIsLoadingEditData);
    } else {
      getAddressTypeList();
      getCityList(selectedCompanyMaster.CountryMasterID, 0);
      if (selectedCompanyMaster) {
        setEditCompanyAddress(popupData);
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const checkEditFormControlValues = () => {
    if (JSON.stringify(formValues) === JSON.stringify(oldFormValues) && popupData) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (editCompanyAddress && editCompanyAddress.CompanyAddressID) {
      setFormValues((existingValue) => ({
        ...existingValue,
        txtAddressType: addressTypeList.find((x) => x.AddressTypeID === editCompanyAddress.AddressTypeCode),
      }));

      setOldFormValues((existingValue) => ({
        ...existingValue,
        txtAddressType: addressTypeList.find((x) => x.AddressTypeID === editCompanyAddress.AddressTypeCode),
      }));
    }
  }, [addressTypeList]);

  useEffect(() => {
    if (editCompanyAddress && editCompanyAddress.CompanyAddressID) {
      setFormValues((existingValue) => ({
        ...existingValue,
        txtCity: cityList.find((x) => x.CityMasterID === editCompanyAddress.CityCode),
      }));

      setOldFormValues((existingValue) => ({
        ...existingValue,
        txtCity: cityList.find((x) => x.CityMasterID === editCompanyAddress.CityCode),
      }));
    }
  }, [cityList]);

  useEffect(() => {
    if (editCompanyAddress && editCompanyAddress.CompanyAddressID) {
      console.log(editCompanyAddress, "editCompanyAddress");
      setFormValues({
        txtAddressType:
          editCompanyAddress.AddressTypeCode && addressTypeList.length > 0 ? addressTypeList.find((x) => x.AddressTypeID === editCompanyAddress.AddressTypeCode) : null,
        txtAddressCode: editCompanyAddress.AddressCode ? editCompanyAddress.AddressCode : "",
        txtAddressTitle: editCompanyAddress.AddressTitle ? editCompanyAddress.AddressTitle : "",
        txtAddressLine1: editCompanyAddress.AddressLine1 ? editCompanyAddress.AddressLine1 : "",
        txtAddressLine2: editCompanyAddress.AddressLine2 ? editCompanyAddress.AddressLine2 : "",
        txtAddressDisplayName: editCompanyAddress.AddressDisplayName ? editCompanyAddress.AddressDisplayName : "",
        txtCity: editCompanyAddress.CityCode && cityList.length > 0 ? cityList.find((x) => x.CityMasterID === editCompanyAddress.CityCode) : null,
        txtPinCode: editCompanyAddress.PinCode ? editCompanyAddress.PinCode : "",
        txtLatitude: editCompanyAddress.AddressLatitude ? editCompanyAddress.AddressLatitude : "",
        txtLongitude: editCompanyAddress.AddressLongitude ? editCompanyAddress.AddressLongitude : "",
        txtImplementationDate: editCompanyAddress.ImplementationDate ? editCompanyAddress.ImplementationDate : "",
        txtAddressLink: editCompanyAddress.AddressLink ? editCompanyAddress.AddressLink : "",
        txtLandmark: editCompanyAddress.AddressLandmark ? editCompanyAddress.AddressLandmark : "",
        isDefaultAddress: editCompanyAddress.IsDefaultAddress === 0 ? false : true,
      });
      setOldFormValues({
        txtAddressType:
          editCompanyAddress.AddressTypeCode && addressTypeList.length > 0 ? addressTypeList.find((x) => x.AddressTypeID === editCompanyAddress.AddressTypeCode) : null,
        txtAddressCode: editCompanyAddress.AddressCode ? editCompanyAddress.AddressCode : "",
        txtAddressTitle: editCompanyAddress.AddressTitle ? editCompanyAddress.AddressTitle : "",
        txtAddressLine1: editCompanyAddress.AddressLine1 ? editCompanyAddress.AddressLine1 : "",
        txtAddressLine2: editCompanyAddress.AddressLine2 ? editCompanyAddress.AddressLine2 : "",
        txtAddressDisplayName: editCompanyAddress.AddressDisplayName ? editCompanyAddress.AddressDisplayName : "",
        txtCity: editCompanyAddress.CityCode && cityList.length > 0 ? cityList.find((x) => x.CityMasterID === editCompanyAddress.CityCode) : null,
        txtPinCode: editCompanyAddress.PinCode ? editCompanyAddress.PinCode : "",
        txtLatitude: editCompanyAddress.AddressLatitude ? editCompanyAddress.AddressLatitude : "",
        txtLongitude: editCompanyAddress.AddressLongitude ? editCompanyAddress.AddressLongitude : "",
        txtImplementationDate: editCompanyAddress.ImplementationDate ? editCompanyAddress.ImplementationDate : "",
        txtAddressLink: editCompanyAddress.AddressLink ? editCompanyAddress.AddressLink : "",
        txtLandmark: editCompanyAddress.AddressLandmark ? editCompanyAddress.AddressLandmark : "",
        isDefaultAddress: editCompanyAddress.IsDefaultAddress === 0 ? false : true,
      });
    }

    console.log(editCompanyAddress, "editCompanyAddress");
  }, [editCompanyAddress]);

  const updateState = (name, value) => {
    console.log("name, value", name, value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtAddressCode" || name === "txtAddressLine1" || name === "txtAddressType" || name === "txtCity" || name === "txtPinCode" || name === "txtImplementationDate") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtAddressDisplayName") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      } else if (value.length > 15) {
        errorsMsg = "Cannot be longer than 15 characters.";
      }
    } else if (name === "txtLatitude" || name === "txtLongitude") {
      if (value && value.trim() !== "" && typeof value !== "undefined") {
        if (isNaN(value)) {
          errorsMsg = "Please enter number only";
        }
      }
    }
    return errorsMsg;
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    errors["txtAddressTitle"] = validateField("txtAddressTitle", formValues.txtAddressTitle);
    if (!isEditMode) errors["txtAddressCode"] = validateField("txtAddressCode", formValues.txtAddressCode);
    errors["txtAddressDisplayName"] = validateField("txtAddressDisplayName", formValues.txtAddressDisplayName);
    errors["txtAddressType"] = validateField("txtAddressType", formValues.txtAddressType);
    errors["txtAddressLine1"] = validateField("txtAddressLine1", formValues.txtAddressLine1);
    errors["txtCity"] = validateField("txtCity", formValues.txtCity);
    errors["txtPinCode"] = validateField("txtPinCode", formValues.txtPinCode);
    errors["txtLatitude"] = validateField("txtLatitude", formValues.txtLatitude);
    errors["txtLongitude"] = validateField("txtLongitude", formValues.txtLongitude);
    errors["txtImplementationDate"] = validateField("txtImplementationDate", formValues.txtImplementationDate);
    if (Object.values(errors).join("").toString()) {
      formIsValid = false;
    }
    setFormValidationError(errors);
    return formIsValid;
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: isEditMode ? "Update" : "Save",
        msg: isEditMode ? "Are you sure to Update ?" : "Are you sure to Save ?",
        button: {
          confirmText: isEditMode ? "Update" : "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    debugger;
    try {
      const userData = getSessionStorage("user");
      const ip = await publicIpv4();
      const insertUserID = userData.User.user.AppAccessID;
      setBtnloaderActive(true);

      let requestData = {};
      if (isEditMode) {
        requestData = {
          bmcAddressCode: editCompanyAddress.BMCAddressCode,
          addressCode: formValues.txtAddressCode,
          addressTitle: formValues.txtAddressTitle,
          addressLine1: formValues.txtAddressLine1,
          addressLine2: formValues.txtAddressLine2,
          addressDisplayName: formValues.txtAddressDisplayName,
          cityCode: formValues.txtCity && formValues.txtCity.CityMasterID ? formValues.txtCity.CityMasterID : 0,
          pinCode: formValues.txtPinCode,
          addressLandmark: formValues.txtLandmark,
          addressLatitude: formValues.txtLatitude && formValues.txtLatitude.trim() !== "" && typeof formValues.txtLatitude !== "undefined" ? formValues.txtLatitude : "0",
          addressLongitude: formValues.txtLongitude && formValues.txtLongitude.trim() !== "" && typeof formValues.txtLongitude !== "undefined" ? formValues.txtLongitude : "0",
          isDefaultAddress: formValues.isDefaultAddress === true ? 1 : 0,
          addressLink: formValues.txtAddressLink && formValues.txtAddressLink.trim() !== "" && typeof formValues.txtAddressLink !== "undefined" ? formValues.txtAddressLink : "",
          implementationDate: formValues.txtImplementationDate,
          activeStatus: 0,
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      } else {
        requestData = {
          addressCode: 0,
          companyMasterId: selectedCompanyMaster.CompanyMasterID,
          addressTypeCode: formValues.txtAddressType && formValues.txtAddressType.AddressTypeID ? formValues.txtAddressType.AddressTypeID : 0,
          addressCode: formValues.txtAddressCode,
          addressTitle: formValues.txtAddressTitle,
          addressLine1: formValues.txtAddressLine1,
          addressLine2: formValues.txtAddressLine2,
          addressDisplayName: formValues.txtAddressDisplayName,
          cityCode: formValues.txtCity && formValues.txtCity.CityMasterID ? formValues.txtCity.CityMasterID : 0,
          pinCode: formValues.txtPinCode,
          addressLandmark: formValues.txtLandmark,
          addressLatitude: formValues.txtLatitude && formValues.txtLatitude.trim() !== "" && typeof formValues.txtLatitude !== "undefined" ? formValues.txtLatitude : "0",
          addressLongitude: formValues.txtLongitude && formValues.txtLongitude.trim() !== "" && typeof formValues.txtLongitude !== "undefined" ? formValues.txtLongitude : "0",
          isDefaultAddress: formValues.isDefaultAddress === true ? 1 : 0,
          addressLink: formValues.txtAddressLink && formValues.txtAddressLink.trim() !== "" && typeof formValues.txtAddressLink !== "undefined" ? formValues.txtAddressLink : "",
          implementationDate: formValues.txtImplementationDate,
          activeStatus: 0,
          objCommon: {
            insertUserId: insertUserID,
            insertIpAddress: ip,
          },
        };
      }

      let fun = isEditMode ? updateCompanyAddress : createCompanyAddress;
      let result = await fun(requestData);
      // let result = await  createCompanyAddress(formData);
      setBtnloaderActive(false);

      if (result.responseCode === 1) {
        debugger;
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        if (!isEditMode) {
          if (result.responseData && result.responseData.length > 0) {
            let addedCode = {
              BMCAddressCode: result.responseData.BMCAddressCode,
              CompanyAddressID: result.responseData.CompanyAddressID,
              AddressCode: formValues.txtAddressCode,
              AddressDisplayName: formValues.txtAddressDisplayName,
              CompanyMasterID: 2,
              AddressTitle: formValues.txtAddressTitle,
              AddressLine1: formValues.txtAddressLine1,
              AddressLine2: formValues.txtAddressLine2,
              PinCode: formValues.txtPinCode,
              Landmark: formValues.txtLandmark,
              IsDefaultAddress: formValues.isDefaultAddress === true ? 1 : 0,
              AssignedLocationType: 0,
              IsNewlyAdded: true,
            };
            updateAddressList(addedCode);
            clearForm();
            toggleAddCompanyAddressPopup(false, null);
          } else {
            setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
            console.log(result.responseMessage);
          }
        }
        props.getCompanyAddress(1, props.selectedCompanyMaster.CompanyMasterID, 0, "GETADDRESS");
        toggleAddCompanyAddressPopup(false, null);
        // const locationTypeId = selectedLocationType ? selectedLocationType.CommonMasterValueID : 0;
        // getCompanyAddress(1, selectedCompanyMaster.CompanyMasterID, 0, "GETADDRESS");
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtAddressType: null,
      txtAddressCode: "",
      txtAddressTitle: "",
      txtAddressLine1: "",
      txtAddressLine2: "",
      txtAddressDisplayName: "",
      txtCity: null,
      txtPinCode: "",
      txtLatitude: "",
      txtLongitude: "",
      txtImplementationDate: "",
      txtAddressLink: "",
      txtLandmark: "",
      isDefaultAddress: false,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  // useEffect(() => {
  //   getAddressTypeList();
  //   getCityList(selectedCompanyMaster.CountryMasterID, 0);
  // }, []);

  const [citySearch, setCitySearch] = useState("");

  return (
    <React.Fragment>
      <div onClick={toggleModalAnimOpen} className="Biz_Bm__Company_AddAddressPopup_overlay"></div>
      <div className={isModalAnimOpen ? "Biz_Bm__Company_AddAddressPopup_Box Biz_Bm__Company_AddAddressPopup_overlay_PopupAnim" : "Biz_Bm__Company_AddAddressPopup_Box"}>
        <header className="Biz_Bm__Company_AddAddressPopup_Header">
          <h2>{isEditMode ? "Update Company Address" : "Add Company Address"}</h2>
          <a onClick={() => toggleAddCompanyAddressPopup(false, null)} className="Biz_Bm__Company_AddAddressPopup_HeaderCloseBtn">
            <FaTimes className="Biz_Bm_Icon" />
          </a>
        </header>
        <form method="post" onSubmit={(e) => handleSave(e)} className="Biz_Bm__Company_AddAddressPopup_Content">
          {isLoadingEditData ? <Loader /> : null}
          <div className="Biz_Bm__Company_AddAddressPopup_FormContent">
            <div className="Biz_Bm__Company_AddUserPopup_FormGroup">
              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt">Address Title</label>
              <div
                className={
                  formValidationError["txtAddressTitle"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <input
                  type="text"
                  name="txtAddressTitle"
                  maxLength="20"
                  value={formValues.txtAddressTitle}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                  placeholder="Address Title"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtAddressTitle"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt Biz_Bm__Company_AddAddressPopup_Req">Address Code</label>
              <div
                className={
                  formValidationError["txtAddressCode"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <input
                  type="text"
                  name="txtAddressCode"
                  value={formValues.txtAddressCode}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                  placeholder="Address Code"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtAddressCode"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt Biz_Bm__Company_AddAddressPopup_Req">Address Line1</label>
              <div
                className={
                  formValidationError["txtAddressLine1"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddUserPopup_FullWidthInputGrp Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddUserPopup_FullWidthInputGrp"
                }
              >
                <input
                  type="text"
                  name="txtAddressLine1"
                  value={formValues.txtAddressLine1}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                  placeholder="Address Line1"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtAddressLine1"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt">Address Line2</label>
              <div
                className={
                  formValidationError["txtAddressLine2"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddUserPopup_FullWidthInputGrp Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddUserPopup_FullWidthInputGrp"
                }
              >
                <input
                  type="text"
                  name="txtAddressLine2"
                  value={formValues.txtAddressLine2}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                  placeholder="Address Line2"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtAddressLine2"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt Biz_Bm__Company_AddAddressPopup_Req">Display Name</label>
              <div
                className={
                  formValidationError["txtAddressDisplayName"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <input
                  type="text"
                  name="txtAddressDisplayName"
                  value={formValues.txtAddressDisplayName}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                  placeholder="Address Display Name"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtAddressDisplayName"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt Biz_Bm__Company_AddAddressPopup_Req">Address Type</label>
              <div
                className={
                  formValidationError["txtAddressType"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <Select
                  name="txtAddressType"
                  menuPlacement="auto"
                  isClearable={true}
                  getOptionLabel={(option) => `${option.AddressType}`}
                  value={formValues.txtAddressType}
                  getOptionValue={(option) => `${option}`}
                  options={addressTypeList}
                  onChange={(e) => updateState("txtAddressType", e)}
                  isSearchable={true}
                  styles={FormSelectStyle}
                  placeholder="Select Address Type"
                  noOptionsMessage={() => "No Result Found"}
                ></Select>
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtAddressType"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt Biz_Bm__Company_AddAddressPopup_Req">City</label>
              <div
                className={
                  formValidationError["txtCity"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <Select
                  name="txtCity"
                  menuPlacement="auto"
                  isClearable={true}
                  getOptionLabel={(option) => `${option.CityName}`}
                  value={formValues.txtCity}
                  getOptionValue={(option) => `${option}`}
                  options={cityList}
                  onChange={(e) => updateState("txtCity", e)}
                  isSearchable={true}
                  isLoading={isLoadingCity}
                  styles={FormSelectStyle}
                  placeholder="Select City"
                  inputValue={branchSearchTerms}
                  onInputChange={(e) => {
                    setBranchSearchText(e);
                  }}
                  noOptionsMessage={() => "No Result Found"}
                ></Select>
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtCity"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt Biz_Bm__Company_AddAddressPopup_Req">Pin Code</label>
              <div
                className={
                  formValidationError["txtPinCode"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <input
                  type="text"
                  name="txtPinCode"
                  value={formValues.txtPinCode}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                  placeholder="Pin Code"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtPinCode"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt Biz_Bm__Company_AddAddressPopup_Req">Implementation Date</label>
              <div
                className={
                  formValidationError["txtImplementationDate"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <input
                  disabled={isEditMode}
                  type="date"
                  name="txtImplementationDate"
                  value={formValues.txtImplementationDate}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtImplementationDate"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt">Latitude</label>
              <div
                className={
                  formValidationError["txtLatitude"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <input
                  type="text"
                  name="txtLatitude"
                  value={formValues.txtLatitude}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                  placeholder="Latitude"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtLatitude"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt">Longitude</label>
              <div
                className={
                  formValidationError["txtLongitude"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <input
                  type="text"
                  name="txtLongitude"
                  value={formValues.txtLongitude}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                  placeholder="Longitude"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtLongitude"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt">Landmark</label>
              <div
                className={
                  formValidationError["txtLandmark"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <input
                  type="text"
                  name="txtLandmark"
                  value={formValues.txtLandmark}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                  placeholder="Landmark"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtLandmark"]}</span>
              </div>

              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt">AddressLink</label>
              <div
                className={
                  formValidationError["txtAddressLink"]
                    ? "Biz_Bm__Company_AddAddressPopup_InputGroup Biz_Bm__Company_AddAddressPopup_InputGroup_Error"
                    : "Biz_Bm__Company_AddAddressPopup_InputGroup"
                }
              >
                <input
                  type="text"
                  name="txtAddressLink"
                  value={formValues.txtAddressLink}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  className="Biz_Bm__Company_AddAddressPopup_Inputbox"
                  placeholder="AddressLink"
                />
                <span className="Biz_Bm__Company_AddAddressPopup_validate_ErrorMsg">{formValidationError["txtAddressLink"]}</span>
              </div>
              <label className="Biz_Bm__Company_AddAddressPopup_LabelTxt">Default Address</label>
              <div className="Biz_Bm__Company_AddAddressPopup_InputGroup">
                <label className="Biz_BM__panel_Switch">
                  <input
                    disabled={isEditMode}
                    className="Biz_BM__panel_Switch-input"
                    type="checkbox"
                    checked={formValues.isDefaultAddress}
                    name="isDefaultAddress"
                    onChange={(e) => updateState(e.target.name, !formValues.isDefaultAddress)}
                  />
                  <span className="Biz_BM__panel_Switch-label" data-on="Yes" data-off="No"></span>
                  <span className="Biz_BM__panel_Switch-handle"></span>
                </label>
              </div>
            </div>
          </div>
          <footer className="Biz_Bm__Company_AddAddressPopup_Footer">
            <button type="submit" disabled={checkEditFormControlValues() || btnloaderActive} className={btnloaderActive ? "Biz_Bm__Company_AddAddressPopupBtnActive" : ""}>
              <span className="Biz_Bm__Company_AddAddressPopupBtnTxt">{isEditMode ? "Update" : "Save"}</span>
              <div className="Biz_Bm__Company_AddAddressPopupbtn__spinner">
                <div className="Biz_Bm__Company_AddAddressPopupbtn__bounce1"></div>
                <div className="Biz_Bm__Company_AddAddressPopupbtn__bounce2"></div>
                <div className="Biz_Bm__Company_AddAddressPopupbtn__bounce3"></div>
              </div>
            </button>
            <button type="button" onClick={() => toggleAddCompanyAddressPopup(false, null)}>
              Cancel
            </button>
          </footer>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddCompanyAddressPopup;
