import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import ApiCalling from "../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const GetBankMasterData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.GetBankMasterData);
  return response;
};

export const CreateBankMaster = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.CreateBankMaster);
  return response;
};

export const UpdateBankMaster = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.UpdateBankMaster);
  return response;
};

export const BankMasterStatusUpdate = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.BankMasterStatusUpdate);
  return response;
};

export const divisionData = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling(requestData, APIEndpoints.Master.GetDivision);
  return response;
};
