import React, { useEffect, useState, useMemo, useRef } from "react";
import Splitter from "../../../Framework/Components/Layout/Splitter/Splitter";
import BizClass from "./MenuManagement.module.scss";
import Loader from "../../../Framework/OldFramework/Loader/Loader";
import { addMenuOrSubMenu, getMenuList, getMenuType } from "./Resources/MenuMangementService";
import { getModuleMaster } from "../Module/Resources/moduleService";
import Footer from "../../../Framework/OldFramework/Footer/Footer";
import ErrorMessage from "../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import ConfirmDialog from "../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import { SearchTextLength } from "../../../Configration/Utilities/Constants";
import DataGrid from "../../../Framework/Components/Common/DataGrid/DataGrid";
import { ImBin, ImEye } from "react-icons/im";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import { HiArrowCircleRight, HiPlusCircle } from "react-icons/hi";
import PageBar from "../../../Framework/Components/Layout/Pagebar/PageBar";
import classNames from "classnames";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import AddMenuList from "./Views/AddMenuList/AddMenuList";
import { publicIpv4 } from "public-ip";
import AddMenu from "./Views/AddMenuList/AddMenu/AddMenu";
function MenuManagement() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });
  const [moduleMasterData, setModuleMasterData] = useState([]);
  const [filterModuleMasterData, setFilterModuleMasterData] = useState([]);
  const [isLoadingModuleMasterData, setIsLoadingModuleMasterData] = useState(false);
  const [menuListData, setMenuListData] = useState([]);
  const [menuTypeListData, setMenuTypeListData] = useState([]);
  const [treeMenuListData, setTreeMenuListData] = useState([]);
  const [isLoadingMenuList, setIsLoadingMenuList] = useState(false);

  const [selectedModule, setSelectedModule] = useState({});
  const [submenuPopupData, openSubmenuPopup] = useState({
    open: false,
    data: null,
    isEditMode: false,
  });
  const [addedMenuPopupActiveId, setAddedMenuPopupActiveId] = useState();
  const userData = getSessionStorage("user");
  const insertUserID = userData.User.user.AppAccessID;
  const getModuleMasterData = async () => {
    try {
      setIsLoadingModuleMasterData(true);
      let result = await getModuleMaster();
      setIsLoadingModuleMasterData(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setModuleMasterData(result.responseData);
          setFilterModuleMasterData(result.responseData);
        } else {
          setModuleMasterData([]);
          setFilterModuleMasterData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const getMenuListData = async (moduleMasterId, menuMasterId, action) => {
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      setIsLoadingMenuList(true);
      const ip = await publicIpv4();
      console.log(ip, "IP address");
      const formData = {
        action: action,
        searchText: "#ALL",
        moduleMasterId: moduleMasterId,
        menuMasterId: menuMasterId,
        filterId1: 0,
        objCommon: {
          insertUserId: insertUserID,
          insertIpAddress: ip,
        },
      };
      let result = await getMenuList(formData);
      setIsLoadingMenuList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setMenuListData(result.responseData);
          console.log(result.responseData);
        } else {
          setMenuListData([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [addMenuPopup, setAddMenuPopup] = useState({
    isOpen: false,
    popupData: null,
  });
  const toggleAddMenu = (data) => {
    setAddMenuPopup({
      isOpen: !addMenuPopup.isOpen,
      popupData: data,
    });
  };
  const updateMenu = (data) => {
    treeMenuListData.unshift(data);
    setTreeMenuListData(treeMenuListData);
    if (MenuListGridApi) {
      MenuListGridApi.setRowData(treeMenuListData);
    }
  };

  const onRefreshClick = () => {
    setTreeMenuListData([]);
    getModuleMasterData();
  };

  useEffect(() => {
    getModuleMasterData();
  }, []);

  const onDisplayClick = (moduleData) => {
    console.log(moduleData, "&&&&&&&&&&&&&&&&&moduleData&&&&&&&&&&&&&&&");
    getMenuListData(moduleData.ModuleCode, "0", "MENULIST");
    setSelectedModule(moduleData);
  };

  const updateMenuList = (subMenu, isEditMode) => {
    debugger;
    console.log(subMenu, "subMenu");
    if (isEditMode) {
      let index = treeMenuListData.findIndex((x) => x.MenuMasterID === subMenu.MenuMasterID);
      treeMenuListData[index].MenuName = subMenu.MenuName;
      treeMenuListData[index].MenuSequence = subMenu.MenuSequence;
      treeMenuListData[index].MenuType = subMenu.MenuType;
      treeMenuListData[index].UnderMenuID = subMenu.UnderMenuID;
      treeMenuListData[index].APIURL = subMenu.APIURL;
      treeMenuListData[index].AppURL = subMenu.AppURL;
      treeMenuListData[index].ReactURL = subMenu.ReactURL;
      treeMenuListData[index].WebURL = subMenu.WebURL;
      treeMenuListData[index].WPFURL = subMenu.WPFURL;
      treeMenuListData[index].WinURL = subMenu.WinURL;
      treeMenuListData[index].HasChild = subMenu.HasChild;
      treeMenuListData[index].isUpdated = true;
      treeMenuListData[index].isNewlyAdded = false;
      setTreeMenuListData(treeMenuListData);
      if (MenuListGridApi) {
        MenuListGridApi.setRowData(treeMenuListData);
      }
    } else {
      treeMenuListData.push(subMenu);
    }
    setTreeMenuListData(treeMenuListData);
    if (MenuListGridApi) {
      MenuListGridApi.setRowData(treeMenuListData);
    }
  };

  const buildHierarchy = () => {
    let menuTreeListData = [];
    menuListData.forEach((menu) => {
      let menuData = {
        MenuMasterID: menu.MenuMasterID,
        MenuName: menu.MenuName,
        ReactURL: menu.ReactURL,
        ModuleMasterID: menu.ModuleMasterID,
        WebURL: menu.WebURL,
        IsNewlyAdded: menu.IsNewlyAdded,
        orgHierarchy: [menu.MenuMasterID],
        HasChild: menu.HasChild,
        APIURL: menu.APIURL,
        AppURL: menu.AppURL,
        MenuSequence: menu.MenuSequence,
        MenuType: menu.MenuType,
        MenuTypeName: menu.MenuTypeName,
        UnderMenuID: menu.UnderMenuID,
        WPFURL: menu.WPFURL,
        WinURL: menu.WinURL,
        ModuleName: menu.ModuleName,
        InsertUser: menu.InsertUser,
        InsertDateTime: menu.InsertDateTime,
      };
      if (menu.UnderMenuID.toString() !== "0") {
        const parentMenu = menuListData.find((x) => x.MenuMasterID.toString() === menu.UnderMenuID.toString());
        if (parentMenu !== null && parentMenu !== undefined) {
          menuData.orgHierarchy.push(parentMenu.MenuMasterID);
          recHierarchy(menuData, parentMenu);
        }
      }

      menuTreeListData.push(menuData);
    });

    menuTreeListData.forEach((menu) => {
      menu.orgHierarchy = menu.orgHierarchy.reverse();
    });
    console.log(menuTreeListData, "**menuTreeListData**");
    setTreeMenuListData(menuTreeListData);
  };

  const recHierarchy = (menu, parent) => {
    if (parent.UnderMenuID.toString() !== 0) {
      var parentMenu = menuListData.find((x) => x.MenuMasterID.toString() === parent.UnderMenuID.toString());
      if (parentMenu !== null && parentMenu != undefined) {
        menu.orgHierarchy.push(parentMenu.MenuMasterID);
        recHierarchy(menu, parentMenu);
      }
    }
  };

  const [recentAddedMenuId, setRecentAddedMenuId] = useState(null);
  useEffect(() => {
    if (menuListData != null && menuListData.length > 0) {
      buildHierarchy();
    } else {
      setTreeMenuListData([]);
    }
  }, [menuListData]);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       if (OnSetupMenuListGridReady && treeMenuListData.length > 0 && recentAddedMenuId) {
  //         OnSetupMenuListGridReady.forEachNode(function (rowNode) {
  //           if (rowNode.data.MenuMasterID.toString() === recentAddedMenuId.toString()) {
  //             OnSetupMenuListGridReady.ensureIndexVisible(rowNode.rowIndex);
  //             setRecentAddedMenuId(null);
  //           }
  //         });
  //       }
  //     }, 200);
  //   }, [treeMenuListData]);

  const [moduleMasterGridApi, setModuleMasterGridApi] = useState();
  const onModuleMasterGridReady = (params) => {
    setModuleMasterGridApi(params.api);
  };

  const [searchTextModuleMaster, setSearchTextModuleMaster] = useState("");
  const onSearchMasterCommonCode = (val) => {
    setSearchTextModuleMaster(val);
    moduleMasterGridApi.setQuickFilter(val);
    moduleMasterGridApi.refreshCells();
    setModuleMasterData([]);
  };

  const onClickSearchModuleMaster = () => {
    // if (searchTextModuleMaster && searchTextModuleMaster.length >= SearchTextLength) {
    getModuleMaster();
    setModuleMasterData([]);
    // } else {
    //   setModuleMasterData([]);
    //   setMsgAlert({
    //     open: true,
    //     type: "warning",
    //     msg: "Please type at least 3 character.",
    //   });
    //   return;
    // }
  };

  const [MenuListGridApi, setMenuListGridApi] = useState();
  const OnSetupMenuListGridReady = (params) => {
    setMenuListGridApi(params.api);
  };

  const [searchTextMenuList, setSearchTextMenuList] = useState("");
  const onSearchMasterDataFile = (val) => {
    setSearchTextMenuList(val);
    MenuListGridApi.setQuickFilter(val);
    MenuListGridApi.refreshCells();
  };

  //   const onClickSearchMasterDataFile = () => {
  //     if (selectedModuleMaster && selectedModuleMaster.ModuleMasterId) {
  //       getModuleMasterData(selectedModuleMaster.ModuleMasterId);
  //     } else {
  //       setMsgAlert({
  //         open: true,
  //         type: "warning",
  //         msg: "Please Select  Master Code.",
  //       });
  //       return;
  //     }
  //   };

  const getRowStyle = (data) => {
    if (data?.data?.IsSelected) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isNewlyAdded) {
      return { background: "#ffc176" };
    }
    if (data?.data?.isUpdated) {
      return { background: "#ffd40085" };
    }
    return { background: "" };
  };

  const moduleGridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: "1",
      cellRenderer: "actionTemplate",
      cellRendererParams: { onDisplayClick, toggleAddMenu, setAddMenuPopup },
    },
    {
      field: "ModuleCode",
      headerName: "Module Code",
      width: 180,
      pinned: "left",
      lockPosition: "1",
    },
    {
      field: "ModuleName",
      headerName: "Module Name",
      width: 180,
      pinned: "left",
    },
  ];
  const menuListGridColumnDefs = [
    { field: "#", headerName: "Action", width: 180, pinned: "left", lockPosition: 1, cellRenderer: "actionTemplate", cellRendererParams: { onDisplayClick, openSubmenuPopup } },
    {
      field: "MenuName",
      hide: true,
      headerName: "Menu Name",
      width: 180,
      pinned: "left",
      lockPosition: 2,
    },
    {
      field: "MenuTypeName",
      headerName: "Menu Type",
      width: 120,
    },
    {
      field: "WebURL",
      headerName: "Web Url",
      width: 120,
    },
    {
      field: "ReactURL",
      headerName: "React Url",
      width: 120,
    },
    {
      field: "WPFURL",
      headerName: "WPF Url",
      width: 120,
    },
  ];

  return (
    <div className={classNames(BizClass.Biz_Bm_Manage_Menu_page, BizClass.Biz_Bm__PageStart)}>
      {msgAlert.open && <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />}
      {confirmAlert.open && <ConfirmDialog confirmAlert={confirmAlert} setConfirmAlert={setConfirmAlert} />}
      {submenuPopupData.open ? (
        <AddMenuList
          isEditMode={submenuPopupData.isEditMode}
          updateMenuList={updateMenuList}
          moduleData={selectedModule}
          menuData={submenuPopupData.data}
          openSubmenuPopup={openSubmenuPopup}
          submenuPopupData={submenuPopupData}
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
          getMenuListData={getMenuListData}
        />
      ) : null}
      {addMenuPopup.isOpen ? (
        <AddMenu
          updateMenuList={updateMenuList}
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
          toggleAddMenu={toggleAddMenu}
          menuData={addMenuPopup.popupData}
          getMenuListData={getMenuListData}
        />
      ) : null}
      <div className={BizClass.Biz_Bm_Manage_Menu_ContentBox}>
        <Splitter varient="column" template="1fr 9px 2fr">
          <div className={BizClass.MainSection}>
            <PageBar title="Menu Management">
              <PageBar.Search focus={true} onClick={() => onRefreshClick()} value={searchTextModuleMaster} onChange={(e) => onSearchMasterCommonCode(e.target.value)} />
              {/* <PageBar.Button onClick={() => onRefreshClick()}>Reload</PageBar.Button> */}
            </PageBar>

            <DataGrid
              rowData={filterModuleMasterData}
              columnDefs={moduleGridColumnDefs}
              onGridReady={onModuleMasterGridReady}
              onDisplayClick={onDisplayClick}
              animateRows={true}
              loader={isLoadingModuleMasterData ? <Loader /> : null}
              getRowStyle={function (data) {
                if (data.data.IsSelected) {
                  return { background: "#ffc176" };
                } else {
                  if (data.data.IsNewlyAdded) {
                    return { background: "#d5a10e" };
                  }
                  return { background: "" };
                }
              }}
              frameworkComponents={{
                actionTemplate: cellTemplate,
              }}
            />
          </div>
          <div className={BizClass.MainSection}>
            <PageBar title={` ${selectedModule && selectedModule.ModuleName ? `(${selectedModule.ModuleName}) Menu List` : "Menu List"}`}>
              <PageBar.Search value={searchTextMenuList} onClick={() => onDisplayClick(selectedModule)} onChange={(e) => onSearchMasterDataFile(e.target.value)} />
            </PageBar>
            <DataGrid
              getRowStyle={getRowStyle}
              onGridReady={OnSetupMenuListGridReady}
              columnDefs={menuListGridColumnDefs}
              rowData={treeMenuListData}
              autoGroupColumnDef={{
                headerName: "Menu Name",
                minWidth: 300,
                lockPosition: 2,
                cellRendererParams: {
                  innerRenderer: function (params) {
                    return params.data.MenuName;
                  },
                },
              }}
              loader={isLoadingMenuList ? <Loader /> : null}
              frameworkComponents={{
                actionTemplate: actionTemplate,
              }}
              openSubmenuPopup={openSubmenuPopup}
              treeData={true}
              animateRows={true}
              groupDefaultExpanded={-1}
              getDataPath={(data) => {
                return data.orgHierarchy;
              }}
            />
          </div>
        </Splitter>
        <Footer />
      </div>
    </div>
  );
}

export default MenuManagement;

const cellTemplate = (props) => {
  let menuData = props.data;
  const { setAddMenuPopup } = props;
  const onDisplayClick = () => {
    console.log("***********Selected Data***********", props.data);
    props.onDisplayClick(props.data);
  };

  const Addmenu = () => {
    console.log(menuData, "popUpData");
    setAddMenuPopup({
      isOpen: true,
      popupData: menuData,
    });
  };

  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "2px" }}>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="View File"
      >
        <ImEye style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => onDisplayClick()} />
      </span>
      <span
        style={{
          cursor: "pointer",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="Add Menu"
      >
        <AiOutlineFileAdd style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }} onClick={() => Addmenu()} />
      </span>
    </div>
  );
};

const actionTemplate = (props) => {
  let menuData = props.data;
  const { openSubmenuPopup } = props;
  const onDeleteClick = () => {};
  const onAddClick = () => {
    debugger;
    openSubmenuPopup({
      open: true,
      data: menuData,
      isEditMode: false,
    });
  };
  const onEditClick = () => {
    openSubmenuPopup({
      open: true,
      data: menuData,
      isEditMode: true,
    });
  };

  return (
    <React.Fragment>
      {menuData.HasChild === 1 ? (
        <div>
          <span style={{ cursor: "pointer", paddingRight: "8px" }}>
            {" "}
            <HiPlusCircle className="Biz_Bm__Icon" onClick={() => onAddClick()} />
          </span>
          <span style={{ cursor: "pointer", paddingRight: "8px" }}>
            <FaEdit className="Biz_Bm__Icon" onClick={() => onEditClick()} />{" "}
          </span>
          {/* <span>
            <FaTrashAlt className="Biz_Bm__Icon" onClick={() => onDeleteClick()} />
          </span> */}
        </div>
      ) : (
        <div>
          <span style={{ cursor: "pointer", paddingRight: "8px" }}>
            <FaEdit className="Biz_Bm__Icon" onClick={() => onEditClick()} />{" "}
          </span>
          {/* <FaTrashAlt className="Biz_Bm__Icon" onClick={() => onDeleteClick()} /> */}
        </div>
      )}
    </React.Fragment>
  );
};
