/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
import React, { useEffect, useState } from "react";
import "../AddressAssignment/AddressAssignment.scss";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Modal from "../../../../../Framework/Components/Layout/Modal/Modal";
import PageBar from "../../../../../Framework/Components/Layout/Pagebar/PageBar";
import Button from "../../../../../Framework/Components/Widgets/Button/Button";
import { companyAddressRelationInsert, getCompanyAddressRelationSelect } from "../../../Resources/companyRegistrationService";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import { ImEye } from "react-icons/im";
import ViewAddressRelationHistory from "../ViewAddressRelationHistory/ViewAddressRelationHistory";

const UnAssignedAddressPopup = (props) => {
  const toggleUnAssignedAddressPopup = props.toggleUnAssignedAddressPopup;
  const selectedCompanyMaster = props.selectedCompanyMaster;
  const address = props.address;
  const selectedLocationType = props.selectedLocationType;
  const deletedAssignedAddress = props.deletedAssignedAddress;
  const setMsgAlert = props.setMsgAlert;
  const selectedTab = props.selectedTab;

  const [unAssignedAddressList, setUnAssignedAddressList] = useState([]);
  const [filteredUnAssignedAddressList, setFilteredUnAssignedAddressList] = useState([]);
  const [isLoadingUnAssignedAddress, setIsLoadingUnAssignedAddress] = useState(false);
  console.log("props", props);
  const getUnAssignedCompanyAddressToCompanyAddress = async (accountingBranchId, pType, companyMasterId) => {
    console.log("props", props);
    debugger;
    try {
      setIsLoadingUnAssignedAddress(true);
      let warehouseMasterId = address.CompanyAddressID;
      let viewMode = "";
      if (pType === "ACCWAR") {
        viewMode = "WUNASSIGN";
      } else if (pType === "PRDWAR") {
        viewMode = "PPUNASSIGN";
      }
      debugger;
      const result = await getCompanyAddressRelationSelect(accountingBranchId ? accountingBranchId : 0, companyMasterId ? companyMasterId : 0, viewMode, warehouseMasterId);
      console.log("result", result);
      setIsLoadingUnAssignedAddress(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          const mappedData = result.responseData.map((data, i) => {
            return { ...data, IsChecked: false };
          });
          setUnAssignedAddressList(mappedData);
          setFilteredUnAssignedAddressList(mappedData);
          console.log("mappedData", mappedData);
        } else {
          setUnAssignedAddressList([]);
          setFilteredUnAssignedAddressList([]);
        }
      } else {
        setUnAssignedAddressList([]);
        setFilteredUnAssignedAddressList([]);
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const [formValues, setFormValues] = useState({
    txtAccountingBranchList: "",
  });

  const [gridApi, setGridApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSearchUnAssignedAddress = (val) => {
    console.log(val, "value");
    if (val) {
      const filteredData = unAssignedAddressList.filter((data) => {
        return data.AddressDisplayName.toLowerCase().includes(val.toLowerCase());
      });
      setUnAssignedAddressList(filteredData);
    } else {
      setUnAssignedAddressList(filteredUnAssignedAddressList);
    }
  };

  useEffect(() => {
    if (props.selectedLocationType && props.selectedLocationType.code !== "") {
      if (props.address && props.address.CompanyAddressID)
        getUnAssignedCompanyAddressToCompanyAddress(props.address.CompanyAddressID, selectedLocationType.value, selectedCompanyMaster.CompanyMasterID);
    }
  }, [deletedAssignedAddress]);

  const getSelectedRowData = () => {};

  const [btnLoaderActive, setBtnLoaderActive] = useState(false);
  const handleSave = async (e) => {
    debugger;
    const userData = getSessionStorage("user");
    const insertUserID = userData.User.user.AppAccessID;
    try {
      if (e) e.preventDefault();
      const ip = await publicIpv4();
      const checkedItem = getSelectedRowData();
      const checkedItems = unAssignedAddressList.filter((row) => row.isChecked).map((row) => row.WarehouseMasterID);
      if (checkedItem && checkedItem.length === 0) {
        setMsgAlert({
          open: true,
          type: "warning",
          msg: "Please select atleast one location.",
        });
        return;
      } else {
        debugger;

        // const aasignID = checkedItem.map((data) => data.CompanyAddressID).join(",");
        console.log(props.selectedLocationType, "props.selectedLocationType");
        let formData;
        if (props.selectedLocationType.value === "ACCWAR") {
          formData = {
            action: "Assign",
            addressRelationId: "0",
            addressRelationType: props.selectedLocationType.value.toString(),
            companyMasterId: selectedCompanyMaster.CompanyMasterID,
            accountingBranchId: props.address.CompanyAddressID,
            warehouseMasterId: checkedItems.toString(),
            productionPlantId: "0",
            storeId: 0,
            objCommon: {
              insertUserId: insertUserID,
              insertIpAddress: ip,
            },
          };
        }
        if (props.selectedLocationType.value === "PRDWAR") {
          formData = {
            action: "Assign",
            addressRelationId: "0",
            addressRelationType: props.selectedLocationType.value,
            companyMasterId: selectedCompanyMaster.CompanyMasterID,
            accountingBranchId: "0",
            warehouseMasterId: checkedItems.toString(),
            productionPlantId: props.address.CompanyAddressID.toString(),
            storeId: "0",
            objCommon: {
              insertUserId: insertUserID,
              insertIpAddress: ip,
            },
          };
        }
        console.log("formData", formData);
        const result = await companyAddressRelationInsert(formData);

        console.log("result", result);

        setBtnLoaderActive(false);
        if (result.responseCode === 1) {
          console.log(result.responseData);

          setMsgAlert({
            open: true,
            type: "success",
            msg: result.responseMessage,
          });
          getUnAssignedCompanyAddressToCompanyAddress(props.address.CompanyAddressID, selectedLocationType.value, selectedCompanyMaster.CompanyMasterID);
          props.getCompanyAddressAssignedToCompanyAddress(
            props.address.CompanyAddressID,
            selectedTab && selectedTab.value ? selectedTab.value : "ACCWAR",
            selectedCompanyMaster.CompanyMasterID,
          );
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowChange = (event, WarehouseMasterID) => {
    const updatedData = unAssignedAddressList.map((row) => {
      if (row.WarehouseMasterID === WarehouseMasterID) {
        return { ...row, isChecked: event.target.checked };
      }
      return row;
    });
    setUnAssignedAddressList(updatedData);
  };

  const companyAddressColnDef = [
    {
      lockPosition: "1",
      pinned: "left",
      headerName: "",
      field: "",
      width: 60,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    { field: "#", headerName: "Sr No.", width: 80, valueGetter: "node.rowIndex + 1", lockPosition: "1" },
    { field: "AddressDisplayName", headerName: "Display Name", width: 150 },
  ];

  const [historyPopupActive, sethistoryPopupActive] = useState({
    IsOpen: false,
    popupData: null,
    viewMode: "",
  });

  const toggleHistory = (data, viewMode) => {
    sethistoryPopupActive({
      IsOpen: !historyPopupActive.IsOpen,
      popupData: data,
      viewMode: viewMode,
    });
  };
  return (
    <>
      {historyPopupActive.IsOpen ? (
        <ViewAddressRelationHistory
          show={toggleHistory}
          viewMode={historyPopupActive.viewMode}
          setMsgAlert={setMsgAlert}
          popupData={historyPopupActive.popupData}
          address={address}
          selectedCompanyMaster={selectedCompanyMaster}
          selectedLocationType={selectedLocationType}
        />
      ) : null}
      <Modal onSubmit={handleSave} varient={"bottom"} title="Address Un-Assigned List" show={toggleUnAssignedAddressPopup} left={0} width={"49.5vw"} top={0}>
        <Modal.Body>
          <div className="Card">
            <PageBar>
              <PageBar.Search
                onClick={() => getUnAssignedCompanyAddressToCompanyAddress(props.address.CompanyAddressID, selectedLocationType.value, selectedCompanyMaster.CompanyMasterID)}
                onChange={(e) => onSearchUnAssignedAddress(e.target.value)}
              />
            </PageBar>

            <div className="TeamManagement__Data_ContentBox" style={{ width: "100%" }}>
              <table style={{ overflow: "hidden" }} className="TeamManagement__Data_ContentBoxTable">
                <thead className="TeamManagement__Data_ContentBoxThead">
                  <tr>
                    <th>Select</th>
                    <th>Action</th>
                    <th>Sr No.</th>
                    <th>Display Name</th>
                  </tr>
                </thead>

                <tbody>
                  {unAssignedAddressList.map((row, i) => (
                    <>
                      <tr key={i}>
                        <td style={{ textAlign: "center" }}>
                          <input type="checkbox" checked={row.isChecked} onChange={(e) => handleRowChange(e, row.WarehouseMasterID)} />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <ImEye title="View History" style={{ fontSize: "18px", color: "#34495E" }} onClick={() => toggleHistory(row, "UnAssigned")} />
                        </td>
                        <td style={{ textAlign: "center" }}>{i + 1}</td>
                        <td style={{ textAlign: "center" }}>{row.AddressDisplayName}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button varient={"secondary"} type="submit" trigger={btnLoaderActive}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UnAssignedAddressPopup;
